import React, { ReactNode } from 'react';
// utils
import cn from 'classnames';
// styles
import styles from './BadgeColumn.module.scss';
// types
import { ColumnRenderType } from 'types';

const isEmptyData = (data?: string | number | ReactNode) => !data;

const BadgeColumn = ({ data, placeholder, className, isEmpty = isEmptyData }: ColumnRenderType) => (
  <span className={cn(styles['badge'], className)}>{!isEmpty(data) ? data : placeholder}</span>
);

export default BadgeColumn;
