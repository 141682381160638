import React from 'react';
// components
import Input from '../Input';
// utils
import cn from 'classnames';
// types
import { IconInputProps } from '../Input.types';
// styles
import styles from './IconInput.module.scss';

const IconInput: React.FC<IconInputProps> = ({
  icon,
  isIconRight,
  className,
  inputClassName,
  ...props
}) => (
  <div className={cn(styles.wrapper, className, { [styles['icon-right']]: isIconRight })}>
    <span className={styles.icon}>{icon}</span>
    <Input {...props} className={cn(styles['icon-input'], inputClassName)} />
  </div>
);

export default IconInput;
