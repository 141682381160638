import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import TruckingCompanyCard from 'components/TruckingCompanyCard';
import TruncatedText from 'components/TruncatedText';
import LabelValueRow from 'components/LabelValueRow';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';
import { ReactComponent as MoneyIcon } from 'icons/money.svg';
// types
import { TruckingViewSectionProps } from '../../LoadSections.types';
// styles
import styles from './TruckingViewSection.module.scss';

const TruckingViewSection: React.FC<TruckingViewSectionProps> = ({
  rate,
  rateType,
  truckingCompany,
  className,
}) => (
  <div className={className}>
    <div className={styles['trucking-company-title']}>
      <FormattedMessage id="general.truckingCompany" />
    </div>
    <div className={styles['trucking-company-name']}>
      <div className={styles['trucking-company-logo']}>
        <TruckIcon width={15} height={15} />
      </div>
      <TruncatedText hasTooltip maxWidth={350} size="small" text={truckingCompany.name} />
    </div>
    <TruckingCompanyCard truckingCompany={truckingCompany} />
    <LabelValueRow
      className="mt-10 mb-5"
      label={<FormattedMessage id="load.truckingRate" />}
      value={rate}
      icon={MoneyIcon}
    />
    <LabelValueRow
      className="mb-5"
      label={<FormattedMessage id="load.truckingRateType" />}
      value={rateType}
      icon={MoneyIcon}
    />
  </div>
);

export default TruckingViewSection;
