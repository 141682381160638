import React from 'react';
// utils
import classnames from 'classnames/bind';
// types
import { NavBarProps, NavItemType } from './NavBar.types';
// styles
import styles from './NavBar.module.scss';

const cn = classnames.bind(styles);

function NavBar<T extends NavItemType>({
  items,
  className = '',
  children,
  height = 45,
  onSelect,
  currentValue,
  unbordered,
  isSticky,
  isActive = ({ value }) => value === currentValue,
}: NavBarProps<T>) {
  const itemStyle = { height, lineHeight: height + 'px' };
  return (
    <div className={cn('navbar-container', { sticky: isSticky, [className]: !!className })}>
      <div className={cn('navbar-section', { unbordered })}>
        {items.map((item, idx) => (
          <span
            key={idx}
            onClick={() => onSelect(item)}
            className={cn('navbar-item', { active: isActive(item) })}
            style={itemStyle}
          >
            {item.label}
          </span>
        ))}
      </div>
      {children && <div className="mt-25">{children}</div>}
    </div>
  );
}

export default NavBar;
