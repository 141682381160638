import React, { useState } from 'react';
// components
import Select from '../Select';
// hooks
import { useQuery } from 'react-query';
// types
import { StatesSelectType, OptionKeysTypes } from '../Select.types';

const StatesSelect = ({ value, onChange, placeholder, isSmall }: StatesSelectType) => {
  const [isFetchEnabled, setIsFetchEnabled] = useState(false);
  const { isFetching, data } = useQuery(
    'states',
    async () => {
      const data = await import('./states.json');
      return data.default;
    },
    { enabled: isFetchEnabled }
  );

  const handleFocus = () => {
    if (!data) setIsFetchEnabled(true);
  };

  return (
    <Select
      placeholder={placeholder}
      value={data?.find(option => option?.value === value)}
      onChange={option => onChange((option as OptionKeysTypes)?.value)}
      onFocus={handleFocus}
      options={data}
      isLoading={isFetching}
      isSearchable
      isClearable
      isSmall={isSmall}
    />
  );
};

export default StatesSelect;
