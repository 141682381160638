import React, { ChangeEvent } from 'react';
// components
import Input from 'components/Input';
import { ReactComponent as AngleUpIcon } from 'icons/angle-up.svg';
import { ReactComponent as AngleDownIcon } from 'icons/angle-down.svg';
// utils
import classnames from 'classnames/bind';
// types
import { NumberInputProps } from './NumberInput.types';
// styles
import styles from './NumberInput.module.scss';

const cn = classnames.bind(styles);

const NumberInput: React.FC<NumberInputProps> = ({
  className,
  setValue,
  placeholder = '00',
  value = '',
  disabled,
  name = '',
  step = 1,
  min = 0,
  max = 99,
  maxLength = 2,
}) => {
  const onUpClick = () => {
    const newValue = Number(value) + step;
    if (newValue > max || disabled) return;
    setValue(newValue, name);
  };

  const onDownClick = () => {
    const newValue = Number(value) - step;
    if (newValue < min || disabled) return;
    setValue(newValue, name);
  };

  const formatValue = (value: string | number, maxLength: number) => {
    if (value === '') return value;

    return String(value).padStart(maxLength, '0');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      value,
      name,
      validity: { valid },
    } = e.target;

    if (!valid) return;

    const formattedValue = Number(value).toString();
    if (value.length > maxLength && formattedValue.length <= maxLength) {
      setValue(formattedValue, name);
      return;
    }

    setValue(value, name);
  };

  const inputProps = { step, name, min, max, placeholder, disabled };
  return (
    <div className={cn('number-input-wrapper', className)}>
      <Input
        type="number"
        className={cn('number-input')}
        {...inputProps}
        pattern="[0-9]*"
        value={formatValue(value, maxLength)}
        onChange={handleChange}
      />
      <div className={cn('input-btn-group')}>
        <div onClick={onUpClick} className={cn('icon-wrapper')}>
          <AngleUpIcon className={cn('number-btn-icon')} />
        </div>
        <div onClick={onDownClick} className={cn('icon-wrapper')}>
          <AngleDownIcon className={cn('number-btn-icon')} />
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
