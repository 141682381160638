import { DateTime } from 'luxon';
import { LoadCommitment } from 'types';
import { getCommitmentTimeToLoad } from 'utils/loadHelper';

export const calculateEstimatedTime = (
  currentCommitmentIndex: number,
  commitmentsList: LoadCommitment[]
) => {
  if (currentCommitmentIndex === 0) {
    return '';
  }

  let baseTime = '';
  let travelTime = 0;

  for (let i = currentCommitmentIndex - 1; i >= 0; i--) {
    const currentCommitment = commitmentsList[i];
    const { travel_seconds, arrive_at } = currentCommitment;
    const timeToLoadInMinutes = getCommitmentTimeToLoad(currentCommitment);
    travelTime += (travel_seconds || 0) + timeToLoadInMinutes * 60;

    if (arrive_at) {
      baseTime = arrive_at;
      break;
    }
  }

  return DateTime.fromISO(baseTime)
    .plus({ seconds: travelTime })
    .toISO();
};

export const calculateSaleEstimatedTime = (
  currentCommitmentIndex: number,
  commitmentsList: LoadCommitment[]
) => {
  const currentCommitment = commitmentsList[currentCommitmentIndex];
  const { travel_seconds } = currentCommitment;
  const timeToLoadInMinutes = getCommitmentTimeToLoad(currentCommitment);

  let baseTime = '';
  let travelTime = (travel_seconds || 0) + timeToLoadInMinutes * 60;

  for (let i = currentCommitmentIndex + 1; i < commitmentsList.length; i++) {
    const commitment = commitmentsList[i];
    const { travel_seconds, arrive_at } = commitment;

    if (arrive_at) {
      baseTime = arrive_at;
      break;
    }
    const timeToLoadInMinutes = getCommitmentTimeToLoad(commitment);

    travelTime += (travel_seconds || 0) + timeToLoadInMinutes * 60;
  }

  return DateTime.fromISO(baseTime)
    .minus({ seconds: travelTime })
    .toISO();
};
