import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// hooks
import { useHistory } from 'react-router';
import useMeasurementSystem from './useMeasurementSystem';
// requests
import { getCsvByLoadIds } from 'requests/loads';
// utils
import { saveAs } from 'file-saver';
import {
  getMatchingLoadLink,
  isLoadEditable,
  isLoadDraftOrProcessing,
  isLoadCanBeResetOrUnmatched,
  loadDeclineStatuses,
} from 'utils/loadHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// types
import { ConnectedLoadProps } from 'store/connectors/loadDataConnector';

export default function useLoadDropdownOptions(
  props: ConnectedLoadProps & {
    handleLoadDecline?: () => void;
    handleLoadUnmatch?: () => void;
    handleLoadReset?: () => void;
  }
) {
  const { push, location } = useHistory();
  const { measurementSystem } = useMeasurementSystem();
  const {
    acceptByTrucking,
    acceptCommitment,
    addPreFilledSalesResult,
    load,
    handleLoadDecline,
    handleLoadUnmatch,
    handleLoadReset,
    makeLoadAsArchived,
    makeLoadAsUnArchived,
  } = props;

  if (load) {
    const exportCsv = () =>
      getCsvByLoadIds([load.id], load.archived)
        .then(data => saveAs(data, `loading-${load.name}.csv`))
        .catch(toastResponseErrors);
    const matchingLoadLink = load.plan_id ? getMatchingLoadLink(load, load.plan_id) : '';
    const handlePushToWorksheet = () => {
      push(matchingLoadLink, { prevPathname: location.pathname });
    };

    return [
      {
        label: <FormattedMessage id="general.moveToArchive" />,
        itemProps: {
          onClick: () => makeLoadAsArchived(load.id),
        },
        isHidden: !load.allow_archive,
      },
      {
        label: <FormattedMessage id="general.moveFromArchive" />,
        itemProps: {
          onClick: () => makeLoadAsUnArchived(load.id),
        },
        isHidden: !load.allow_unarchive,
      },
      {
        label: <FormattedMessage id="loads.enterSalesResults" />,
        itemProps: {
          onClick: () => addPreFilledSalesResult(load, measurementSystem),
        },
        isHidden: !load.allow_sales_results_create || !!load.sales_results,
      },
      {
        label: <FormattedMessage id="general.continueMatching" />,
        itemProps: {
          onClick: handlePushToWorksheet,
        },
        isHidden: !matchingLoadLink || !isLoadDraftOrProcessing(load),
      },
      {
        label: <FormattedMessage id="general.editLoad" />,
        itemProps: {
          onClick: handlePushToWorksheet,
        },
        isHidden: !matchingLoadLink || !isLoadEditable(load),
      },
      {
        label: <FormattedMessage id="general.unmatchLoad" />,
        itemProps: {
          onClick: handleLoadUnmatch,
        },
        isHidden: !isLoadCanBeResetOrUnmatched(load),
      },
      {
        label: <FormattedMessage id="modal.loadModal.resetLoad" />,
        itemProps: {
          onClick: handleLoadReset,
        },
        isHidden: !isLoadCanBeResetOrUnmatched(load),
      },
      {
        label: <FormattedMessage id="loads.declineLoad" />,
        itemProps: {
          onClick: handleLoadDecline,
        },
        isHidden: !loadDeclineStatuses.includes(load.status) || !!load.edit_loading_id,
      },
      ...(load.commitments || []).map(commitment => ({
        label: (
          <FormattedMessage
            id="modal.loadModal.commitmentForceAccept"
            values={{ farmName: <span className="bold mh-5">{commitment.farm?.name}</span> }}
          />
        ),
        itemProps: {
          onClick: () => acceptCommitment(load.id, commitment.id),
        },
        isHidden: !commitment.can_force_accept || commitment.status === 'accepted',
      })),
      {
        label: <FormattedMessage id="modal.loadModal.truckingForceAccept" />,
        itemProps: {
          onClick: () => acceptByTrucking(load.id),
        },
        isHidden: !load.can_trucking_force_accept || load.trucking_company_status === 'accepted',
      },
      {
        label: <FormattedMessage id="general.exportData" />,
        itemProps: {
          onClick: exportCsv,
        },
      },
    ];
  }

  return [];
}
