import { toastErrorNotifier } from 'components/ToastContent/toastNotifier';
import { capitalize } from 'utils';

type ErrorType = {
  title: string;
  meta: { attribute: string };
};

type ResponseType<T> = {
  status?: number;
  data: {
    errors?: T[];
    error?: string;
  };
};

function formatError(error: ErrorType | string) {
  if (typeof error === 'object') {
    const { title, meta } = error;
    return meta ? `${capitalize(meta.attribute)}: ${title}` : capitalize(title);
  }
  return capitalize(error);
}

export function toastResponseErrors(
  response: ResponseType<ErrorType | string>,
  customText?: string | JSX.Element
) {
  const { data } = response;

  if (customText) {
    toastErrorNotifier(customText);
    return;
  }

  if (Array.isArray(data?.errors)) {
    const errorsArr = data.errors.map(formatError);
    toastErrorNotifier(errorsArr.join(', '));
    return;
  }

  if (typeof data?.error === 'string') {
    return toastErrorNotifier(data.error);
  }

  console.warn(response);
}
