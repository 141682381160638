import React, { ChangeEvent, useEffect, useState } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import CompanyAvatar from 'components/CompanyAvatar';
import FieldHighlighter from 'components/FieldHighlighter';
import FormInput from 'components/Input/FormInput';
import IconAsyncSelect from 'components/Select/AsyncSelect/IconAsyncSelect';
import IconInput from 'components/Input/IconInput';
import IconSelect from 'components/Select/IconSelect';
import Loader from 'components/Loader';
import SuggestionBox from 'components/SuggestionBox';
import TruckBadge from 'components/TruckBadge';
import TruckingCompanyCard from 'components/TruckingCompanyCard';
import { ReactComponent as MoneyIcon } from 'icons/money.svg';
// hooks
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
// api requests
import { getTruckingTerm } from 'requests/loads';
// utils
import cn from 'classnames';
import { bold } from 'utils';
import { getRateTypeOptions } from '../utils';
import { applyThousandSeparation } from 'components/Input/NumberInput/helpers';
// types
import { TruckingCompanySectionTypes } from './LoadFormSections.types';
import { ValueType } from 'react-select';
import { OptionKeysTypes } from 'components/Select/Select.types';
import { Load, RateType, TruckingCompany, TruckingTerm } from 'types';
// styles
import styles from '../LoadMatchingForm.module.scss';

const selectPlaceholder = (
  <div className={styles['select-placeholder']}>
    <TruckBadge className="mr-10" />
    <FormattedMessage id="general.none" />
  </div>
);

function getSectionState(load: Load) {
  return {
    rate: load.rate ? Number(load.rate).toFixed(2) : null,
    rateType: load.rate_type,
    skipTrucking: load.skip_trucking_company,
    truckingCompany: load.trucking_company,
  };
}

const TruckingSection = ({
  isAllCommitmentsMatched,
  load,
  setIsSectionEditing,
  updateLoad,
}: TruckingCompanySectionTypes) => {
  const [{ rate, rateType, skipTrucking, truckingCompany }, setState] = useState(
    getSectionState(load)
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [term, setTerm] = useState<TruckingTerm>();
  const { formatMessage } = useIntl();

  useDidUpdateEffect(() => {
    setState(getSectionState(load));
  }, [load.trucking_company?.id, load.rate, load.rate_type, load.skip_trucking_company]);

  useEffect(() => {
    if (isAllCommitmentsMatched) {
      getTruckingTerm(load.id).then(fetchedTerm => {
        if (
          !term ||
          term.trucking_company?.id !== fetchedTerm.trucking_company?.id ||
          term.rate !== fetchedTerm.rate ||
          term.rate_type !== fetchedTerm.rate_type
        ) {
          setTerm(fetchedTerm);
        }
      });
    } else {
      setTerm(undefined);
    }
  }, [isAllCommitmentsMatched]);

  const handleRateChange = ({ target: { value, validity } }: ChangeEvent<HTMLInputElement>) => {
    if (validity.valid) {
      setState(prevState => ({ ...prevState, rate: value.replace(',', '') }));
    }
  };

  const handleRateTypeChange = (option: ValueType<OptionKeysTypes>) => {
    setState(prevState => ({
      ...prevState,
      rateType: (option as OptionKeysTypes)?.value as RateType,
    }));
  };

  const handleChangeTruckingCompany = (truckingCompany: TruckingCompany) => {
    setState(prevState => ({ ...prevState, truckingCompany }));
  };

  const handleSkipTrucking = () => {
    setState(prevState => ({ ...prevState, skipTrucking: !prevState.skipTrucking }));
  };

  const handleStartEdit = () => {
    setIsEdit(true);
    setIsSectionEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    setIsSectionEditing(false);
    setState(getSectionState(load));
  };

  const handleUpdateLoad = async () => {
    setIsLoading(true);
    try {
      await updateLoad({
        rate: skipTrucking ? null : rate,
        rate_type: skipTrucking ? null : rateType,
        skip_trucking_company: skipTrucking,
        trucking_company_id: skipTrucking ? null : truckingCompany?.id || null,
      });
      setIsEdit(false);
      setIsSectionEditing(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onRateFocusLost = () => {
    const formattedRate = rate
      ? Number(rate)
          .toFixed(2)
          .replaceAll(',', '')
      : '';
    if (rate !== formattedRate) {
      setState(prevState => ({ ...prevState, rate: formattedRate }));
    }
  };

  const rateTypeOptions = getRateTypeOptions(formatMessage);

  return (
    <div className={cn({ [styles['is-loading']]: isLoading })}>
      <Loader isActive={isLoading} />
      <div className={styles['trucking-section-title']}>
        <CheckBox
          isChecked={!skipTrucking}
          isDisabled={!isEdit}
          onClick={handleSkipTrucking}
          label={<FormattedMessage id="load.scheduleTruckingCompany" />}
        />
        {!isEdit && (
          <div onClick={handleStartEdit} className={styles['edit-btn']}>
            <FormattedMessage id="general.btn.edit" />
          </div>
        )}
      </div>
      {!skipTrucking && (
        <>
          <FieldHighlighter hasValue={!!truckingCompany}>
            <IconAsyncSelect
              className="mv-15"
              noBorder
              isDisabled={!isEdit}
              optionDataFormatter={company => ({
                ...company,
                value: company.id,
                label: company.name,
              })}
              selected={
                truckingCompany
                  ? { value: truckingCompany.id, label: truckingCompany.name }
                  : undefined
              }
              placeholderIcon={selectPlaceholder}
              valueIconRenderer={({ label }) => <CompanyAvatar size="small" name={label} />}
              onChange={handleChangeTruckingCompany}
              iconRenderer={({ label }) => <CompanyAvatar name={label} />}
              optionsPath="/logistics/trucking_companies"
            />
          </FieldHighlighter>
          {truckingCompany && (
            <TruckingCompanyCard className="mb-10" truckingCompany={truckingCompany} />
          )}
          {!!term?.trucking_company?.id && term.trucking_company.id !== truckingCompany?.id && (
            <SuggestionBox
              className="mb-10"
              isDisabled={!isEdit}
              suggestion={
                <FormattedMessage
                  id="load.suggestedTruckingCompany"
                  values={{ b: bold, name: term.trucking_company.name }}
                />
              }
              buttonLabel={<FormattedMessage id="general.yes" />}
              onAccept={() =>
                setState(prevState => ({ ...prevState, truckingCompany: term.trucking_company }))
              }
            />
          )}
          <FieldHighlighter hasValue={skipTrucking || !!rate}>
            <FormInput
              label={<FormattedMessage id="load.truckingRate" />}
              inputRenderer={() => (
                <IconInput
                  pattern="(\d{0,1},\d{0,3}|\d{0,4})((?<=\d)\.?|\.(?=\d)\d{0,2})?"
                  placeholder="0.00"
                  name="rate"
                  onBlur={onRateFocusLost}
                  value={applyThousandSeparation(rate || '')}
                  hasNoBorders
                  icon={<MoneyIcon className={styles['icon']} />}
                  disabled={!isEdit}
                  onChange={handleRateChange}
                />
              )}
            />
            {!!term?.rate && Number(term.rate) !== Number(rate) && (
              <SuggestionBox
                className="mb-10"
                isDisabled={!isEdit}
                suggestion={
                  <FormattedMessage
                    id="load.suggestedRate"
                    values={{ b: bold, value: '$' + Number(term.rate).toFixed(2) }}
                  />
                }
                buttonLabel={<FormattedMessage id="general.yes" />}
                onAccept={() =>
                  setState(prevState => ({ ...prevState, rate: Number(term.rate).toFixed(2) }))
                }
              />
            )}
          </FieldHighlighter>

          <FieldHighlighter hasValue={skipTrucking || !!rateType}>
            <FormInput
              label={<FormattedMessage id="load.truckingRateType" />}
              inputRenderer={() => (
                <IconSelect
                  noBorder
                  icon={MoneyIcon}
                  isDisabled={!isEdit}
                  options={rateTypeOptions}
                  placeholderText={<FormattedMessage id="general.placeholder.selectRateType" />}
                  value={rateTypeOptions.find(item => item.value === rateType) || null}
                  onChange={handleRateTypeChange}
                />
              )}
            />
            {!!term?.rate_type && term.rate_type !== rateType && (
              <SuggestionBox
                className="mb-10"
                isDisabled={!isEdit}
                suggestion={
                  <FormattedMessage
                    id="load.suggestedRateType"
                    values={{ b: bold, value: term.rate_type }}
                  />
                }
                buttonLabel={<FormattedMessage id="general.yes" />}
                onAccept={() =>
                  setState(prevState => ({ ...prevState, rateType: term.rate_type || null }))
                }
              />
            )}
          </FieldHighlighter>
        </>
      )}
      {isEdit && (
        <div className={styles.actions}>
          <Button medium onClick={handleCancelEdit}>
            <FormattedMessage id="general.btn.cancel" />
          </Button>
          <Button medium primary disabled={isLoading} className="ml-10" onClick={handleUpdateLoad}>
            <FormattedMessage id="general.btn.save" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default TruckingSection;
