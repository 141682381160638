import React, { useState } from 'react';
// components
import TruckingSection from './LoadFormSections/TruckingSection';
import CommitmentsLogisticsSection from './LoadFormSections/CommitmentsLogisticsSection';
import InternalCommentSection from './LoadFormSections/InternalCommentSection';
import LoadFormButtons from './LoadFormButtons';
import SectionWrapper from './LoadFormSections/SectionWrapper';
import { FormattedMessage } from 'react-intl';
import LoadFlow from 'components/LoadFlow';
// utils
import cn from 'classnames';
import { getCommitmentsValidationData } from 'utils/loadHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// types
import { LoadModalLogisticsTabProps } from './LoadMatchingForm.types';
import { LoadCommitmentUpdateAttrs, LoadUpdateAttrs } from 'types/handlers';

const LoadModalLogisticsTab = ({
  load,
  className,
  onSubmit,
  updateCommitment,
}: LoadModalLogisticsTabProps) => {
  const [internalComment, setInternalComment] = useState(load.internal_comment || '');
  const [{ isCommitmentsEditing, isTruckingEditing }, setSectionsEditing] = useState({
    isCommitmentsEditing: false,
    isTruckingEditing: false,
  });

  const { isAllCommitmentsMatched, isReadyToSchedule } = getCommitmentsValidationData(
    load.commitments
  );

  const isTruckingFulfilled =
    load.skip_trucking_company || (!!load.rate && !!load.rate_type && !!load.trucking_company);

  const handleUpdateCommitment = async (id: string, attrs: LoadCommitmentUpdateAttrs) => {
    try {
      return await updateCommitment(load.id, id, attrs);
    } catch (errors) {
      toastResponseErrors(errors);
    }
  };

  const handleUpdateLoad = async (attrs: LoadUpdateAttrs) => {
    try {
      return await onSubmit(attrs);
    } catch (errors) {
      toastResponseErrors(errors);
      throw errors;
    }
  };

  const handleUpdateLoadStatus = async (status: 'processing' | 'draft') => {
    return handleUpdateLoad({ internal_comment: internalComment, status });
  };

  return (
    <div className={cn('ph-30', className)}>
      <SectionWrapper title={<FormattedMessage id="general.loadFlow" />}>
        <LoadFlow load={load} />
      </SectionWrapper>

      <SectionWrapper title={<FormattedMessage id="loadsTable.trucking" />}>
        <TruckingSection
          isAllCommitmentsMatched={isAllCommitmentsMatched}
          load={load}
          updateLoad={handleUpdateLoad}
          setIsSectionEditing={isTruckingEditing =>
            setSectionsEditing(prevState => ({ ...prevState, isTruckingEditing }))
          }
        />
      </SectionWrapper>

      <SectionWrapper title={<FormattedMessage id="general.logistics" />}>
        <CommitmentsLogisticsSection
          setIsSectionEditing={isCommitmentsEditing =>
            setSectionsEditing(prevState => ({ ...prevState, isCommitmentsEditing }))
          }
          load={load}
          updateCommitment={handleUpdateCommitment}
        />
      </SectionWrapper>

      <InternalCommentSection
        creator={load.modified_by}
        comment={internalComment}
        setComment={setInternalComment}
      />

      <LoadFormButtons
        isSomeSectionEditing={isCommitmentsEditing || isTruckingEditing}
        canPublishLoad={isReadyToSchedule && isTruckingFulfilled}
        load={load}
        saveDraft={() => handleUpdateLoadStatus('draft')}
        saveProcessing={() => handleUpdateLoadStatus('processing')}
      />
    </div>
  );
};

export default LoadModalLogisticsTab;
