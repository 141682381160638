import React, { useState, Fragment } from 'react';
// components
import Input from 'components/Input';
import CheckboxMenu from './CheckboxMenu';
import { FormattedMessage } from 'react-intl';
import {
  DayPickerSingleDateController,
  DayPickerRangeController,
  FocusedInputShape,
} from 'react-dates';
import { ReactComponent as ArrowLeftIcon } from 'icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'icons/arrow-right.svg';
// utils
import classnames from 'classnames/bind';
import { pick } from 'utils';
// types
import { DateMenuTypes } from './Menu.types';
// constants
import { checkboxProps, relativeDatesConditions } from 'utils/constants/userFilter';
// styles
import styles from './Menu.module.scss';

const cn = classnames.bind(styles);

const absoluteConditions = ['gt', 'eq', 'lt'];
const START_DATE = 'startDate';
const END_DATE = 'endDate';

function getLabelKey(condition: string) {
  switch (condition) {
    case 'gt_days_ago':
    case 'eq_days_ago':
    case 'lt_days_ago':
      return 'general.daysAgoWithValue';
    case 'gt_days_in':
    case 'lt_days_in':
      return 'general.nextDaysWithValue';
    case 'eq_days_in':
      return 'general.exactlyNextDays';
    default:
      return '';
  }
}

const DateMenu = ({
  currentValue,
  dateValue,
  dateStartValue,
  dateEndValue,
  onInputChange,
  onDateChange,
  onDatesRangeChange,
  inputValue,
  ...others
}: DateMenuTypes) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(
    dateStartValue ? END_DATE : START_DATE
  );
  const onFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(!focusedInput ? START_DATE : focusedInput);
  };
  return (
    <CheckboxMenu {...pick(others, checkboxProps)} currentValue={currentValue}>
      {value => (
        <Fragment>
          {value === currentValue && relativeDatesConditions.includes(value) && (
            <div className={cn('value-input-line')}>
              <FormattedMessage
                id={getLabelKey(value)}
                values={{
                  count: inputValue,
                  inputComponent: (
                    <Input
                      name="days"
                      type="number"
                      min={0}
                      max={99999}
                      placeholder="0"
                      className={cn('value-input', { 'ml-10': value === 'eq_days_in' })}
                      value={inputValue}
                      onChange={onInputChange}
                    />
                  ),
                }}
              />
            </div>
          )}
          {value === currentValue && absoluteConditions.includes(value) && (
            <div className={cn('day-picker-line')}>
              <DayPickerSingleDateController
                focused
                onFocusChange={() => null}
                date={dateValue}
                onDateChange={onDateChange}
                numberOfMonths={1}
                daySize={30}
                navPrev={<ArrowLeftIcon className={cn('nav-icon')} />}
                navNext={<ArrowRightIcon className={cn('nav-icon')} />}
              />
            </div>
          )}
          {value === currentValue && value === 'range' && (
            <div className={cn('day-picker-line')}>
              <DayPickerRangeController
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                startDate={dateStartValue}
                endDate={dateEndValue}
                onDatesChange={onDatesRangeChange}
                numberOfMonths={2}
                daySize={30}
                navPrev={<ArrowLeftIcon className={cn('nav-icon')} />}
                navNext={<ArrowRightIcon className={cn('nav-icon')} />}
              />
            </div>
          )}
        </Fragment>
      )}
    </CheckboxMenu>
  );
};

export default DateMenu;
