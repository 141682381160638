export function applyThousandSeparation(number: string) {
  // remove leading zero: 01.10 => 1.10
  const formattedValue = number.replace(/^0+(?=[0-9]+)/g, '').replaceAll(',', '');

  // set thousand separator: 1111 => 1,111
  if (/^\d{4,}/.test(formattedValue)) {
    return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // reset thousand separator: 1,11 => 111
  if (/^((,\d{0,3})|(\d,\d{0,2}))($|\.)/.test(formattedValue)) {
    return formattedValue.replace(',', '');
  }

  return formattedValue;
}

export const parseNumberString = (stringNumber: string) => {
  return Number(stringNumber.replaceAll(',', ''));
};

export const applyDecimalSeparation = (value: number) => {
  return Number.isInteger(value) ? value.toString() : value.toFixed(2);
};
