import { ReactNode } from 'react';

export enum SortDirectionTypes {
  asc = 'asc',
  desc = 'desc',
}

export type SortDirection = keyof typeof SortDirectionTypes;

export type SortOptions = {
  sortKey: string;
  direction: SortDirection;
};

type RendererOptionsType = {
  textColor?: string;
  hasArchiveBadge?: boolean;
  maxWidth?: number;
};

export type AdditionalRendererData = {
  emptyPlaceholder: string;
  callback?: () => void;
  rowIdx: string | number;
  dataPath?: string;
  colIdx: string | number;
  options?: RendererOptionsType;
};

export interface ColumnRenderType<T = string | number | ReactNode> {
  data?: T;
  placeholder: string;
  className?: string;
  isEmpty?: (data?: T) => boolean;
}

type ColumnRendererType =
  | 'boolean'
  | 'date'
  | 'default'
  | 'badge'
  | 'badgeDate'
  | 'badgeStatus'
  | 'weight';

export type RendererType<T> = (
  rowItem: T,
  additionalData: AdditionalRendererData
) => ReactNode | string;

export type Column<T> = {
  width?: number;
  label: string;
  ratio?: number;
  renderer?: RendererType<T>;
  dataPath?: string;
  emptyPlaceholder?: string;
  className?: string;
  sortable?: string;
  showOnHover?: boolean;
  rendererType?: ColumnRendererType;
  options?: RendererOptionsType;
};
