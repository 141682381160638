import React, { useState } from 'react';
// components
import Portal from 'components/Portal';
// utils
import cn from 'classnames';
// styles
import styles from './Tooltip.module.scss';

type PortalTooltipProps<T> = {
  className?: string;
  content: T;
  isCenteredHorizontally?: boolean;
  isContentCentered?: boolean;
  size: 'small' | 'medium';
  style?: React.CSSProperties;
  triggerBottom: number;
  triggerLeft: number;
  triggerTop: number;
  triggerWidth: number;
};

type PortalTooltipState = {
  position: 'top' | 'bottom' | '';
  tooltipHeight: number;
  tooltipWidth: number;
};

function PortalTooltip<T>({
  className = '',
  content,
  isCenteredHorizontally = false,
  isContentCentered = false,
  size,
  style,
  triggerBottom,
  triggerLeft,
  triggerTop,
  triggerWidth,
}: PortalTooltipProps<T>) {
  const [{ tooltipHeight, tooltipWidth, position }, setState] = useState<PortalTooltipState>({
    position: '',
    tooltipHeight: 0,
    tooltipWidth: 0,
  });
  const getTopOffset = () => {
    return position === 'bottom' ? triggerBottom : triggerTop - tooltipHeight;
  };
  const getLeftOffset = () => {
    const shouldCenteredHorizontally =
      isCenteredHorizontally && ['top', 'bottom'].includes(position);
    return shouldCenteredHorizontally
      ? triggerLeft + triggerWidth / 2 - tooltipWidth / 2
      : triggerLeft;
  };
  return (
    <Portal>
      <div
        style={{ ...style, top: getTopOffset(), left: getLeftOffset() }}
        className={cn(styles['portal-tooltip'], {
          [styles.small]: size === 'small',
          [className]: !!className,
          [styles.bottom]: position === 'bottom',
          [styles.top]: position === 'top',
          [styles['content-centered']]: isContentCentered,
        })}
        ref={ref => {
          if (ref && !position) {
            const tooltipHeight = ref.offsetHeight + 20; // 20 - margins
            const tooltipWidth = ref.offsetWidth;
            const availBottom = window.innerHeight - triggerBottom;
            const isOpenedBottom = availBottom > tooltipHeight;
            setState({ position: isOpenedBottom ? 'bottom' : 'top', tooltipHeight, tooltipWidth });
          }
        }}
      >
        <div className={styles.triangle} />
        {content}
      </div>
    </Portal>
  );
}

export default PortalTooltip;
