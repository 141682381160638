import React from 'react';
// components
import ReactSelect, { components } from 'react-select';
// utils
import cn from 'classnames';
// types
import { SelectProps, SelectOptionProps, OptionKeysTypes } from './Select.types';
// styles, assets
import { ReactComponent as AngleDownIcon } from 'icons/angle-down.svg';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
import './Select.scss';

const IndicatorsContainer = () => <AngleDownIcon className="dropdown-icon" />;

const Option: SelectOptionProps = props => (
  <div className="select-option-wrapper">
    <components.Option {...props} />
    {props.isSelected && <CheckIcon className="selected-icon" />}
  </div>
);

function Select<T extends OptionKeysTypes>({
  getRef,
  className,
  disabled,
  optionRenderer,
  noBorder,
  isSmall,
  ...others
}: SelectProps<T>) {
  return (
    <ReactSelect
      {...others}
      className={cn('react-select', className, {
        disabled,
        'without-borders': noBorder,
        'is-small': isSmall,
      })}
      classNamePrefix="select"
      components={{ IndicatorsContainer, Option: optionRenderer || Option }}
      ref={getRef}
    />
  );
}

Select.defaultProps = {
  isSearchable: false,
};

export default Select;
