import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Load, LoadLog, SalesResult } from 'types';

type LoadState = {
  data?: Load;
  logs: LoadLog[];
  isLoadLoading: boolean;
  isLogsLoading: boolean;
};

const initialState: LoadState = {
  isLoadLoading: false,
  logs: [],
  isLogsLoading: false,
};

const loadSlice = createSlice({
  name: 'load',
  initialState,
  reducers: {
    setLoad(state, action: PayloadAction<Load>) {
      state.data = action.payload;
      state.isLoadLoading = false;
    },
    mergeLoad(state, action: PayloadAction<{ sales_results?: SalesResult[] }>) {
      if (state.data) {
        state.data.sales_results = action.payload.sales_results;
      }
      state.isLoadLoading = false;
    },
    setLoadLogs(state, action: PayloadAction<LoadLog[]>) {
      state.logs = action.payload;
      state.isLogsLoading = false;
    },
    setLoadIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoadLoading = action.payload;
    },
    setLoadLogsIsLoading(state, action: PayloadAction<boolean>) {
      state.isLogsLoading = action.payload;
    },
    resetLoadState() {
      return initialState;
    },
  },
});

export const {
  mergeLoad,
  setLoad,
  setLoadLogs,
  setLoadIsLoading,
  setLoadLogsIsLoading,
  resetLoadState,
} = loadSlice.actions;

export default loadSlice.reducer;
