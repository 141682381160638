import React from 'react';
// utils
import round from 'lodash.round';
import moment from 'moment';
// constants
import { EP_API_URL, METERS_IN_MILE, IS_DEVELOPMENT } from '../constants';
// types
import { AddressEntity } from 'types';

export function getLetterInitials(name = '') {
  const words = name
    .toUpperCase()
    .split(' ')
    .filter(Boolean);

  return words.length > 1 ? words[0][0] + words[1][0] : name.substring(0, 2).toUpperCase();
}

export function get(obj: Record<string, any>, path: string, defaultValue?: number | string): any {
  const result = String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce((res, key) => (res !== null && res !== undefined ? (res as any)[key] : res), obj);
  return result === undefined || result === null || result === obj ? defaultValue : result;
}

export function searchByKey<T>(options: T[] = [], search = '', key: keyof T): T[] {
  return search
    ? options.filter(option =>
        String(option?.[key] || '')
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    : options;
}

export function isEmpty(obj: any) {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
}

export function isObjectAllValueEmpty(obj: any) {
  const objectValues = Object.entries(obj || {});

  return (
    objectValues.reduce((prev: number, [, value]: any) => (!value ? prev + 1 : prev), 0) ===
    objectValues.length
  );
}

export function pick(object: { [index: string]: any }, keys: any[]) {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}

export function pick2<T, K extends keyof T>(object: T, keys: K[]) {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {} as Pick<T, K>);
}

export function isEqualFields(
  obj1: { [index: string]: any },
  obj2: { [index: string]: any },
  fields: string[]
) {
  return !fields.some(field => obj1[field] !== obj2[field]);
}

export function isEqualStringArrays(firstArr: string[], secondArr: string[]) {
  return firstArr.length === secondArr.length
    ? firstArr.every((item, index) => secondArr[index] === item)
    : false;
}

export function bold(msg: string) {
  return <span className="bold">{msg}</span>;
}

export function formatSrcWithApiUrl(src = '') {
  if (IS_DEVELOPMENT) return EP_API_URL + src;

  return src;
}

export function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function readFile(
  files: FileList | null,
  callback: (file: string) => void,
  maxSize?: number
) {
  if (!files || !files[0]) return;
  if (maxSize && files[0].size > maxSize * 1000000) return;
  const reader = new FileReader();
  reader.readAsDataURL(files[0]);
  reader.onload = () => {
    if (reader.result && typeof reader.result === 'string') callback(reader.result);
  };
}

export function debounce(func: any, ms: number) {
  let timeout: any;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, ms);
  };
}

export function toCamelCase(str: string) {
  if (/_|-/.test(str)) {
    return str.replace(/_|-[a-z]/, p1 => p1.toUpperCase()).replace(/_|-/, '');
  } else {
    return str
      .toLowerCase()
      .replace(/\s\S/g, p1 => p1.toUpperCase())
      .replace(/\s/g, '');
  }
}

export function getValueFromQuery(locationSearch: string, queryKey: string) {
  const params = new URLSearchParams(locationSearch);
  return params.get(queryKey) || '';
}

export function formatAddress({ city = '', state = '', zipcode = '', country_code = '' } = {}) {
  const country = country_code === 'US' ? 'USA' : country_code;
  const addressArr = [state, country, zipcode].filter(str => !!str);

  if (!city && !addressArr.length) {
    return 'N/A';
  }

  return city
    ? `${city}${addressArr.length ? ', ' : ' '}${addressArr.join(' ')}`
    : addressArr.join(' ');
}

export function formatFullAddress(info?: AddressEntity) {
  if (!info) return 'N/A';
  const result = [];
  if (info.address) result.push(info.address);
  if (info.city) result.push(info.city);
  if (info.state) result.push(info.state);
  return result.length ? result.join(', ') : 'N/A';
}

export function convertSecondsToHours(seconds = 0) {
  const minutes = round(seconds / 60);
  if (minutes < 60) return minutes + 'm';
  const hours = Math.floor(minutes / 60);
  const minWithoutHours = minutes % 60;
  return `${hours}h ${minWithoutHours}m`;
}

export function formatTravelTime(meters: number | null, seconds: number | null) {
  if (!meters || !seconds) return '';
  const miles = Number(round(meters / METERS_IN_MILE, 1));
  const time = convertSecondsToHours(seconds);
  return time + ` (${miles} miles)`;
}

const types = {
  transfers: 'transfer',
  arrivals: 'arrival',
  sales: 'sale',
  drafts: 'draft',
  sowUnit: 'SowUnitContract',
  packer: 'PackerContract',
} as { [key: string]: string };

export const formatType = (type: string) => types[type] || type;

export const isSafari = () => {
  const ua = navigator.userAgent;
  return ua.indexOf('Safari') !== -1 && ua.indexOf('Chrome') === -1;
};

export const isChrome = () => {
  const ua = navigator.userAgent;
  return ua.indexOf('Chrome') !== -1 && /Google Inc/.test(navigator.vendor);
};

export const isFirefox = () => {
  const ua = navigator.userAgent;
  return ua.indexOf('Firefox') !== -1;
};

// except Edge
export const isIE = () => {
  const ua = navigator.userAgent;
  return ua.indexOf('MSIE ') !== -1 || ua.indexOf('Trident/') !== -1;
};

export const isEdge = () => {
  return /Edge/.test(navigator.userAgent);
};

export const getBrowserName = () => {
  if (isSafari()) return 'Safari';
  if (isChrome()) return 'Chrome';
  if (isFirefox()) return 'Firefox';
  if (isEdge()) return 'Edge';
  if (isIE()) return 'IE';

  return '';
};

export function getAverage(...numbers: any) {
  return numbers.filter(Boolean).reduce((a: number, b: number) => a + b, 0) / numbers.length;
}

export function getStartedPath(pathname = '') {
  return pathname.split('/').filter(Boolean)[0];
}

export const getWeightRange = (weight: number, depth = 10, measure = 'lbs') => {
  const min = (Math.floor(weight / depth) * depth).toFixed();
  const max = (Math.floor(weight / depth) * depth + depth - 1).toFixed();
  return `${min} - ${max} ${measure}`;
};

export function isTruckingSystem(pathname: string) {
  return getStartedPath(pathname) === 'trucking';
}

export function convertDateToFormat(
  date?: string,
  fromFormat = 'DD-MM-YYYY',
  toFormat = 'MM/DD/YYYY'
) {
  return date ? moment(date, fromFormat).format(toFormat) : undefined;
}
