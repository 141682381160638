// utils
import { generateEmptyLoad } from './loadHelper';
// types
import { DraggableLocation } from 'react-beautiful-dnd';
import { AppGetState } from 'store/reduxStore';
import { Load, MovementType } from 'types';

export function reorderList<T>(data: T[], startIndex: number, endIndex: number) {
  const dataClone = Array.from(data);
  const [removed] = dataClone.splice(startIndex, 1);
  dataClone.splice(endIndex, 0, removed);

  return dataClone;
}

export const moveToOtherList = (
  source: string[],
  destination: string[],
  droppableSourceIndex: number,
  droppableDestinationIndex: number
) => {
  const sourceClone = [...source];
  const destClone = [...destination];
  const [removed] = sourceClone.splice(droppableSourceIndex, 1);

  destClone.splice(droppableDestinationIndex, 0, removed);

  return [sourceClone, destClone];
};

export function getUpdatedTables(
  hashTable: Record<string, Load[]>,
  source: DraggableLocation,
  destination: DraggableLocation,
  selectedIds: string[]
) {
  const sourceTable = hashTable[source.droppableId];
  const destTable = hashTable[destination.droppableId];
  const draggableElem = sourceTable[source.index];
  const updDestTable = [...destTable];

  const selectedLoads =
    selectedIds.length > 1 && selectedIds.includes(draggableElem.id)
      ? sourceTable.filter(load => selectedIds.includes(load.id))
      : [draggableElem];

  const selectedLoadsIds = selectedLoads.map(load => load.id);
  const updSourceTable = sourceTable.filter(load => !selectedLoadsIds.includes(load.id));
  updDestTable.splice(destination.index, 0, ...selectedLoads);

  return {
    updDestTable,
    updSourceTable,
  };
}

export function generateLoadsBasedOnCommitments(
  getState: AppGetState,
  source: DraggableLocation,
  destination: DraggableLocation,
  type: MovementType
) {
  const { hashTable, scheduledCommitments, selectedIds } = getState().plansList;
  const draggableElem = scheduledCommitments.resources[source.index];
  const updPlan = [...hashTable[destination.droppableId]];

  let updScheduledList = [...scheduledCommitments.resources];
  let contractCommitmentIds: string[] = [];

  if (selectedIds.length > 1 && selectedIds.includes(draggableElem.id)) {
    const sourceRemovedIndexes = selectedIds.map(id =>
      updScheduledList.findIndex(item => id === item.id)
    );
    const draggableElements = sourceRemovedIndexes.map(index => updScheduledList[index]);
    updPlan.splice(
      destination.index,
      0,
      ...draggableElements.map(elem => generateEmptyLoad(type, elem))
    );
    updScheduledList = updScheduledList.filter((_, idx) => !sourceRemovedIndexes.includes(idx));
    contractCommitmentIds = selectedIds;
  } else {
    const [removed] = updScheduledList.splice(source.index, 1);
    const tempLoad = generateEmptyLoad(type, removed);
    updPlan.splice(destination.index, 0, tempLoad);
    contractCommitmentIds = [removed.id];
  }

  return {
    hashTable,
    contractCommitmentIds,
    scheduledCommitments,
    selectedIds,
    updPlan,
    updScheduledList,
  };
}
