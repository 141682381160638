import React, { useState, Fragment } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/Button';
import CutLevelSelect from './CutLevelSelect';
import DateTimeSubSection from './SubSections/DateTimeSubSection';
import EstimatedTravelTimeRow from 'components/EstimatedTravelTimeRow';
import GroupAssignmentSubSection from './SubSections/GroupAssignmentSubSection';
import FieldHighlighter from 'components/FieldHighlighter';
import FormInput from 'components/Input/FormInput';
import IconInput from 'components/Input/IconInput';
import NumberInput from 'components/Input/NumberInput';
import Loader from 'components/Loader';
import Location from 'components/Location';
import NoteCollapse from 'components/Collapse/NoteCollapse';
import TruncatedText from 'components/TruncatedText';
import WaypointWithLine from 'components/WaypointWithLine';
import HeadCountSubSection from './SubSections/HeadCountSubSection';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
// hooks
import useStateArray from 'hooks/useStateArray';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
// utils
import { calculateEstimatedTime, calculateSaleEstimatedTime } from './helper';
import cn from 'classnames';
import pick from 'lodash.pick';
import {
  getCommitmentTimeToLoad,
  getHeadCountSumByCommitmentType,
  getLoadCommitmentAddressEntity,
  getUnMatchedSourceName,
  getUnMatchedDestinationName,
  isSaleLoad,
  isValidCommitmentDateRange,
  findSourceCommitment,
} from 'utils/loadHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// types
import { CommitmentsLogisticsSectionTypes, DefaultUpdatedKeys } from './LoadFormSections.types';
import { CommitmentTypes, LoadCommitment } from 'types';
// styles
import styles from '../LoadMatchingForm.module.scss';

const defaultValuesKeys: DefaultUpdatedKeys[] = ['arrive_at', 'comment', 'head_count'];

function formatCommitments(commitments: LoadCommitment[]): LoadCommitment[] {
  return commitments.map(cm => ({ ...cm, minutes_for_load: getCommitmentTimeToLoad(cm) }));
}

const CommitmentsLogisticsSection = ({
  load,
  updateCommitment,
  setIsSectionEditing,
}: CommitmentsLogisticsSectionTypes) => {
  const [isLoadingId, setIsLoadingId] = useState('');
  const [editingIds, setEditingIds] = useState<string[]>([]);
  const [stateCommitments, setCommitments, { setValuesById }] = useStateArray<LoadCommitment>(
    formatCommitments(load.commitments)
  );
  const { formatMessage } = useIntl();
  const isSale = isSaleLoad(load);
  const isSomeCommitmentEditing = editingIds.length > 0;
  const { sourcesHeadCount, destHeadCount } = getHeadCountSumByCommitmentType(stateCommitments);

  useDidUpdateEffect(() => {
    setIsSectionEditing(isSomeCommitmentEditing);
  }, [isSomeCommitmentEditing]);

  useDidUpdateEffect(() => {
    setCommitments(formatCommitments(load.commitments));
  }, [setCommitments, load.commitments]);

  const handleChangeDateTime = (commitmentId: string, dateTimeValue: string) => {
    setValuesById(commitmentId, { arrive_at: dateTimeValue });
  };

  const handleChangeCutLevel = (commitmentId: string, value: string) => {
    setValuesById(commitmentId, { cut_level: +value });
  };

  const handleChangeHeadCount = (commitmentId: string, value?: number) => {
    setValuesById(commitmentId, { head_count: value });
  };

  const handleCancelEditing = (commitmentId: string) => {
    setCommitments(formatCommitments(load.commitments));
    setEditingIds(editingIds.filter(editingId => editingId !== commitmentId));
  };

  const handleSaveCommitment = (commitment: LoadCommitment) => {
    setIsLoadingId(commitment.id);
    const attrs = pick(commitment, defaultValuesKeys);
    const customAttrs = isSale
      ? { cut_level: commitment.cut_level }
      : {
          pig_group_assign_type: commitment.pig_group_assign_type || 'skip',
          pig_group_assign_name: commitment.pig_group_assign_name || '',
          pig_group_id:
            commitment.pig_group_assign_type === 'existing'
              ? Number(commitment.pig_group?.id || commitment.pig_group_id)
              : null,
        };
    updateCommitment(commitment.id, {
      ...attrs,
      ...customAttrs,
      minutes_for_load: commitment.minutes_for_load || 0,
    })
      .then(() => {
        setEditingIds(editingIds.filter(editingId => editingId !== commitment.id));
      })
      .catch(toastResponseErrors)
      .finally(() => {
        setIsLoadingId('');
      });
  };

  return (
    <Fragment>
      {stateCommitments.map((stateCommitment, index) => {
        const {
          arrive_at,
          barn_id,
          comment,
          commitment_type,
          cut_level,
          farm,
          head_count,
          id,
          matched,
          minutes_for_load,
          pig_group,
          pig_group_assign_name,
          pig_group_assign_type,
          position,
          travel_distance,
          travel_seconds,
        } = stateCommitment;
        const entity = getLoadCommitmentAddressEntity(stateCommitment);
        const isDestination = commitment_type === CommitmentTypes.destination;
        const hasCutLevel = isSale && !isDestination;
        const isEditing = editingIds.includes(id);
        const isLoading = isLoadingId === id;
        const isLast = !stateCommitments[index + 1];
        const isValidDate = isValidCommitmentDateRange(
          stateCommitment,
          stateCommitments[index - 1]
        );
        const headCountDiff = isDestination
          ? sourcesHeadCount - destHeadCount
          : destHeadCount - sourcesHeadCount;
        const commitmentName =
          entity?.name ||
          (isDestination
            ? getUnMatchedDestinationName(
                position,
                stateCommitments.filter(findSourceCommitment).length,
                formatMessage
              )
            : getUnMatchedSourceName(position, formatMessage));

        return (
          <WaypointWithLine type={commitment_type} isEnd={isLast} key={id}>
            <div
              className={cn(styles['commitment-timeline'], { [styles['is-loading']]: isLoading })}
            >
              <Loader isActive={isLoading} />
              <div className="mb-15">
                <div className={styles.name}>
                  <TruncatedText text={commitmentName} maxWidth={300} size="small" hasTooltip />
                  {matched && !isEditing && (
                    <div
                      onClick={() => setEditingIds([...editingIds, id])}
                      className={styles['edit-btn']}
                    >
                      <FormattedMessage id="general.btn.edit" />
                    </div>
                  )}
                </div>
                {matched && (
                  <div className={styles.label}>
                    <Location state={entity?.state} city={entity?.city} />
                  </div>
                )}
              </div>
              {matched && (
                <Fragment>
                  {!isLast && (
                    <FormInput
                      label={<FormattedMessage id="general.timeToLoad" />}
                      inputRenderer={() => (
                        <NumberInput
                          disabled={!isEditing}
                          max={999999}
                          onChange={value => setValuesById(id, { minutes_for_load: value })}
                          value={minutes_for_load ?? undefined}
                        >
                          {props => (
                            <IconInput
                              placeholder="0"
                              hasNoBorders
                              icon={<ClockIcon className={styles['icon']} />}
                              {...props}
                            />
                          )}
                        </NumberInput>
                      )}
                    />
                  )}
                  <FieldHighlighter leftIndent={65} hasValue={isValidDate}>
                    <DateTimeSubSection
                      // next commitment arrive time depends on previous one
                      estimatedTime={(isSale ? calculateSaleEstimatedTime : calculateEstimatedTime)(
                        index,
                        stateCommitments
                      )}
                      timeType={isDestination ? 'ArrivalTime' : 'PickUpTime'}
                      label={
                        <FormattedMessage
                          id={`load.${isDestination ? 'arrivalDateTime' : 'pickUpDateTime'}`}
                        />
                      }
                      isDisabled={!isEditing}
                      pickerId={`datetime-${id}`}
                      dateTimeValue={arrive_at || ''}
                      onChange={value => handleChangeDateTime(id, value)}
                    />
                  </FieldHighlighter>
                  {hasCutLevel && (
                    <FieldHighlighter leftIndent={65} hasValue={!!cut_level}>
                      <CutLevelSelect
                        isDisabled={!isEditing}
                        value={(cut_level || '').toString()}
                        onChange={value => handleChangeCutLevel(id, value)}
                      />
                    </FieldHighlighter>
                  )}
                  <FieldHighlighter leftIndent={65} hasValue={!!head_count}>
                    <HeadCountSubSection
                      commitment={stateCommitment}
                      headCountDiff={headCountDiff}
                      isDisabled={!isEditing}
                      onChange={value => handleChangeHeadCount(id, value)}
                    />
                  </FieldHighlighter>

                  {!isSale && isDestination && (
                    <GroupAssignmentSubSection
                      farmType={farm?.farm_type}
                      isDisabled={!isEditing}
                      barnId={barn_id}
                      assignType={pig_group_assign_type || 'skip'}
                      customGroupName={pig_group_assign_name || ''}
                      existingGroup={pig_group}
                      onChange={values => setValuesById(id, values)}
                    />
                  )}

                  <NoteCollapse
                    isDisabled={!isEditing}
                    isOpened={Boolean(comment)}
                    label={<FormattedMessage id="note.addLoadNote" />}
                    className={cn('mt-15', { 'mb-30': !isEditing, 'mb-15': isEditing })}
                    value={comment || ''}
                    onChange={value => setValuesById(id, { comment: value })}
                  />

                  {farm?.caretaker_availability && (
                    <div className="mb-10">
                      <span className="bold">
                        <FormattedMessage id="general.caregiverAvailability" />
                      </span>
                      <div className="pre-text mt-5">{farm.caretaker_availability}</div>
                    </div>
                  )}

                  {isEditing && (
                    <div className={styles.actions}>
                      <Button medium onClick={() => handleCancelEditing(id)}>
                        <FormattedMessage id="general.btn.cancel" />
                      </Button>
                      <Button
                        medium
                        primary
                        disabled={isLoading}
                        className="ml-10"
                        onClick={() => handleSaveCommitment(stateCommitment)}
                      >
                        <FormattedMessage id="general.btn.save" />
                      </Button>
                    </div>
                  )}
                </Fragment>
              )}
              {!isLast && (
                <EstimatedTravelTimeRow distance={travel_distance} seconds={travel_seconds} />
              )}
            </div>
          </WaypointWithLine>
        );
      })}
    </Fragment>
  );
};

export default CommitmentsLogisticsSection;
