import React from 'react';
// components
import Modal from 'components/Modal';
import TruncatedTextColumn from '../TruncatedTextColumn';
// utils
import { useModalSetter } from 'context/useModal';
// styles
import styles from './NoteColumn.module.scss';

type NoteColumnProps = {
  maxWidth?: number;
  note: string;
  placeholder?: string | JSX.Element;
};

const NoteColumn = ({ maxWidth, note, placeholder }: NoteColumnProps) => {
  const setModal = useModalSetter();

  const handleClick = (isTruncated: boolean) => {
    if (isTruncated) {
      setModal(
        <Modal hasAutoWidth>
          {({ closeModal }) => (
            <div className={styles['notes-modal']}>
              <div className={styles.header}>
                <Modal.CloseButton onClick={closeModal} className={styles['close-icon']} />
              </div>
              <div className={styles.content}>{note}</div>
            </div>
          )}
        </Modal>
      );
    }
  };

  return (
    <TruncatedTextColumn
      hasOnHoverEffect
      maxWidth={maxWidth}
      text={note}
      onClick={handleClick}
      placeholder={placeholder}
    />
  );
};

export default NoteColumn;
