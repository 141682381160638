import React, { FC, useState, useEffect } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import EmptyPlaceholder, { NoData } from 'components/EmptyPlaceholder';
import NavBar from 'components/NavBar';
import EditableSalesResultSection from '../../LoadSections/EditableSalesResultSection';
import LoadModalLogisticsTab from '../../LoadMatchingForm/LoadModalLogisticsTab';
import LogisticsSection from '../../LoadSections/LogisticsSection';
import ContractSection from '../../LoadSections/ContractSection';
// utils
import classnames from 'classnames/bind';
import { prepareContractInfo } from 'utils/contractHelper';
import { isLoadProcessing, isLoadDraft } from 'utils/loadHelper';
// types
import { LoadModalTabsProps, TabsItemType } from './LoadModal.types';
import { Load } from 'types';
// styles
import styles from './LoadModalTabs.module.scss';
import formStyles from 'components/LoadMatchingForm/LoadMatchingForm.module.scss';

const cn = classnames.bind(styles);

function getTabs(load: Load, hasContract: boolean) {
  const tabs = [
    {
      value: 'sale-result-tab',
      label: <FormattedMessage id="general.salesResults" />,
      isVisible: !!load.sales_results?.length,
    },
    {
      value: 'logistics-tab',
      label: <FormattedMessage id="load.information.logistics" />,
      isVisible: load.commitments.every(({ matched }) => matched),
    },
    {
      value: 'contract-tab',
      label: <FormattedMessage id="general.contract" />,
      isVisible: hasContract,
    },
  ];
  return tabs.filter(tab => tab.isVisible).map(tab => ({ value: tab.value, label: tab.label }));
}

const LoadModalTabs: FC<LoadModalTabsProps> = ({
  createSalesResults,
  load,
  revertToDraft,
  toggleLockResult,
  className,
  updateCommitment,
  updateLoad,
  updateSalesResults,
}) => {
  const [commitmentsWithContract, entitiesByContract] = prepareContractInfo(load.commitments);
  const tabs = getTabs(load, !!commitmentsWithContract.length);
  const [currentTabValue, toggleTab] = useState(tabs[0]?.value);
  const tabsCount = tabs.length;
  const hasNavbar = tabsCount > 1;

  useEffect(() => {
    toggleTab(tabs[0]?.value);
  }, [tabsCount]);

  const onTabClick = (tab: TabsItemType) => {
    if (tab.value !== currentTabValue) toggleTab(tab.value);
  };

  const renderLogisticsTab = () =>
    isLoadDraft(load) && load.plan_id ? (
      <LoadModalLogisticsTab
        load={load}
        onSubmit={updateLoad}
        updateCommitment={updateCommitment}
      />
    ) : (
      <LogisticsSection load={load}>
        {isLoadProcessing(load) && load.plan_id && (
          <div className={formStyles['btn-section']}>
            <Button
              onClick={revertToDraft}
              className={cn(formStyles['submit-btn'], formStyles.white)}
              rounded
              shine
            >
              <FormattedMessage id="general.btn.revertToDraft" />
            </Button>
          </div>
        )}
      </LogisticsSection>
    );

  return (
    <div className={cn('tab-holder', className)}>
      {hasNavbar && (
        <NavBar
          height={40}
          unbordered
          className={cn('tabs-navbar-container')}
          items={tabs}
          currentValue={currentTabValue}
          onSelect={onTabClick}
        />
      )}
      <div className={cn('tab-content', { 'has-navbar': hasNavbar })}>
        {currentTabValue === 'sale-result-tab' && !!load.sales_results?.length && (
          <EditableSalesResultSection
            isSubmitBtnSticky
            className="mh-30"
            salesResults={load.sales_results}
            commitments={load.commitments}
            toggleLockResult={toggleLockResult}
            onCreate={createSalesResults}
            onUpdate={updateSalesResults}
          />
        )}
        {currentTabValue === 'logistics-tab' && renderLogisticsTab()}
        {currentTabValue === 'contract-tab' &&
          commitmentsWithContract.map(loadCommitment => {
            if (loadCommitment.contract_commitment) {
              const contractId = loadCommitment.contract_commitment.contract?.id;
              const entities = contractId ? entitiesByContract[contractId] : [];
              return (
                <ContractSection
                  key={loadCommitment.id}
                  className="mb-30"
                  loadCommitmentWithContract={loadCommitment}
                  contractEntities={entities}
                />
              );
            }
          })}
        {!tabsCount && (
          <EmptyPlaceholder
            icon={NoData}
            title={<FormattedMessage id="emptyPlaceholder.noData" />}
          />
        )}
      </div>
    </div>
  );
};

export default LoadModalTabs;
