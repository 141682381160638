import React, { useState, useEffect } from 'react';
// components
import { Collapse } from 'react-collapse';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
// types
import { NoteCollapseProps } from './NoteCollapse.types';
// styles
import styles from './NoteCollapse.module.scss';

const NoteCollapse = ({
  value = '',
  className,
  isDisabled = false,
  isOpened = false,
  autoFocus = false,
  onChange,
  label = <FormattedMessage id="note.addNote" />,
}: NoteCollapseProps) => {
  const [isOpen, toggleOpen] = useState(isOpened);

  useEffect(() => {
    if (isOpened && !isOpen) {
      toggleOpen(isOpened);
    }
  }, [toggleOpen, isOpened]);

  const onRemove = () => {
    onChange?.('');
    toggleOpen(false);
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.header}>
        <span onClick={() => toggleOpen(!isOpen)} className={styles.btn}>
          {label}
        </span>
        <span
          onClick={onRemove}
          className={cn(styles.btn, { [styles.hidden]: !isOpen || isDisabled })}
        >
          <FormattedMessage id="general.remove" />
        </span>
      </div>
      <Collapse isOpened={isOpen}>
        <textarea
          disabled={isDisabled}
          autoFocus={autoFocus}
          onChange={({ target: { value } }) => onChange?.(value)}
          className={styles.notes}
          value={value}
        />
      </Collapse>
    </div>
  );
};

export default NoteCollapse;
