import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
// utils
import { debounce } from 'utils';

type AppSetting = {
  isLargeScreen: boolean;
  isHeaderMounted: boolean;
};

type AppSettingData = {
  setting: AppSetting;
  setSetting: Dispatch<SetStateAction<AppSetting>>;
};

const AppSettingContext = createContext<AppSettingData>({
  setting: {
    isLargeScreen: true,
    isHeaderMounted: false,
  },
  setSetting: () => null,
});

export const useAppSetting = () => useContext(AppSettingContext);

export const AppSettingProvider: FC = ({ children }) => {
  const [setting, setSetting] = useState({
    isLargeScreen: window.innerWidth >= 1024,
    isHeaderMounted: false,
  });

  useEffect(() => {
    const handleScreenResize = debounce(() => {
      setSetting(prevState => ({ ...prevState, isLargeScreen: window.innerWidth >= 1024 }));
    }, 300);
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);

  return (
    <AppSettingContext.Provider value={{ setting, setSetting }}>
      {children}
    </AppSettingContext.Provider>
  );
};
