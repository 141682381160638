import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import ButtonTooltip from './ButtonTooltip';
// utils
import cn from 'classnames';
import { isLoadDraft, isLoadProcessing } from 'utils/loadHelper';
// types
import { Load } from 'types';
// styles
import styles from './LoadMatchingForm.module.scss';

type HandlerFunction = () => void;

type LoadFormButtonsProps = {
  applyEditLoad?: HandlerFunction;
  hasEditButtons?: boolean;
  canPublishLoad: boolean;
  isSomeSectionEditing?: boolean;
  load: Load;
  removeEditLoad?: HandlerFunction;
  saveDraft: HandlerFunction;
  saveProcessing: HandlerFunction;
};

const LoadFormButtons = ({
  applyEditLoad,
  canPublishLoad,
  hasEditButtons = false,
  isSomeSectionEditing = false,
  load,
  removeEditLoad,
  saveDraft,
  saveProcessing,
}: LoadFormButtonsProps) => {
  const isDraft = isLoadDraft(load);
  const isProcessing = isLoadProcessing(load);
  const canEdit = hasEditButtons && !!load.edit_loading && !isDraft && !isProcessing;
  return (
    <div className={styles['btn-section']}>
      <ButtonTooltip label={<FormattedMessage id="general.unsavedChanges" />}>
        {({ handleClick }) => (
          <Fragment>
            {isDraft && (
              <Button
                onClick={isSomeSectionEditing ? handleClick : saveDraft}
                className={cn(styles['submit-btn'], styles.white)}
                rounded
                shine
              >
                <FormattedMessage id="general.btn.saveDraft" />
              </Button>
            )}

            {isDraft && canPublishLoad && (
              <Button
                onClick={isSomeSectionEditing ? handleClick : saveProcessing}
                className={styles['submit-btn']}
                primary
                rounded
                shine
              >
                <FormattedMessage id="general.btn.scheduleLoad" />
              </Button>
            )}
            {canEdit && (
              <Button
                onClick={isSomeSectionEditing ? handleClick : removeEditLoad}
                className={styles['submit-btn']}
                rounded
                shine
              >
                <FormattedMessage id="general.btn.revertChanges" />
              </Button>
            )}
            {canEdit && canPublishLoad && (
              <Button
                onClick={isSomeSectionEditing ? handleClick : applyEditLoad}
                className={styles['submit-btn']}
                primary
                rounded
                shine
              >
                <FormattedMessage id="general.btn.applyChanges" />
              </Button>
            )}
          </Fragment>
        )}
      </ButtonTooltip>
    </div>
  );
};

export default LoadFormButtons;
