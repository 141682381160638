import React from 'react';
// components
import GoogleMapReact, { MapOptions } from 'google-map-react';
import HintMarker from '../Markers/HintMarker';
import TruncatedText from 'components/TruncatedText';
import Location from 'components/Location';
// utils
import useLoadMapDirectionsServices from 'hooks/useLoadMapDirectionsServices';
import { getCenterFromArray } from 'utils/googleMapHelper';
import { getLoadCommitmentAddressEntity } from 'utils/loadHelper';
// types
import { CommitmentTravelData, Load } from 'types';
// styles
import theme from 'styles/GoogleMapsGrayTheme.json';

type MapProps = {
  className: string;
  load: Load;
  setTravelData?: (commitmentId: string, data: CommitmentTravelData) => void;
  defaultZoom?: number;
  mapOptions?: MapOptions;
};

const hintThemes: { [key: string]: 'primary' | 'light-blue' | 'brand-blue' } = {
  sale: 'primary',
  transfer: 'light-blue',
  arrival: 'brand-blue',
};

const defaultMapOptions = {
  styles: theme,
  fullscreenControl: false,
  zoomControl: false,
  mapTypeControl: false,
};

const LoadDirectionsMap = ({
  className,
  defaultZoom = 12,
  mapOptions = {},
  load,
  setTravelData,
}: MapProps) => {
  const { trucking_company } = load;
  const coords = [
    ...(trucking_company ? [trucking_company] : []),
    ...load.commitments.map(getLoadCommitmentAddressEntity),
  ];
  const centerCoords = getCenterFromArray(coords);

  const { onGoogleApiLoaded, travelDistanceData } = useLoadMapDirectionsServices(
    load,
    trucking_company,
    setTravelData
  );

  return (
    <div className={className}>
      <GoogleMapReact
        onGoogleApiLoaded={onGoogleApiLoaded}
        center={centerCoords}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={defaultZoom}
        options={{ ...defaultMapOptions, ...mapOptions }}
      >
        {!!load.commitments.length &&
          load.commitments.map((commitment, idx) => {
            const addressEntity = getLoadCommitmentAddressEntity(commitment);
            const prevCommitmentId = load.commitments[idx - 1]?.id;
            return addressEntity?.lat && addressEntity?.lng ? (
              <HintMarker
                theme={hintThemes[load.load_type]}
                key={idx}
                name={
                  <Location
                    isTooltipHidden
                    nameMaxWidth={200}
                    name={addressEntity.name}
                    city={addressEntity.city}
                    state={addressEntity.state}
                  />
                }
                label={addressEntity.address}
                lat={addressEntity.lat}
                lng={addressEntity.lng}
                {...travelDistanceData[prevCommitmentId]}
              />
            ) : null;
          })}
        {trucking_company?.lat && trucking_company?.lng && (
          <HintMarker
            theme="purple"
            name={<TruncatedText text={trucking_company.name} maxWidth={250} />}
            label={<Location state={trucking_company.state} city={trucking_company.city} />}
            lat={trucking_company.lat}
            lng={trucking_company.lng}
            {...travelDistanceData.trucking}
          />
        )}
      </GoogleMapReact>
    </div>
  );
};

export default LoadDirectionsMap;
