import React, { Fragment, ReactNode } from 'react';
// components
import Tooltip from 'components/Tooltip/Tooltip2';
import PortalTooltipColumn from '../PortalTooltipColumn';
// utils, hooks
import uniq from 'lodash.uniq';
// styles
import styles from './PortalTooltipColumn.module.scss';

type TooltipColumnProps<T> = {
  className?: string;
  maxWidth?: number;
  size?: 'small' | 'medium';
  shouldBeUniq?: boolean;
  items?: T[] | null;
  emptyPlaceholder?: string | JSX.Element;
  itemRenderer?: (item: T) => ReactNode;
};

const plusMoreElemWidth = 30;

function ShowMorePortalTooltipColumn<T>({
  className,
  emptyPlaceholder,
  items = [],
  itemRenderer,
  maxWidth,
  size = 'small',
  shouldBeUniq,
}: TooltipColumnProps<T>) {
  const formattedItems = (shouldBeUniq ? uniq(items) : items)?.filter(Boolean) || [];
  const formattedItemsCount = formattedItems.length;
  const [firstItemContent] = formattedItems;
  const formattedContent = itemRenderer?.(firstItemContent) || firstItemContent;

  if (formattedItemsCount < 2) {
    return (
      <PortalTooltipColumn
        size={size}
        text={formattedContent}
        maxWidth={maxWidth}
        placeholder={emptyPlaceholder}
      />
    );
  }

  const remainingItems = formattedItems.slice(1);
  return (
    <Fragment>
      <PortalTooltipColumn
        size={size}
        text={formattedContent}
        maxWidth={maxWidth ? maxWidth - plusMoreElemWidth : undefined}
        placeholder={emptyPlaceholder}
      />
      <Tooltip
        className={className}
        wrapperClassName={styles['plus-more-counter']}
        maxWidth={maxWidth}
        size={size}
        style={{ width: plusMoreElemWidth }}
        trigger={`+${formattedItemsCount - 1}`}
        tooltipContent={remainingItems.map((item, idx) => (
          <Fragment key={idx}>
            {item}
            {idx !== remainingItems.length - 1 && <>,&nbsp;</>}
          </Fragment>
        ))}
      />
    </Fragment>
  );
}

export default ShowMorePortalTooltipColumn;
