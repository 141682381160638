import React, { ReactNode } from 'react';
// components
import Divider from 'components/Divider';
// utils
import cn from 'classnames';
// styles
import styles from './SectionWrapper.module.scss';

type SectionWrapperProps = {
  children: ReactNode;
  title: ReactNode;
  isSectionDisabled?: boolean;
};

const SectionWrapper = ({ children, title, isSectionDisabled }: SectionWrapperProps) => (
  <div className={cn(styles['section-wrapper'], { [styles.disabled]: isSectionDisabled })}>
    <div className={styles.title}>{title}</div>
    <div className={styles['wrapper-content']}>{children}</div>
    <Divider />
  </div>
);

export default SectionWrapper;
