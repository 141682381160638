import React, { useEffect, useRef, useState } from 'react';
// components
import InfoRow from 'components/InfoRow';
import Location from 'components/Location';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowIcon } from 'icons/arrow-right.svg';
// utils
import cn from 'classnames';
import debounce from 'lodash.debounce';
import { formatDate } from 'utils/formatDateHelper';
// types
import { TruckingRouteCardProps } from './TruckingRouteCard.types';
// styles
import styles from './TruckingRouteCard.module.scss';

const TruckingRouteCard = ({ source, destination, className = '' }: TruckingRouteCardProps) => {
  const [isVertical, setIsVertical] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsVertical((ref.current?.offsetWidth || 0) < 375);
    const handleScreenResize = debounce(() => {
      setIsVertical((ref.current?.offsetWidth || 0) < 375);
    }, 300);
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={cn(styles['trucking-route'], {
        [styles['is-vertical']]: isVertical,
        [className]: !!className,
      })}
    >
      <div className={styles.route}>
        <div className="bold">
          {source.farm?.name || <FormattedMessage id="general.notAvailableAbbr" />}
        </div>
        {source.farm?.logistics_contact && (
          <div>
            {source.farm.logistics_contact.full_name},&nbsp;
            {source.farm.logistics_contact.contact_phone || (
              <FormattedMessage id="general.notAvailableAbbr" />
            )}
          </div>
        )}
        <Location city={source.farm?.city} state={source.farm?.state} />
        <div>
          <InfoRow
            label={<FormattedMessage id="general.group" />}
            value={source.pig_group?.name || <FormattedMessage id="general.notAvailableAbbr" />}
          />
        </div>
      </div>
      <span className={styles.arrow}>
        <ArrowIcon width={15} height={15} />
      </span>
      <div className={styles.route}>
        <div className="bold">
          {destination.packing_plant?.name || <FormattedMessage id="general.notAvailableAbbr" />}
        </div>
        <Location city={destination.packing_plant?.city} state={destination.packing_plant?.state} />
        <InfoRow
          label={<FormattedMessage id="general.dockTime" />}
          value={
            destination.arrive_at ? (
              formatDate(destination.arrive_at, 't')
            ) : (
              <FormattedMessage id="general.notAvailableAbbr" />
            )
          }
        />
      </div>
    </div>
  );
};

export default TruckingRouteCard;
