import React, { FC, useState, useRef } from 'react';
// components
import { ReactComponent as RemoveIcon } from 'icons/times.svg';
// types
import { SectionWrapperTypes } from '../Filters.types';
// hooks
import useOnClickOutside from 'hooks/useOnClickOutside';
// styles
import classnames from 'classnames/bind';
import styles from './FilterSectionWrapper.module.scss';

const cn = classnames.bind(styles);

const FilterSectionWrapper: FC<SectionWrapperTypes> = ({
  className,
  children,
  currentValue = '',
  isInitialOpened = false,
  isDisabled = false,
  isInvalid = false,
  isRemovable = true,
  triggerIcon: Icon,
  triggerLabel,
  onClose,
  onRemove,
}) => {
  const wrapperRef = useRef(null);
  const [isOpened, setIsOpen] = useState(isInitialOpened);

  const handleClick = () => {
    if (!isDisabled) setIsOpen(!isOpened);
  };

  const handleClickOutside = () => {
    if (isOpened) {
      setIsOpen(false);
      if (typeof onClose === 'function') onClose();
    }
  };

  useOnClickOutside(wrapperRef, handleClickOutside, { isOpen: isOpened });

  return (
    <div
      ref={wrapperRef}
      onClick={handleClick}
      className={cn('section-wrapper', className, {
        'is-disabled': isDisabled,
        'is-opened': isOpened,
        'is-empty-value': !isOpened && isInvalid,
      })}
    >
      <Icon className={cn('filter-icon')} />
      <div className={cn('search-label')}>{triggerLabel}</div>
      {isRemovable && !isDisabled && (
        <RemoveIcon
          className={cn('remove-icon', { visible: isOpened || !currentValue })}
          onClick={onRemove}
        />
      )}
      {isOpened && children}
    </div>
  );
};

export default FilterSectionWrapper;
