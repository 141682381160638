import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import { MeasurementSystemType } from 'types';

export default function useMeasurementSystem(): {
  measurementSystem: MeasurementSystemType;
  weightMeasureKey: 'kg' | 'lbs';
} {
  const measurementSystem =
    useSelector((state: RootState) => state?.auth?.user?.current_company?.measurement_system) ||
    'imperial';

  const weightMeasureKey = measurementSystem === 'metric' ? 'kg' : 'lbs';

  return {
    measurementSystem,
    weightMeasureKey,
  };
}
