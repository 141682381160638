import { DateTime } from 'luxon';

export const MAX_HOUR_NUMBER = 12;

export function currentDate() {
  return DateTime.local().toFormat('yyyy-MM-dd');
}

export function getDateDiff(startDate: string, endDate: string) {
  return DateTime.fromISO(startDate)
    .diff(DateTime.fromISO(endDate), ['days'])
    .toObject();
}

export function formatISOToCustom(date = '', format = 'MM/dd/yyyy') {
  return DateTime.fromISO(date).toFormat(format);
}

export function formatDate(date: string | null | undefined, toFormat = 'MM/dd/yyyy') {
  const parsedDate = DateTime.fromISO(date || '');

  return !parsedDate.invalidReason ? parsedDate.toFormat(toFormat) : 'N/A';
}

export function formatToISO(date = '') {
  return DateTime.fromISO(date).toISO();
}

export function formatToISODate(date = '') {
  return DateTime.fromISO(date).toISODate();
}

export function formatCustomToISO(date = '', format = 'MM/dd/yyyy') {
  return DateTime.fromFormat(date, format).toISO();
}

export function formatDateToISO(date = '') {
  return DateTime.fromFormat(date, 'MM/dd/yyyy').toISODate();
}

export function formatDateHourMinute(date = '', hour = 0, minute = 0, format: string) {
  const strHour = String(hour || 0).padStart(2, '0');
  const strMinute = String(minute || 0).padStart(2, '0');
  return DateTime.fromFormat(`${date} ${strHour}:${strMinute}`, 'yyyy-MM-dd hh:mm').toFormat(
    format
  );
}

export function formatToTime(hour = 0, minute = 0) {
  const strHour = String(hour).padStart(2, '0');
  const strMinute = String(minute).padStart(2, '0');
  return DateTime.fromFormat(`${strHour}:${strMinute}`, 'hh:mm').toLocaleString(
    DateTime.TIME_SIMPLE
  );
}

export function formatDateTimeToISO(date = '', hour = 0, minute = 0) {
  const stringHour = String(hour).padStart(2, '0');
  const stringMinute = String(minute).padStart(2, '0');
  return DateTime.fromFormat(`${date}-${stringHour}:${stringMinute}`, 'MM/dd/yyyy-hh:mm').toISO();
}

export function formatDateToRelativeTimeCalendar(date: string | null | undefined, units = {}) {
  const dateDiff = DateTime.local()
    .diff(DateTime.fromISO(date || ''), ['days'])
    .toObject().days;
  if (dateDiff && dateDiff < 2 && dateDiff > -2)
    return DateTime.fromISO(date || '').toRelativeCalendar(units);
  return formatDate(date);
}

export function formatDateToTime(date?: string | null) {
  return DateTime.fromISO(date || '').toLocaleString(DateTime.TIME_SIMPLE);
}

export function formatDateToFormat(date = '', format = '') {
  const parsedDate = DateTime.fromISO(date);

  return !parsedDate.invalidReason ? parsedDate.toFormat(format) : 'N/A';
}

export function formatDateToRelativeTime(date = '') {
  const dateDiff =
    DateTime.local()
      .diff(DateTime.fromISO(date), ['seconds'])
      .toObject().seconds || 0;
  if (dateDiff < 1) return 0;
  return DateTime.fromISO(date).toRelative();
}

export const isBeforeNoon = (hour = 0) => Number(hour) < MAX_HOUR_NUMBER;

export const formatHour24To12h = (hour?: number) => {
  if (hour === 0) return MAX_HOUR_NUMBER;
  if (Number(hour) > MAX_HOUR_NUMBER) return Number(hour) - MAX_HOUR_NUMBER;
  return hour;
};

export const formatHour12To24h = (hour?: number, isAm?: boolean) => {
  if (!hour) return;
  if (isAm) {
    return hour === MAX_HOUR_NUMBER ? 0 : hour;
  }
  return hour === MAX_HOUR_NUMBER ? hour : hour + MAX_HOUR_NUMBER;
};

export const getCombinedDate = (date: string, time: string) => {
  if (time) return DateTime.fromFormat(`${date} ${time}`, 'MM/dd/yyyy HH:mm').toISO();
  return formatDateToISO(date);
};
