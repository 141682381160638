import { MetaType, SystemSide } from './common';
import { User } from './user';
import { DeclineReason, NotificationLoad } from './load';
import { SalesResultWithIncluded } from './salesResult';

export enum NotificationTypes {
  driver_declined_loading_trucking = 'driver_declined_loading_trucking',
  loading_canceled_trucking = 'loading_canceled_trucking',
  loading_created_trucking = 'loading_created_trucking',
  loading_declined_logistics = 'loading_declined_logistics',
  loading_driver_assigned_trucking = 'loading_driver_assigned_trucking',
  loading_force_accepted_trucking = 'loading_force_accepted_trucking',
  sales_result_created_logistics = 'sales_result_created_logistics',
  sales_result_expected_trucking = 'sales_result_expected_trucking',
  loading_edited_trucking = 'loading_edited_trucking',
  loading_edited_confirmation_trucking = 'loading_edited_confirmation_trucking',
}

export type NotificationType = keyof typeof NotificationTypes;

export type AppNotification = {
  id: number;
  group_name: SystemSide;
  notification_type: NotificationType;
  target: NotificationLoad | SalesResultWithIncluded | DeclineReason;
  initiator: User;
  read: boolean;
  recipient_id: number;
  trucking_company_id: number | null;
  created_at: string;
  updated_at: string;
};

export type FormattedTarget = {
  declineReason?: DeclineReason;
  load?: NotificationLoad;
  salesResult?: SalesResultWithIncluded;
  initiator: User;
};

export type NotificationsResponse = {
  meta: MetaType;
  resources: AppNotification[];
};

export type DefaultNotificationsParams = {
  page: number;
  per_page: number;
  padding?: number;
};

export type NotificationsParams = DefaultNotificationsParams & {
  group: SystemSide;
  trucking_company_id?: string | number;
  current_company_id?: number;
};
