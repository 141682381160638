import React, { useState } from 'react';
// components
import PortalTooltip from 'components/Tooltip/PortalTooltip';
// utils, hooks
import usePortalTooltipState from 'utils/hooks/usePortalTooltipState';
import cn from 'classnames';
// styles
import styles from './TruncatedText.module.scss';

type TruncatedTextProps = {
  className?: string;
  hasTooltip?: boolean;
  size?: 'small' | 'medium';
  text: string | JSX.Element;
  maxWidth?: number;
};

const TruncatedText = ({
  className = '',
  hasTooltip = false,
  maxWidth,
  size = 'medium',
  text,
}: TruncatedTextProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const { coords, isHovered, onMouseEnter, onMouseLeave } = usePortalTooltipState();
  const style = isTruncated ? { maxWidth } : undefined;
  const showTooltip = isTruncated && hasTooltip;

  return (
    <div
      className={cn(styles['truncated-text'], {
        [className]: !!className,
        [styles['is-hovered']]: isHovered,
      })}
      onMouseEnter={showTooltip ? onMouseEnter : undefined}
      onMouseLeave={showTooltip ? onMouseLeave : undefined}
    >
      <div
        style={style}
        className={cn({ 'truncated-ellipsis': isTruncated })}
        ref={ref => {
          if (ref && maxWidth && ref.offsetWidth > maxWidth) {
            setIsTruncated(true);
          }
        }}
      >
        {text}
      </div>
      {isHovered && <PortalTooltip size={size} content={text} style={style} {...coords} />}
    </div>
  );
};

export default TruncatedText;
