import React, { ReactNode, MouseEventHandler, useRef, useState } from 'react';
// utils
import cn from 'classnames';
// styles
import styles from './TruncatedTextColumn.module.scss';

type TruncatedTextColumnProps<T> = {
  children?: ReactNode;
  hasOnHoverEffect?: boolean;
  text: T;
  maxWidth?: number;
  onClick?: (isTruncated: boolean) => void;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  placeholder?: string | JSX.Element;
  setIsTruncatedCallback?: (isTruncated: boolean) => void;
};

function TruncatedTextColumn<T>({
  children,
  hasOnHoverEffect = false,
  text,
  maxWidth,
  onClick,
  placeholder = '-',
  setIsTruncatedCallback,
  ...other
}: TruncatedTextColumnProps<T>) {
  const [isTruncated, setIsTruncated] = useState(false);
  const tempRef = useRef({ isGetRefInvokedOnce: false });

  if (!text) {
    return <>{placeholder}</>;
  }

  const getRef = (ref: HTMLDivElement | null) => {
    if (!tempRef.current.isGetRefInvokedOnce) {
      if (ref && maxWidth && ref.offsetWidth > maxWidth) {
        setIsTruncated(true);
        setIsTruncatedCallback?.(true);
      }
    }
    tempRef.current.isGetRefInvokedOnce = true;
  };

  return (
    <div
      style={isTruncated ? { maxWidth } : undefined}
      onClick={() => onClick?.(isTruncated)}
      className={cn(styles['text-column'], {
        [styles['has-on-hover']]: hasOnHoverEffect,
        [styles['truncated-note']]: isTruncated,
        'truncated-ellipsis': isTruncated,
      })}
      ref={getRef}
      {...other}
    >
      {text}
      {children}
    </div>
  );
}

export default TruncatedTextColumn;
