import React from 'react';
// components
import UserAvatar from 'components/UserAvatar';
import { ReactComponent as ActivityPlaceholderIcon } from 'icons/activity-placeholder.svg';
// utils
import { getLogMessage } from './helper';
import { formatDateToFormat } from 'utils/formatDateHelper';
// types
import { CurrentUser, Load, LoadLog } from 'types';
// styles
import styles from './LoadActivity.module.scss';

type LoadActivityItemProps = {
  load: Load;
  log: LoadLog;
  authUser: CurrentUser;
};

const LoadActivityItem = ({ load, log, authUser }: LoadActivityItemProps) => (
  <div className={styles['activity-log-item']}>
    {log.user ? (
      <UserAvatar size="medium" srcData={log.user.profile_photo} name={log.user.full_name || ''} />
    ) : (
      <ActivityPlaceholderIcon />
    )}
    <div className={styles['activity-log-info']}>
      <div className={styles['activity-log-title']}>{getLogMessage(log, load, authUser)}</div>
      <div className={styles['activity-log-date']}>
        {formatDateToFormat(log.created_at, 'MMMM d, yyyy, t')}
      </div>
    </div>
  </div>
);

export default LoadActivityItem;
