export const OPTION_HEIGHT = 46;
const indent = 5; // distance between dropdown menu & dropdown trigger

function getTransformValue(isOpenTop: boolean, isOpenLeft: boolean) {
  if (isOpenTop && isOpenLeft) {
    return 'translate(-100%, -100%)';
  }
  if (isOpenTop) {
    return 'translateY(-100%)';
  }
  if (isOpenLeft) {
    return 'translateX(-100%)';
  }
  return '';
}

export function calcDropdownOffsets(
  dropdownTrigger: HTMLElement,
  optionsCount: number,
  isOpenLeft: boolean
) {
  const coords = dropdownTrigger.getBoundingClientRect();

  const dropdownHeight = optionsCount * OPTION_HEIGHT;
  const availAreaToOpenDown = window.innerHeight - coords.bottom;
  const isOpenTop = availAreaToOpenDown < dropdownHeight;
  const heightWithIndent = coords.height + indent;

  const top = isOpenTop ? coords.bottom - heightWithIndent : coords.top + heightWithIndent;

  const left = coords[isOpenLeft ? 'right' : 'left'];
  const transform = getTransformValue(isOpenTop, isOpenLeft);

  return { top, left, transform };
}
