import React, { useState, useCallback, ChangeEvent, MouseEvent, FC, SVGProps } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FilterSectionWrapper from '../FilterSectionWrapper';
import ValueMenu from '../Menu/ValueMenu';
// types
import { CountSectionType, UserFilterSectionProps } from 'types/filterSections';
import { SectionNames } from 'types';
// utils
import isEqual from 'lodash.isequal';

type CountSectionProps = UserFilterSectionProps<CountSectionType> & {
  inputLabel: JSX.Element;
  measure?: JSX.Element;
  triggerIcon: FC<SVGProps<SVGSVGElement>>;
};

function getPlaceholder(sectionType: CountSectionType['type']) {
  switch (sectionType) {
    case SectionNames.Capacity:
      return <FormattedMessage id="filters.emptyCapacity" />;
    case SectionNames.ContractCommitmentHeadCount:
      return <FormattedMessage id="filters.emptyContractHeadCount" />;
    case SectionNames.EstimatedDaysOnFeed:
      return <FormattedMessage id="filters.emptyDaysOnFeed" />;
    case SectionNames.HeadCount:
      return <FormattedMessage id="filters.emptyHeadCount" />;
    case SectionNames.PigsCount:
      return <FormattedMessage id="filters.emptyGroupInventory" />;
    case SectionNames.PigGroupEstimatedWeight:
      return <FormattedMessage id="filters.emptyAvgWeight" />;
    case SectionNames.ProjectedInventory:
      return <FormattedMessage id="filters.emptyGroupProjectedInventory" />;
    case SectionNames.SalesResultAvgPigWeight:
      return <FormattedMessage id="filters.emptyPrelimWeight" />;
    case SectionNames.Weight:
      return <FormattedMessage id="filters.emptyWeight" />;
  }
}

const initialCountFields = {
  count: '',
  count_from: '',
  count_to: '',
};

const prepareCountFields = (options: CountSectionType['options']) => {
  const values: typeof initialCountFields = { ...initialCountFields };
  options?.forEach(({ key, value }) => {
    values[key] = value;
  });
  return values;
};

const CountSection = ({
  conditions,
  isDisabled,
  inputLabel,
  measure,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
  triggerIcon,
}: CountSectionProps) => {
  const { isInitialOpened, isInvalid, condition: savedCondition, options, type } = section;
  const [state, setState] = useState({
    ...prepareCountFields(options),
    condition: savedCondition,
    isRangeError: false,
  });
  const { condition, count, count_from, count_to, isRangeError } = state;
  const placeholder = getPlaceholder(type);

  const handleConditionChange = ({ target: { name } }: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...initialCountFields,
      isRangeError: false,
      condition: name as CountSectionType['condition'],
    });
  };

  const handleInputChange = useCallback(
    ({ target: { value, name, validity } }: ChangeEvent<HTMLInputElement>) => {
      if (!validity.valid) return;
      setState(prevState => ({ ...prevState, [name]: value, isRangeError: false }));
    },
    [setState]
  );

  const handleApply = (e: MouseEvent<HTMLButtonElement>) => {
    if (condition === 'range') {
      if ((+count_from || 0) >= (+count_to || 0)) {
        e.stopPropagation();
        setState(prevState => ({ ...prevState, isRangeError: true }));
        return;
      }
      onOptionSelect(condition, [
        { key: 'count_to', value: count_to || '0' },
        { key: 'count_from', value: count_from || '0' },
      ]);
      return;
    }
    onOptionSelect(condition, [{ key: 'count', value: count }]);
  };

  const resetData = () => {
    const newState: typeof state = {
      ...prepareCountFields(options),
      condition: savedCondition,
      isRangeError: false,
    };
    const isEqualState = isEqual(newState, state);
    if (!isEqualState) setState(newState);
  };

  const triggerLabel =
    isInvalid || !savedCondition ? (
      placeholder
    ) : (
      <FormattedMessage
        id={labelKeys?.[savedCondition]}
        values={{ ...prepareCountFields(options), measure }}
      />
    );

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={triggerIcon}
      triggerLabel={triggerLabel}
    >
      <ValueMenu
        hasRangeError={isRangeError}
        inputValue={count}
        inputLabel={inputLabel}
        inputStartValue={count_from}
        inputEndValue={count_to}
        currentValue={condition}
        options={conditions}
        onConditionChange={handleConditionChange}
        onInputChange={handleInputChange}
        onApply={handleApply}
      />
    </FilterSectionWrapper>
  );
};

export default CountSection;
