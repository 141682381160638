import React from 'react';
// utils
import cn from 'classnames';
// components
import { ReactComponent as InfoIcon } from 'icons/info-circle.svg';
// styles
import styles from './SuggestionBox.module.scss';

type SuggestionBoxProps = {
  className?: string;
  buttonLabel: string | JSX.Element;
  suggestion: string | JSX.Element;
  onAccept: () => void;
  isDisabled?: boolean;
};

const SuggestionBox = ({
  className = '',
  isDisabled,
  suggestion,
  buttonLabel,
  onAccept,
}: SuggestionBoxProps) => (
  <div
    className={cn(styles['suggestion-box'], {
      [styles.disabled]: isDisabled,
      [className]: !!className,
    })}
  >
    <InfoIcon className={styles.icon} />
    <span className={styles.content}>
      {suggestion}
      <span onClick={isDisabled ? undefined : onAccept} className={cn(styles['btn'])}>
        {buttonLabel}
      </span>
    </span>
  </div>
);

export default SuggestionBox;
