import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// types
import { ConditionOptionItem, SectionNames } from 'types';

export const sectionOptionsDateFormat = 'DD-MM-YYYY';

export const checkboxProps = ['options', 'onApply', 'onConditionChange'];

export const relativePastDatesConditions = ['gt_days_ago', 'eq_days_ago', 'lt_days_ago'];
export const relativeFutureDatesConditions = ['gt_days_in', 'eq_days_in', 'lt_days_in'];
export const relativeDatesConditions = relativePastDatesConditions.concat(
  relativeFutureDatesConditions
);

type ConditionIndexKey =
  | 'contains'
  | 'containsAllOf'
  | 'containsAnyOf'
  | 'containsNoneOf'
  | 'dateAfter'
  | 'dateBefore'
  | 'dateOn'
  | 'doesNotContain'
  | 'endsWith'
  | 'exactlyDaysAgo'
  | 'exactlyDaysIn'
  | 'greaterThan'
  | 'hasAnyValue'
  | 'hasRange'
  | 'is'
  | 'isNot'
  | 'isUnknown'
  | 'lessThan'
  | 'lessThanDaysAgo'
  | 'lessThanDaysIn'
  | 'moreThanDaysAgo'
  | 'moreThanDaysIn'
  | 'notAny'
  | 'notEqual'
  | 'startsWith';

export const availSectionConditions: { [key in ConditionIndexKey]: ConditionOptionItem } = {
  contains: { value: 'cont', label: <FormattedMessage id="filters.contains" /> },
  containsAllOf: { value: 'cont_all_of', label: <FormattedMessage id="filters.all" /> },
  containsAnyOf: { value: 'cont_any_of', label: <FormattedMessage id="filters.any" /> },
  containsNoneOf: { value: 'cont_none_of', label: <FormattedMessage id="filters.none" /> },
  dateAfter: {
    value: 'gt',
    label: <FormattedMessage id="filters.after" />,
    title: <FormattedMessage id="filters.absolute" />,
  },
  dateBefore: { value: 'lt', label: <FormattedMessage id="filters.before" /> },
  dateOn: { value: 'eq', label: <FormattedMessage id="filters.on" /> },
  doesNotContain: { value: 'not_cont', label: <FormattedMessage id="filters.doesNotContain" /> },
  endsWith: { value: 'end', label: <FormattedMessage id="filters.endsWith" /> },
  exactlyDaysAgo: { value: 'eq_days_ago', label: <FormattedMessage id="filters.exactly" /> },
  exactlyDaysIn: { value: 'eq_days_in', label: <FormattedMessage id="filters.exactly" /> },
  greaterThan: { value: 'gt', label: <FormattedMessage id="filters.greaterThan" /> },
  hasAnyValue: { value: 'any', label: <FormattedMessage id="filters.hasAnyValue" /> },
  hasRange: { value: 'range', label: <FormattedMessage id="filters.hasRange" /> },
  is: { value: 'eq', label: <FormattedMessage id="filters.is" /> },
  isNot: { value: 'not_eq', label: <FormattedMessage id="filters.isNot" /> },
  isUnknown: { value: 'none', label: <FormattedMessage id="filters.isUnknown" /> },
  lessThan: { value: 'lt', label: <FormattedMessage id="filters.lessThan" /> },
  lessThanDaysAgo: { value: 'gt_days_ago', label: <FormattedMessage id="filters.lessThan" /> },
  lessThanDaysIn: { value: 'lt_days_in', label: <FormattedMessage id="filters.lessThan" /> },
  moreThanDaysAgo: {
    value: 'lt_days_ago',
    label: <FormattedMessage id="filters.moreThan" />,
    title: <FormattedMessage id="filters.relative" />,
  },
  moreThanDaysIn: {
    value: 'gt_days_in',
    label: <FormattedMessage id="filters.moreThan" />,
    title: <FormattedMessage id="filters.relativeFuture" />,
  },
  notAny: { value: 'none', label: <FormattedMessage id="filters.isUnknown" /> },
  notEqual: { value: 'not_eq', label: <FormattedMessage id="filters.isNot" /> },
  startsWith: { value: 'start', label: <FormattedMessage id="filters.startsWith" /> },
};

export const looseSowHousedOptions: ConditionOptionItem[] = [
  { value: 'yes', label: <FormattedMessage id="general.looseSowHoused" /> },
  { value: 'no', label: <FormattedMessage id="general.notLooseSowHoused" /> },
];

export const dateOptions = [
  availSectionConditions.moreThanDaysAgo,
  availSectionConditions.exactlyDaysAgo,
  availSectionConditions.lessThanDaysAgo,
  availSectionConditions.dateAfter,
  availSectionConditions.dateOn,
  availSectionConditions.dateBefore,
  availSectionConditions.hasRange,
];

export const extendedDateOptions = [
  availSectionConditions.moreThanDaysAgo,
  availSectionConditions.exactlyDaysAgo,
  availSectionConditions.lessThanDaysAgo,
  availSectionConditions.moreThanDaysIn,
  availSectionConditions.exactlyDaysIn,
  availSectionConditions.lessThanDaysIn,
  availSectionConditions.dateAfter,
  availSectionConditions.dateOn,
  availSectionConditions.dateBefore,
  availSectionConditions.hasRange,
];

export const weightSections = [
  SectionNames.Weight,
  SectionNames.PigGroupEstimatedWeight,
  SectionNames.SalesResultAvgPigWeight,
];
