import React from 'react';
// components
import { ReactComponent as TruckIcon } from 'icons/truck.svg';
// utils
import cn from 'classnames';
// types
import { TruckBadgeProps } from './TruckBadge.types';
// styles
import styles from './TruckBadge.module.scss';

const TruckBadge = ({ isActive, className }: TruckBadgeProps) => (
  <div className={cn(styles['truck-badge'], { [styles.active]: isActive }, className)}>
    <TruckIcon className={styles.icon} />
  </div>
);

export default TruckBadge;
