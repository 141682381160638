import React from 'react';
// components
import { Link as ReactRouterLink, LinkProps as ReactLinkProps } from 'react-router-dom';
// utils
import cn from 'classnames';
// types
import { LinkProps } from './Link.types';
// styles
import styles from './Link.module.scss';

const Link: React.FC<LinkProps & ReactLinkProps> = ({
  children,
  className,
  underlined,
  textColorMode = 'primary',
  ...other
}) => (
  <ReactRouterLink
    className={cn(className, styles[textColorMode], styles.link, {
      [styles.underlined]: underlined,
    })}
    {...other}
  >
    {children}
  </ReactRouterLink>
);

export default Link;
