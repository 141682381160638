import React from 'react';
// components
import Link from 'components/Link';
// utils
import classnames from 'classnames/bind';
// types
import { ButtonProps } from './Button.types';
// styles
import styles from './Button.module.scss';

const cn = classnames.bind(styles);

const Button: React.FC<ButtonProps> = ({
  children,
  id,
  tabIndex,
  onClick,
  className,
  disabled,
  type,
  isLink,
  to,
  ...other
}) => {
  const classes = {
    primary: other.primary,
    large: other.large,
    medium: other.medium,
    'full-width': other.fullWidth,
    'dark-grey': other.darkGray,
    'transparent-bordered': other.transparentBordered,
    blended: other.blended,
    disabled: disabled,
    rounded: other.rounded,
    gray: other.gray,
    decline: other.decline,
    transparent: other.transparent,
    hollow: other.hollow,
    punch: other.punch,
    blue: other.blue,
    shine: other.shine,
    light: other.light,
  };

  if (isLink)
    return (
      <Link
        to={to || ''}
        className={cn('button', 'is-link', className, classes)}
        onClick={e => (disabled ? e.preventDefault() : null)}
      >
        {children}
      </Link>
    );

  return (
    <button
      id={id}
      tabIndex={tabIndex}
      className={cn('button', className, classes)}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
