import React, { Suspense, lazy } from 'react';
// components
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import Auth from './Auth';
// pages
import Logout from 'pages/Logout';
import Notifications from 'pages/Notifications';
import SelectTruckingCompany from 'pages/SelectTruckingCompany';
import UserController from 'pages/MainLayout/UserController';
import CompanySwitch from 'pages/CompanySwitch/CompanySwitch';
import TruckingCompanySwitch from 'pages/CompanySwitch/TruckingCompanySwitch';
import ManageTableColumnsPage from '../ManageTableColumnsPage';
// utils
import { isTruckingSystem } from 'utils';
// styles
import 'flexboxgrid';
// initializing
import 'react-dates/initialize';
// lazy components
const MainLayout = lazy(() => import('pages/MainLayout'));
const Logistics = lazy(() => import('pages/Logistics'));
const Trucking = lazy(() => import('pages/Trucking'));

const App = () => {
  const { pathname } = useLocation();
  const isTrucking = isTruckingSystem(pathname);
  const isSetUserRoute = pathname === '/set-user';

  return (
    <Auth isTruckingSystem={isTrucking} isSetUserRoute={isSetUserRoute}>
      <Suspense fallback={<Loader isActive className="align-hor-ver" />}>
        <Switch>
          <Route exact path="/logout" component={Logout} />
          <Route path="/switch-company/:id" component={CompanySwitch} />
          <Route path="/switch-trucking-company/:id" component={TruckingCompanySwitch} />
          <Route exact path="/select-trucking-company" component={SelectTruckingCompany} />
          <Route path="/">
            <MainLayout isTruckingSystem={isTrucking} pathname={pathname}>
              <UserController isTruckingSystem={isTrucking} />
              <Switch>
                <Route exact path="/manage-table-columns" component={ManageTableColumnsPage} />
                <Route path="/scheduling" component={Logistics} />
                <Route path="/trucking" component={Trucking} />
                <Route
                  exact
                  path="/notifications"
                  render={routeProps => <Notifications type="logistics" {...routeProps} />}
                />
                <Redirect to="/scheduling/sales-plans" />
              </Switch>
            </MainLayout>
          </Route>
        </Switch>
      </Suspense>
    </Auth>
  );
};

export default App;
