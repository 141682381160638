import React, { useState } from 'react';
// components
import Button from 'components/Button';
import Loader from 'components/Loader';
import LoadActivityItem from './LoadActivityItem';
import { FormattedMessage } from 'react-intl';
import { Collapse } from 'react-collapse';
import { ReactComponent as AngleDownIcon } from 'icons/angle-down.svg';
// redux selectors
import useAuthUserSelector from 'store/selectors/useAuthUserSelector';
// utils
import classnames from 'classnames/bind';
// types
import { Load, LoadLog } from 'types';
// styles
import styles from './LoadActivity.module.scss';

type LoadActivityProps = {
  load: Load;
  logs: LoadLog[];
  className?: string;
  isLoading: boolean;
};

const cn = classnames.bind(styles);

const LoadActivity = ({ className, load, isLoading, logs }: LoadActivityProps) => {
  const authUser = useAuthUserSelector();
  const [isOpened, setIsOpened] = useState(true);

  const toggleCollapse = () => setIsOpened(!isOpened);

  return (
    <div className={cn(styles['activity-block'], className)}>
      <div className={styles['activity-header']}>
        <div className={styles['activity-title']}>
          <FormattedMessage id="modal.loadModal.activity" />
        </div>
        <Button
          className={cn(styles['activity-filter'], { opened: isOpened })}
          onClick={toggleCollapse}
        >
          <FormattedMessage id="modal.loadModal.history" />{' '}
          <AngleDownIcon width={14} fill="#a3a3a3" />
        </Button>
      </div>
      <Loader isActive={isLoading} className={styles['loader']} />
      <div className={styles['activity-log-wrapper']}>
        <Collapse isOpened={isOpened}>
          {logs.map((log, index) => (
            <LoadActivityItem key={index} load={load} log={log} authUser={authUser} />
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default LoadActivity;
