import React, { createContext, useState, useCallback, useContext, FC, ReactNode } from 'react';
import ModalAnimatedWrapper from 'components/Modal/ModalAnimationWrapper';

type ModalComponent = ReactNode | null;
export type ModalSetterType = (component: ModalComponent) => void;

const ModalContext = createContext((() => null) as ModalSetterType);

export const useModalSetter = () => useContext(ModalContext);

export const ModalProvider: FC = ({ children }) => {
  const [modal, setState] = useState(null);

  const setModal = useCallback(
    component => {
      setState(component);
    },
    [setState]
  );

  return (
    <ModalContext.Provider value={setModal}>
      {children}
      <ModalAnimatedWrapper>{modal}</ModalAnimatedWrapper>
    </ModalContext.Provider>
  );
};
