import React from 'react';
// types
import { CheckBoxProps } from './CheckBox.types';
// icons
import { ReactComponent as CheckIcon } from 'icons/check-circle-bts.svg';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './CheckBox.module.scss';

const cn = classnames.bind(styles);

const CheckBox = ({
  className = '',
  isChecked,
  isDisabled = false,
  onClick,
  label,
  description,
}: CheckBoxProps) => (
  <div className={cn('checkbox-wrapper', { [className]: !!className, 'is-disabled': isDisabled })}>
    <div
      className={cn('checkbox', { 'is-checked': isChecked })}
      onClick={!isDisabled ? onClick : undefined}
    >
      {isChecked && <CheckIcon className={cn('check-icon')} />}
    </div>
    {label && (
      <div className={cn('label-wrapper')}>
        <div className={cn('label')}>{label}</div>
        {description && <div className={cn('description')}>{description}</div>}
      </div>
    )}
  </div>
);

export default CheckBox;
