import React, { Fragment, useEffect, useState } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import GoogleMapReact from 'google-map-react';
import HintMarker from 'components/GoogleMap/Markers/HintMarker';
import CommitmentModalHeader from './CommitmentModalHeader';
import DotsDropdown from 'components/Dropdown/DotsDropdown';
import ContractCommitmentInfo from './ContractCommitmentInfo';
import ConfirmActionModal from '../ConfirmActionModal';
import Location from 'components/Location';
import Modal from 'components/Modal';
import ModalAnimatedWrapper from '../ModalAnimationWrapper';
// requests
import { getContractSowUnits } from 'requests/contracts';
import { cancelContractCommitment, updateContractCommitment } from 'requests/commitments';
// utils
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getCenterFromArray } from 'utils/googleMapHelper';
// types
import { ContractCommitment, Farm, PackingPlant } from 'types';
import { ContractCommitmentUpdateAttrs } from 'types/handlers';
// styles
import styles from './CommitmentModal.module.scss';
import theme from 'styles/GoogleMapsGrayTheme.json';

type CommitmentModalProps = {
  className?: string;
  commitment: ContractCommitment;
  callback?: () => void;
};

const mapOptions = {
  styles: theme,
  fullscreenControl: false,
  zoomControl: false,
  mapTypeControl: false,
};

const CommitmentModal = ({
  className,
  commitment: initialCommitment,
  callback,
}: CommitmentModalProps) => {
  const [{ commitment, isEditMode, showModal }, setData] = useState({
    commitment: initialCommitment,
    isEditMode: false,
    showModal: false,
  });
  const { packing_plant, sow_unit, contract } = commitment;
  const addressEntity = packing_plant || sow_unit;
  const [addressEntities, setEntities] = useState<(PackingPlant | Farm)[]>(
    addressEntity ? [addressEntity] : []
  );
  const isEmptyEntities = !addressEntities.length;

  useEffect(() => {
    if (isEmptyEntities && contract?.id)
      getContractSowUnits(contract.id)
        .then(setEntities)
        .catch(toastResponseErrors);
  }, [isEmptyEntities, setEntities, contract?.id]);

  const onCommitmentCancel = () => {
    cancelContractCommitment(commitment.id)
      .then(() => {
        setData(prevState => ({
          ...prevState,
          showModal: false,
          commitment: { ...prevState.commitment, status: 'canceled' },
        }));
        callback?.();
      })
      .catch(toastResponseErrors);
  };

  const handleCommitmentUpdate = (updateAttrs: ContractCommitmentUpdateAttrs) => {
    return updateContractCommitment(commitment.id, updateAttrs)
      .then(commitment => {
        setData(prevState => ({
          ...prevState,
          isEditMode: false,
          commitment: { ...prevState.commitment, ...commitment },
        }));
        callback?.();
        return commitment;
      })
      .catch(errors => {
        toastResponseErrors(errors);
        throw errors;
      });
  };

  const options = [
    {
      label: <FormattedMessage id="general.editContractInfo" />,
      isHidden: commitment.status !== 'draft',
      itemProps: {
        onClick: () => setData(prevState => ({ ...prevState, isEditMode: true })),
      },
    },
    {
      label: <FormattedMessage id="general.btn.cancel" />,
      isHidden: commitment.status !== 'draft',
      itemProps: {
        onClick: () => setData(prevState => ({ ...prevState, showModal: true })),
      },
    },
  ];

  const coords = getCenterFromArray(addressEntities);
  return (
    <Modal className={className}>
      {({ closeModal }) => (
        <Fragment>
          <ModalAnimatedWrapper>
            {showModal && (
              <ConfirmActionModal
                onHide={() => setData(prevState => ({ ...prevState, showModal: false }))}
                onContinue={onCommitmentCancel}
              >
                <FormattedMessage id="modal.confirm.cancel" />
              </ConfirmActionModal>
            )}
          </ModalAnimatedWrapper>

          <Modal.Header isOverflowVisible>
            <CommitmentModalHeader commitment={commitment} />
            <div className={cn(styles.wrapper, styles.actions)}>
              <DotsDropdown className={styles['more-tools']} options={options} />
              <Modal.CloseButton onClick={closeModal} className="ml-5" />
            </div>
          </Modal.Header>
          <Modal.Body className={styles['modal-body']}>
            <Fragment>
              <div className={styles.map}>
                <GoogleMapReact center={coords} defaultZoom={10} options={mapOptions}>
                  {addressEntities.map(
                    entity =>
                      entity.lat &&
                      entity.lng && (
                        <HintMarker
                          key={entity.id}
                          theme="brand-blue"
                          name={
                            <Location
                              name={entity.name}
                              nameMaxWidth={200}
                              isTooltipHidden
                              state={entity.state}
                              city={entity.city}
                            />
                          }
                          label={entity.address}
                          lat={entity.lat}
                          lng={entity.lng}
                        />
                      )
                  )}
                </GoogleMapReact>
              </div>
              {commitment.contract && (
                <ContractCommitmentInfo
                  addresses={addressEntities}
                  onUpdate={handleCommitmentUpdate}
                  isEditMode={isEditMode}
                  cancelEditMode={() => setData(prevState => ({ ...prevState, isEditMode: false }))}
                  commitment={commitment}
                />
              )}
            </Fragment>
          </Modal.Body>
        </Fragment>
      )}
    </Modal>
  );
};

export default CommitmentModal;
