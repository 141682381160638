import React from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import FormInput from 'components/Input/FormInput';
import IconSelect from 'components/Select/IconSelect';
import { ReactComponent as ScissorsIcon } from 'icons/scissors.svg';
// utils
import { getCutLevelOptions } from '../utils';
// types
import { OptionKeysTypes } from 'components/Select/Select.types';

type CutLevelSelectProps = {
  value?: string;
  onChange: (value: string) => void;
  isDisabled: boolean;
};

const CutLevelSelect = ({ value, onChange, isDisabled }: CutLevelSelectProps) => {
  const { formatMessage } = useIntl();
  const cutLevelOptions = getCutLevelOptions(formatMessage);
  return (
    <FormInput
      label={<FormattedMessage id="load.cutLevel" />}
      inputRenderer={() => (
        <IconSelect
          isDisabled={isDisabled}
          noBorder
          icon={ScissorsIcon}
          options={cutLevelOptions}
          placeholderText={<FormattedMessage id="general.placeholder.selectCutLevel" />}
          value={cutLevelOptions.find(option => option.value === value) || null}
          onChange={option => onChange((option as OptionKeysTypes)?.value)}
        />
      )}
    />
  );
};

export default CutLevelSelect;
