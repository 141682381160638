import React from 'react';
// utils
import classnames from 'classnames/bind';
// types
import { InputProps } from './Input.types';
// styles
import styles from './Input.module.scss';

const cn = classnames.bind(styles);

const Input: React.FC<InputProps> = ({
  className,
  large,
  bolded,
  disabled,
  hasNoBorders,
  hasError,
  inputRef,
  floatRight,
  value = '',
  defaultValue = '',
  style,
  ...others
}) => (
  <input
    style={style}
    className={cn(
      className,
      'input',
      { 'no-borders': hasNoBorders, 'has-error': hasError },
      { bolded, floatRight, disabled, large }
    )}
    ref={inputRef}
    disabled={disabled}
    value={defaultValue || value}
    {...others}
  />
);

export default Input;
