import EmptyPlaceholder from './EmptyPlaceholder';
// placeholders
import { ReactComponent as SalesNoLoads } from './placeholders/sales-no-loads.svg';
import { ReactComponent as ArrivalNoLoads } from './placeholders/arrivals-no-loads.svg';
import { ReactComponent as TransfersNoLoads } from './placeholders/transfers-no-loads.svg';
import { ReactComponent as NoContracts } from './placeholders/no-contracts.svg';
import { ReactComponent as NoData } from './placeholders/no-data.svg';
import { ReactComponent as NoLoadsScheduled } from './placeholders/no-loads-scheduled.svg';
import { ReactComponent as NoReadyGroups } from './placeholders/no-ready-groups.svg';
import { ReactComponent as NoReadyGroupsWhite } from './placeholders/no-ready-groups-white.svg';
import { ReactComponent as NoMessages } from './placeholders/no-messages.svg';

export {
  SalesNoLoads,
  ArrivalNoLoads,
  TransfersNoLoads,
  NoContracts,
  NoData,
  NoLoadsScheduled,
  NoReadyGroups,
  NoReadyGroupsWhite,
  NoMessages,
};

export default EmptyPlaceholder;
