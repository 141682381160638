import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import TruncatedText from 'components/TruncatedText';

type LocationProps = {
  isTooltipHidden?: boolean;
  nameMaxWidth?: number;
  name?: string;
  city?: string;
  state?: string;
};

const Location = ({ isTooltipHidden, city, state, name, nameMaxWidth = 250 }: LocationProps) => {
  let address = '';
  if (city) address += city;
  if (city && state) address += `, ${state}`;

  return (
    <Fragment>
      {name && (
        <TruncatedText
          hasTooltip={!isTooltipHidden}
          size="small"
          text={name}
          maxWidth={nameMaxWidth}
        />
      )}
      {name && ' • '}
      {address || <FormattedMessage id="general.notAvailableAbbr" />}
    </Fragment>
  );
};

export default Location;
