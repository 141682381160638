import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
// components
import Loader from 'components/Loader';
// redux, actions
import { connect, ConnectedProps } from 'react-redux';
import { setCurrentCompany } from 'store/actionCreators/auth';
// utils
import { getValueFromQuery } from 'utils';
// types
import { RootState } from 'store/reducers/rootReducer';

const connector = connect(
  (state: RootState) => ({
    rootCompanies: state.auth.user.root_companies,
  }),
  { setCurrentCompany }
);

type CompanySwitchProps = ConnectedProps<typeof connector> & RouteComponentProps<{ id: string }>;

const CompanySwitch: FC<CompanySwitchProps> = ({
  setCurrentCompany,
  rootCompanies,
  history: { push },
  location: { search },
  match: {
    params: { id },
  },
}) => {
  const redirectPath = getValueFromQuery(search, 'redirect_url');

  useEffect(() => {
    const company = rootCompanies?.find(({ id: company_id }) => company_id === id);
    if (rootCompanies && !company) push('/');
    if (company) {
      setCurrentCompany(company.id).then(() => {
        push(redirectPath);
      });
    }
  }, [setCurrentCompany, redirectPath, id, rootCompanies, push]);

  return <Loader isActive className="align-hor-ver" />;
};

export default connector(CompanySwitch);
