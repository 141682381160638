import React, { Fragment } from 'react';
// components
import Divider from 'components/Divider';
import { FormattedMessage } from 'react-intl';
import DotSeparator from 'components/DotSeparator';
import CommitmentInfo from 'components/CommitmentInfo';
import WaypointWithLine from 'components/WaypointWithLine';
// types
import { Load } from 'types';
// utils
import { getLoadCommitmentAddressEntity } from 'utils/loadHelper';

const LoadFlow = ({ load }: { load: Load }) => (
  <Fragment>
    {load.commitments.map((commitment, index) => {
      const addressEntity = getLoadCommitmentAddressEntity(commitment);
      const { commitment_type, farm, id, pig_group } = commitment;
      return (
        <WaypointWithLine type={commitment_type} isEnd={!load.commitments[index + 1]} key={id}>
          {addressEntity ? (
            <CommitmentInfo data={addressEntity} nameMaxWidth={350}>
              {farm && (
                <Fragment>
                  <FormattedMessage id="general.group" />
                  <DotSeparator />
                  {pig_group?.name || <FormattedMessage id="general.notAvailableAbbr" />}
                </Fragment>
              )}
            </CommitmentInfo>
          ) : (
            <div className="bold">
              <FormattedMessage id="general.notAvailableAbbr" />
            </div>
          )}
          {index !== load.commitments.length - 1 && <Divider isDashed className="mt-15 mb-15" />}
        </WaypointWithLine>
      );
    })}
  </Fragment>
);

export default LoadFlow;
