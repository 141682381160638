import React from 'react';
// types
import { RadioButtonProps } from './RadioButton.types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './RadioButton.module.scss';

const cn = classnames.bind(styles);

const RadioButton: React.FC<RadioButtonProps> = ({
  isSelected,
  onChange,
  label,
  isDisabled = false,
  className,
  name,
}) => (
  <label className={cn('radio-button', className, { disabled: isDisabled, checked: isSelected })}>
    <input
      name={name}
      className={cn('input')}
      type="radio"
      onChange={onChange}
      checked={isSelected}
      disabled={isDisabled}
    />
    <div className={cn('label')}>{label}</div>
  </label>
);

export default RadioButton;
