import React, { ChangeEvent, Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import RadioButton from 'components/RadioButton';
import { ReactComponent as GroupIcon } from 'icons/pig-groups.svg';
import FormInput from 'components/Input/FormInput';
import Input from 'components/Input';
import IconAsyncSelect from 'components/Select/AsyncSelect/IconAsyncSelect';
// utils
import cn from 'classnames';
// types
import { GroupSubSectionTypes } from '../LoadFormSections.types';
import { OptionKeysTypes } from 'components/Select/Select.types';
import { FarmPigGroup, PigGroupAssignType } from 'types';
// styles
import styles from './GroupAssignmentSubSection.module.scss';

const groupPlaceholder = (
  <div className={styles.placeholder}>
    <GroupIcon className={cn(styles.icon, 'mr-10')} />
    <FormattedMessage id="general.none" />
  </div>
);

const GroupAssignmentSubSection = ({
  isDisabled,
  barnId,
  existingGroup,
  onChange,
  assignType,
  customGroupName,
  farmType,
}: GroupSubSectionTypes) => {
  const onGroupNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ pig_group_assign_name: value });
  };

  const onAssignTypeChange = ({ target: { name } }: ChangeEvent<HTMLInputElement>) => {
    onChange({
      pig_group_assign_type: name as PigGroupAssignType,
      pig_group_assign_name: name !== 'custom_name' ? '' : customGroupName,
    });
  };

  const onGroupChange = (groupOption: OptionKeysTypes & FarmPigGroup) => {
    onChange({ pig_group: groupOption });
  };

  return (
    <FormInput
      label={<FormattedMessage id={`load.groupAssignment${farmType ? `.${farmType}` : ''}`} />}
      inputRenderer={() => (
        <Fragment>
          <RadioButton
            name="existing"
            isDisabled={isDisabled}
            isSelected={assignType === 'existing'}
            onChange={onAssignTypeChange}
            label={
              <span className="bold">
                <FormattedMessage id="load.assignToExistingGroup" />
              </span>
            }
          />
          {assignType === 'existing' && (
            <div className={styles['radio-content']}>
              <IconAsyncSelect
                noBorder
                isDisabled={isDisabled}
                optionDataFormatter={group => ({ ...group, value: group.id, label: group.name })}
                iconRenderer={() => <GroupIcon className={styles.icon} />}
                valueIconRenderer={() => <GroupIcon className={styles.icon} />}
                optionsPath={`/logistics/movements/barns_info/${barnId}/pig_groups`}
                placeholderIcon={groupPlaceholder}
                selected={
                  existingGroup ? { value: existingGroup.id, label: existingGroup.name } : undefined
                }
                onChange={onGroupChange}
              />
            </div>
          )}
          <RadioButton
            name="generate"
            isDisabled={isDisabled}
            isSelected={assignType === 'generate'}
            onChange={onAssignTypeChange}
            label={
              <span className="bold">
                <FormattedMessage id="load.assignToAutoGeneratedGroup" />
              </span>
            }
          />
          <RadioButton
            name="custom_name"
            isDisabled={isDisabled}
            isSelected={assignType === 'custom_name'}
            onChange={onAssignTypeChange}
            label={
              <span className="bold">
                <FormattedMessage id="load.assignToNewGroup" />
              </span>
            }
          />
          {assignType === 'custom_name' && (
            <div className={styles['radio-content']}>
              <FormInput
                label={
                  <div className={styles['input-label']}>
                    <FormattedMessage id="load.createGroupID" />
                  </div>
                }
                inputRenderer={() => (
                  <div className={styles['input-content']}>
                    <Input
                      disabled={isDisabled}
                      onChange={onGroupNameChange}
                      placeholder="RC-000-0000"
                      value={customGroupName}
                    />
                  </div>
                )}
              />
              <div className={cn(styles.desc, 'mb-10')}>
                <FormattedMessage id="load.assignGroup.desc" />
              </div>
            </div>
          )}
          <RadioButton
            name="skip"
            isDisabled={isDisabled}
            isSelected={assignType === 'skip'}
            onChange={onAssignTypeChange}
            label={
              <Fragment>
                <span className="bold">
                  <FormattedMessage id="load.dontAssignGroup" />
                </span>
                <span className={cn(styles.desc, 'ml-10')}>
                  <FormattedMessage id="load.dontAssignGroup.desc" />
                </span>
              </Fragment>
            }
          />
        </Fragment>
      )}
    />
  );
};

export default GroupAssignmentSubSection;
