export const EP_API_URL = process.env.REACT_APP_EP_API_URL;
export const EP_PRODUCTION_URL = process.env.REACT_APP_EP_PRODUCTION_URL;
export const WEB_PUSH_ID = process.env.REACT_APP_WEB_PUSH_ID;
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const MAP_INIT_POSITION = { lat: 41.79562614187575, lng: -74.74447008123319 };

export const ADMIN_ROLE = 'trucking_admin';
export const DRIVER_ROLE = 'driver';

export const LOAD_TYPE_PLURAL = {
  arrival: 'arrivals',
  sale: 'sales',
  transfer: 'transfers',
};

export const METERS_IN_MILE = 1609;

export const NOTIFICATION_TAB = 'notifications';
export const SCHEDULING_TAB = 'scheduling';
export const TRUCKING_TAB = 'trucking';
export const PRODUCTION_TAB = 'production';
export const ADMIN_TAB = 'admin';
export const INTERCOM_TAB = 'intercom';

export const TRUCKING_PATHS = [
  '/trucking/loads',
  '/trucking/schedule',
  '/trucking/sales-results',
  '/trucking/archive',
];

export const SCHEDULED_LIST_NAME = 'main-list';

export const COLORS_BY_LOAD_TYPE = {
  arrival: '#0069a1',
  sale: '#ff5e31',
  transfer: '#96adbd',
};
