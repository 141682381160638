import makeAxiosRequest from 'utils/api';
import { parseResponse } from 'utils/parseHelper';
import { sortLoadCommitments } from 'utils/loadHelper';
// types
import { ContractCommitment, ResponseType, Load, RequestParams } from 'types';
import {
  CreateCommitmentFunc,
  UpdateCommitmentFunc,
  ContractCommitmentUpdateAttrs,
} from 'types/handlers';

export const getContractFutureCommitments = (contractId: string): Promise<ContractCommitment[]> => {
  return makeAxiosRequest(`/logistics/contracts/${contractId}/commitments/future_list`)
    .then(parseResponse)
    .then(response => response.data);
};

export const getContractGeneratedCommitments = (
  contractId: string,
  params: RequestParams = {}
): Promise<ResponseType<ContractCommitment>> => {
  return makeAxiosRequest(`/logistics/contracts/${contractId}/commitments`, { params }).then(
    parseResponse
  );
};

export const getCommitments = (
  params: RequestParams = {}
): Promise<ResponseType<ContractCommitment>> =>
  makeAxiosRequest('/logistics/movements/contract_commitments', { params }).then(parseResponse);

export const getCommitmentsFilter = (params = {}): Promise<ResponseType<ContractCommitment>> => {
  return makeAxiosRequest('/logistics/movements/contract_commitments/filter', {
    method: 'post',
    data: params,
  }).then(parseResponse);
};

export const cancelContractCommitment = (id: string) => {
  return makeAxiosRequest(`/logistics/movements/contract_commitments/${id}/cancel`, {
    method: 'put',
  });
};

export const updateContractCommitment = (
  id: string,
  attributes: ContractCommitmentUpdateAttrs
): Promise<ContractCommitment> => {
  return makeAxiosRequest(`/logistics/contract_commitments/${id}`, {
    method: 'put',
    data: { data: { attributes } },
  })
    .then(parseResponse)
    .then(res => res.data);
};

export const createLoadCommitment: CreateCommitmentFunc = (id, attributes, meta) => {
  return makeAxiosRequest(`/logistics/loadings/${id}/commitments`, {
    method: 'post',
    data: { data: { attributes, meta } },
  })
    .then(parseResponse)
    .then(res => res.data)
    .then(sortLoadCommitments);
};

export const updateLoadCommitment: UpdateCommitmentFunc = (loadId, commitmentId, attributes) => {
  return makeAxiosRequest(`/logistics/loadings/${loadId}/commitments/${commitmentId}`, {
    method: 'put',
    data: { data: { attributes } },
  })
    .then(parseResponse)
    .then(res => res.data)
    .then(sortLoadCommitments);
};

export const updateLoadCommitmentsSequence = (loadId: string, ids: string[]): Promise<Load> => {
  return makeAxiosRequest(`/logistics/loadings/${loadId}/commitments/update_sequence`, {
    method: 'put',
    data: { data: { attributes: { commitment_ids: ids } } },
  })
    .then(parseResponse)
    .then(res => res.data)
    .then(sortLoadCommitments);
};

export const deleteLoadCommitment = (loadId: string, commitmentId: string): Promise<Load> => {
  return makeAxiosRequest(`/logistics/loadings/${loadId}/commitments/${commitmentId}`, {
    method: 'delete',
  })
    .then(parseResponse)
    .then(res => res.data)
    .then(sortLoadCommitments);
};
