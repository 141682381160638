import makeAxiosRequest, { makeAxiosRequestV1Api } from 'utils/api';
import { parseResponse } from 'utils/parseHelper';
// types
import { TokenType, UserTruckingRole, User, ResponseType } from 'types';
import { UserUpdateAttrs } from 'types/handlers';

export const getUsers = (params = {}): Promise<ResponseType<User>> => {
  return makeAxiosRequest('/truckings/users', { params }).then(parseResponse);
};

export const getUser = (userId: string): Promise<User> => {
  return makeAxiosRequest(`/truckings/users/${userId}`)
    .then(parseResponse)
    .then(response => response.data);
};

export const assignRole = (id: string, role_name: string): Promise<UserTruckingRole> => {
  return makeAxiosRequest(`/truckings/users/${id}/roles`, {
    method: 'post',
    data: { data: { attributes: { role_name } } },
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const unAssignRole = (id: string, role_id: string) => {
  return makeAxiosRequest(`/truckings/users/${id}/roles/${role_id}`, {
    method: 'delete',
  });
};

export const updateUser = (id: string, resource: UserUpdateAttrs): Promise<User> => {
  return makeAxiosRequest(`/truckings/users/${id}`, {
    method: 'put',
    data: {
      data: { attributes: resource },
    },
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const saveUserDevice = ({ tokens }: { tokens: TokenType[] }) => {
  return makeAxiosRequestV1Api('/user_devices/batch', {
    method: 'post',
    data: {
      resource: {
        tokens: tokens.map(({ iid_token, agent, action }) => ({
          iid_token,
          agent,
          action,
        })),
      },
    },
  }).catch(response => {
    throw response;
  });
};
