import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

type ReadMoreCommentProps = {
  comment?: string | null;
  length?: number;
  placeholder?: JSX.Element;
};

const ReadMoreComment = ({
  comment,
  length = 100,
  placeholder = <FormattedMessage id="general.notAvailableAbbr" />,
}: ReadMoreCommentProps) => {
  const [isReadMore, setIsReadMore] = useState(false);
  if (!comment) return placeholder;
  if (comment.length > length && !isReadMore)
    return (
      <div>
        {comment.substr(0, length)}...
        <span className="primary cursor-pointer" onClick={() => setIsReadMore(true)}>
          <FormattedMessage id="general.readMore" />
        </span>
      </div>
    );
  return <div className="pre-text">{comment}</div>;
};

export default ReadMoreComment;
