import React from 'react';
// components
import Link from 'components/Link';
import BadgeColumn from '../BadgeColumn';
// utils
import { formatDate } from 'utils/formatDateHelper';
// types
import { FormatMessageFunc, SalesResultWithIncluded, RendererType, Column } from 'types';
// renderers
import { renderTooltipColumn, renderUserAvatarColumn } from '../columnRenderers';
// styles
import styles from '../Columns.module.scss';

const renderDeliveredDateTime: RendererType<SalesResultWithIncluded> = (
  { delivered_at },
  { emptyPlaceholder }
) => (
  <BadgeColumn
    data={delivered_at ? formatDate(delivered_at, 'MM/dd/yy • h:mm a') : ''}
    placeholder={emptyPlaceholder}
  />
);

const renderLogisticsLoadNumber: RendererType<SalesResultWithIncluded> = ({
  loading: { id, name },
}) => (
  <Link to={`/scheduling/loads/${id}`} className={styles['results-load-name']}>
    {name}
  </Link>
);

const renderTruckingLoadNumber: RendererType<SalesResultWithIncluded> = ({
  loading: { id, name },
}) => (
  <Link
    to={`/trucking/archive/${id}/sales-results?from=/trucking/sales-results`}
    className={styles['results-load-name']}
  >
    {name}
  </Link>
);

export const truckingResultsColumns = [
  'loadNameTrucking',
  'targetHead',
  'headCount',
  'headDelivered',
  'avgPigWeight',
  'prelimWeight',
  'plantName',
  'contractName',
  'originName',
  'deliveryDateTime',
  'status',
  'user',
];

export const getSalesResultsColumns = (
  formatMessage: FormatMessageFunc
): Record<string, Column<SalesResultWithIncluded>> => ({
  loadName: {
    label: formatMessage({ id: 'loadsTable.loadNumber' }),
    renderer: renderLogisticsLoadNumber,
    width: 200,
    sortable: 'loading_name',
  },
  loadNameTrucking: {
    label: formatMessage({ id: 'loadsTable.loadNumber' }),
    renderer: renderTruckingLoadNumber,
    width: 200,
    sortable: 'loading_name',
  },
  targetHead: {
    label: formatMessage({ id: 'general.targetHead' }),
    dataPath: 'destination_loading_commitment.contract_commitment.head_count',
    rendererType: 'badge',
    sortable: 'contract_commitment_head_count',
  },
  headCount: {
    label: formatMessage({ id: 'general.headScheduled' }),
    dataPath: 'destination_loading_commitment.head_count',
    rendererType: 'badge',
    sortable: 'destination_loading_commitment_head_count',
  },
  headDelivered: {
    label: formatMessage({ id: 'loadsTable.prelimHeadDelivered' }),
    dataPath: 'head_count',
    rendererType: 'badge',
    width: 180,
    sortable: 'head_count',
  },
  avgPigWeight: {
    label: formatMessage({ id: 'load.avgWeight' }),
    dataPath: 'avg_pig_weight',
    rendererType: 'weight',
    sortable: 'avg_pig_weight',
  },
  prelimWeight: {
    label: formatMessage({ id: 'loadsTable.prelimWeight' }),
    dataPath: 'total_weight',
    rendererType: 'weight',
    sortable: 'total_weight',
  },
  plantName: {
    label: formatMessage({ id: 'general.plant' }),
    renderer: ({ destination_loading_commitment }, data) =>
      renderTooltipColumn(destination_loading_commitment?.packing_plant?.name || '', {
        ...data,
        valueMaxWidth: 230,
      }),
    sortable: 'packing_plant_name',
    width: 250,
  },
  contractName: {
    label: formatMessage({ id: 'general.contract' }),
    renderer: ({ destination_loading_commitment }, data) =>
      renderTooltipColumn(
        destination_loading_commitment?.contract_commitment?.contract?.name || '',
        {
          ...data,
          valueMaxWidth: 230,
        }
      ),
    sortable: 'contract_name',
    width: 250,
  },
  originName: {
    label: formatMessage({ id: 'loadsTable.origin' }),
    renderer: ({ source_loading_commitment }, data) =>
      renderTooltipColumn(source_loading_commitment?.farm?.name || '', {
        ...data,
        valueMaxWidth: 180,
      }),
    sortable: 'farm_name',
    width: 200,
  },
  truckingName: {
    label: formatMessage({ id: 'loadsTable.trucking' }),
    renderer: ({ loading }, data) =>
      renderTooltipColumn(loading?.trucking_company?.name || '', {
        ...data,
        valueMaxWidth: 180,
      }),
    sortable: 'trucking_company_name',
    width: 200,
  },
  deliveryDateTime: {
    label: formatMessage({ id: 'general.deliveryDate' }),
    renderer: renderDeliveredDateTime,
    sortable: 'delivered_at',
  },
  status: {
    label: formatMessage({ id: 'general.status' }),
    rendererType: 'badgeStatus',
    dataPath: 'loading.status',
    width: 100,
    sortable: 'loading_status',
  },
  user: {
    label: '',
    renderer: ({ modified_by }) => renderUserAvatarColumn(modified_by),
    emptyPlaceholder: '',
    width: 40,
  },
});
