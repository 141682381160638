import React from 'react';
// utils
import cn from 'classnames';
// styles
import styles from './Loader.module.scss';

interface Props {
  isActive: boolean;
  isCentered?: boolean;
  isSmall?: boolean;
  className?: string;
}

const Loader = ({ isActive, isCentered = false, isSmall = false, className = '' }: Props) => (
  <div
    className={cn(styles.loader, className, {
      [styles.active]: isActive,
      [styles.centered]: isCentered,
      [styles.small]: isSmall,
    })}
  />
);

export default Loader;
