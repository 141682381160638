import React, { useEffect, useState, useRef, Fragment } from 'react';
// redux
import loadDataConnector, { ConnectedLoadProps } from 'store/connectors/loadDataConnector';
// components
import LoadActivity from 'components/LoadActivity';
import LoadDirectionsMap from 'components/GoogleMap/LoadDirectionsMap';
import LoadModalHeader from './LoadModalHeader';
import LoadModalTabs from './LoadModalTabs';
import HeaderLoader from 'components/Loader/HeaderLoader';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import DotsDropdown from 'components/Dropdown/DotsDropdown';
import ModalAnimatedWrapper from '../ModalAnimationWrapper';
import ConfirmActionModal from 'components/Modal/ConfirmActionModal';
import { FormattedMessage } from 'react-intl';
// hooks
import useLoadDropdownOptions from 'hooks/useLoadDropdownOptions';
// utils
import cn from 'classnames';
import { bold } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// types
import { ConfirmationType, ConfirmData, LoadModalProps } from './LoadModal.types';
// styles
import styles from './LoadModal.module.scss';

const LoadModal = (props: ConnectedLoadProps & LoadModalProps) => {
  const dataRef = useRef({ isCallbackNeedToRun: false, isLoadFetched: false });
  const {
    className,
    callback,
    createLoadSalesResults,
    fetchLoadWithLogs,
    id,
    isLoadLoading,
    isLogsLoading,
    load,
    loadLogs,
    resetLoadState,
    updateLoadData,
    declineLoad,
    unMatchLoadData,
    resetLoadData,
    toggleLockSalesResult,
    updateCommitment,
    updateLoadSalesResults,
  } = props;
  const hasCallback = !!callback;

  useEffect(() => {
    fetchLoadWithLogs(id);
    return () => {
      resetLoadState();
    };
  }, [id, fetchLoadWithLogs, resetLoadState]);

  useEffect(() => {
    if (!load?.id || !hasCallback) return;
    if (!dataRef.current.isLoadFetched) {
      dataRef.current.isLoadFetched = true;
      return;
    }
    dataRef.current.isCallbackNeedToRun = true;
  }, [load?.status, load?.commitments, load?.trucking_company?.id, load?.id, hasCallback]);

  const [visibleConfirmType, setVisibleConfirmType] = useState<ConfirmationType>();

  const hideConfirmationModal = () => setVisibleConfirmType(undefined);

  const onLoadDecline = () => {
    declineLoad(id)
      .then(hideConfirmationModal)
      .catch(toastResponseErrors);
  };

  const onLoadUnmatch = () => {
    unMatchLoadData(id)
      .then(hideConfirmationModal)
      .catch(toastResponseErrors);
  };

  const onLoadReset = () => {
    resetLoadData(id)
      .then(hideConfirmationModal)
      .catch(toastResponseErrors);
  };

  const onRevertToDraft = () => {
    updateLoadData(id, { status: 'draft' })
      .then(hideConfirmationModal)
      .catch(toastResponseErrors);
  };

  const onCloseCallback = () => {
    if (callback && dataRef.current.isCallbackNeedToRun) callback();
  };

  const confirmData: ConfirmData = {
    decline: {
      messageId: 'modal.confirm.declineLoad',
      handleConfirm: onLoadDecline,
    },
    revert: {
      messageId: 'modal.confirm.revertToDraft',
      handleConfirm: onRevertToDraft,
    },
    unmatch: {
      messageId: 'modal.confirm.unMatch',
      handleConfirm: onLoadUnmatch,
    },
    reset: {
      messageId: 'modal.confirm.loadReset',
      handleConfirm: onLoadReset,
    },
  };

  const options = useLoadDropdownOptions({
    ...props,
    handleLoadDecline: () => setVisibleConfirmType('decline'),
    handleLoadUnmatch: () => setVisibleConfirmType('unmatch'),
    handleLoadReset: () => setVisibleConfirmType('reset'),
  });

  return (
    <Modal closeCallback={onCloseCallback} className={className}>
      {({ closeModal }) => (
        <Fragment>
          <Loader isActive={!load?.id || isLoadLoading} className="align-hor-ver" />

          <ModalAnimatedWrapper>
            {visibleConfirmType && (
              <ConfirmActionModal
                onHide={hideConfirmationModal}
                onContinue={confirmData[visibleConfirmType].handleConfirm}
              >
                <FormattedMessage
                  id={confirmData[visibleConfirmType].messageId}
                  values={{ name: load?.name, b: bold }}
                />
              </ConfirmActionModal>
            )}
          </ModalAnimatedWrapper>

          <Modal.Header isOverflowVisible>
            <HeaderLoader isActive={!load?.id} type="square">
              {!!load && <LoadModalHeader load={load} />}
              <div className={cn(styles.wrapper, styles.actions)}>
                <DotsDropdown className={styles['more-tools']} options={options} />
                <Modal.CloseButton onClick={closeModal} className="ml-5" />
              </div>
            </HeaderLoader>
          </Modal.Header>

          <Modal.Body className={styles['modal-body']}>
            {load?.id && (
              <Fragment>
                <div className={styles['load-activity']}>
                  <LoadDirectionsMap load={load} className={styles.map} />
                  <LoadActivity load={load} logs={loadLogs} isLoading={isLogsLoading} />
                </div>
                <LoadModalTabs
                  className={styles['tab-wrapper']}
                  load={load}
                  updateLoad={attrs => updateLoadData(load.id, attrs)}
                  updateCommitment={updateCommitment}
                  revertToDraft={() => setVisibleConfirmType('revert')}
                  updateSalesResults={attrs => updateLoadSalesResults(load.id, attrs)}
                  createSalesResults={attrs => createLoadSalesResults(load.id, attrs)}
                  toggleLockResult={resultId => toggleLockSalesResult(load, resultId)}
                />
              </Fragment>
            )}
          </Modal.Body>
        </Fragment>
      )}
    </Modal>
  );
};

export default loadDataConnector(LoadModal);
