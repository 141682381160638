import React, { FC, ReactNode, SVGAttributes, SVGProps } from 'react';
// utils
import cn from 'classnames';
// styles
import styles from './LabelValueRow.module.scss';

const iconProps: SVGAttributes<SVGSVGElement> = {
  width: 22,
  height: 22,
  color: '#ff5e31',
};

type Props = {
  isValueHovered?: boolean;
  isValueHasPaddings?: boolean;
  label: string | ReactNode;
  value: string | number | ReactNode;
  className?: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  customIconProps?: SVGAttributes<SVGSVGElement>;
};

const LabelValueRow = ({
  className = '',
  customIconProps = {},
  label,
  value,
  isValueHovered = true,
  isValueHasPaddings = true,
  icon: Icon,
}: Props) => (
  <div className={cn(styles.row, { [className]: !!className })}>
    <div className={styles.label}>{label}</div>
    <div
      className={cn(styles.value, {
        [styles['is-hovered']]: isValueHovered,
        [styles['has-paddings']]: isValueHasPaddings,
      })}
    >
      {!!Icon && <Icon {...iconProps} {...customIconProps} className="mr-10" />}
      {value}
    </div>
  </div>
);

export default LabelValueRow;
