import React from 'react';
import ReactDOM from 'react-dom';
// components, providers
import App from './pages/App';
import { AppSettingProvider } from 'context/useAppSettingData';
import ReactIntlProvider from 'components/ReactIntlProvider';
import store from 'store/reduxStore';
import { Provider } from 'react-redux';
import { ModalProvider } from 'context/useModal';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// utils, services
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorker from './serviceWorker';
import { createAirbrakeClient } from 'utils/airbrakeHelper';
// styles
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const queryClient = new QueryClient();

global.Airbrake = createAirbrakeClient();

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactIntlProvider>
          <Router>
            <ModalProvider>
              <ToastContainer
                className="logistics-toast"
                hideProgressBar
                closeOnClick={false}
                autoClose={4000}
              />
              <AppSettingProvider>
                <App />
              </AppSettingProvider>
            </ModalProvider>
          </Router>
        </ReactIntlProvider>
      </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./pages/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
