import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import NotificationBody from './NotificationBody';
import { Waypoint } from 'react-waypoint';
// utils
import classnames from 'classnames/bind';
// types
import { AppNotification } from 'types';
// styles
import styles from './NotificationItem.module.scss';

const cn = classnames.bind(styles);

type NotificationItemProps = {
  notification: AppNotification;
  isDropdownView?: boolean;
  hasTodayDivider: boolean;
  hasEarlierDivider: boolean;
  onMarkAsRead: (id: number) => void;
  onDismiss: (id: number) => void;
};

const NotificationItem = ({
  hasTodayDivider,
  hasEarlierDivider,
  isDropdownView = false,
  onMarkAsRead,
  onDismiss,
  notification,
}: NotificationItemProps) => {
  const hasTopDivider = (hasTodayDivider || hasEarlierDivider) && isDropdownView;
  return (
    <Fragment>
      {hasTodayDivider && (
        <div className={cn('divider', { 'has-padding': isDropdownView })}>
          <FormattedMessage id="general.time.today" />
          {!isDropdownView && <hr className={cn('horizontal-line')} />}
        </div>
      )}
      {hasEarlierDivider && (
        <div className={cn('divider', { 'has-padding': isDropdownView })}>
          <FormattedMessage id="general.time.earlier" />
          {!isDropdownView && <hr className={cn('horizontal-line')} />}
        </div>
      )}
      {!isDropdownView && (
        <div className={cn('notification-item')}>
          <div className={cn('triangle-mark', { read: notification.read })} />
          <NotificationBody
            hasTopDivider={hasTopDivider}
            notification={notification}
            handleDismissAction={onDismiss}
          />
          {!notification.read && <Waypoint onEnter={() => onMarkAsRead(notification.id)} />}
        </div>
      )}
      {isDropdownView && (
        <NotificationBody
          isDropdownView
          hasTopDivider={hasTopDivider}
          notification={notification}
          handleDismissAction={onDismiss}
        />
      )}
    </Fragment>
  );
};

export default NotificationItem;
