import React from 'react';
import { createPortal } from 'react-dom';

const portalRoot = document.getElementById('portal-root');

const Portal: React.FC = ({ children }) => {
  return portalRoot && children ? createPortal(children, portalRoot) : null;
};

export default Portal;
