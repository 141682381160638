import React, { ChangeEvent } from 'react';
// components
import AvatarCropperModal from 'components/Modal/AvatarCropperModal';
import { ReactComponent as CameraIcon } from 'icons/camera.svg';
// context
import { useModalSetter } from 'context/useModal';
// utils
import { formatSrcWithApiUrl, readFile } from 'utils';
import cn from 'classnames';
// types
import { UserAvatarUploadButtonProps } from '../UserAvatar.types';
// styles
import styles from './UserAvatarUploadButton.module.scss';

const UserAvatarUploadButton = ({ avatarSrc, className }: UserAvatarUploadButtonProps) => {
  const setModal = useModalSetter();

  const openModal = (src = '') => {
    setModal(<AvatarCropperModal avatarSrc={src} />);
  };

  const uploadAvatar = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    readFile(files, openModal);
  };

  return (
    <label
      className={cn(styles['avatar-label-wrapper'], className)}
      onClick={avatarSrc ? () => openModal(formatSrcWithApiUrl(avatarSrc)) : undefined}
    >
      <CameraIcon className={styles['camera-icon']} width={20} />
      <input
        value=""
        disabled={!!avatarSrc}
        onChange={uploadAvatar}
        className={styles['upload-input']}
        type="file"
        accept="image/jpeg, image/png"
      />
    </label>
  );
};

export default UserAvatarUploadButton;
