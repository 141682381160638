import React, { FC, SVGProps } from 'react';
// components
import Select from './Select';
import { ReactComponent as CheckIcon } from '../../icons/check.svg';
// types
import { SelectProps } from './Select.types';
import { OptionTypeBase } from 'react-select';
// utils
import cn from 'classnames';
// styles
import './Select.scss';

type IconSelectType = {
  icon: FC<SVGProps<SVGSVGElement>>;
};

const optionRenderer = ({ innerRef, innerProps, isSelected, label }: OptionTypeBase) => (
  <div ref={innerRef} {...innerProps}>
    <div className="option">
      <div className="name">{label}</div>
      {isSelected && <CheckIcon className="selected-icon" />}
    </div>
  </div>
);

const IconSelect = ({
  icon: Icon,
  className,
  placeholderText,
  ...others
}: SelectProps & IconSelectType) => {
  const formatOptionLabel = (option: OptionTypeBase) => (
    <span className="option-label">
      <Icon className="label-icon" />
      <div className="name">{option.label}</div>
    </span>
  );

  const placeholder = (
    <div className="custom-placeholder">
      <Icon className="placeholder-icon" />
      {placeholderText}
    </div>
  );

  return (
    <Select
      {...others}
      placeholder={placeholder}
      className={cn('IconSelectWrapper', className)}
      formatOptionLabel={formatOptionLabel}
      optionRenderer={optionRenderer}
    />
  );
};

export default IconSelect;
