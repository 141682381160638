import { User, UserTruckingRole } from 'types';
import { UserInviteAttrs } from 'types/handlers';
import { CurrentUser } from 'types/user';

export function generateNickname(
  { last_name = '', first_name = '', nickname = '' }: UserInviteAttrs,
  newFirstName = '',
  newLastName = ''
): string {
  const isAutoGeneratedNickname = nickname === first_name + last_name;
  return isAutoGeneratedNickname ? newFirstName + newLastName : nickname;
}

export const isRole = (roles?: UserTruckingRole[], currentRole = ''): boolean =>
  !!roles?.find(role => role.name === currentRole);

export const formatUserForIntercom = (user: CurrentUser) =>
  user.id
    ? {
        user_id: user.id,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        phone: user.phone || '',
        country_code: user.country_code,
        company: {
          id: user?.current_company?.id,
          name: user?.current_company?.name,
        },
        is_driver: user.is_driver,
        is_trucking_admin: user.is_trucking_admin,
      }
    : {};

export const isUserInactive = (user?: User) => {
  return !!user && !user.active;
};
