import React from 'react';
// components
import AsyncSelect from 'components/Select/AsyncSelect';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
// utils
import cn from 'classnames';
// types
import { OptionTypeBase } from 'react-select';
import { AsyncIconSelectProps } from 'components/Select/Select.types';
// styles
import styles from './IconAsyncSelect.module.scss';

const IconAsyncSelect = ({
  iconRenderer,
  valueIconRenderer,
  placeholderIcon,
  optionDataFormatter,
  ...other
}: AsyncIconSelectProps) => {
  const optionRenderer = ({ innerRef, innerProps, isSelected, ...other }: OptionTypeBase) => (
    <div ref={innerRef} {...innerProps}>
      <div className={styles.option}>
        {iconRenderer(other)}
        <div className={cn(styles.name, 'truncated-ellipsis pr-20')}>{other.label}</div>
        {isSelected && <CheckIcon className={styles['selected-icon']} />}
      </div>
    </div>
  );

  const formatOptionLabel = (option: OptionTypeBase) => (
    <span className={styles['option-label']}>
      {valueIconRenderer && valueIconRenderer(option)}
      <div className={cn(styles.name, 'truncated-ellipsis pr-10')}>{option.label}</div>
    </span>
  );

  return (
    <AsyncSelect
      {...other}
      formatOptionLabel={formatOptionLabel}
      optionRenderer={optionRenderer}
      optionDataFormatter={optionDataFormatter}
      placeholder={placeholderIcon || ''}
    />
  );
};

export default IconAsyncSelect;
