import React, { Fragment } from 'react';
// components
import Badge from 'components/Badge';
import BadgeColumn from '../BadgeColumn';
import BadgeDateColumn from '../BadgeColumn/BadgeDateColumn';
import FormattedWeight from 'components/FormattedWeight';
import PortalTooltipColumn from '../PortalTooltipColumn';
// utils, renderers
import pick from 'lodash.pick';
import { getColumns } from 'utils/manageColumnsHelper';
import {
  renderShowMoreColumn,
  renderTooltipColumn,
  renderUserAvatarColumn,
} from '../columnRenderers';
// types
import {
  AdditionalRendererData,
  Column,
  Farm,
  FarmPigGroup,
  FormatMessageFunc,
  RendererType,
  User,
} from 'types';

const sourcedGroupsColumnsKeys = [
  'groupName',
  'farmNameWithBadge',
  'startDate',
  'inventory',
  'projectedInventory',
  'groupAvgWeight',
  'externalSource',
  'maleGeneticNames',
  'looseSowHoused',
];

const renderFarmNameWithType = (
  farm: Farm | undefined,
  { emptyPlaceholder, valueMaxWidth }: AdditionalRendererData & { valueMaxWidth?: number }
) => {
  return (
    <Fragment>
      <PortalTooltipColumn
        text={farm?.name}
        size="small"
        maxWidth={valueMaxWidth ? valueMaxWidth - 25 : undefined}
        placeholder={emptyPlaceholder}
      />
      {farm?.farm_type && <Badge className="ml-5" label={farm.farm_type} />}
    </Fragment>
  );
};

const renderStartDate: RendererType<FarmPigGroup> = ({ started_on }, { emptyPlaceholder }) => {
  return <BadgeDateColumn data={started_on || ''} placeholder={emptyPlaceholder} />;
};

export const getGroupsColumnsData = (
  formatMessage: FormatMessageFunc
): Record<string, Column<FarmPigGroup>> => ({
  farmName: {
    label: formatMessage({ id: 'general.farm' }),
    renderer: ({ farm }, data) =>
      renderTooltipColumn(farm?.name || '', { ...data, valueMaxWidth: 230 }),
    sortable: 'farm_name',
    width: 250,
  },
  farmNameWithBadge: {
    label: formatMessage({ id: 'general.farm' }),
    renderer: ({ farm }, data) => renderFarmNameWithType(farm, { ...data, valueMaxWidth: 230 }),
    sortable: 'farm_name',
    width: 250,
  },
  groupName: {
    label: formatMessage({ id: 'general.group' }),
    dataPath: 'name',
    sortable: 'name',
    width: 200,
  },
  startDate: {
    label: formatMessage({ id: 'general.startDate' }),
    renderer: renderStartDate,
    sortable: 'started_on',
  },
  inventory: {
    label: formatMessage({ id: 'general.inventory' }),
    dataPath: 'pigs',
    rendererType: 'badge',
    sortable: 'pigs',
  },
  projectedInventory: {
    label: formatMessage({ id: 'general.projectedInventory' }),
    dataPath: 'projected_inventory',
    sortable: 'projected_inventory',
    renderer: ({ projected_inventory }) => (
      <BadgeColumn
        data={Math.max(0, projected_inventory || 0)}
        isEmpty={data => data === null || data === undefined}
        placeholder="N/A"
      />
    ),
  },
  groupAvgWeight: {
    label: formatMessage({ id: 'table.headers.avgWt' }),
    sortable: 'estimated_weight',
    renderer: ({ estimated_weight }) => (
      <BadgeColumn data={<FormattedWeight value={estimated_weight} />} placeholder="N/A" />
    ),
  },
  recentSaleAvgWeight: {
    label: formatMessage({ id: 'general.recentSaleAvgWeight' }),
    sortable: 'last_sale_avg_pig_weight',
    renderer: ({ last_sale_avg_pig_weight }) => (
      <BadgeColumn data={<FormattedWeight value={last_sale_avg_pig_weight} />} placeholder="N/A" />
    ),
  },
  daysOnFeed: {
    label: formatMessage({ id: 'general.daysOnFeed' }),
    emptyPlaceholder: 'N/A',
    dataPath: 'estimated_days_on_feed',
    rendererType: 'badge',
    sortable: 'estimated_days_on_feed',
  },
  cutLevel: {
    label: formatMessage({ id: 'general.latestCut' }),
    emptyPlaceholder: 'N/A',
    renderer: ({ cut_level }) => (
      <BadgeColumn
        data={cut_level ? formatMessage({ id: `cutLevel.abbr.${cut_level}` }) : ''}
        placeholder="N/A"
      />
    ),
    sortable: 'cut_level',
    width: 90,
  },
  firstSaleOn: {
    label: formatMessage({ id: 'general.earliestSaleDate' }),
    dataPath: 'first_sale_on',
    rendererType: 'badge',
    sortable: 'first_sale_on',
  },
  lastSaleOn: {
    label: formatMessage({ id: 'general.latestSaleDate' }),
    dataPath: 'last_sale_on',
    rendererType: 'badge',
    sortable: 'last_sale_on',
  },
  lastPackingPlant: {
    label: formatMessage({ id: 'general.latestPlant' }),
    renderer: ({ last_packing_plant }, data) =>
      renderTooltipColumn(last_packing_plant?.name || '', { ...data, valueMaxWidth: 180 }),
    sortable: 'last_packing_plant_name',
    width: 200,
  },
  externalSource: {
    label: formatMessage({ id: 'general.externalSource' }),
    renderer: ({ external_source_name }, data) =>
      renderTooltipColumn(external_source_name || '', { ...data, valueMaxWidth: 180 }),
    sortable: 'external_source_name',
    width: 200,
  },
  maleGeneticNames: {
    label: formatMessage({ id: 'general.maleGenetic' }),
    renderer: ({ male_genetic_names }, data) =>
      renderShowMoreColumn(male_genetic_names || [], { ...data, valueMaxWidth: 130 }),
    sortable: 'male_genetic_names',
    width: 150,
  },
  looseSowHoused: {
    label: formatMessage({ id: 'general.looseSowHoused' }),
    sortable: 'loose_sow_housed',
    dataPath: 'loose_sow_housed',
    rendererType: 'boolean',
  },
  user: {
    label: '',
    emptyPlaceholder: '',
    width: 40,
    renderer: (data: FarmPigGroup & { modified_by?: User }) =>
      renderUserAvatarColumn(data.modified_by),
  },
});

export function getSourcesPigGroupsColumns(fm: FormatMessageFunc) {
  return Object.values(pick(getGroupsColumnsData(fm), sourcedGroupsColumnsKeys));
}

export function getWorkSheetGroupsColumns(fm: FormatMessageFunc) {
  return getColumns(getGroupsColumnsData(fm), 'worksheetGroups');
}
