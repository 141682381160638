import makeAxiosRequest from 'utils/api';
// utils
import { sortLoadCommitments } from 'utils/loadHelper';
import { parseResponse } from 'utils/parseHelper';
// types
import {
  ExtendedLoad,
  HistoryPigGroupCommitment,
  Load,
  LoadCommitmentWithLoad,
  ResponseType,
  TruckingTerm,
} from 'types';
import {
  GetLoadLogsFunction,
  UpdateLoadCommitmentFunction,
  LoadFunction,
  UpdateLoadFunction,
  SalesResultCreateAttrs,
  SalesResultUpdateAttrs,
} from 'types/handlers';

export const getLoads = (params = {}): Promise<ResponseType<ExtendedLoad>> => {
  return makeAxiosRequest('/logistics/loadings', { params }).then(parseResponse);
};

export const getPlanLoads = (
  planId: string,
  params?: { sort?: string }
): Promise<ExtendedLoad[]> => {
  return makeAxiosRequest(`/logistics/plans/${planId}/loadings`, { params })
    .then(parseResponse)
    .then(response => response.data);
};

export const applyPlanLoadsFilter = (planId: string, data = {}): Promise<ExtendedLoad[]> => {
  return makeAxiosRequest(`/logistics/plans/${planId}/loadings/filter`, {
    method: 'post',
    data,
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const getLoadsCsv = (params = {}) => {
  return makeAxiosRequest('/logistics/loadings.csv', { params, responseType: 'blob' });
};

export const getLoad: LoadFunction = id => {
  return makeAxiosRequest(`/logistics/loadings/${id}`)
    .then(parseResponse)
    .then(response => response.data)
    .then(sortLoadCommitments);
};

export const getLoadHistory: GetLoadLogsFunction = id => {
  return makeAxiosRequest(`/logistics/loadings/${id}/logs`)
    .then(parseResponse)
    .then(response => response.data);
};

export const updateLoad: UpdateLoadFunction = (id, attributes) => {
  return makeAxiosRequest(`/logistics/loadings/${id}`, {
    method: 'PUT',
    data: { data: { attributes } },
  })
    .then(parseResponse)
    .then(response => response.data)
    .then(sortLoadCommitments);
};

export const createEditLoad = (id: string): Promise<Load> => {
  return makeAxiosRequest(`/logistics/loadings/${id}/edit_loading`, {
    method: 'post',
  })
    .then(parseResponse)
    .then(response => response.data)
    .then(sortLoadCommitments);
};

export const saveEditLoad = (id: string): Promise<Load> => {
  return makeAxiosRequest(`/logistics/loadings/${id}/edit_loading/save_edit`, {
    method: 'put',
  })
    .then(parseResponse)
    .then(response => response.data)
    .then(sortLoadCommitments);
};

export const deleteEditLoad = (id: string): Promise<Load> => {
  return makeAxiosRequest(`/logistics/loadings/${id}/edit_loading`, {
    method: 'delete',
  })
    .then(parseResponse)
    .then(response => response.data)
    .then(sortLoadCommitments);
};

export const unMatchLoad: LoadFunction = id => {
  return makeAxiosRequest(`/logistics/loadings/${id}/reset`, {
    method: 'PUT',
  })
    .then(parseResponse)
    .then(response => response.data)
    .then(sortLoadCommitments);
};

export const resetLoad: LoadFunction = id => {
  return makeAxiosRequest(`/logistics/loadings/${id}/reset`, {
    method: 'PUT',
    params: { full_reset: true },
  })
    .then(parseResponse)
    .then(response => response.data)
    .then(sortLoadCommitments);
};

export const resetLoadCommitment: UpdateLoadCommitmentFunction = (loadId, commitmentId) => {
  return makeAxiosRequest(`/logistics/loadings/${loadId}/commitments/${commitmentId}/reset`, {
    method: 'put',
  })
    .then(parseResponse)
    .then(response => response.data)
    .then(sortLoadCommitments);
};

export const declineLoad: LoadFunction = id => {
  return makeAxiosRequest(`/logistics/loadings/${id}/decline`, { method: 'put' })
    .then(parseResponse)
    .then(response => response.data);
};

export const applyLoadingsFilters = (params = {}): Promise<ResponseType<ExtendedLoad>> => {
  return makeAxiosRequest('/logistics/loadings/filter', {
    method: 'post',
    data: params,
  }).then(parseResponse);
};

export const getLoadingsFilterCsv = (params = {}) => {
  return makeAxiosRequest('/logistics/loadings/filter.csv', {
    method: 'post',
    data: params,
    responseType: 'blob',
  });
};

export const getGroupScheduledCommitments = (groupId: string): Promise<LoadCommitmentWithLoad[]> =>
  makeAxiosRequest(`/logistics/movements/pig_groups_info/${groupId}/scheduled_commitments`)
    .then(parseResponse)
    .then(response => response.data);

export const getBarnScheduledCommitments = (barnId: string): Promise<LoadCommitmentWithLoad[]> =>
  makeAxiosRequest(`/logistics/movements/barns_info/${barnId}/scheduled_commitments`)
    .then(parseResponse)
    .then(response => response.data);

export const getGroupCommitmentsHistory = (groupId: string): Promise<HistoryPigGroupCommitment[]> =>
  makeAxiosRequest(`/logistics/movements/pig_groups_info/${groupId}/commitments_history`)
    .then(parseResponse)
    .then(response => response.data);

export const getBarnCommitmentsHistory = (barnId: string): Promise<LoadCommitmentWithLoad[]> =>
  makeAxiosRequest(`/logistics/movements/barns_info/${barnId}/commitments_history`)
    .then(parseResponse)
    .then(response => response.data);

export const getTruckingTerm = (loadId: string): Promise<TruckingTerm> =>
  makeAxiosRequest(`/logistics/loadings/${loadId}/trucking_term`)
    .then(parseResponse)
    .then(response => response.data)
    .catch(() => ({}));

export const forceAcceptCommitment = (loadId: string, commitmentId: string): Promise<Load> => {
  return makeAxiosRequest(
    `/logistics/loadings/${loadId}/commitments/${commitmentId}/force_accept`,
    { method: 'put' }
  )
    .then(parseResponse)
    .then(response => response.data);
};

export const forceAcceptByTrucking: LoadFunction = id => {
  return makeAxiosRequest(`/logistics/loadings/${id}/trucking_force_accept`, { method: 'put' })
    .then(parseResponse)
    .then(response => response.data);
};

export const moveLoadToArchive: LoadFunction = id => {
  return makeAxiosRequest(`/logistics/loadings/${id}/archive`, { method: 'put' })
    .then(parseResponse)
    .then(response => response.data);
};

export const moveLoadFromArchive: LoadFunction = id => {
  return makeAxiosRequest(`/logistics/loadings/${id}/unarchive`, { method: 'put' })
    .then(parseResponse)
    .then(response => response.data);
};

export const createSalesResults = (
  loadId: string,
  attributes: SalesResultCreateAttrs[]
): Promise<Load> => {
  return makeAxiosRequest(`/logistics/loadings/${loadId}/sales_result`, {
    method: 'post',
    data: { data: { attributes } },
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const updateSalesResults = (
  loadId: string,
  attributes: SalesResultUpdateAttrs[]
): Promise<Load> => {
  return makeAxiosRequest(`/logistics/loadings/${loadId}/sales_result`, {
    method: 'put',
    data: { data: { attributes } },
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const lockLogisticsSaleResult = (loadId: string, saleResultId: string) => {
  return makeAxiosRequest(`/logistics/loadings/${loadId}/sales_results/${saleResultId}/lock`, {
    method: 'put',
  });
};

export const unlockLogisticsSaleResult = (loadId: string, saleResultId: string) => {
  return makeAxiosRequest(`/logistics/loadings/${loadId}/sales_results/${saleResultId}/unlock`, {
    method: 'put',
  });
};

export const getCsvByLoadIds = (loading_ids: string[] = [], archived = false) => {
  return makeAxiosRequest('/logistics/loadings/by_ids.csv', {
    method: 'post',
    params: { ids: loading_ids, 'filter[archived]': archived },
    responseType: 'blob',
  });
};

export const getPlanLoadsCSV = (planId: string, ids: string[]) => {
  return makeAxiosRequest(`/logistics/plans/${planId}/loadings/by_ids.csv`, {
    method: 'post',
    params: { ids },
    responseType: 'blob',
  });
};
