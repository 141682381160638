import makeAxiosRequest from 'utils/api';
import { parseResponse } from 'utils/parseHelper';
// types
import {
  AssignSowUnitsFunction,
  CreateContractFunction,
  SetPlantFunction,
  UpdateContractFunction,
} from 'types/handlers';
import { Contract, Farm, ResponseType } from 'types';

export const createContract: CreateContractFunction = attributes => {
  return makeAxiosRequest('/logistics/contracts', {
    method: 'post',
    data: { data: { attributes } },
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const getContract = (id: string): Promise<Contract> => {
  return makeAxiosRequest(`/logistics/contracts/${id}`)
    .then(parseResponse)
    .then(response => response.data);
};

export const activateContract = (id: string): Promise<Contract> => {
  return makeAxiosRequest(`/logistics/contracts/${id}/activate`, { method: 'put' })
    .then(parseResponse)
    .then(response => response.data);
};

export const deactivateContract = (id: string): Promise<Contract> => {
  return makeAxiosRequest(`/logistics/contracts/${id}/disable`, { method: 'put' })
    .then(parseResponse)
    .then(response => response.data);
};

export const getContracts = (params = {}): Promise<ResponseType<Contract>> => {
  return makeAxiosRequest('/logistics/contracts', { params }).then(parseResponse);
};

export const getFilteredContracts = (params = {}): Promise<ResponseType<Contract>> => {
  return makeAxiosRequest('/logistics/contracts/filter', { method: 'POST', data: params }).then(
    parseResponse
  );
};

export const updateContract: UpdateContractFunction = (id, attributes) => {
  return makeAxiosRequest(`/logistics/contracts/${id}`, {
    method: 'put',
    data: { data: { attributes } },
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const setPackerPlantToContract: SetPlantFunction = (contractId, packing_plant_id) => {
  return makeAxiosRequest(`/logistics/contracts/${contractId}/packing_plant`, {
    method: 'put',
    data: { data: { meta: { packing_plant_id } } },
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const deleteContract = (id: string) => {
  return makeAxiosRequest(`/logistics/contracts/${id}`, {
    method: 'delete',
  });
};

export const setSowUnitsToContract: AssignSowUnitsFunction = (contractId, sow_unit_ids) => {
  return makeAxiosRequest(`/logistics/contracts/${contractId}/sow_units/assign`, {
    method: 'put',
    data: { data: { meta: { sow_unit_ids } } },
  })
    .then(parseResponse)
    .then(response => response.data);
};

export const getContractSowUnits = (contractId: string): Promise<Farm[]> => {
  return makeAxiosRequest(`/logistics/contracts/${contractId}/sow_units`)
    .then(parseResponse)
    .then(response => response.data);
};
