import React, { FC, useEffect, useState } from 'react';
// redux
import { useSelector } from 'react-redux';
// utils
import { IntlProvider } from 'react-intl';
import { sendNotify } from 'utils/airbrakeHelper';
import { Settings } from 'luxon';
import { isEmpty } from 'utils';
// types
import { RootState } from 'store/reducers/rootReducer';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
}

// time localization setting
Settings.defaultLocale = 'en';
Settings.defaultZoneName = 'America/Chicago';

const ReactIntlProvider: FC = ({ children }) => {
  const language = useSelector((state: RootState) => state.auth.user?.language || 'en');
  const [localeData, setLocaleData] = useState({ locale: language, messages: {} });

  useEffect(() => {
    import(`i18n/${language}.json`)
      .then(res => {
        return setLocaleData({ locale: language, messages: res.default });
      })
      .catch(error => {
        const formattedError = new Error(
          `Error loading translation ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`
        );
        sendNotify({ error: formattedError }, '');
        throw formattedError;
      });
  }, [language]);

  return (
    <IntlProvider defaultLocale="en" {...localeData}>
      {!isEmpty(localeData.messages) ? children : null}
    </IntlProvider>
  );
};

export default ReactIntlProvider;
