import React, { Fragment } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import FilterSectionWrapper from '../FilterSectionWrapper';
import CheckboxMenu from '../Menu/CheckboxMenu';
import Input from 'components/Input';
import StatesSelect from 'components/Select/StatesSelect';
import { ReactComponent as MarkerIcon } from 'icons/map-marker.svg';
// types
import { FarmStateSectionType, UserFilterSectionProps } from 'types/filterSections';
// utils, hooks, constants
import cn from 'classnames';
import useFilterSectionState from 'utils/hooks/useFilterSectionState';
// styles
import styles from './Sections.module.scss';

const FarmStateSection = ({
  conditions,
  isDisabled,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
}: UserFilterSectionProps<FarmStateSectionType>) => {
  const { formatMessage } = useIntl();
  const { condition, isInitialOpened, isInvalid, options } = section;
  const sectionText = options?.[0]?.value || '';
  const { state, handleConditionChange, handleValueChange, resetData } = useFilterSectionState(
    sectionText,
    condition
  );
  const { text, value } = state;

  const handleApply = () => {
    if (value) {
      onOptionSelect(value, [{ key: 'text', value: text }]);
    }
  };

  const triggerLabel =
    isInvalid || !condition ? (
      <FormattedMessage id="filters.emptyFarmState" />
    ) : (
      <FormattedMessage id={labelKeys?.[condition]} values={{ text: sectionText }} />
    );

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isDisabled={isDisabled}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={MarkerIcon}
      triggerLabel={triggerLabel}
    >
      <CheckboxMenu
        options={conditions}
        onApply={handleApply}
        onConditionChange={handleConditionChange}
        currentValue={value}
      >
        {optionValue =>
          optionValue === value && (
            <Fragment>
              {['start', 'end', 'cont', 'not_cont'].includes(value) && (
                <div className={cn(styles['condition-input-row'], 'p-10')}>
                  <Input
                    placeholder={formatMessage({ id: 'general.farmState' })}
                    className={cn(styles['value-input'], styles.longer)}
                    value={text}
                    onChange={e => handleValueChange(e.target.value)}
                  />
                </div>
              )}
              {['eq', 'not_eq'].includes(value) && (
                <div className="p-10">
                  <StatesSelect
                    value={text}
                    onChange={handleValueChange}
                    isSmall
                    placeholder={<FormattedMessage id="general.searchState" />}
                  />
                </div>
              )}
            </Fragment>
          )
        }
      </CheckboxMenu>
    </FilterSectionWrapper>
  );
};

export default FarmStateSection;
