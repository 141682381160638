import React from 'react';
// components
import FormattedWeight from 'components/FormattedWeight';
// utils
import round from 'lodash.round';
// types
import { MeasurementSystemType } from 'types';

// imperial system - lbs, metric system - kg, on back-end always lbs

const kgInPounds = 0.45359237;

export function convertLbsToKg(lbsValue: number) {
  return round(lbsValue * kgInPounds, 0);
}

export function convertKgToLbs(kgValue: number) {
  return round(kgValue / kgInPounds, 2);
}

export function formatWeight(
  value?: number,
  targetSystem = 'imperial',
  currentSystem = 'imperial'
) {
  if (value === undefined) {
    return value;
  }

  if (targetSystem === 'imperial') {
    return currentSystem === targetSystem ? round(value, 2) : convertKgToLbs(value);
  }

  return currentSystem === 'imperial' ? convertLbsToKg(value) : round(value, 0);
}

export function formatBackEndWeightToSystem(
  lbsValue: number,
  measurementSystem: MeasurementSystemType
) {
  return measurementSystem === 'imperial' ? round(lbsValue, 2) : convertLbsToKg(lbsValue);
}

export function formatWeightToBackEndFormat(
  weightValue: number,
  currentMeasurementSystem: MeasurementSystemType
) {
  return currentMeasurementSystem === 'imperial' ? weightValue : convertKgToLbs(weightValue);
}

export function addLabelToWeightValue(value?: string | number) {
  return value ? <FormattedWeight value={value} /> : '';
}
