import React from 'react';
// components
import { ReactComponent as RemoveIcon } from 'icons/times.svg';
// styles
import styles from '../Menu.module.scss';

type Props = {
  label: string;
  onRemove: () => void;
};

const SelectedItem = ({ label, onRemove }: Props) => (
  <div className={styles['selected-item']}>
    {label}
    <RemoveIcon className="ml-5" width={10} height={10} onClick={onRemove} />
  </div>
);

export default SelectedItem;
