import { Notifier, INotice } from '@airbrake/browser';
import { IS_PRODUCTION } from '../constants';

export function createAirbrakeClient() {
  return process.env.REACT_APP_AIRBRAKE_PROJECT_ID
    ? new Notifier({
        projectId: Number(process.env.REACT_APP_AIRBRAKE_PROJECT_ID),
        projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY || '',
      })
    : undefined;
}

export function sendNotify(err: Record<string, Error>, email: string, severity = 'error') {
  if (!IS_PRODUCTION) return undefined;
  const errorObject: INotice = {
    error: err?.exception || err?.error || 'Unknown error',
    context: {
      response: err,
      email,
      severity,
    },
  };
  return global.Airbrake?.notify(errorObject);
}
