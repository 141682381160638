import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import StatusBadge from 'components/StatusBadge';
import Tooltip from 'components/Tooltip/Tooltip2';
import { ReactComponent as ArchiveIcon } from 'icons/archive.svg';
import { ReactComponent as EditIcon } from 'icons/user-editing.svg';
import { ReactComponent as LoadIcon } from 'icons/ep-load.svg';
// utils
import { getLoadContractNames } from 'utils/contractHelper';
// constants
import { COLORS_BY_LOAD_TYPE } from 'constants/index';
// types
import { Load } from 'types';
// styles
import styles from './LoadModal.module.scss';

type Props = {
  load: Load;
};

const LoadModalHeader = ({ load }: Props) => {
  const names = getLoadContractNames(load.commitments);
  return (
    <div className={styles['wrapper']}>
      <LoadIcon height={40} color={COLORS_BY_LOAD_TYPE[load.load_type]} className="mr-10" />
      <div className={styles['name-wrapper']}>
        <div className={styles['name']}>
          {load.name}
          <StatusBadge className="ml-5" status={load.status} />
          {load.status === 'incomplete' && load.archived && (
            <Tooltip
              size="small"
              trigger={<ArchiveIcon width={20} />}
              tooltipContent={<FormattedMessage id="general.archived" />}
              wrapperClassName={styles.icon}
            />
          )}
          {load.edit_loading_id && (
            <Tooltip
              size="small"
              trigger={<EditIcon width={20} />}
              tooltipContent={<FormattedMessage id="general.editingNow" />}
              wrapperClassName={styles.icon}
            />
          )}
        </div>
        {names.join(', ')}
      </div>
    </div>
  );
};

export default LoadModalHeader;
