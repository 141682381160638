import React from 'react';
// components
import Loader from 'components/Loader';
import LoadFlow from 'components/LoadFlow';
import SectionDivider from '../SectionDivider';
import CommentSection from './CommentSection';
import TruckingViewSection from './TruckingViewSection';
import TimeLineSection from './TimeLineSection';
// utils
import { formatTruckingRate } from 'utils/formatHelper';
import cn from 'classnames';
// types
import { LoadSectionProps } from '../LoadSections.types';

const LogisticsSection: React.FC<LoadSectionProps> = ({
  isLoading,
  load,
  className = '',
  children,
}) => (
  <div className={cn('ph-30', { [className]: !!className })}>
    {isLoading && <Loader isActive />}
    <SectionDivider>
      <LoadFlow load={load} />
    </SectionDivider>
    {load.trucking_company && (
      <SectionDivider>
        <TruckingViewSection
          truckingCompany={load.trucking_company}
          rate={load.rate ? `$${formatTruckingRate(load.rate)}` : 'N/A'}
          rateType={load.rate_type || 'N/A'}
        />
      </SectionDivider>
    )}

    <SectionDivider>
      <TimeLineSection commitments={load.commitments} loadType={load.load_type} />
    </SectionDivider>
    <CommentSection load={load} className="pt-5" />
    {children}
  </div>
);

export default LogisticsSection;
