import { useMemo } from 'react';
import { isEmpty, pick } from 'utils';
import { formatWeight } from 'utils/weightHelper';
import { CONTRACT_CREATE_PATH_BY_TYPE, REQUIREMENTS_SECTION_FIELDS } from 'constants/contracts';
// types
import {
  FormatMessageFunc,
  Contract,
  ContractType,
  ContractTypes,
  LoadCommitment,
  Farm,
  Company,
} from 'types';
// hooks
import useMeasurementSystem from 'hooks/useMeasurementSystem';

export const getPigTypeOptions = (formatMessage: FormatMessageFunc) => [
  { value: 'isowean', label: formatMessage({ id: 'general.pigType.isowean' }) },
  { value: 'feeder', label: formatMessage({ id: 'general.pigType.feeder' }) },
];

export const getDaysOfWeekOptions = (formatMessage: FormatMessageFunc) => [
  { value: 'mon', label: formatMessage({ id: 'daysOfWeek.mon' }) },
  { value: 'tue', label: formatMessage({ id: 'daysOfWeek.tue' }) },
  { value: 'wed', label: formatMessage({ id: 'daysOfWeek.wed' }) },
  { value: 'thu', label: formatMessage({ id: 'daysOfWeek.thu' }) },
  { value: 'fri', label: formatMessage({ id: 'daysOfWeek.fri' }) },
  { value: 'sat', label: formatMessage({ id: 'daysOfWeek.sat' }) },
  { value: 'sun', label: formatMessage({ id: 'daysOfWeek.sun' }) },
];

export const getFrequencyTypeOptions = (formatMessage: FormatMessageFunc) => [
  { value: 'once', label: formatMessage({ id: 'contracts.once' }) },
  { value: 'periodic', label: formatMessage({ id: 'contracts.periodic' }) },
];

export const formatContractType = (type?: ContractType) => {
  if (type === ContractTypes.SowUnitContract) return 'sowUnit';
  return 'packer';
};

export const getContractEditUrl = (contractId: string, status: string, type: ContractType) => {
  return status === 'draft'
    ? `${CONTRACT_CREATE_PATH_BY_TYPE[type]}?draftId=${contractId}`
    : `/scheduling/contracts/${contractId}`;
};

export function checkSectionsSave(contract?: Contract): { [key: string]: boolean } {
  if (!contract) {
    return {
      isBasicInfoSaved: false,
      isContractRequirementsSaved: false,
      isLoadIdParadigmSectionSaved: false,
    };
  }
  const {
    type,
    use_custom_name = false,
    packing_plant,
    sow_units,
    owner_company,
    delivery_frequency_type,
    start_date,
    special_contract,
    special_requirements,
    custom_name_parts,
  } = contract;

  const isBasicInfoSaved =
    type === ContractTypes.PackerContract
      ? !!packing_plant?.id && !!owner_company?.id
      : !!sow_units?.length && !!owner_company?.id;

  const isContractRequirementsSaved =
    !Object.values(
      pick(contract, [
        'pig_type',
        'head_count',
        'weight_min',
        'weight_max',
        'skip_trucking_company',
      ])
    ).some(value => value === null || value === undefined) &&
    !!delivery_frequency_type &&
    !!start_date;

  return {
    isBasicInfoSaved,
    isContractRequirementsSaved,
    isSpecialRequirementsSectionSaved: special_contract ? !isEmpty(special_requirements) : true,
    isLoadIdParadigmSectionSaved: use_custom_name ? !!custom_name_parts?.length : true,
  };
}

export function useInitialValues(initialContract?: Contract) {
  const { measurementSystem } = useMeasurementSystem();
  const contract =
    initialContract?.weight_min && initialContract.weight_max
      ? {
          ...initialContract,
          weight_min: formatWeight(initialContract.weight_min, measurementSystem),
          weight_max: formatWeight(initialContract.weight_max, measurementSystem),
        }
      : initialContract;

  const {
    arrive_hour,
    arrive_minute,
    use_custom_name,
    end_condition,
    end_date,
    end_count,
    head_count,
    packing_plant,
    sow_units,
    custom_name_parts,
    owner_company,
    delivery_frequency_type,
    start_date,
    special_contract,
    special_requirements,
    user,
    weight_min,
    weight_max,
    delivery_schedules,
    skip_trucking_company,
  } = contract || {};

  return {
    sowUnitBasicInitValues: useMemo(() => ({ owner_company, user, sow_units }), [
      owner_company?.id,
      sow_units,
      user?.id,
    ]),
    packerBasicInitValues: useMemo(() => ({ packing_plant, owner_company, user }), [
      packing_plant?.id,
      owner_company?.id,
      user?.id,
    ]),
    requirementsInitValues: useMemo(() => pick(contract || {}, REQUIREMENTS_SECTION_FIELDS), [
      head_count,
      weight_min,
      weight_max,
      skip_trucking_company,
      delivery_frequency_type,
      start_date,
      end_count,
      end_date,
      end_condition,
      delivery_schedules,
      arrive_hour,
      arrive_minute,
    ]),
    loadIdParadigmInitValues: useMemo(() => ({ custom_name_parts, use_custom_name }), [
      custom_name_parts,
      use_custom_name,
    ]),
    specialContractInitValues: useMemo(() => ({ special_contract, special_requirements }), [
      special_contract,
      special_requirements,
    ]),
  };
}

export const formatContractQueryType = (type: string) => {
  switch (type) {
    case 'sowUnit':
      return ContractTypes.SowUnitContract;
    case 'packer':
      return ContractTypes.PackerContract;
    case 'drafts':
      return 'draft';
    default:
      return type;
  }
};

export function prepareContractInfo(
  loadCommitments: LoadCommitment[] = []
): [LoadCommitment[], Record<string, (Farm | Company)[]>] {
  const loadCommitmentsWithUniqueContract: LoadCommitment[] = [];
  let contractEntities: Record<string, (Farm | Company)[]> = {};
  const contractIds: string[] = [];
  loadCommitments.forEach(loadCommitment => {
    const { contract } = loadCommitment.contract_commitment || {};
    const entity = loadCommitment.sow_unit || loadCommitment.packing_plant;
    if (contract?.id && !contractIds.includes(contract.id)) {
      contractIds.push(contract.id);
      loadCommitmentsWithUniqueContract.push(loadCommitment);
    }
    if (contract?.id && entity) {
      const currentEntities = contractEntities[contract.id] || [];
      const findExisted = currentEntities.find(item => item.id === entity.id);
      if (!findExisted) {
        contractEntities = {
          ...contractEntities,
          [contract.id]: [...currentEntities, entity],
        };
      }
    }
  });
  return [loadCommitmentsWithUniqueContract, contractEntities];
}

export function getLoadContractNames(loadCommitments: LoadCommitment[] = []) {
  const contractNamesSet = new Set<string>();
  loadCommitments.forEach(loadCommitment => {
    const { name } = loadCommitment.contract_commitment?.contract || {};
    if (name) contractNamesSet.add(name);
  });
  return Array.from(contractNamesSet);
}
