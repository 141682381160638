import React, { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import ToastContent from './ToastContent';
import InAppNotification from './InAppNotification';
// types
import { AppNotification } from 'types';

const defaultNotifiersOptions: ToastOptions = { position: 'top-center' };

export const toastErrorNotifier = (text: string | ReactNode) => {
  toast.error(<ToastContent type="error">{text}</ToastContent>, defaultNotifiersOptions);
};

export const toastSuccessNotifier = (text: string | ReactNode) => {
  toast.success(<ToastContent type="success">{text}</ToastContent>, defaultNotifiersOptions);
};

export const toastWarningNotifier = (text: string | ReactNode) => {
  toast.warn(<ToastContent type="warning">{text}</ToastContent>, defaultNotifiersOptions);
};

export const inAppNotifier = (resource: AppNotification) => {
  toast(<InAppNotification resource={resource} />, { position: 'top-right' });
};
