import React from 'react';
// components
import SingleDatePicker from 'components/SingleDatePicker';
import TimePicker from './TimePicker';
// utils
import classnames from 'classnames/bind';
// types
import { DateTimePickerProps } from './DateTimePicker.types';
// styles
import styles from './DateTimePicker.module.scss';
// override SingleDatePicker styles
import './DatePicker.scss';

const cn = classnames.bind(styles);

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  id,
  isDisabled,
  onDateChange,
  dateValue,
  onTimeChange,
  hourValue,
  minuteValue,
}) => (
  <div className="date-time-picker">
    <div className={cn('date-time-picker', { disabled: isDisabled })}>
      <SingleDatePicker
        numberOfMonths={1}
        iconLeft
        value={dateValue}
        onChange={onDateChange}
        id={id}
      />
      <TimePicker
        hourValue={hourValue}
        minuteValue={minuteValue}
        onChange={onTimeChange}
        isDisabled={!dateValue}
        className={cn('time-picker')}
      />
    </div>
  </div>
);

export default DateTimePicker;
