import { MessageDescriptor } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';
import { Plan } from './plan';
import { User } from './user';
import { Column } from './table';
import { FarmBarn, FarmPigGroup } from './farm';
import { ExtendedLoad } from './load';
import { SalesResultWithIncluded } from './salesResult';

export enum SystemSides {
  logistics = 'logistics',
  trucking = 'trucking',
}

export enum HeaderColors {
  blue = 'blue',
  orange = 'orange',
  lightBlue = 'lightBlue',
  white = 'white',
}

export type SystemSide = keyof typeof SystemSides;

export type MovementType = 'sale' | 'transfer' | 'arrival';

export type MovementPluralType = 'arrivals' | 'sales' | 'transfers';

export type AddressEntity = {
  id?: string;
  name?: string;
  lat?: number;
  lng?: number;
  city?: string;
  state?: string;
  address?: string;
  country_code?: string;
  zipcode?: string;
  logistics_contact?: User;
};

export type FormatMessageFunc = (
  descriptor: MessageDescriptor,
  values?: Record<string, PrimitiveType>
) => string;

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type WithRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type MoveSelectedLoadsFunction = (
  sourceId: string,
  destId: string,
  ids: string[]
) => Promise<Plan>;

export type MetaType = {
  total: number;
  stats?: Record<string, number>;
  stats_by_type?: Record<string, number>;
  stats_by_status?: Record<string, number>;
  is_admin?: boolean;
};

export type PaginationParams = {
  page?: number;
  per_page?: number;
  padding?: number;
};

export type RequestParams = PaginationParams & {
  sort?: string;
};

export type ResponseType<T> = {
  meta: MetaType;
  data: T[];
};

export type TokenType = {
  iid_token: string;
  agent?: string;
  action: string;
};

export enum Types {
  active,
  incomplete,
  pending,
  disabled,
  draft,
  closed,
  conflict,
  confirmed,
  in_progress,
  declined,
  delivered,
  matched,
  opened,
}

export type StatusType = keyof typeof Types;

export type TableColumnsType =
  | 'arrivalBarns'
  | 'transferBarns'
  | 'worksheetGroups'
  | 'confirmArrivalPlanLoads'
  | 'confirmSalePlanLoads'
  | 'confirmTransferPlanLoads'
  | 'loads'
  | 'loadArchive'
  | 'salesResults';

export type AvailManageColumns =
  | Column<FarmBarn>
  | Column<FarmPigGroup>
  | Column<ExtendedLoad>
  | Column<SalesResultWithIncluded>;

export type ManageColumnNamesByType = {
  fixed: string[];
  manageable: string[];
  unmanageable: string[];
  hidden: string[];
};

export type CoordsType = { lat?: number; lng?: number };

export type CountryItem = {
  country_code: string;
  country_name: string;
  dialling_code: string;
};
