import React, { ChangeEvent, useRef } from 'react';
// types
import { InputRangeProps } from './InputRange.types';
// utils
import cn from 'classnames';
// styles
import styles from './InputRange.module.scss';

const InputRange = ({
  className,
  label,
  step,
  from,
  to,
  onChange,
  defaultValue,
}: InputRangeProps) => {
  const rangeRef = useRef<HTMLDivElement>(null);

  const calculateCurrentWidth = (value: number) =>
    (100 * Math.abs(value - from)) / (Math.abs(from) + Math.abs(to));

  const onRangeChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (rangeRef.current)
      rangeRef.current.style.width = `${Math.abs(calculateCurrentWidth(+value))}%`;
    onChange(+value);
  };

  return (
    <div className={cn(styles.range, className)}>
      {label && <label>{label}</label>}
      <div className={styles['input-wrapper']}>
        <div
          ref={rangeRef}
          className={cn(styles.track)}
          style={{ width: `${calculateCurrentWidth(defaultValue ? +defaultValue : 0)}%` }}
        />
        <div className={cn(styles.track, styles.empty)} />
        <input
          className={styles.input}
          defaultValue={defaultValue}
          step={step}
          onChange={onRangeChange}
          type="range"
          min={from}
          max={to}
        />
      </div>
    </div>
  );
};

export default InputRange;
