import React, { Fragment } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import FilterSectionWrapper from '../FilterSectionWrapper';
import CheckboxMenu from '../Menu/CheckboxMenu';
import Select from 'components/Select';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';
// types
import { LoadTypeSectionType, UserFilterSectionProps } from 'types/filterSections';
import { OptionKeysTypes } from 'components/Select/Select.types';
// utils, kooks
import useFilterSectionState from 'utils/hooks/useFilterSectionState';
import { getLoadTypeOptions } from 'utils/loadHelper';

const LoadTypeSection = ({
  conditions,
  isDisabled,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
}: UserFilterSectionProps<LoadTypeSectionType>) => {
  const { formatMessage } = useIntl();
  const loadTypeOptions = getLoadTypeOptions(formatMessage);
  const { condition, isInitialOpened, isInvalid, options } = section;
  const sectionText = options?.[0]?.value || '';
  const { state, handleConditionChange, handleValueChange, resetData } = useFilterSectionState(
    sectionText,
    condition
  );
  const { text, value } = state;
  const isConditionWithOptions = ['eq', 'not_eq'].includes(value);

  const handleApply = () => {
    if (value) {
      onOptionSelect(
        value,
        isConditionWithOptions ? [{ key: 'load_type', value: text }] : undefined
      );
    }
  };

  const triggerLabel =
    isInvalid || !condition ? (
      <FormattedMessage id="filters.emptyLoadType" />
    ) : (
      <FormattedMessage id={labelKeys?.[condition]} values={{ text: sectionText }} />
    );

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isDisabled={isDisabled}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={TruckIcon}
      triggerLabel={triggerLabel}
    >
      <CheckboxMenu
        options={conditions}
        onApply={handleApply}
        onConditionChange={handleConditionChange}
        currentValue={value}
      >
        {optionValue => (
          <Fragment>
            {optionValue === value && isConditionWithOptions && (
              <div className="p-10">
                <Select
                  placeholder={<FormattedMessage id="general.placeholder.searchLoadType" />}
                  value={loadTypeOptions.find(option => option?.value === text)}
                  onChange={option => handleValueChange((option as OptionKeysTypes)?.value)}
                  options={loadTypeOptions}
                  isSearchable
                  isClearable
                  isSmall
                />
              </div>
            )}
          </Fragment>
        )}
      </CheckboxMenu>
    </FilterSectionWrapper>
  );
};

export default LoadTypeSection;
