import { Driver, User } from './user';
import { MovementType, SystemSide } from './common';
import { Tenant, TruckingCompany } from './company';
import { LoadCommitment, TruckingLoadCommitment } from './loadCommitment';
import { SalesResult, TruckingSalesResult } from './salesResult';
import { LoadUpdateAttrs, LoadCommitmentUpdateAttrs } from './handlers';

export type TruckingLoadsParams = {
  user_id?: string | null;
  'filter[archived]'?: boolean;
  'filter[type]'?: string;
};

export type ConfirmationStatus = 'pending' | 'accepted' | 'declined' | null;

export type RateType = 'cwt' | 'hourly' | 'flat';

export type LoadStatus =
  | 'draft'
  | 'processing'
  | 'pending'
  | 'confirmed'
  | 'in_progress'
  | 'delivered'
  | 'conflict'
  | 'declined'
  | 'incomplete';

export type Load = {
  allow_archive: boolean;
  allow_sales_results_create: boolean;
  allow_unarchive: boolean;
  archived: boolean;
  base_date: string | null;
  can_trucking_force_accept: boolean;
  commitments: LoadCommitment[];
  edit_loading?: Omit<Load, 'edit_loading'>;
  edit_loading_id: number | null;
  id: string;
  internal_comment: string | null;
  load_type: MovementType;
  matched: boolean;
  matching_started: boolean;
  modified_by?: User;
  name: string;
  plan_id: string | null;
  rate: string | null;
  rate_type: RateType | null;
  sales_results?: SalesResult[];
  skip_trucking_company: boolean;
  status: LoadStatus;
  trucking_company?: TruckingCompany;
  trucking_company_status: ConfirmationStatus;
};

export type TruckingLoad = {
  allow_sales_results_create: boolean;
  archived: boolean;
  commitments: TruckingLoadCommitment[];
  company: Pick<Tenant, 'id' | 'name'>;
  confirmable: boolean;
  driver?: Driver;
  driver_status: ConfirmationStatus;
  id: string;
  load_type: MovementType;
  name: string;
  rate: string;
  rate_type: RateType;
  sales_results?: TruckingSalesResult[];
  status: LoadStatus;
  trucking_company_status: NonNullable<ConfirmationStatus>;
};

export type ExtendedLoad = Load & {
  first_arrive_at: string | null;
  last_arrive_at: string | null;
  contract_names: string[] | null;
  destination_entity_names: string[] | null;
  pig_group_estimated_weight: string[] | null;
  sales_result_avg_pig_weight: string[] | null;
  source_entity_names: string[] | null;
};

export type NotificationLoad = Pick<
  Load,
  | 'id'
  | 'load_type'
  | 'name'
  | 'rate'
  | 'rate_type'
  | 'status'
  | 'trucking_company_status'
  | 'commitments'
>;

export enum Reasons {
  scheduling_conflict = 'scheduling_conflict',
  trucking_rate_issue = 'trucking_rate_issue',
  other = 'other',
}

export type DeclineReason = {
  id: string;
  comment: string;
  reason: keyof typeof Reasons;
  side: SystemSide;
  loading: Load;
  user: User;
};

export enum LogEventTypes {
  archived = 'archived',
  created = 'created',
  updated = 'updated',
  unarchived = 'unarchived',
  deleted = 'deleted',
  status_changed = 'status_changed',
  unmatched = 'unmatched',
  trucking_company_status_changed = 'trucking_company_status_changed',
}

export type LoadLogEventType = keyof typeof LogEventTypes;

export type LoadLog = {
  id: string;
  event_type: LoadLogEventType[];
  additional_info?: Record<
    keyof (LoadUpdateAttrs & LoadCommitmentUpdateAttrs) | 'trucking_company_status',
    (string | number | boolean | null)[]
  >;
  created_at: string;
  loading_commitment_id?: number;
  loading_commitment?: LoadCommitment;
  user?: User;
};

export type ReturnedGroupPigsObject = {
  groupId: string;
  pigs: number;
};
