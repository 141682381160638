// redux toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// types
import { Contract } from 'types';

type ContractState = {
  data?: Contract;
  isLoading: boolean;
};

const initialState: ContractState = {
  data: undefined,
  isLoading: false,
};

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContract(state, action: PayloadAction<Contract>) {
      state.data = action.payload;
      state.isLoading = false;
    },
    setContractIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetContractState() {
      return initialState;
    },
  },
});

export const { setContract, setContractIsLoading, resetContractState } = contractSlice.actions;

export default contractSlice.reducer;
