import React from 'react';
// components
import StatsBlocks from 'components/StatsBlocks';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import { formatFullAddress } from 'utils';
// types
import { TruckingCompany } from 'types';
// styles
import styles from './TruckingCompanyCard.module.scss';

export const getOptions = (truckingCompany?: TruckingCompany) => [
  {
    label: <FormattedMessage id="load.truckingCompany.semi" />,
    value: truckingCompany?.semi_trailer_count,
  },
  {
    label: <FormattedMessage id="load.truckingCompany.goose" />,
    value: truckingCompany?.goose_trailer_count,
  },
];

type Props = {
  truckingCompany: TruckingCompany;
  className?: string;
};

const TruckingCompanyCard = ({ className = '', truckingCompany }: Props) => (
  <div className={cn(styles['company-card'], { [className]: !!className })}>
    <div className="bold truncated-ellipsis">{truckingCompany.name}</div>
    <div className={styles.desc}>{formatFullAddress(truckingCompany)}</div>
    <StatsBlocks className="mt-15" options={getOptions(truckingCompany)} />
  </div>
);

export default TruckingCompanyCard;
