import React, { FC, Fragment } from 'react';
// icons
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import { ReactComponent as ErrorIcon } from 'icons/ban.svg';
import { ReactComponent as SuccessIcon } from 'icons/thumbs-up.svg';
// types
import { ToastContentTypes } from './ToastContent.types';
// utils
import cn from 'classnames';
// styles
import styles from './ToastContent.module.scss';

const iconTypes = {
  success: <SuccessIcon className={styles.icon} />,
  warning: <WarningIcon className={styles.icon} />,
  info: <WarningIcon className={styles.icon} />,
  error: <ErrorIcon className={styles.icon} />,
};

const ToastContent: FC<ToastContentTypes> = ({ type, children }) => {
  return (
    <Fragment>
      <div className={cn(styles['icon-column'], styles[type])}>{iconTypes[type]}</div>
      <div className={styles['content-column']}>{children}</div>
    </Fragment>
  );
};

export default ToastContent;
