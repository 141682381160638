import React, { Fragment } from 'react';
// components
import BadgeColumn from './BadgeColumn';
// types
import { ColumnRenderType } from 'types';

const BadgesColumn = ({ data, placeholder }: ColumnRenderType<string[]>) =>
  data?.length ? (
    <Fragment>
      {data.map((item, index) => (
        <BadgeColumn className="mr-5" key={index} data={item} placeholder={placeholder} />
      ))}
    </Fragment>
  ) : (
    <div>{placeholder}</div>
  );

export default BadgesColumn;
