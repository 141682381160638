import React from 'react';
// utils
import cn from 'classnames';
// styles
import styles from './Badge.module.scss';

type BadgeProps = {
  className?: string;
  label: string | JSX.Element;
};

const Badge = ({ className = '', label }: BadgeProps) => (
  <span className={cn(styles.badge, { [className]: !!className })}>{label}</span>
);

export default Badge;
