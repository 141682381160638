import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PiggyIcon } from 'icons/piggy.svg';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
import { ReactComponent as WeightIcon } from 'icons/weight.svg';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as StarIcon } from 'icons/star.svg';
import Link from 'components/Link';
import StatsBlocks from 'components/StatsBlocks';
import CheckBox from 'components/CheckBox';
import TenantAvatar from 'components/TenantAvatar';
import LabelValueRow from 'components/LabelValueRow';
import Location from 'components/Location';
import FormattedWeight from 'components/FormattedWeight';
import FilterSectionSwitcher from 'components/Filters/FilterSectionSwitcher';
// utils
import cn from 'classnames';
import { formatDate, formatDateHourMinute } from 'utils/formatDateHelper';
// types
import { LoadContractSectionProps } from './LoadSections.types';
// styles
import styles from './ContractSection.module.scss';

const statsOptions = [
  {
    label: <FormattedMessage id="contracts.specialty" />,
    value: <StarIcon className={styles['star-icon']} />,
  },
];

const ContractSection: React.FC<LoadContractSectionProps> = ({
  loadCommitmentWithContract,
  contractEntities,
  className,
}) => {
  const { contract_commitment } = loadCommitmentWithContract;
  const { contract, contract_date, head_count, hour, minute, weight_max, weight_min } =
    contract_commitment || {};
  const hasSpecialSections = !!contract?.special_requirements?.sections?.length;

  return (
    <div className={cn(styles['contract-wrapper'], className)}>
      <div className={styles['contract-location']}>
        <div className={styles['contract-name-block']}>
          <span className={styles['contract-name']}>{contract?.name}</span>
          <Link to={`/scheduling/contracts/${contract?.id}/info`}>
            <FormattedMessage id="general.btn.manageContract" />
          </Link>
        </div>
        {contractEntities?.map(addressEntity => (
          <div key={addressEntity.id}>
            <Location
              name={addressEntity.name}
              city={addressEntity.city}
              state={addressEntity.state}
            />
          </div>
        ))}
        {contract?.special_contract && (
          <div className={styles['stats-info']}>
            <StatsBlocks options={statsOptions} />
          </div>
        )}
      </div>
      {contract?.owner_company && (
        <div className={styles['contract-information']}>
          <div className={styles['contract-title']}>
            <FormattedMessage id="general.contractOwner" />
          </div>
          <TenantAvatar
            className="mr-10"
            name={contract.owner_company.name || ''}
            highlighted
            size="medium"
          />
          {contract.owner_company.name}
        </div>
      )}
      <LabelValueRow
        className="mb-5"
        label={<FormattedMessage id="modal.loadModal.fulfillmentDate" />}
        value={formatDate(contract_date)}
        icon={CalendarIcon}
      />
      {hour !== null && minute !== null && (
        <LabelValueRow
          className="mb-5"
          label={<FormattedMessage id="general.dockTime" />}
          value={formatDateHourMinute(contract_date, hour, minute, 'h:mm a')}
          icon={ClockIcon}
        />
      )}
      <LabelValueRow
        className="mb-5"
        label={<FormattedMessage id="general.targetHead" />}
        value={
          head_count ? (
            <FormattedMessage id="general.pigsWithCount" values={{ count: head_count }} />
          ) : (
            'N/A'
          )
        }
        icon={PiggyIcon}
      />
      <LabelValueRow
        className="mb-5"
        label={<FormattedMessage id="general.targetWeightRange" />}
        value={<FormattedWeight.Range min={weight_min} max={weight_max} />}
        icon={WeightIcon}
      />
      {hasSpecialSections && (
        <div className={styles['special-contract-sections']}>
          <CheckBox
            className={styles['special-contract-checkbox']}
            isChecked
            label={<FormattedMessage id="general.specialContract" />}
            onClick={() => null}
          />
          <div className={styles['sections-list']}>
            {contract?.special_requirements?.sections.map((item, index) => (
              <FilterSectionSwitcher
                key={index}
                isDisabled
                section={{ ...item, sectionIndex: index }}
                onOptionSelect={() => null}
                onRemove={() => null}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractSection;
