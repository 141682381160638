import React, { ChangeEvent } from 'react';
// components
import Input from 'components/Input';
// types
import { NumberInputProps, OverrideNumberInputProps } from '../Input.types';
// utils
import { applyDecimalSeparation, applyThousandSeparation, parseNumberString } from './helpers';

const applyNumberSeparation = (value: number) => {
  const floatNumber = applyDecimalSeparation(value);

  return applyThousandSeparation(floatNumber);
};

const floatPattern = '(\\d{0,4})((,\\d{0,4})+)?((?<=\\d)\\.?|\\.(?=\\d)\\d{0,2})?';
const intPattern = '(\\d{0,4})((,\\d{0,4})+)?';

function NumberInput({
  children,
  disabled,
  onChange,
  value,
  max = 999999,
  float = false,
  min = 0,
  ...others
}: NumberInputProps) {
  const formattedValue = !value && value !== 0 ? '' : applyNumberSeparation(value);

  const handleChangeInput = ({
    target: { value: targetValue, validity },
  }: ChangeEvent<HTMLInputElement>) => {
    if (targetValue === '') {
      onChange?.(undefined);
      return;
    }
    const numberValue = parseNumberString(targetValue);
    if (validity.valid && numberValue !== value && numberValue <= max && numberValue >= min) {
      onChange?.(numberValue);
    }
  };

  const overrideProps: OverrideNumberInputProps = {
    disabled: disabled || !onChange,
    onChange: handleChangeInput,
    pattern: float ? floatPattern : intPattern,
    value: formattedValue,
  };

  return children?.(overrideProps) || <Input {...others} {...overrideProps} />;
}

export default NumberInput;
