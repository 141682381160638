import React, { useState } from 'react';

export default function usePortalTooltipState() {
  const [{ isHovered, ...coords }, setData] = useState({
    isHovered: false,
    triggerBottom: 0,
    triggerLeft: 0,
    triggerTop: 0,
    triggerWidth: 0,
  });

  const onMouseEnter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { bottom, left, top, width } = event.currentTarget.getBoundingClientRect();
    if (!isHovered)
      setData({
        triggerBottom: bottom,
        triggerTop: top,
        triggerLeft: left,
        triggerWidth: width,
        isHovered: true,
      });
  };

  const onMouseLeave = () => {
    if (isHovered) setData(prevState => ({ ...prevState, isHovered: false }));
  };

  return {
    coords,
    isHovered,
    onMouseEnter,
    onMouseLeave,
  };
}
