// notifications api
import {
  getUserNotifications,
  dismissAllNotifications,
  dismissNotification,
  fetchNotificationAfterDismiss,
  markNotificationAsRead,
} from 'requests/notifications';
// actions
import {
  setAsDismissed,
  setAsRead,
  setAllDismiss,
  setNotifications,
  setNotificationsIsLoading,
} from '../reducers/notifications';
// types
import { AppDispatch, AppGetState } from '../reduxStore';
import { NotificationsParams } from 'types';

export const fetchNotifications = (userId: string, params: NotificationsParams) => (
  dispatch: AppDispatch
) => {
  dispatch(setNotificationsIsLoading(true));
  return getUserNotifications(userId, params)
    .then(response => {
      dispatch(setNotifications({ ...response, params }));
      return response;
    })
    .catch(errors => {
      dispatch(setNotificationsIsLoading(false));
      throw errors;
    });
};

export const dismissAll = (
  userId: string,
  lastNotificationId: number,
  params: Pick<NotificationsParams, 'group'>
) => (dispatch: AppDispatch) => {
  dispatch(setNotificationsIsLoading(true));
  return dismissAllNotifications(userId, lastNotificationId, params)
    .then(() => {
      dispatch(setAllDismiss());
      return lastNotificationId;
    })
    .catch(errors => {
      dispatch(setNotificationsIsLoading(false));
      throw errors;
    });
};

export const markAsDismissed = (
  userId: string,
  notificationId: number,
  params: Pick<NotificationsParams, 'group'>
) => (dispatch: AppDispatch, getState: AppGetState) => {
  dispatch(setNotificationsIsLoading(true));
  return dismissNotification(userId, notificationId, params)
    .then(() => {
      const notificationsCount = getState().notifications.resources.length;
      return fetchNotificationAfterDismiss(userId, {
        ...params,
        padding: notificationsCount - 1,
      }).then(data => {
        dispatch(setAsDismissed({ dismissedId: notificationId, resources: data.resources }));
        return notificationId;
      });
    })
    .catch(errors => {
      dispatch(setNotificationsIsLoading(false));
      throw errors;
    });
};

export const markAsRead = (
  userId: string,
  viewedIds: number[],
  params: Pick<NotificationsParams, 'group'>
) => (dispatch: AppDispatch) => {
  dispatch(setNotificationsIsLoading(true));
  return markNotificationAsRead(userId, viewedIds, params)
    .then(() => {
      dispatch(setAsRead(viewedIds));
      return viewedIds;
    })
    .catch(errors => {
      dispatch(setNotificationsIsLoading(false));
      throw errors;
    });
};
