import React from 'react';
// redux, action creators
import { connect, ConnectedProps } from 'react-redux';
import { setTruckingCompany } from 'store/actionCreators/auth';
import { RootState } from 'store/reducers/rootReducer';
// components
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import AuthorizationBox from 'components/AuthorizationBox';
// utils
import { getLetterInitials } from 'utils';
import cn from 'classnames';
// types
import { TruckingCompany } from 'types';
// assets
import { ReactComponent as ArrowIcon } from 'icons/angle-right.svg';
import styles from './SelectTruckingCompany.module.scss';

const connector = connect(
  (state: RootState) => ({
    authUser: state.auth.user,
  }),
  { setTruckingCompany }
);

const SelectTruckingCompany: React.FC<ConnectedProps<typeof connector> & RouteComponentProps> = ({
  history: { push },
  authUser,
  setTruckingCompany,
}) => {
  const { first_name, trucking_companies } = authUser;

  const onCompanySelect = (company: TruckingCompany) => {
    setTruckingCompany(company);
    push('/trucking');
  };

  const isNoCompanies = !trucking_companies?.length;

  return (
    <AuthorizationBox>
      <FormattedMessage id="selectCompany.welcomeBack" values={{ name: first_name }}>
        {text => <div className={styles['welcome-text']}>{text}</div>}
      </FormattedMessage>

      {!isNoCompanies && (
        <FormattedMessage id="selectCompany.selectAccount">
          {text => <div className={styles['welcome-subtext']}>{text}</div>}
        </FormattedMessage>
      )}

      {isNoCompanies && (
        <FormattedMessage id="selectCompany.noCompanies">
          {text => <div className={styles['welcome-subtext']}>{text}</div>}
        </FormattedMessage>
      )}

      {trucking_companies?.map(company => (
        <div
          key={company.id}
          className={styles['company-item']}
          onClick={() => onCompanySelect(company)}
          title={company.name}
        >
          <div className={styles.avatar}>{getLetterInitials(company.name)}</div>
          <div className={cn(styles.name, 'truncated-ellipsis')}>{company.name}</div>
          <ArrowIcon width={16} className={styles.icon} />
        </div>
      ))}
    </AuthorizationBox>
  );
};

export default connector(SelectTruckingCompany);
