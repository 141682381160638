import { Dispatch, SetStateAction, useState, useCallback } from 'react';

type SetByIndexFunc<T> = (index: number, values: Partial<T>) => void;
type SetByIDFunc<T> = (id: string, values: Partial<T>) => void;

function useStateArray<T extends { id?: string }>(
  items: T[] = []
): [
  T[],
  Dispatch<SetStateAction<T[]>>,
  { setValuesById: SetByIDFunc<T>; setValuesByIndex: SetByIndexFunc<T> }
] {
  const [state, setState] = useState<T[]>(items);

  const setValuesByIndex: SetByIndexFunc<T> = useCallback(
    (index, values) => {
      setState(prevState => [
        ...prevState.slice(0, index),
        { ...prevState[index], ...values },
        ...prevState.slice(index + 1),
      ]);
    },
    [setState]
  );

  const setValuesById: SetByIDFunc<T> = useCallback(
    (id, values) => {
      setState(prevState =>
        prevState.map(item => (item.id === id ? { ...item, ...values } : item))
      );
    },
    [setState]
  );

  return [state, setState, { setValuesById, setValuesByIndex }];
}

export default useStateArray;
