import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import RadioButton from 'components/RadioButton';
import { ReactComponent as GroupIcon } from 'icons/pig-groups.svg';
import FormInput from 'components/Input/FormInput';
import Input from 'components/Input';
// types
import { FarmPigGroup, FarmType, PigGroupAssignType } from 'types';
// styles
import styles from './GroupAssignmentViewSection.module.scss';

type Props = {
  existingGroup?: FarmPigGroup;
  assignType: PigGroupAssignType;
  customGroupName: string;
  farmType?: FarmType;
};

const GroupAssignmentViewSection = ({
  assignType,
  customGroupName,
  existingGroup,
  farmType,
}: Props) => (
  <FormInput
    label={<FormattedMessage id={`load.groupAssignment${farmType ? `.${farmType}` : ''}`} />}
    inputRenderer={() => (
      <Fragment>
        <RadioButton
          name="existing"
          isDisabled
          isSelected={assignType === 'existing'}
          label={
            <span className="bold">
              <FormattedMessage id="load.assignToExistingGroup" />
            </span>
          }
        />
        {assignType === 'existing' && (
          <div className={styles['existing-group-content']}>
            <GroupIcon className={styles.icon} />
            {existingGroup?.name || 'N/A'}
          </div>
        )}
        <RadioButton
          name="generate"
          isDisabled
          isSelected={assignType === 'generate'}
          label={
            <span className="bold">
              <FormattedMessage id="load.assignToAutoGeneratedGroup" />
            </span>
          }
        />
        <RadioButton
          name="custom_name"
          isDisabled
          isSelected={assignType === 'custom_name'}
          label={
            <span className="bold">
              <FormattedMessage id="load.assignToNewGroup" />
            </span>
          }
        />
        {assignType === 'custom_name' && (
          <div className={styles['radio-content']}>
            <Input disabled value={customGroupName} />
          </div>
        )}
        <RadioButton
          name="skip"
          isDisabled
          isSelected={assignType === 'skip'}
          label={
            <span className="bold">
              <FormattedMessage id="load.dontAssignGroup" />
            </span>
          }
        />
      </Fragment>
    )}
  />
);

export default GroupAssignmentViewSection;
