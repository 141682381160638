import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Input from 'components/Input';
import CheckboxMenu from './CheckboxMenu';
// utils
import classnames from 'classnames/bind';
import { pick } from 'utils';
// types
import { ValueMenuTypes } from './Menu.types';
// constants
import { checkboxProps } from 'utils/constants/userFilter';
// styles
import styles from './Menu.module.scss';

const cn = classnames.bind(styles);

const ValueMenu = ({
  hasRangeError,
  currentValue,
  onInputChange,
  inputValue,
  inputLabel,
  inputStartValue,
  inputEndValue,
  ...others
}: ValueMenuTypes) => (
  <CheckboxMenu {...pick(others, checkboxProps)} currentValue={currentValue}>
    {value => (
      <Fragment>
        {value === currentValue && !['range', 'any'].includes(value) && (
          <div className={cn('value-input-line')}>
            <Input
              name="count"
              type="number"
              min={0}
              max={999999}
              placeholder="0"
              className={cn('value-input')}
              value={inputValue}
              onChange={onInputChange}
            />
            <div className={cn('value-label')}>{inputLabel}</div>
          </div>
        )}
        {value === currentValue && value === 'range' && (
          <Fragment>
            <div className={cn('value-input-line')}>
              <FormattedMessage id="general.from">
                {text => <span className={cn('from-or-to')}>{text}</span>}
              </FormattedMessage>
              <Input
                name="count_from"
                type="number"
                min={0}
                max={999999}
                placeholder="0"
                className={cn('value-input')}
                hasError={hasRangeError}
                value={inputStartValue}
                onChange={onInputChange}
              />
              <div className={cn('value-label')}>{inputLabel}</div>
            </div>
            <div className={cn('value-input-line')}>
              <FormattedMessage id="general.to">
                {text => <span className={cn('from-or-to')}>{text}</span>}
              </FormattedMessage>
              <Input
                name="count_to"
                type="number"
                min={0}
                max={999999}
                placeholder="0"
                className={cn('value-input')}
                hasError={hasRangeError}
                value={inputEndValue}
                onChange={onInputChange}
              />
              <div className={cn('value-label')}>{inputLabel}</div>
            </div>
          </Fragment>
        )}
      </Fragment>
    )}
  </CheckboxMenu>
);

export default ValueMenu;
