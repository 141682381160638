import React, { ReactNode } from 'react';
// components
import { ReactComponent as MarkerIcon } from 'icons/map-marker-bts.svg';
// utils
import classnames from 'classnames/bind';
// types
import { CommitmentType, CommitmentTypes } from 'types';
// styles
import styles from './Waypoint.module.scss';

const cn = classnames.bind(styles);

type WaypointWithLineProps = {
  children: ReactNode;
  type: CommitmentType;
  isEnd: boolean;
};

const WaypointWithLine = ({ children, type, isEnd }: WaypointWithLineProps) => (
  <div className={cn('wrapper')}>
    <div
      className={cn('marker-wrapper', {
        'is-not-end': !isEnd,
        'is-dest-marker': type === CommitmentTypes.destination,
      })}
    >
      {type === CommitmentTypes.source ? (
        <div className={cn('source', 'marker')} />
      ) : (
        <MarkerIcon className={cn('marker')} />
      )}
    </div>
    <div className={cn('content')}>{children}</div>
  </div>
);

export default WaypointWithLine;
