import React from 'react';
// components
import UserAvatar from 'components/UserAvatar';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as LoadIcon } from './icons/ep-load.svg';
import { ReactComponent as DeclinedIcon } from './icons/ep-declined.svg';
import { ReactComponent as ResultsIcon } from './icons/ep-sales-results-msg.svg';
import { ReactComponent as PencilIcon } from './icons/edit-trucking.svg';
// utils
import { getNotificationValues } from './helper';
// types
import { FormattedTarget, NotificationType, NotificationTypes } from 'types';

type NotificationsListType = Record<
  NotificationType,
  {
    icon?: JSX.Element;
    iconType?: string;
    getIcon?: (target: FormattedTarget) => JSX.Element;
    getText: (target: FormattedTarget) => JSX.Element;
    getRedirectLink?: (target: FormattedTarget) => string;
    linkLabel: JSX.Element;
  }
>;

const loadIcon = <LoadIcon height={40} width={40} />;

export const notificationsList: NotificationsListType = {
  [NotificationTypes.loading_driver_assigned_trucking]: {
    icon: loadIcon,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.driverAssigned"
        values={getNotificationValues(target, NotificationTypes.loading_driver_assigned_trucking)}
      />
    ),
    getRedirectLink: ({ load }) => `/trucking/schedule/${load?.id}`,
    linkLabel: <FormattedMessage id="loads.reviewDetails" />,
  },
  [NotificationTypes.driver_declined_loading_trucking]: {
    icon: <DeclinedIcon />,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.driverLoadDeclined"
        values={getNotificationValues(target, NotificationTypes.driver_declined_loading_trucking)}
      />
    ),
    getRedirectLink: ({ declineReason }) => `/trucking/loads/${declineReason?.loading.id}`,
    linkLabel: <FormattedMessage id="load.manageLoad" />,
  },
  [NotificationTypes.loading_created_trucking]: {
    icon: loadIcon,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.loadScheduledTrucking"
        values={getNotificationValues(target, NotificationTypes.loading_created_trucking)}
      />
    ),
    getRedirectLink: ({ load }) => `/trucking/loads/${load?.id}`,
    linkLabel: <FormattedMessage id="loads.reviewDetails" />,
  },
  [NotificationTypes.loading_declined_logistics]: {
    icon: <DeclinedIcon />,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.truckingAdminDecline"
        values={getNotificationValues(target, NotificationTypes.loading_declined_logistics)}
      />
    ),
    getRedirectLink: ({ declineReason }) => `/scheduling/loads/${declineReason?.loading.id}`,
    linkLabel: <FormattedMessage id="load.manageLoad" />,
  },
  [NotificationTypes.sales_result_created_logistics]: {
    icon: <ResultsIcon color="#FF5E31" />,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.resultsAreIn"
        values={getNotificationValues(target, NotificationTypes.sales_result_created_logistics)}
      />
    ),
    getRedirectLink: ({ salesResult }) => `/scheduling/loads/${salesResult?.loading?.id}`,
    linkLabel: <FormattedMessage id="loads.reviewSalesResults" />,
  },
  [NotificationTypes.sales_result_expected_trucking]: {
    icon: <PencilIcon color="#0069a1" />,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.resultsExpected"
        values={getNotificationValues(target, NotificationTypes.sales_result_expected_trucking)}
      />
    ),
    getRedirectLink: ({ load }) => `/trucking/schedule/${load?.id}/sales-results/edit`,
    linkLabel: <FormattedMessage id="loads.enterResults" />,
  },
  [NotificationTypes.loading_canceled_trucking]: {
    icon: <DeclinedIcon />,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.loadCanceledTrucking"
        values={getNotificationValues(target, NotificationTypes.loading_canceled_trucking)}
      />
    ),
    linkLabel: <FormattedMessage id="loads.reviewDetails" />,
  },
  [NotificationTypes.loading_force_accepted_trucking]: {
    getIcon: ({ load, initiator }) => (
      <UserAvatar
        name={initiator.full_name || load?.name || ''}
        srcData={initiator?.profile_photo}
        size="large"
      />
    ),
    getText: target => (
      <FormattedMessage
        id="notification.forceAcceptTrucking"
        values={getNotificationValues(target, NotificationTypes.loading_force_accepted_trucking)}
      />
    ),
    getRedirectLink: ({ load }) => `/trucking/loads/${load?.id}`,
    linkLabel: <FormattedMessage id="loads.reviewDetails" />,
  },
  [NotificationTypes.loading_edited_trucking]: {
    icon: loadIcon,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.loadEditedTrucking"
        values={getNotificationValues(target, NotificationTypes.loading_edited_trucking)}
      />
    ),
    getRedirectLink: ({ load }) => `/trucking/loads/${load?.id}`,
    linkLabel: <FormattedMessage id="loads.reviewDetails" />,
  },
  [NotificationTypes.loading_edited_confirmation_trucking]: {
    icon: loadIcon,
    iconType: 'svg',
    getText: target => (
      <FormattedMessage
        id="notification.loadEditedTrucking"
        values={getNotificationValues(
          target,
          NotificationTypes.loading_edited_confirmation_trucking
        )}
      />
    ),
    getRedirectLink: ({ load }) => `/trucking/loads/${load?.id}`,
    linkLabel: <FormattedMessage id="loads.reviewDetailsAndConfirm" />,
  },
};
