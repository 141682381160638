// redux, action creators
import { connect, ConnectedProps } from 'react-redux';
import {
  dismissAll,
  fetchNotifications,
  markAsDismissed,
  markAsRead,
} from '../actionCreators/notifications';
import { increaseUnreadNotsCount } from '../reducers/auth';
// types
import { RootState } from '../reducers/rootReducer';

const notificationsConnector = connect(
  (state: RootState) => ({
    authUserId: state.auth.user.id,
    resources: state.notifications.resources,
    params: state.notifications.params,
    meta: state.notifications.meta,
    isLoading: state.notifications.isLoading,
    isLoaded: state.notifications.isLoaded,
  }),
  {
    dismissAll,
    fetchNotifications,
    increaseUnreadNotsCount,
    markAsDismissed,
    markAsRead,
  }
);

export type ConnectedNotificationsProps = ConnectedProps<typeof notificationsConnector>;

export default notificationsConnector;
