import React, { Fragment } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import FilterSectionWrapper from '../FilterSectionWrapper';
import CheckboxMenu from '../Menu/CheckboxMenu';
import Select from 'components/Select';
import { ReactComponent as ScissorsIcon } from 'icons/scissors.svg';
// types
import { CutLevelSectionType, UserFilterSectionProps } from 'types/filterSections';
import { OptionKeysTypes } from 'components/Select/Select.types';
// utils, kooks
import useFilterSectionState from 'utils/hooks/useFilterSectionState';

const CutLevelSection = ({
  conditions,
  isDisabled,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
}: UserFilterSectionProps<CutLevelSectionType>) => {
  const { formatMessage } = useIntl();
  const { condition, isInitialOpened, isInvalid, options } = section;
  const sectionText = options?.[0]?.value || '';
  const { state, handleConditionChange, handleValueChange, resetData } = useFilterSectionState(
    sectionText,
    condition
  );
  const { text, value } = state;
  const isConditionWithOptions = ['eq', 'not_eq'].includes(value);

  const handleApply = () => {
    if (value) {
      onOptionSelect(
        value,
        isConditionWithOptions ? [{ key: 'cut_level', value: text }] : undefined
      );
    }
  };

  const triggerLabel =
    isInvalid || !condition ? (
      <FormattedMessage id="filters.emptyCutLevel" />
    ) : (
      <FormattedMessage id={labelKeys?.[condition]} values={{ text: sectionText }} />
    );

  const cutLevelOptions = [
    { value: '1', label: formatMessage({ id: 'cutLevel.1' }) },
    { value: '2', label: formatMessage({ id: 'cutLevel.2' }) },
    { value: '3', label: formatMessage({ id: 'cutLevel.3' }) },
  ];

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isDisabled={isDisabled}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={ScissorsIcon}
      triggerLabel={triggerLabel}
    >
      <CheckboxMenu
        options={conditions}
        onApply={handleApply}
        onConditionChange={handleConditionChange}
        currentValue={value}
      >
        {optionValue => (
          <Fragment>
            {optionValue === value && isConditionWithOptions && (
              <div className="p-10">
                <Select
                  placeholder={<FormattedMessage id="general.placeholder.selectCutLevel" />}
                  value={cutLevelOptions.find(option => option?.value === text)}
                  onChange={option => handleValueChange((option as OptionKeysTypes)?.value)}
                  options={cutLevelOptions}
                  isSearchable
                  isClearable
                  isSmall
                />
              </div>
            )}
          </Fragment>
        )}
      </CheckboxMenu>
    </FilterSectionWrapper>
  );
};

export default CutLevelSection;
