import React from 'react';
// components
import ContractBadge from 'components/ContractBadge';
import SpecialtyContractStar from 'components/ContractBadge/SpecialtyContractStar';
import StatusBadge from 'components/StatusBadge';
// utils
import { formatContractType } from 'utils/contractHelper';
// types
import { ContractCommitment } from 'types';
// styles
import styles from './CommitmentModal.module.scss';

const CommitmentModalHeader = ({ commitment }: { commitment: ContractCommitment }) => (
  <div className={styles.wrapper}>
    {commitment.contract && (
      <ContractBadge
        size="medium"
        className="mr-10"
        type={formatContractType(commitment.contract.type)}
        frequency={commitment.contract.delivery_frequency_type}
      />
    )}
    <div className={styles['name-wrapper']}>
      <div className={styles.name}>
        {commitment.name}
        {commitment.contract?.special_contract && <SpecialtyContractStar className="ml-5" />}
        <StatusBadge status={commitment.status} />
      </div>
      {commitment.contract?.name}
    </div>
  </div>
);

export default CommitmentModalHeader;
