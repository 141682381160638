import React, { FC } from 'react';
// components
import { RouteComponentProps, Redirect } from 'react-router';
import Loader from 'components/Loader';
// hooks
import useAuthUserSelector from 'store/selectors/useAuthUserSelector';
// utils
import { setAuthDataFromQuery } from 'utils/authData';
import { getValueFromQuery } from 'utils';

const SetUser: FC<RouteComponentProps> = ({ location: { search } }) => {
  setAuthDataFromQuery(search);
  const { id } = useAuthUserSelector();
  const isUnauthorized = !id;
  const redirect = getValueFromQuery(search, 'redirectTarget');

  return isUnauthorized ? (
    <Loader isActive className="align-hor-ver" />
  ) : (
    <Redirect to={`/${redirect}`} />
  );
};

export default SetUser;
