import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
// hooks
import useMountedRef from 'hooks/useMountedRef';
// constants
import { COLORS_BY_LOAD_TYPE } from '../constants';
// utils
import { getLoadCommitmentAddressEntity } from 'utils/loadHelper';
import { getRoutePairs } from 'utils/googleMapHelper';
import round from 'lodash.round';
import { toastErrorNotifier } from '../components/ToastContent/toastNotifier';
// types
import { CommitmentTravelData, TruckingCompany, Load } from 'types';

type TravelDataType = { [key: string]: { distanceValue?: number; distanceLabel?: string } };

type GoogleData = {
  drService?: google.maps.DirectionsService;
  mapInstance?: google.maps.Map;
};

type DrRenderersRef = { [key: string]: google.maps.DirectionsRenderer | undefined };

const TRUCKING_ROUTE_COLOR = '#cc66cb';

export default function(
  load: Load,
  truckingCompany: TruckingCompany | undefined,
  setTravelInfo?: (commitmentId: string, data: CommitmentTravelData) => void
) {
  const { current: drRenderers } = useRef<DrRenderersRef>({});
  const [{ drService, mapInstance }, setGoogleMapsData] = useState<GoogleData>({});
  const [travelDistanceData, setTravelDistanceData] = useState<TravelDataType>({});

  const onGoogleApiLoaded = ({ map }: { map: google.maps.Map }) => {
    const directionsService = new window.google.maps.DirectionsService();
    setGoogleMapsData({
      drService: directionsService,
      mapInstance: map,
    });
  };

  const commitmentsCoords = load.commitments.map(commitment => {
    const addressEntity = getLoadCommitmentAddressEntity(commitment);
    return { lat: addressEntity?.lat, lng: addressEntity?.lng };
  });

  const coordsString = commitmentsCoords
    .map(data => `${data?.lat || 0},${data?.lng || 0}`)
    .join('');
  const mountedRef = useMountedRef();

  useEffect(() => {
    if (!mapInstance || !drService) return;
    const pairs = getRoutePairs(load, truckingCompany);
    pairs.forEach(({ currCommitment, id, origin, destination }) => {
      if (!drRenderers[id]) {
        drRenderers[id] = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor:
              id === 'trucking' ? TRUCKING_ROUTE_COLOR : COLORS_BY_LOAD_TYPE[load.load_type],
            strokeWeight: 6,
          },
        });
      }

      if (!origin?.lat || !origin.lng || !destination?.lng || !destination.lat) {
        if (drRenderers[id]?.getMap()) {
          drRenderers[id]?.setMap(null);
          setTravelDistanceData(prevState => ({
            ...prevState,
            [id]: {},
          }));
        }
        return;
      }
      drRenderers[id]?.setMap(mapInstance);
      drService.route(
        {
          origin: origin as google.maps.LatLngLiteral,
          destination: destination as google.maps.LatLngLiteral,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (!mountedRef.current?.isMounted) return;
          if (status === window.google.maps.DirectionsStatus.OK) {
            drRenderers[id]?.setDirections(result);
            const { duration, distance } = result.routes[0].legs[0];
            const minutes = duration.value / 60;

            // for map markers
            setTravelDistanceData(prevState => ({
              ...prevState,
              [id]: {
                distanceValue: minutes < 60 ? round(minutes) : round(minutes / 60, 1),
                distanceLabel: minutes < 60 ? 'minutes' : 'hours',
              },
            }));

            // for commitments update
            if (currCommitment?.id && load.status === 'draft') {
              const isDistanceChanged = currCommitment?.travel_distance !== distance.value;
              const isDurationChanged = currCommitment?.travel_seconds !== duration.value;
              if (isDistanceChanged || isDurationChanged) {
                setTravelInfo?.(currCommitment.id, {
                  travel_distance: distance.value,
                  travel_seconds: duration.value,
                });
              }
            }
          } else {
            toastErrorNotifier(<FormattedMessage id="map.truckingRouteError" />);
          }
        }
      );
    });
  }, [drService, mapInstance, truckingCompany?.lat, truckingCompany?.lng, coordsString]);

  return {
    onGoogleApiLoaded,
    travelDistanceData,
  };
}
