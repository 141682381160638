import React, { ReactNode } from 'react';
// components
import SuggestionBox from 'components/SuggestionBox';
import { FormattedMessage } from 'react-intl';
import DateTimePicker from 'components/DateTimePicker';
import FormInput from 'components/Input/FormInput';
// utils
import { DateTime } from 'luxon';
import { bold } from 'utils';
import { formatCustomToISO, formatISOToCustom, formatToISODate } from 'utils/formatDateHelper';

type DateTimeSubSectionProps = {
  dateTimeValue: string;
  isDisabled: boolean;
  label: ReactNode;
  onChange: (dateTimeValue: string) => void;
  pickerId: string;
  estimatedTime?: string;
  timeType: 'ArrivalTime' | 'PickUpTime';
};

const DateTimeSubSection = ({
  dateTimeValue,
  isDisabled,
  label,
  onChange,
  pickerId,
  estimatedTime,
  timeType,
}: DateTimeSubSectionProps) => {
  const arriveAtISO = formatToISODate(dateTimeValue); // yyyy-mm-dd
  const minute = dateTimeValue ? Number(formatISOToCustom(dateTimeValue, 'mm')) : undefined;
  const hour = dateTimeValue ? Number(formatISOToCustom(dateTimeValue, 'HH')) : undefined;

  const onDateChange = (date: string) => {
    // date - MM/dd/yyyy
    const formattedHour = String(hour || 0).padStart(2, '0');
    const formattedMinute = String(minute || 0).padStart(2, '0');
    const dateTime = date + ' ' + formattedHour + ' ' + formattedMinute;
    onChange(formatCustomToISO(dateTime, 'MM/dd/yyyy HH mm'));
  };

  const onTimeChange = (data: { hour?: string; minute?: string }) => {
    const updatedHour = data.hour === undefined ? hour : data.hour;
    const updatedMinute = data.minute === undefined ? minute : data.minute;
    const seconds = DateTime.fromISO(dateTimeValue).second;
    const formattedHour = String(updatedHour || 0).padStart(2, '0');
    const formattedMinute = String(updatedMinute || 0).padStart(2, '0');
    const dateTime = arriveAtISO + ' ' + formattedHour + ' ' + formattedMinute + ' ' + seconds;
    onChange(formatCustomToISO(dateTime, 'yyyy-MM-dd HH mm s'));
  };

  // to the format without seconds (seconds can differ)
  const formattedCurrentTime = formatISOToCustom(dateTimeValue, 'yyyy-MM-dd HH mm');
  const formattedEstimatedTime = formatISOToCustom(estimatedTime, 'yyyy-MM-dd HH mm');
  const isEstimatedTimeUsed = formattedCurrentTime === formattedEstimatedTime;

  const onEstimatedTimeAccept = () => onChange(estimatedTime || arriveAtISO);
  const isSuggestionVisible = estimatedTime && !isEstimatedTimeUsed;

  return (
    <div className="mb-10">
      <FormInput
        label={label}
        inputRenderer={() => (
          <DateTimePicker
            id={pickerId}
            hourValue={hour}
            minuteValue={minute}
            dateValue={arriveAtISO}
            onDateChange={onDateChange}
            onTimeChange={onTimeChange}
            isDisabled={isDisabled}
          />
        )}
      />

      {isSuggestionVisible && (
        <SuggestionBox
          isDisabled={isDisabled}
          suggestion={
            <FormattedMessage
              id={`load.est${timeType}`}
              values={{ time: formatISOToCustom(estimatedTime, 'MM/dd/yyyy t'), bold }}
            />
          }
          onAccept={onEstimatedTimeAccept}
          buttonLabel={<FormattedMessage id="general.yes" />}
        />
      )}
    </div>
  );
};

export default DateTimeSubSection;
