import { ManageColumnNamesByType, TableColumnsType } from 'types';

export const updatePlanCollapseStateStorage = (id: string, isClosed: boolean) => {
  const openedPlans: string[] = JSON.parse(localStorage.getItem('closedPlanCollapses') || '[]');
  return !isClosed
    ? localStorage.setItem(
        'closedPlanCollapses',
        JSON.stringify(openedPlans.filter(planId => planId !== id))
      )
    : localStorage.setItem('closedPlanCollapses', JSON.stringify([...openedPlans, id]));
};

export const isPlanClosed = (planId: string) =>
  JSON.parse(localStorage.getItem('closedPlanCollapses') || '[]').includes(planId);

export const getColumnsFromStorage = (
  columnsName: TableColumnsType
): ManageColumnNamesByType | undefined => {
  return JSON.parse(localStorage.getItem('columnsOrder') || '{}')[columnsName];
};

export const setColumnsToStorage = (
  columns: ManageColumnNamesByType,
  tableName: TableColumnsType
) => {
  if (localStorage.getItem('columnsSequence')) {
    // clear wrong columns data structure
    localStorage.removeItem('columnsSequence');
  }
  const columnsOrder: Record<TableColumnsType, ManageColumnNamesByType> = JSON.parse(
    localStorage.getItem('columnsOrder') || '{}'
  );
  const updatedColumnsSequence = JSON.stringify({ ...columnsOrder, [tableName]: columns });
  localStorage.setItem('columnsOrder', updatedColumnsSequence);
};
