import {
  AppNotification,
  DeclineReason,
  FormattedTarget,
  NotificationLoad,
  NotificationTypes,
  SalesResultWithIncluded,
} from 'types';

export function formatNotificationTarget(notification: AppNotification): FormattedTarget {
  switch (notification.notification_type) {
    case NotificationTypes.driver_declined_loading_trucking:
    case NotificationTypes.loading_declined_logistics:
      return {
        declineReason: notification.target as DeclineReason,
        initiator: notification.initiator,
      };
    case NotificationTypes.loading_canceled_trucking:
    case NotificationTypes.loading_created_trucking:
    case NotificationTypes.loading_driver_assigned_trucking:
    case NotificationTypes.loading_force_accepted_trucking:
    case NotificationTypes.loading_edited_trucking:
    case NotificationTypes.loading_edited_confirmation_trucking:
    case NotificationTypes.sales_result_expected_trucking:
      return { load: notification.target as NotificationLoad, initiator: notification.initiator };
    case NotificationTypes.sales_result_created_logistics:
      return {
        salesResult: notification.target as SalesResultWithIncluded,
        initiator: notification.initiator,
      };
    default:
      return { initiator: notification.initiator };
  }
}
