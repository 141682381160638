import React, { FC } from 'react';
// components
import Input from '../Input';
// utils
import cn from 'classnames';
// types
import { FormInputProps } from '../Input.types';

const FormInput: FC<FormInputProps> = ({
  label,
  description,
  className,
  inputRenderer,
  inputProps,
}) => {
  const defaultRenderer = () => <Input hasNoBorders {...inputProps} />;
  const renderer = inputRenderer || defaultRenderer;
  return (
    <div className={cn(className, 'mb-10')}>
      {label && <div className="input-label full-width">{label}</div>}
      {renderer()}
      {description && <i className="description">{description}</i>}
    </div>
  );
};

export default FormInput;
