import React from 'react';
// components
import { ReactComponent as PeriodicIcon } from 'icons/repeat.svg';
// types
import { ContractBadgeProps } from './ContractBadge.types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './ContractBadge.module.scss';

const cn = classnames.bind(styles);

const theme = {
  periodic: {
    inner: <PeriodicIcon className={cn('periodic-icon')} />,
    sowUnit: 'brand-blue',
    packer: 'orange',
  },
  once: { inner: <span className={cn('round-icon')} />, sowUnit: 'blue', packer: 'yellow' },
};

const ContractBadge = ({
  frequency = 'once',
  type,
  className,
  size = 'small',
}: ContractBadgeProps) => (
  <span className={cn(className, 'badge', theme[frequency][type], size)}>
    {theme[frequency].inner}
  </span>
);

export default ContractBadge;
