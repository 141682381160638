import { FormatMessageFunc } from 'types';

export const getCutLevelOptions = (formatMessage: FormatMessageFunc) =>
  ['1', '2', '3'].map(level => ({
    value: level,
    label: formatMessage({ id: `cutLevel.${level}` }),
  }));

export const getRateTypeOptions = (formatMessage: FormatMessageFunc) =>
  ['cwt', 'hourly', 'flat'].map(rateType => ({
    value: rateType,
    label: formatMessage({ id: `rateType.${rateType}` }),
  }));
