import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useRouteListenEffect = (func: () => void, deps: any[]) => {
  const { listen } = useHistory();

  useEffect(() => {
    return listen(func);
  }, [...deps, listen]);
};

export default useRouteListenEffect;
