import React, { Fragment } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import FilterSectionWrapper from '../FilterSectionWrapper';
import CheckboxMenu from '../Menu/CheckboxMenu';
import Select from 'components/Select';
import { ReactComponent as CheckCircleIcon } from 'icons/check-circle.svg';
// types
import { FormatMessageFunc, SectionNames } from 'types';
import { StatusSectionType, UserFilterSectionProps } from 'types/filterSections';
import { OptionKeysTypes } from 'components/Select/Select.types';
// utils, kooks
import useFilterSectionState from 'utils/hooks/useFilterSectionState';

function getStatusOptions(formatMessage: FormatMessageFunc, type: StatusSectionType['type']) {
  switch (type) {
    case SectionNames.Status:
      return [
        { value: 'draft', label: formatMessage({ id: 'status.draft' }) },
        { value: 'processing', label: formatMessage({ id: 'status.processing' }) },
        { value: 'pending', label: formatMessage({ id: 'status.pending' }) },
        { value: 'confirmed', label: formatMessage({ id: 'status.confirmed' }) },
        { value: 'in_progress', label: formatMessage({ id: 'status.in_progress' }) },
        { value: 'conflict', label: formatMessage({ id: 'status.conflict' }) },
        { value: 'declined', label: formatMessage({ id: 'status.declined' }) },
        { value: 'incomplete', label: formatMessage({ id: 'status.incomplete' }) },
        { value: 'delivered', label: formatMessage({ id: 'status.delivered' }) },
      ];
    case SectionNames.TruckingCompanyStatus:
      return [
        { value: 'accepted', label: formatMessage({ id: 'status.accepted' }) },
        { value: 'pending', label: formatMessage({ id: 'status.pending' }) },
        { value: 'declined', label: formatMessage({ id: 'status.declined' }) },
      ];
  }
}

const StatusSection = ({
  conditions,
  isDisabled,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
}: UserFilterSectionProps<StatusSectionType>) => {
  const { formatMessage } = useIntl();
  const { condition, isInitialOpened, isInvalid, options, type } = section;
  const sectionText = options?.[0]?.value || '';
  const { state, handleConditionChange, handleValueChange, resetData } = useFilterSectionState(
    sectionText,
    condition
  );
  const { text, value } = state;
  const isConditionWithOptions = ['eq', 'not_eq'].includes(value);

  const handleApply = () => {
    if (value) {
      onOptionSelect(value, isConditionWithOptions ? [{ key: 'status', value: text }] : undefined);
    }
  };

  const triggerLabel =
    isInvalid || !condition ? (
      <FormattedMessage id="filters.emptyLoadStatus" />
    ) : (
      <FormattedMessage id={labelKeys?.[condition]} values={{ text: sectionText }} />
    );

  const selectOptions = getStatusOptions(formatMessage, type);

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isDisabled={isDisabled}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={CheckCircleIcon}
      triggerLabel={triggerLabel}
    >
      <CheckboxMenu
        options={conditions}
        onApply={handleApply}
        onConditionChange={handleConditionChange}
        currentValue={value}
      >
        {optionValue => (
          <Fragment>
            {optionValue === value && isConditionWithOptions && (
              <div className="p-10">
                <Select
                  placeholder={<FormattedMessage id="general.placeholder.searchLoadStatus" />}
                  value={selectOptions.find(option => option?.value === text)}
                  onChange={option => handleValueChange((option as OptionKeysTypes)?.value)}
                  options={selectOptions}
                  isSearchable
                  isClearable
                  isSmall
                />
              </div>
            )}
          </Fragment>
        )}
      </CheckboxMenu>
    </FilterSectionWrapper>
  );
};

export default StatusSection;
