import React, { useState, useCallback, MouseEvent, Fragment, ReactNode } from 'react';
// utils
import { debounce } from 'utils';
import cn from 'classnames';
// styles
import styles from './ButtonTooltip.module.scss';

type ButtonTooltipProps = {
  children: (data: { handleClick: (event: MouseEvent<HTMLButtonElement>) => void }) => ReactNode;
  label: string | ReactNode;
};

const initialTooltipData = {
  style: {},
  isVisible: false,
};

const ButtonTooltip = ({ children, label }: ButtonTooltipProps) => {
  const [{ isVisible, style }, setTooltipData] = useState(initialTooltipData);

  const hideTooltip = useCallback(
    debounce(() => setTooltipData(initialTooltipData), 2500),
    []
  );

  const handleClick = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    if (isVisible) return;
    const coords = currentTarget.getBoundingClientRect();
    const left = currentTarget.offsetLeft + coords.width / 2;

    setTooltipData({
      isVisible: true,
      style: { left },
    });

    return hideTooltip();
  };

  return (
    <Fragment>
      {isVisible && (
        <div className={cn(styles['tooltip-wrapper'])}>
          <div className={cn(styles.tooltip)}>
            <div className={cn(styles.corner)} style={style} />
            <span>{label}</span>
          </div>
        </div>
      )}
      {children({ handleClick })}
    </Fragment>
  );
};

export default ButtonTooltip;
