import React from 'react';
// types
import { LoadCommitment, NotificationTypes } from 'types';
import { FormattedTarget, NotificationType } from 'types/notifications';
// utils
import { sortCommitmentsByPosition, getLoadCommitmentAddressEntity } from 'utils/loadHelper';
import { formatDate, formatDateToTime } from 'utils/formatDateHelper';
import { bold } from 'utils';

const getFirstCommitmentSource = (commitments: LoadCommitment[]) => {
  const sortedCommitments = sortCommitmentsByPosition(commitments);
  return sortedCommitments[0];
};

const getLastCommitmentDestination = (commitments: LoadCommitment[]) => {
  const sortedCommitments = sortCommitmentsByPosition(commitments);
  return sortedCommitments[sortedCommitments.length - 1];
};

// TODO: optimize logic with values, too much branching
export const getNotificationValues = (
  { load, declineReason, salesResult, initiator }: FormattedTarget,
  type: NotificationType
) => {
  switch (type) {
    case NotificationTypes.loading_driver_assigned_trucking:
    case NotificationTypes.loading_created_trucking:
    case NotificationTypes.loading_canceled_trucking: {
      const firstSource = getFirstCommitmentSource(load?.commitments || []);
      const lastDestination = getLastCommitmentDestination(load?.commitments || []);
      return {
        b: bold,
        source_name: getLoadCommitmentAddressEntity(firstSource)?.name,
        pick_up_date: formatDate(firstSource.arrive_at),
        pick_up_time: formatDateToTime(firstSource.arrive_at),
        destination_name: getLoadCommitmentAddressEntity(lastDestination)?.name,
        arrival_date: formatDate(lastDestination.arrive_at),
        arrival_time: formatDateToTime(lastDestination.arrive_at),
      };
    }
    case NotificationTypes.sales_result_expected_trucking: {
      const firstSource = getFirstCommitmentSource(load?.commitments || []);
      return {
        b: bold,
        pick_up_date: formatDate(firstSource.arrive_at),
        pick_up_time: formatDateToTime(firstSource.arrive_at),
      };
    }
    case NotificationTypes.driver_declined_loading_trucking:
      return {
        b: bold,
        em: (text: string) => <i>{text}</i>,
        username: declineReason?.user?.full_name,
        loading_name: declineReason?.loading?.name,
        reason_type: declineReason?.reason || null,
        comment: declineReason?.comment,
      };
    case NotificationTypes.loading_declined_logistics: {
      const firstSource = getFirstCommitmentSource(declineReason?.loading?.commitments || []);
      return {
        b: bold,
        em: (text: string) => <i>{text}</i>,
        username: declineReason?.user?.full_name,
        role: declineReason?.side,
        loading_name: declineReason?.loading?.name,
        reason_type: declineReason?.reason || null,
        comment: declineReason?.comment,
        source_name: getLoadCommitmentAddressEntity(firstSource)?.name,
        pick_up_date: formatDate(firstSource.arrive_at),
        pick_up_time: formatDateToTime(firstSource.arrive_at),
      };
    }
    case NotificationTypes.sales_result_created_logistics:
      return {
        b: bold,
        load_time: salesResult?.source_loading_commitment?.arrive_at
          ? formatDateToTime(salesResult?.source_loading_commitment?.arrive_at)
          : null,
        load_date: salesResult?.source_loading_commitment?.arrive_at
          ? formatDate(salesResult?.source_loading_commitment?.arrive_at)
          : null,
        loading_name: salesResult?.loading?.name,
        farm_name: salesResult?.source_loading_commitment?.farm?.name,
        group_name: salesResult?.source_loading_commitment?.pig_group?.name,
      };
    case NotificationTypes.loading_force_accepted_trucking:
      return {
        b: bold,
        user_name: initiator?.full_name,
        loading_name: load?.name,
        pick_up_date: formatDate(load?.commitments[0].arrive_at),
        pick_up_time: formatDateToTime(load?.commitments[0].arrive_at),
      };
    case NotificationTypes.loading_edited_trucking:
    case NotificationTypes.loading_edited_confirmation_trucking:
      return {
        b: bold,
        loading_name: load?.name,
      };
    default:
      return {};
  }
};
