import React, { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FilterSectionWrapper from '../FilterSectionWrapper';
import DateMenu from '../Menu/DateMenu';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
// types
import { DateSectionType, UserFilterSectionProps } from 'types/filterSections';
import { SectionNames } from 'types';
// utils
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { convertDateToFormat } from 'utils';
import { relativeDatesConditions } from 'utils/constants/userFilter';

function getPlaceholder(sectionType: DateSectionType['type']) {
  switch (sectionType) {
    case SectionNames.ArriveAt:
      return <FormattedMessage id="filters.emptyDate" />;
    case SectionNames.BaseDate:
    case SectionNames.ContractDate:
      return <FormattedMessage id="filters.emptyLoadDate" />;
    case SectionNames.DeliveredAt:
      return <FormattedMessage id="filters.emptyDeliveryDate" />;
    case SectionNames.FirstSaleDate:
      return <FormattedMessage id="filters.emptyFirstDateSale" />;
    case SectionNames.LastSaleDate:
      return <FormattedMessage id="filters.emptyLastDateSale" />;
    case SectionNames.ScheduleToEmptyDate:
      return <FormattedMessage id="filters.emptyScheduleToEmptyDate" />;
  }
}

const initialDate = moment().format('DD-MM-YYYY');

const initialState = {
  days: '',
  date: initialDate,
  date_start: '',
  date_end: '',
};

const formatState = (options: DateSectionType['options']) => {
  const values: typeof initialState = { ...initialState };
  options?.forEach(({ key, value }) => {
    values[key] = value;
  });
  return values;
};

const DateSection = ({
  conditions,
  isDisabled,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
}: UserFilterSectionProps<DateSectionType>) => {
  const { isInvalid, isInitialOpened, options, type, condition: savedCondition } = section;
  const [state, setState] = useState({
    condition: savedCondition,
    ...formatState(options),
  });
  const { condition, days, date, date_start, date_end } = state;
  const placeholder = getPlaceholder(type);

  const handleConditionChange = ({ target: { name } }: ChangeEvent<HTMLInputElement>) => {
    setState({ ...initialState, condition: name as DateSectionType['condition'] });
  };

  const handleInputChange = useCallback(
    ({ target: { value, validity } }: ChangeEvent<HTMLInputElement>) => {
      if (!validity.valid) return;
      setState(prevState => ({ ...prevState, days: value, date: initialDate }));
    },
    [setState]
  );

  const handleDateChange = useCallback(
    date => {
      setState(prevState => ({ ...prevState, date: date.format('DD-MM-YYYY') }));
    },
    [setState]
  );

  const handleDatesRangeChange = useCallback(
    ({ startDate, endDate }) => {
      setState(prevState => ({
        ...prevState,
        date_start: startDate ? startDate.format('DD-MM-YYYY') : '',
        date_end: endDate ? endDate.format('DD-MM-YYYY') : '',
      }));
    },
    [setState]
  );

  const handleApply = (e: MouseEvent<HTMLButtonElement>) => {
    if (relativeDatesConditions.includes(condition)) {
      onOptionSelect(condition, [{ key: 'days', value: days }]);
      return;
    }
    if (condition === 'range') {
      if (!date_start || !date_end) {
        e.stopPropagation();
        return;
      }
      onOptionSelect(condition, [
        { key: 'date_start', value: date_start },
        { key: 'date_end', value: date_end },
      ]);
      return;
    }
    onOptionSelect(condition, [{ key: 'date', value: date }]);
  };

  const resetData = useCallback(() => {
    const newState: typeof state = {
      ...initialState,
      ...formatState(options),
      condition: savedCondition,
    };
    const isEqualState = isEqual(newState, state);
    if (!isEqualState) setState(newState);
  }, [state, savedCondition, options]);

  const getLabel = () => {
    if (isInvalid || !savedCondition) return placeholder;
    const countValues = formatState(options);
    const formattedValues = {
      ...countValues,
      date: convertDateToFormat(countValues.date),
      date_start: convertDateToFormat(countValues.date_start),
      date_end: convertDateToFormat(countValues.date_end),
    };
    const messageKey = labelKeys?.[savedCondition];
    return <FormattedMessage id={messageKey} values={formattedValues} />;
  };

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={CalendarIcon}
      triggerLabel={getLabel()}
    >
      <DateMenu
        currentValue={condition}
        options={conditions}
        onConditionChange={handleConditionChange}
        onInputChange={handleInputChange}
        onDateChange={handleDateChange}
        onDatesRangeChange={handleDatesRangeChange}
        onApply={handleApply}
        inputValue={days}
        dateValue={moment(date, 'DD-MM-YYYY')}
        dateStartValue={date_start ? moment(date_start, 'DD-MM-YYYY') : null}
        dateEndValue={date_end ? moment(date_end, 'DD-MM-YYYY') : null}
      />
    </FilterSectionWrapper>
  );
};

export default DateSection;
