import React from 'react';
// components
import FilterSectionWrapper from '../FilterSectionWrapper';
import SelectMenu from '../SectionDropdownMenu/SelectMenu';
// types
import { SelectionSectionTypes } from '../Filters.types';

function SelectionSection<T extends string>({
  isDisabled,
  isRemovable,
  isInvalid,
  isInitialOpened,
  condition,
  listOptions,
  onOptionSelect,
  onRemove,
  placeholder,
  triggerIcon,
}: SelectionSectionTypes<T>) {
  const { label } = listOptions.find(({ value }) => value === condition) || {};
  return (
    <FilterSectionWrapper
      isInitialOpened={isInitialOpened}
      isDisabled={isDisabled}
      currentValue={condition}
      triggerLabel={label || placeholder || listOptions[0].label}
      isRemovable={isRemovable}
      isInvalid={isInvalid}
      onRemove={onRemove}
      triggerIcon={triggerIcon}
    >
      <SelectMenu currentValue={condition} options={listOptions} onOptionClick={onOptionSelect} />
    </FilterSectionWrapper>
  );
}

export default SelectionSection;
