import React from 'react';
// utils
import { getLetterInitials } from 'utils';
import classnames from 'classnames/bind';
// styles
import styles from './TenantAvatar.module.scss';

const cn = classnames.bind(styles);

interface TenantAvatarProps {
  name: string;
  className?: string;
  highlighted: boolean;
  size?: 'small' | 'medium' | '';
}

const TenantAvatar = ({ name, highlighted, size = '', className = '' }: TenantAvatarProps) => (
  <div className={cn('tenant-avatar', { highlighted, [size]: !!size, [className]: !!className })}>
    {getLetterInitials(name)}
  </div>
);

export default TenantAvatar;
