import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// types
import { ContractCommitment, Plan, Load, MovementType } from 'types';

type SetPlansPayloadAction = {
  plans: Plan[];
  type: MovementType;
  total: number;
  page: number;
};

type MoveLoadsPayloadAction = {
  sourcePlanId: string;
  destPlan: Plan;
  movedLoadIds: string[];
};

type ScheduledCommitments = {
  page: number;
  total: number;
  resources: ContractCommitment[];
  sortKey?: string;
  sortDirection?: string;
};

type PlansListState = {
  resources: Plan[];
  hashTable: Record<string, Load[]>;
  scheduledCommitments: ScheduledCommitments;
  isLoading: boolean;
  page: number;
  plansType?: MovementType;
  selectedIds: string[];
  selectedFrom: string;
  total: number;
};

const initialState: PlansListState = {
  resources: [],
  hashTable: {},
  page: 1,
  total: 0,
  isLoading: false,
  plansType: undefined,
  scheduledCommitments: {
    page: 1,
    total: 0,
    resources: [],
  },
  selectedIds: [],
  selectedFrom: '',
};

const plansListSlice = createSlice({
  name: 'plansList',
  initialState,
  reducers: {
    actualizePlanAction(state, action: PayloadAction<Plan>) {
      const planIndex = state.resources.findIndex(plan => plan.id === action.payload.id);
      state.resources = [
        ...state.resources.slice(0, planIndex),
        action.payload,
        ...state.resources.slice(planIndex + 1),
      ];
      state.hashTable = { ...state.hashTable, [action.payload.id]: action.payload.loadings || [] };
    },
    addPlan(state, action: PayloadAction<Plan>) {
      state.total = state.total + 1;
      state.resources = [...state.resources, action.payload];
      state.hashTable = { ...state.hashTable, [action.payload.id]: action.payload.loadings || [] };
    },
    moveLoadsBetweenPlansAction(state, action: PayloadAction<MoveLoadsPayloadAction>) {
      const { sourcePlanId, destPlan, movedLoadIds } = action.payload;
      const sourcePlanLoads = state.hashTable[sourcePlanId].filter(
        load => !movedLoadIds.includes(load.id)
      );
      state.hashTable = {
        ...state.hashTable,
        [destPlan.id]: destPlan.loadings || [],
        [sourcePlanId]: sourcePlanLoads || [],
      };
      state.resources = state.resources.map(plan => {
        if (plan.id === sourcePlanId) return { ...plan, loadings: sourcePlanLoads || [] };
        if (plan.id === destPlan.id) return destPlan;
        return plan;
      });
    },
    removePlanAction(state, action: PayloadAction<string>) {
      state.total = state.total - 1;
      state.resources = state.resources.filter(plan => plan.id !== action.payload);
      state.hashTable = { ...state.hashTable, [action.payload]: [] };
    },
    setScheduledCommitments(state, action: PayloadAction<ScheduledCommitments>) {
      state.scheduledCommitments = action.payload;
    },
    setHashTable(state, action: PayloadAction<Record<string, Load[]>>) {
      state.hashTable = action.payload;
    },
    setPlans(state, action: PayloadAction<SetPlansPayloadAction>) {
      const { plans, type, total, page } = action.payload;
      state.total = total;
      state.page = page;
      state.resources = page === 1 ? plans : state.resources.concat(plans);
      state.hashTable = Object.assign(
        page === 1 ? {} : state.hashTable,
        ...plans.map(({ id, loadings = [] }) => ({ [id]: loadings }))
      );
      state.isLoading = false;
      state.plansType = type;
    },
    setPlan(state, action: PayloadAction<Plan>) {
      const planIndex = state.resources.findIndex(plan => plan.id === action.payload.id);
      state.resources = [
        ...state.resources.slice(0, planIndex),
        action.payload,
        ...state.resources.slice(planIndex + 1),
      ];
    },
    setMissingPlan(state, action: PayloadAction<Plan>) {
      state.resources = [...state.resources, action.payload];
      state.hashTable = { ...state.hashTable, [action.payload.id]: action.payload.loadings || [] };
    },
    setSelectedIds(state, action: PayloadAction<{ ids: string[]; selectedFrom: string }>) {
      state.selectedIds = action.payload.ids;
      state.selectedFrom = action.payload.selectedFrom;
    },
    setPlansIsLoading(state, action: PayloadAction<{ isLoading: boolean; page: number }>) {
      state.isLoading = action.payload.isLoading;
      state.page = action.payload.page;
    },
    resetLoadState() {
      return initialState;
    },
    updatePlanAction(state, action: PayloadAction<Plan>) {
      const updatedPlan = action.payload;
      state.resources = state.resources.map(plan =>
        plan.id === updatedPlan.id ? updatedPlan : plan
      );
      state.hashTable = { ...state.hashTable, [updatedPlan.id]: updatedPlan.loadings || [] };
    },
    updatePlansAction(state, action: PayloadAction<Plan[]>) {
      state.resources = action.payload;
      state.hashTable = Object.assign(
        {},
        ...action.payload.map(({ id, loadings = [] }) => ({ [id]: loadings }))
      );
    },
  },
});

export const {
  actualizePlanAction,
  addPlan,
  moveLoadsBetweenPlansAction,
  removePlanAction,
  resetLoadState,
  setHashTable,
  setPlans,
  setPlansIsLoading,
  setPlan,
  setMissingPlan,
  setScheduledCommitments,
  setSelectedIds,
  updatePlanAction,
  updatePlansAction,
} = plansListSlice.actions;

export default plansListSlice.reducer;
