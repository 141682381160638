import { useState, useCallback, ChangeEvent } from 'react';
// utils
import isEqual from 'lodash.isequal';

export default function useFilterSectionState<T extends string>(sectionText: string, condition: T) {
  const [state, setState] = useState({ text: sectionText, value: condition });

  const handleConditionChange = useCallback(
    ({ target: { name } }: ChangeEvent<HTMLInputElement>) => {
      setState({ value: name as T, text: '' });
    },
    [setState]
  );

  const handleValueChange = useCallback(
    (value?: string) => {
      setState(prevState => ({ ...prevState, text: value || '' }));
    },
    [setState]
  );

  const resetData = useCallback(() => {
    const newState = { value: condition, text: sectionText };
    const isEqualState = isEqual(newState, state);
    if (!isEqualState) setState(newState);
  }, [state, condition, sectionText]);

  return { state, handleConditionChange, handleValueChange, resetData };
}
