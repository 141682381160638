// api
import { validateUser, selectCurrentCompany, fetchTruckingRoles, logoutUser } from 'requests/auth';
import { fetchUnReadNotificationsCount } from 'requests/notifications';
import { saveUserDevice, updateUser } from 'requests/users';
// actions
import { resetUserData, setUserData, setUserLoggedIn, setUnreadNotsCount } from '../reducers/auth';
// utils
import { setTruckingCompanyId, getTruckingCompanyId } from 'utils/truckingCompany';
import { isRole } from 'utils/usersHelper';
import { getDeviceToken } from 'utils/pushNotificationsHelper';
import { clearAuthData } from 'utils/authData';
// types
import { AppDispatch, AppGetState } from '../reduxStore';
import { CurrentUser, NotificationsParams, TruckingCompany } from 'types';
import { UserUpdateAttrs } from 'types/handlers';
// constants
import { ADMIN_ROLE, DRIVER_ROLE } from 'constants/index';

export const validateCurrentUser = () => (dispatch: AppDispatch) => {
  return validateUser().then(user => {
    const { trucking_companies, current_trucking_company_id } = user;
    const localTruckingCompanyId = getTruckingCompanyId();

    if (!localTruckingCompanyId && current_trucking_company_id) {
      setTruckingCompanyId(current_trucking_company_id.toString());
    }

    const truckingId = localTruckingCompanyId || current_trucking_company_id || '';

    const formattedUser: CurrentUser = {
      ...user,
      current_trucking_company: trucking_companies?.find(({ id }) => +id === +truckingId),
      current_trucking_company_id: +truckingId,
    };

    dispatch(setUserData(formattedUser));
    dispatch(setUserLoggedIn(true));
    return formattedUser;
  });
};

export const signOutUser = () => (dispatch: AppDispatch) => {
  const deviceToken = getDeviceToken();
  const callback = () => {
    clearAuthData();
    dispatch(resetUserData());
  };
  if (deviceToken) {
    return saveUserDevice({ tokens: [{ iid_token: deviceToken, action: 'destroy' }] })
      .then(() => logoutUser())
      .then(callback);
  }
  return logoutUser().then(callback);
};

export const updateCurrentUser = (data: UserUpdateAttrs) => (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  const userId = getState().auth.user.id;
  return updateUser(userId, data).then(user => {
    dispatch(setUserData(user));
    return user;
  });
};

export const getUnreadNotificationsCount = (
  userId: string,
  params: Pick<NotificationsParams, 'group' | 'trucking_company_id' | 'current_company_id'>
) => (dispatch: AppDispatch) => {
  return fetchUnReadNotificationsCount(userId, params).then(({ unread_notifications_count }) => {
    dispatch(setUnreadNotsCount(unread_notifications_count));
    return unread_notifications_count;
  });
};

export const setCurrentCompany = (companyId: string) => (dispatch: AppDispatch) => {
  return selectCurrentCompany(companyId).then(({ resource }) => {
    const { current_company } = resource;
    dispatch(setUserData({ current_company }));
    return resource;
  });
};

export const setTruckingCompany = (company: TruckingCompany) => (dispatch: AppDispatch) => {
  setTruckingCompanyId(company.id);
  dispatch(
    setUserData({ current_trucking_company: company, current_trucking_company_id: +company.id })
  );
};

export const fetchUserTruckingRoles = (userId: string) => (dispatch: AppDispatch) => {
  return fetchTruckingRoles(userId).then(roles => {
    dispatch(
      setUserData({
        is_driver: isRole(roles, DRIVER_ROLE),
        is_trucking_admin: isRole(roles, ADMIN_ROLE),
      })
    );
    return roles;
  });
};

export const setUserLanguage = (language: string) => (dispatch: AppDispatch) => {
  dispatch(setUserData({ language }));
};
