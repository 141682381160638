import React, { useRef } from 'react';
// components
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// utils
import useOnClickOutside from 'hooks/useOnClickOutside';
// types
import { ConfirmActionModalProps } from './ConfirmActionModal.types';
// styles
import styles from './ConfirmActionModal.module.scss';

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  onHide,
  onContinue,
  children,
}) => {
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, onHide, { isOpen: true });

  return (
    <div ref={wrapperRef} className={styles['confirm-modal']}>
      <div className={styles['confirm-text']}>{children}</div>
      <div>
        <Button className="mr-15" onClick={onHide}>
          <FormattedMessage id="general.btn.cancel" />
        </Button>
        <Button primary onClick={onContinue}>
          <FormattedMessage id="general.btn.continue" />
        </Button>
      </div>
    </div>
  );
};

export default ConfirmActionModal;
