import React, { useState, Fragment } from 'react';
// utils, hooks
import { useQuery } from 'react-query';
// components
import Select from '../Select';
// types
import { ValueType } from 'react-select';
import { FetchOnceSelectProps, DefaultItemKeys, OptionKeysTypes } from '../Select.types';

function FetchOnceMultiSelect<T extends DefaultItemKeys>({
  children,
  fetchData,
  selected,
  listType,
  onSelect,
  optionFormatter = (item: T) => ({ value: item.id, label: item.name, details: item }),
  placeholder,
  isSmall,
}: FetchOnceSelectProps<T>) {
  const [isFetchEnabled, setIsFetchEnabled] = useState(false);
  const { isFetching, data } = useQuery(
    listType,
    async () => {
      const data = await fetchData();
      return data.map(optionFormatter);
    },
    { enabled: isFetchEnabled }
  );

  const handleChange = (option?: ValueType<OptionKeysTypes & { details: T }>) => {
    onSelect(option);
  };

  const handleFocus = () => {
    if (!data) setIsFetchEnabled(true);
  };

  const formattedSelectedList = selected?.length ? selected.map(optionFormatter) : undefined;
  const selectedIds = formattedSelectedList?.map(item => item.value);
  const isSelected = (option: OptionKeysTypes) => !!selectedIds?.includes(option.value);

  return (
    <Fragment>
      <Select
        placeholder={placeholder}
        value={null}
        isOptionSelected={isSelected}
        isOptionDisabled={isSelected}
        onChange={handleChange}
        onFocus={handleFocus}
        options={data}
        isLoading={isFetching}
        isSearchable
        isClearable
        isSmall={isSmall}
      />
      {!!formattedSelectedList?.length && children(formattedSelectedList)}
    </Fragment>
  );
}

export default FetchOnceMultiSelect;
