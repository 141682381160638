import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import RadioButton from 'components/RadioButton';
// utils
import classnames from 'classnames/bind';
// types
import { CheckboxMenuTypes } from './Menu.types';
// styles
import styles from './Menu.module.scss';

const cn = classnames.bind(styles);

const CheckboxMenu = ({
  currentValue,
  children,
  options,
  onConditionChange,
  onApply,
}: CheckboxMenuTypes) => (
  <div className={cn('menu')}>
    <div onClick={e => e.stopPropagation()} className={cn('menu-checkboxes-wrapper')}>
      {options.map(({ value, label, title }, index) => (
        <Fragment key={index}>
          {value && (
            <Fragment>
              {title && <div className={cn('title', { 'top-padding': !!index })}>{title}</div>}
              <RadioButton
                className={cn('checkbox-item')}
                name={value}
                isSelected={value === currentValue}
                onChange={onConditionChange}
                label={
                  <span className={cn('checkbox-label', { checked: value === currentValue })}>
                    {label}
                  </span>
                }
              />
            </Fragment>
          )}
          {children(value, label, index)}
        </Fragment>
      ))}
    </div>
    <button onClick={onApply} className={cn('done-button')}>
      <FormattedMessage id="general.btn.done" />
    </button>
  </div>
);

export default CheckboxMenu;
