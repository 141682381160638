import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TooltipTheme = 'light' | 'dark';

type TooltipStyle = {
  top?: number;
  height?: number;
  left?: number;
  right?: number;
  minWidth?: number;
};

type LayoutState = {
  sidebar: {
    isCollapsed: boolean;
  };
  moreMenu: {
    isOpened: boolean;
  };
  tooltip: {
    style?: TooltipStyle;
    label: string;
    isVisible: boolean;
    theme: TooltipTheme;
  };
  isMobileHeaderHidden: boolean;
  isOfflineLineVisible: boolean;
};

const initialState: LayoutState = {
  sidebar: {
    isCollapsed: false,
  },
  moreMenu: {
    isOpened: false,
  },
  tooltip: {
    style: undefined,
    isVisible: false,
    label: '',
    theme: 'light',
  },
  isMobileHeaderHidden: false,
  isOfflineLineVisible: !navigator.onLine,
};

const authSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    hideTooltip(state) {
      state.tooltip.isVisible = false;
    },
    toggleSidebarState(state) {
      state.sidebar.isCollapsed = !state.sidebar.isCollapsed;
    },
    setSidebarState(state, action: PayloadAction<boolean>) {
      state.sidebar.isCollapsed = action.payload;
    },
    setTooltipData(
      state,
      action: PayloadAction<{ style: TooltipStyle; label: string; theme: TooltipTheme }>
    ) {
      state.tooltip = {
        isVisible: true,
        ...action.payload,
      };
    },
    setMoreMenuState(state, action: PayloadAction<boolean>) {
      state.moreMenu.isOpened = action.payload;
    },
    setIsMobileHeaderHidden(state, action: PayloadAction<boolean>) {
      state.isMobileHeaderHidden = action.payload;
    },
    setIsOfflineLineVisible(state, action: PayloadAction<boolean>) {
      state.isOfflineLineVisible = action.payload;
    },
    resetLayoutData() {
      return initialState;
    },
  },
});

export const {
  hideTooltip,
  resetLayoutData,
  setIsMobileHeaderHidden,
  setIsOfflineLineVisible,
  setSidebarState,
  setTooltipData,
  setMoreMenuState,
  toggleSidebarState,
} = authSlice.actions;

export default authSlice.reducer;
