import { useEffect, RefObject } from 'react';

type HandlerType = (e: MouseEvent | TouchEvent | Event) => void;

type RefType = RefObject<HTMLDivElement>;

type OptionsType = {
  triggerRef?: RefType;
  blockerId?: string;
  hideOnScroll?: boolean;
  isOpen?: boolean;
  toastElem?: Element | null;
};

export default function useOnClickOutside(
  ref: RefType,
  handler: HandlerType,
  options: OptionsType = {}
) {
  const { hideOnScroll, triggerRef, blockerId, isOpen, toastElem } = options;
  useEffect(() => {
    if (!isOpen) return;
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) return;

      // Do nothing if clicking dropdown trigger's parent element
      if (triggerRef?.current?.contains(event.target as HTMLElement)) return;

      // Do nothing if element with blockerId exists
      if (blockerId && document.getElementById(blockerId)) return;

      // Do nothing if click on toast message
      if (toastElem?.contains(event.target as HTMLElement)) return;

      handler(event);
    };

    if (hideOnScroll) document?.addEventListener('scroll', handler, { capture: true });
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      if (hideOnScroll) document?.removeEventListener('scroll', handler, { capture: true });
    };
  }, [ref, handler, isOpen, triggerRef, blockerId, toastElem]);
}
