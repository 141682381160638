import React, { useState } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import Button from 'components/Button';
import HeaderContentPortal from 'components/Portal/HeaderContentPortal';
import ColumnsDropZone from './components/ColumnsDropZone';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import { ReactComponent as ClearIcon } from 'icons/clear.svg';
// utils
import { reorderList, moveToOtherList } from 'utils/dndHelper';
import { buildColumns, getColumnsKeys } from 'utils/manageColumnsHelper';
import { setColumnsToStorage } from 'utils/localStorageHelper';
import { toastErrorNotifier } from 'components/ToastContent/toastNotifier';
import {
  getColumnsByType,
  getInitialColumnKeys,
  availColumnsNames,
} from 'components/VirtualizedTable/columns/columnsManagement';
// types
import { RouteComponentProps, Redirect } from 'react-router';
import { AvailManageColumns, TableColumnsType } from 'types';
// styles
import styles from './ManageTableColumnsPage.module.scss';

type DroppableIdTypes = 'manageable' | 'hidden';

const ManageTableColumnsPage = ({ location, history }: RouteComponentProps) => {
  const [searchValue, onSearchChange] = useState('');
  const { formatMessage } = useIntl();
  const { search } = location;
  const { goBack } = history;
  const searchParams = new URLSearchParams(search);
  const name = searchParams.get('name') as TableColumnsType;
  const initColumnsData = getColumnsKeys(name);
  const [columnsKeysData, setColumnsKeysData] = useState(initColumnsData);

  if (!name || !availColumnsNames.includes(name) || !initColumnsData) {
    toastErrorNotifier(<FormattedMessage id="toast.error.noManageTableName" />);
    return <Redirect to="/scheduling" />;
  }

  const { fixed, manageable, hidden } = buildColumns(
    columnsKeysData,
    getColumnsByType(name, formatMessage)
  );

  const handleSaveColumnsOrder = () => {
    setColumnsToStorage(columnsKeysData, name);
    goBack();
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    if (source.droppableId !== destination.droppableId) {
      const sourceDroppableId = source.droppableId as DroppableIdTypes;
      const destDroppableId = destination.droppableId as DroppableIdTypes;
      const [newSourceList, newDestinationList] = moveToOtherList(
        columnsKeysData[sourceDroppableId],
        columnsKeysData[destDroppableId],
        source.index,
        destination.index
      );
      setColumnsKeysData(prevState => ({
        ...prevState,
        [sourceDroppableId]: newSourceList,
        [destDroppableId]: newDestinationList,
      }));
      return;
    }

    // the same table, order not changed
    if (source.index === destination.index) return;

    // the same table, order changed
    const sourceDroppableId = source.droppableId as DroppableIdTypes;
    const reorderedData = reorderList(
      columnsKeysData[sourceDroppableId],
      source.index,
      destination.index
    );
    setColumnsKeysData(prevState => ({ ...prevState, [sourceDroppableId]: reorderedData }));
  };

  const onResetToDefaults = () => {
    const initColumnKeys = getInitialColumnKeys(name);
    setColumnsKeysData(initColumnKeys);
  };

  const isMatchSearch = (searchedString: string) => {
    return searchedString?.toLowerCase().includes(searchValue.toLowerCase());
  };

  const isListEmpty = (list: AvailManageColumns[]) => {
    return searchValue
      ? !list.filter(({ label }) => label?.toLowerCase().includes(searchValue.toLowerCase())).length
      : !list.length;
  };

  return (
    <div className={styles['manage-table-columns']}>
      <HeaderContentPortal>
        <FormattedMessage id={`header.manageColumns.${name}.title`} />
        <Button medium onClick={goBack} className="margin-left-auto mr-10">
          <FormattedMessage id="general.btn.cancel" />
        </Button>
        <Button primary medium onClick={handleSaveColumnsOrder}>
          <FormattedMessage id="general.btn.saveChanges" />
        </Button>
      </HeaderContentPortal>
      <div className={styles['search-section']}>
        <FormattedMessage id="searchInput.search">
          {(text: string) => (
            <input
              className={styles['search-input']}
              placeholder={text}
              value={searchValue}
              onChange={({ target: { value } }) => onSearchChange(value)}
            />
          )}
        </FormattedMessage>
        <SearchIcon width={20} height={20} className={styles['search-icon']} />
        {searchValue && (
          <ClearIcon
            width={16}
            height={16}
            className={styles['clear-icon']}
            onClick={() => onSearchChange('')}
          />
        )}
      </div>

      <div className={styles['fields-title']}>
        <FormattedMessage id="manageTableColumns.defaultFields">
          {text => <span className="bold">{text}</span>}
        </FormattedMessage>
        <FormattedMessage id="general.resetAllDefaults">
          {text => (
            <span className={styles['reset-btn']} onClick={onResetToDefaults}>
              {text}
            </span>
          )}
        </FormattedMessage>
      </div>
      {fixed.map(
        column =>
          isMatchSearch(column.label) && (
            <div key={column.label} className={styles['default-field']}>
              {column.label}
              <span className={styles['fields-status']}>
                <FormattedMessage id="general.default" />
              </span>
            </div>
          )
      )}
      {isListEmpty(fixed) && (
        <div className={styles['defaults-placeholder']}>
          <FormattedMessage
            id={`emptyPlaceholder.${searchValue ? 'noDefaultsMatchSearch' : 'noDefaultsFields'}`}
          />
        </div>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <div className={styles['fields-title']}>
          <FormattedMessage id="manageTableColumns.manageableFields">
            {(text: string) => <span className="bold">{text}</span>}
          </FormattedMessage>
        </div>

        <ColumnsDropZone
          droppableId="manageable"
          fields={manageable}
          isEmpty={isListEmpty(manageable)}
          matchSearch={isMatchSearch}
          label={<FormattedMessage id="general.visible" />}
          placeholder={
            <FormattedMessage
              id={`emptyPlaceholder.${searchValue ? 'noVisibleMatchSearch' : 'noVisibleFields'}`}
            />
          }
        />

        <div className={styles['hidden-fields-title']}>
          <hr />
          <FormattedMessage id="manageTableColumns.hiddenFields">
            {(text: string) => <span className={styles.text}>{text}</span>}
          </FormattedMessage>
        </div>

        <ColumnsDropZone
          droppableId="hidden"
          fields={hidden}
          isEmpty={isListEmpty(hidden)}
          matchSearch={isMatchSearch}
          label={<FormattedMessage id="general.hidden" />}
          placeholder={
            <FormattedMessage
              id={`emptyPlaceholder.${searchValue ? 'noHiddenMatchSearch' : 'noHiddenFields'}`}
            />
          }
        />
      </DragDropContext>
    </div>
  );
};

export default ManageTableColumnsPage;
