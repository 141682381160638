import React, { useCallback, useEffect, useState } from 'react';
// components
import Select from '../Select';
// types
import { AsyncSelectProps, ResponseType, ChangeHandler, OptionKeysTypes } from '../Select.types';
// utils
import makeAxiosRequest from 'utils/api';
import { debounce } from 'utils';

const AsyncSelect = ({
  selected,
  noBorder,
  defaultParams = {},
  onChange,
  filterOption,
  autoLoad,
  optionsPath,
  searchKey = 'name',
  placeholder,
  className,
  isDisabled,
  isClearable = true,
  isSearchable = true,
  optionRenderer,
  optionDataFormatter,
  formatOptionLabel,
}: AsyncSelectProps) => {
  const [{ options, isLoading, cache }, setState] = useState({
    isLoading: false,
    cache: [],
    options: [],
  });

  const loadFromServer = useCallback(
    debounce((searchValue: string) => {
      makeAxiosRequest(`${optionsPath}`, {
        params: { ...defaultParams, [`filter[${searchKey}]`]: searchValue.toString() },
      })
        .then(({ data }) => {
          const newOptions = data.map(({ id, attributes }: ResponseType) => ({
            value: id,
            label: attributes.name,
            ...(optionDataFormatter?.({ id, ...attributes }) || {}),
          }));
          setState(prevState => ({
            cache: prevState.cache.length ? prevState.cache : newOptions,
            options: newOptions,
            isLoading: false,
          }));
        })
        .catch(() => {
          setState(prevState => ({
            ...prevState,
            isLoading: false,
            options: prevState.cache,
          }));
        });
    }, 300),
    []
  );

  const loadOptions = (inputValue: string) => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    if (inputValue.length < 2 && cache.length) {
      return setState(prevState => ({ ...prevState, isLoading: false, options: cache }));
    }
    return loadFromServer(inputValue);
  };

  const handleChange: ChangeHandler = option => {
    if (onChange) onChange((option || '') as OptionKeysTypes);
    if (!option) setState(prevState => ({ ...prevState, options: prevState.cache }));
  };

  const handleFocus = () => {
    if (!cache.length) loadOptions('');
  };

  useEffect(() => {
    if (autoLoad) loadOptions('');
  }, []);

  return (
    <Select
      className={className}
      placeholder={placeholder}
      value={selected}
      isDisabled={isDisabled}
      filterOption={filterOption}
      onChange={handleChange}
      onFocus={handleFocus}
      onInputChange={loadOptions}
      optionRenderer={optionRenderer}
      options={options}
      isLoading={isLoading}
      isSearchable={isSearchable}
      noBorder={noBorder}
      isClearable={isClearable}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export default AsyncSelect;
