// utils
import pick from 'lodash.pick';
import { addLabelToWeightValue } from 'utils/weightHelper';
import {
  getLoadArrivingDates,
  getLoadPackingPlantNames,
  getLoadPigGroupNames,
} from 'utils/loadHelper';
// renderers
import {
  renderLoadNumber,
  renderTargetHeads,
  renderTooltipColumn,
  renderShowMoreColumn,
} from '../columnRenderers';
// types
import { Column, FormatMessageFunc, ExtendedLoad } from 'types';

const arrivalPlanLoadsColumnsKeys = [
  'loadName',
  'status',
  'multiLoadDate',
  'contractNames',
  'originNames',
  'destinationFarms',
  'truckingName',
  'targetHeads',
  'avgWeights',
];

const salePlanLoadsColumnsKeys = [
  'loadName',
  'status',
  'multiLoadDateTime',
  'plantNames',
  'contractNames',
  'originNames',
  'truckingName',
  'targetHeads',
  'avgWeights',
];

const transferPlanLoadsColumnsKeys = [
  'loadName',
  'status',
  'multiLoadDate',
  'originNames',
  'destinationFarms',
  'sourceGroups',
  'destinationGroups',
  'truckingName',
  'targetHeads',
  'avgWeights',
];

const getColumnsData = (
  formatMessage: FormatMessageFunc
): Record<string, Column<ExtendedLoad>> => ({
  loadName: {
    label: formatMessage({ id: 'loadsTable.loadNumber' }),
    renderer: renderLoadNumber,
    width: 200,
    sortable: 'name',
  },
  status: {
    label: formatMessage({ id: 'general.status' }),
    rendererType: 'badgeStatus',
    dataPath: 'status',
    width: 100,
    sortable: 'status',
  },
  truckingName: {
    label: formatMessage({ id: 'loadsTable.trucking' }),
    renderer: ({ trucking_company }, data) =>
      renderTooltipColumn(trucking_company?.name || '', { ...data, valueMaxWidth: 220 }),
    width: 240,
  },
  // multivalues
  multiLoadDateTime: {
    label: formatMessage({ id: 'general.date' }),
    renderer: (load, data) =>
      renderShowMoreColumn(getLoadArrivingDates(load, 'MM/dd/yy • h:mm a'), {
        ...data,
        isBadged: true,
      }),
    width: 180,
  },
  multiLoadDate: {
    label: formatMessage({ id: 'general.date' }),
    renderer: (load, data) =>
      renderShowMoreColumn(getLoadArrivingDates(load, 'MM/dd/yy'), { ...data, isBadged: true }),
    width: 200,
  },
  plantNames: {
    label: formatMessage({ id: 'general.plant' }),
    renderer: (load, data) =>
      renderShowMoreColumn(getLoadPackingPlantNames(load), {
        ...data,
        isUniq: true,
        valueMaxWidth: 230,
      }),
    width: 250,
  },
  contractNames: {
    label: formatMessage({ id: 'general.contract' }),
    sortable: 'contract_names',
    renderer: ({ contract_names }, data) =>
      renderShowMoreColumn(contract_names || [], { ...data, isUniq: true, valueMaxWidth: 230 }),
    width: 250,
  },
  originNames: {
    label: formatMessage({ id: 'loadsTable.origin' }),
    sortable: 'source_entity_names',
    renderer: ({ source_entity_names }, data) =>
      renderShowMoreColumn(source_entity_names || [], {
        ...data,
        isUniq: true,
        valueMaxWidth: 230,
      }),
    width: 250,
  },
  targetHeads: {
    label: formatMessage({ id: 'general.targetHead' }),
    emptyPlaceholder: 'N/A',
    renderer: renderTargetHeads,
    width: 180,
  },
  avgWeights: {
    label: formatMessage({ id: 'load.avgWeight' }),
    renderer: ({ pig_group_estimated_weight }, data) =>
      renderShowMoreColumn(pig_group_estimated_weight?.map(addLabelToWeightValue) || [], {
        ...data,
        isBadged: true,
      }),
    sortable: 'pig_group_estimated_weight',
    width: 220,
  },
  destinationFarms: {
    label: formatMessage({ id: 'loadsTable.destinationFarm' }),
    sortable: 'destination_entity_names',
    renderer: ({ destination_entity_names }, data) =>
      renderShowMoreColumn(destination_entity_names || [], {
        ...data,
        isUniq: true,
        valueMaxWidth: 230,
      }),
    width: 250,
  },
  sourceGroups: {
    label: formatMessage({ id: 'loadsTable.originGroup' }),
    renderer: (load, data) =>
      renderShowMoreColumn(getLoadPigGroupNames(load, 'source'), {
        ...data,
        isUniq: true,
        valueMaxWidth: 200,
      }),
    width: 220,
  },
  destinationGroups: {
    label: formatMessage({ id: 'loadsTable.destinationGroup' }),
    renderer: (load, data) =>
      renderShowMoreColumn(getLoadPigGroupNames(load, 'destination'), {
        ...data,
        isUniq: true,
        valueMaxWidth: 200,
      }),
    width: 220,
  },
});

export const getConfirmArrivalPlanLoadsColumns = (
  formatMessage: FormatMessageFunc
): Record<string, Column<ExtendedLoad>> =>
  pick(getColumnsData(formatMessage), arrivalPlanLoadsColumnsKeys);

export const getConfirmSalePlanLoadsColumns = (
  formatMessage: FormatMessageFunc
): Record<string, Column<ExtendedLoad>> =>
  pick(getColumnsData(formatMessage), salePlanLoadsColumnsKeys);

export const getConfirmTransferPlanLoadsColumns = (
  formatMessage: FormatMessageFunc
): Record<string, Column<ExtendedLoad>> =>
  pick(getColumnsData(formatMessage), transferPlanLoadsColumnsKeys);
