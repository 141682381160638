import { Component } from 'react';
// redux, action creators
import { connect, ConnectedProps } from 'react-redux';
import { getUnreadNotificationsCount } from 'store/actionCreators/auth';
import { increaseUnreadNotsCount } from 'store/reducers/auth';
// in-app toasts
import { inAppNotifier } from 'components/ToastContent/toastNotifier';
// websockets
import ActionCable, { Channel } from 'actioncable';
// constants
import { EP_API_URL } from 'constants/index';
// types
import { RootState } from 'store/reducers/rootReducer';
import { AppNotification, SystemSides } from 'types';

const cable = ActionCable.createConsumer(`${EP_API_URL}/api/cable`);

const connector = connect(
  (state: RootState) => ({
    authUser: state.auth.user,
  }),
  { increaseUnreadNotsCount, getUnreadNotificationsCount }
);

type Props = ConnectedProps<typeof connector> & { isTruckingSystem: boolean };

type WebSocketNotificationResponse = {
  resource: AppNotification;
  tenant_id: number;
};

class UserController extends Component<Props> {
  channel: Channel | undefined;

  componentDidMount(): void {
    const { authUser, isTruckingSystem } = this.props;
    this.fetchUnreadNotsCount(authUser.id, isTruckingSystem);
    console.log(`%c Websockets Subscribe User Id: ${authUser.id}`, 'color: blue');
    this.channel = cable.subscriptions.create(
      { channel: 'UserChannel', user_id: authUser.id },
      { received: this.handleNewSocketMessage }
    );
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { authUser, isTruckingSystem } = this.props;
    const { authUser: prevUser, isTruckingSystem: isPrevTruckingSystem } = prevProps;
    const isSystemChanged = isTruckingSystem !== isPrevTruckingSystem;
    const isTruckingCompanyChanged =
      isTruckingSystem &&
      !isSystemChanged &&
      authUser.current_trucking_company_id !== prevUser.current_trucking_company_id;
    if (isSystemChanged || isTruckingCompanyChanged) {
      this.fetchUnreadNotsCount(authUser.id, isTruckingSystem);
    }
  }

  componentWillUnmount(): void {
    console.log('%c Websockets Unsubscribe', 'color: blue');
    this.channel?.unsubscribe();
  }

  fetchUnreadNotsCount = (userId: string, isTrucking: boolean) => {
    const { getUnreadNotificationsCount, authUser } = this.props;
    const currentTruckingId = authUser.current_trucking_company?.id;
    const currentTruckingTenantId = authUser.current_trucking_company?.root_id;

    if (isTrucking && currentTruckingId && currentTruckingTenantId) {
      return getUnreadNotificationsCount(userId, {
        group: SystemSides.trucking,
        trucking_company_id: currentTruckingId,
        current_company_id: currentTruckingTenantId,
      });
    }

    return getUnreadNotificationsCount(userId, { group: SystemSides.logistics });
  };

  handleNewSocketMessage = (response: WebSocketNotificationResponse) => {
    const { increaseUnreadNotsCount, isTruckingSystem, authUser } = this.props;
    const currTenantId = authUser.current_company?.id || '';
    const currentTruckingId = authUser.current_trucking_company?.id || '';
    const { resource, tenant_id } = response;

    const isValidTruckingInApp =
      +currentTruckingId === +(resource.trucking_company_id || '') &&
      resource.group_name === 'trucking';
    const isValidLogisticInApp =
      +currTenantId === +tenant_id && resource.group_name === 'logistics';

    const isValid = isTruckingSystem ? isValidTruckingInApp : isValidLogisticInApp;

    if (isValid) {
      inAppNotifier(resource);
      increaseUnreadNotsCount(1);
    }
  };

  render() {
    return null;
  }
}

export default connector(UserController);
