import React, { ReactNode } from 'react';
// components
import DotSeparator from 'components/DotSeparator';

type InfoRowProps = {
  label: ReactNode | string;
  value: ReactNode | string;
  separator?: (() => ReactNode) | string;
  emptyPlaceholder?: ReactNode | string;
};

const InfoRow = ({
  label,
  value,
  separator: Separator = DotSeparator,
  emptyPlaceholder = 'N/A',
}: InfoRowProps) => (
  <div>
    {label}
    <Separator />
    {value || emptyPlaceholder}
  </div>
);

export default InfoRow;
