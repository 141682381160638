import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Divider from 'components/Divider';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';
// utils
import { formatTravelTime } from 'utils';
import cn from 'classnames';
// styles
import styles from './EstimatedTravelTimeRow.module.scss';

type Props = {
  distance: number | null;
  seconds: number | null;
};

const EstimatedTravelTimeRow = ({ distance, seconds }: Props) => (
  <div className={styles['travel-time-row']}>
    <Divider className="mt-10 mb-10" />
    <TruckIcon width={17} height={17} className="mr-10" />
    <FormattedMessage
      id="load.estTravelTime"
      values={{
        value: formatTravelTime(distance, seconds),
        primary: (text: string) => (
          <span className={cn('ml-5', { primary: !!text })}>{text || 'N/A'}</span>
        ),
      }}
    />
    <Divider className="mt-10 mb-20" />
  </div>
);

export default EstimatedTravelTimeRow;
