import React, { ChangeEvent, useState } from 'react';
// redux, action creators
import { connect, ConnectedProps } from 'react-redux';
import { updateCurrentUser } from 'store/actionCreators/auth';
import { RootState } from 'store/reducers/rootReducer';
// components
import Cropper from 'react-cropper';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Loader from 'components/Loader';
import ReactCropper from 'react-cropper';
import { FormattedMessage } from 'react-intl';
import InputRange from 'components/InputRange';
import { ReactComponent as RotateIcon } from 'icons/rotate.svg';
// utils
import { readFile } from 'utils';
import { updateDiameterIfChanged, setCenteredPosition } from './helper';
// styles
import './cropper.scss';
import 'cropperjs/dist/cropper.css';
import styles from './AvatarCropperModal.module.scss';

const connector = connect(
  (state: RootState) => ({
    isAvatarExist: !!state.auth.user.profile_photo,
  }),
  { updateCurrentUser }
);

type Props = ConnectedProps<typeof connector> & { avatarSrc: string };

const AvatarCropperModal = ({ avatarSrc, isAvatarExist, updateCurrentUser }: Props) => {
  const [isLoading, setLoading] = useState(false);

  let prevZoom = 0;
  let cropperRef: ReactCropper;

  const zoomTo = (value: number) => {
    const ratio = +value - prevZoom;
    const image = cropperRef.getImageData();
    const zoomLevel = image.width / image.naturalWidth + ratio;
    cropperRef.zoomTo(zoomLevel);
    prevZoom = +value;
  };

  const rightAngleRotate = () => {
    cropperRef.rotate(-90);
    updateDiameterIfChanged(cropperRef);
  };

  const rotateTo = (value: number) => {
    cropperRef.rotateTo(+value);
    updateDiameterIfChanged(cropperRef);
  };

  const onRemove = (callback: () => void) => {
    setLoading(true);
    updateCurrentUser({ remove_profile_photo: true }).then(callback);
  };

  const onSave = (callback: () => void) => {
    const avatar = cropperRef.getCroppedCanvas().toDataURL();

    setLoading(true);
    updateCurrentUser({ profile_photo_data_uri: avatar }).then(callback);
  };

  const onReplace = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    readFile(files, image => {
      cropperRef.replace(image);
    });
  };

  return (
    <Modal hasAutoWidth>
      {({ closeModal }) => (
        <div className={styles['avatar-cropper']}>
          <Modal.Header>
            <span className={styles['modal-title']}>
              <FormattedMessage id="croppAvatar.modal.title" />
            </span>
            <Modal.CloseButton onClick={closeModal} />
          </Modal.Header>
          <Loader isActive={isLoading} />
          <div className={styles['wrapper']}>
            <RotateIcon
              onClick={rightAngleRotate}
              color="white"
              width={25}
              height={25}
              className={styles['rotate-btn']}
            />
            <Cropper
              ref={(ref: ReactCropper | null) => (ref ? (cropperRef = ref) : null)}
              className={styles['image-cropper']}
              src={avatarSrc}
              cropBoxResizable={false}
              toggleDragModeOnDblclick={false}
              aspectRatio={3 / 3}
              viewMode={2}
              background={false}
              zoomOnWheel={false}
              ready={() => setCenteredPosition(cropperRef)}
            />
          </div>
          <div className={styles['edit-panel']}>
            <InputRange
              className="pr-25"
              defaultValue="0"
              from={0}
              to={4}
              step={0.05}
              label={<FormattedMessage id="croppAvatar.modal.zoom" />}
              onChange={zoomTo}
            />
            <InputRange
              from={-180}
              to={180}
              step={10}
              label={<FormattedMessage id="croppAvatar.modal.straighten" />}
              onChange={rotateTo}
            />
          </div>
          <Modal.Footer>
            <div className={styles['actions']}>
              {/* remove photo */}
              {isAvatarExist && (
                <Button
                  onClick={() => onRemove(closeModal)}
                  transparent
                  className={styles['remove-btn']}
                >
                  <FormattedMessage id="croppAvatar.modal.deletePhoto" />
                </Button>
              )}
              {/* replace photo */}
              <label className="mr-10">
                <div className={styles['replace-btn']}>
                  <FormattedMessage id="croppAvatar.modal.replacePhoto" />
                </div>
                <input
                  value=""
                  className={styles['file-input']}
                  onChange={onReplace}
                  type="file"
                  accept="image/png, image/jpeg"
                />
              </label>
              {/* save photo*/}
              <Button onClick={() => onSave(closeModal)} primary>
                <FormattedMessage id="croppAvatar.modal.savePhoto" />
              </Button>
            </div>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

export default connector(AvatarCropperModal);
