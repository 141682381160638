import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
// hooks
import useMeasurementSystem from 'hooks/useMeasurementSystem';
// utils
import { formatWeight } from 'utils/weightHelper';
// types
import { FormattedWeightProps, WeightValue } from './FormattedWeight.types';

const FormattedWeight = ({ value, withoutLabel = false }: FormattedWeightProps) => {
  const { measurementSystem, weightMeasureKey } = useMeasurementSystem();

  if (value === undefined) {
    return <FormattedMessage id="general.notAvailableAbbr" />;
  }

  const formattedWeight = formatWeight(+value, measurementSystem);

  if (withoutLabel) {
    return <span>{formattedWeight}</span>;
  }

  const weightLabelKey = `general.${weightMeasureKey}`;

  return (
    <FormattedMessage id={weightLabelKey}>
      {label => `${formattedWeight} ${label}`}
    </FormattedMessage>
  );
};

const Label = () => {
  const { weightMeasureKey } = useMeasurementSystem();
  const weightLabelKey = `general.${weightMeasureKey}`;

  return <FormattedMessage id={weightLabelKey} />;
};

const Range = ({ min, max }: { min: WeightValue; max: WeightValue }) => (
  <Fragment>
    <FormattedWeight withoutLabel value={min} />
    &nbsp;-&nbsp;
    <FormattedWeight value={max} />
  </Fragment>
);

FormattedWeight.Label = Label;
FormattedWeight.Range = Range;

export default FormattedWeight;
