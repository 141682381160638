import React, { FC, Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import TruncatedText from 'components/TruncatedText';
// utils
import { formatAddress } from 'utils';
// types
import { AddressEntity } from 'types';
// styles
import styles from './CommitmentInfo.module.scss';

const CommitmentInfo: FC<{ data?: AddressEntity; nameMaxWidth?: number }> = ({
  children,
  data,
  nameMaxWidth,
}) =>
  data ? (
    <Fragment>
      <TruncatedText
        text={data.name || ''}
        size="small"
        className="bold"
        maxWidth={nameMaxWidth}
        hasTooltip
      />
      {data.logistics_contact && (
        <div className={styles.details}>
          {data.logistics_contact.full_name},&nbsp;
          {data.logistics_contact.contact_phone || (
            <FormattedMessage id="general.notAvailableAbbr" />
          )}
        </div>
      )}
      <div className={styles.details}>
        <div>{formatAddress(data)}</div>
        {children}
      </div>
    </Fragment>
  ) : (
    <div className="bold">
      <FormattedMessage id="general.notAvailableAbbr" />
    </div>
  );

export default CommitmentInfo;
