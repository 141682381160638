import React from 'react';
// components
import { ReactComponent as CheckIcon } from 'icons/check.svg';
// utils
import classnames from 'classnames/bind';
// types
import { SelectMenuType } from '../Filters.types';
// styles
import styles from './SectionDropdownMenu.module.scss';

const cn = classnames.bind(styles);

function SelectMenu<T extends string>({
  currentValue,
  options = [],
  onOptionClick,
}: SelectMenuType<T>) {
  return (
    <ul className={cn('dropdown-list')}>
      {options.map(({ value, label }, index) => (
        <li
          key={index}
          onClick={() => onOptionClick(value)}
          className={cn('list-item', { active: currentValue === value })}
        >
          <div className={cn('option-label')}>{label}</div>
          {currentValue === value && <CheckIcon className={cn('selected-icon')} />}
        </li>
      ))}
    </ul>
  );
}

export default SelectMenu;
