import React, { Fragment, FC } from 'react';
import Divider from 'components/Divider';

const SectionDivider: FC = ({ children }) => (
  <Fragment>
    {children}
    <Divider className="mt-20 mb-20" />
  </Fragment>
);

export default SectionDivider;
