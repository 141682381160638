import React, { Fragment } from 'react';
// components
import FormInput from 'components/Input/FormInput';
import IconInput from 'components/Input/IconInput';
import UserAvatar from 'components/UserAvatar';
import NoteCollapse from 'components/Collapse/NoteCollapse';
import { FormattedMessage } from 'react-intl';
// types
import { InternalCommentSectionTypes } from './LoadFormSections.types';

const InternalCommentSection = ({ creator, comment, setComment }: InternalCommentSectionTypes) => (
  <Fragment>
    {creator?.full_name && (
      <FormInput
        label={<FormattedMessage id="general.creator" />}
        inputRenderer={() => (
          <IconInput
            className="mt-5"
            value={creator.full_name}
            icon={
              <UserAvatar
                size="small"
                srcData={creator.profile_photo}
                name={creator.full_name || ''}
              />
            }
            hasNoBorders
            disabled
          />
        )}
      />
    )}
    <NoteCollapse
      isOpened={Boolean(comment)}
      label={<FormattedMessage id="note.addInternalNote" />}
      value={comment}
      onChange={setComment}
    />
  </Fragment>
);

export default InternalCommentSection;
