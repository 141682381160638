import ReactCropper from 'react-cropper';

const getCropperDiameter = (cropper: ReactCropper) => {
  const imageCoords = cropper.getImageData();
  return Math.min(imageCoords.height, imageCoords.width);
};

export const updateDiameterIfChanged = (cropper: ReactCropper) => {
  const cropBoxData = cropper.getCropBoxData();
  const diameterAfterRotate = getCropperDiameter(cropper);
  if (cropBoxData.height !== diameterAfterRotate) {
    cropper.setCropBoxData({ height: diameterAfterRotate });
  }
};

export const setCenteredPosition = (cropper: ReactCropper) => {
  cropper.setDragMode('move');
  const cropDiameter = getCropperDiameter(cropper);
  const { width, height } = cropper.getContainerData();
  cropper.setCropBoxData({
    height: cropDiameter,
    left: (width - cropDiameter) / 2,
    top: (height - cropDiameter) / 2,
  });
};
