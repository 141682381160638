import { PackingPlant } from './company';
import { ContractCommitment } from './contractCommitment';
import { Farm, FarmBarn, FarmPigGroup } from './farm';
import { Load, ConfirmationStatus } from './load';
import { SalesResult } from './salesResult';

export enum CommitmentTypes {
  source = 'source',
  destination = 'destination',
}

export type CommitmentType = keyof typeof CommitmentTypes;

export type PigGroupAssignType = 'skip' | 'existing' | 'generate' | 'custom_name';

type LoadCommitmentBase = {
  barn?: FarmBarn;
  barn_id: number | null;
  comment: string | null;
  commitment_type: CommitmentType;
  cut_level: number | null;
  farm?: Farm;
  farm_id: number | null;
  id: string;
  minutes_for_load: number | null;
  packing_plant?: PackingPlant;
  pig_group?: FarmPigGroup;
  pig_group_assign_name: string | null;
  pig_group_assign_type: PigGroupAssignType | null;
  pig_group_id: number | null;
  position: number;
  status: ConfirmationStatus;
  sow_unit?: Farm;
  travel_distance: number | null;
  travel_seconds: number | null;
};

export type TruckingLoadCommitment = LoadCommitmentBase & {
  arrive_at: string;
  contract_commitment?: Omit<ContractCommitment, 'contract' | 'packing_plant' | 'sow_unit'>;
  head_count: number;
};

export type LoadCommitment = LoadCommitmentBase & {
  arrive_at: string | null;
  can_force_accept: boolean;
  contract_commitment?: ContractCommitment;
  head_count: number | null;
  matched: boolean;
  faked?: boolean; // front-end key
};

export type LoadCommitmentWithLoad = LoadCommitment & {
  loading: Load;
};

export type HistoryPigGroupCommitment = LoadCommitmentWithLoad & {
  sales_results?: (SalesResult & { destination_loading_commitment: LoadCommitment })[];
};

export type CommitmentTravelData = Pick<LoadCommitment, 'travel_distance' | 'travel_seconds'>;
