import React from 'react';
// utils
import cn from 'classnames';
// types
import { EmptyPlaceholderProps } from './EmptyPlaceholder.types';
// styles
import styles from './EmptyPlaceholder.module.scss';

const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = ({
  title,
  subtitle,
  icon: IconComponent,
  theme = 'almost-white',
  small = false,
  iconClassname,
  className,
  children,
}) => (
  <div className={cn(styles.wrapper, className, { [styles.small]: small })}>
    <div className={styles.content}>
      {IconComponent && (
        <IconComponent height={200} className={cn(iconClassname, { [styles.small]: small })} />
      )}
      {!IconComponent && (
        <div className={cn(styles.round, { [styles.small]: small }, styles[theme])} />
      )}
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{subtitle}</div>
      {children}
    </div>
  </div>
);

export default EmptyPlaceholder;
