import { UserFilterSection } from './filterSections';
import { Farm } from './farm';
import { Company, PackingPlant } from './company';
import { User } from './user';

export type ContractStepType =
  | 'basicInfo'
  | 'requirements'
  | 'deliveryFreq'
  | 'loadIdParadigm'
  | 'specialRequirements'
  | 'reviewAndSave'
  | 'none';

export enum ContractTypes {
  SowUnitContract = 'SowUnitContract',
  PackerContract = 'PackerContract',
}

export type ContractType = keyof typeof ContractTypes;

export type ContractFrequencyType = 'periodic' | 'once';

export type ContractStatus = 'active' | 'disabled' | 'closed' | 'draft';

export type ContractEndCondition = 'never' | 'date' | 'count';

type DeliverySchedule = {
  day_of_week: string;
  hour: number;
  minute: number;
  id: string;
};

export type Contract = {
  name?: string;
  status: ContractStatus;
  id: string;
  type: ContractType;
  packing_plant?: PackingPlant;
  sow_units?: Farm[];
  owner_company?: Company;
  special_contract?: boolean;
  special_requirements?: {
    any_condition: boolean;
    sections: UserFilterSection[];
  };
  pig_type?: string;
  head_count?: number;
  custom_name_parts?: string[];
  use_custom_name?: boolean;
  weight_min?: number;
  weight_max?: number;
  arrive_hour?: number;
  arrive_minute?: number;
  delivery_frequency_type?: ContractFrequencyType;
  start_date?: string;
  delivery_schedules?: DeliverySchedule[];
  end_count?: number;
  end_condition?: ContractEndCondition;
  end_date?: string;
  user?: User;
  skip_trucking_company?: boolean;
};
