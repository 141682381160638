import React, { useRef, useState, useEffect } from 'react';
// redux, action creators
import notificationsConnector, {
  ConnectedNotificationsProps,
} from 'store/connectors/notificationsConnector';
// components
import { FormattedMessage } from 'react-intl';
import { Waypoint } from 'react-waypoint';
import Link from 'components/Link';
import Loader from 'components/Loader';
import Button from 'components/Button';
import HeaderContentPortal from 'components/Portal/HeaderContentPortal';
import EmptyPlaceholder, { NoMessages } from 'components/EmptyPlaceholder';
import NotificationItem from 'components/Notifications/NotificationItem';
// utils
import cn from 'classnames';
import moment from 'moment';
import throttle from 'lodash.throttle';
import { redirectToProd } from 'utils/redirectHelper';
// types
import { SystemSides, SystemSide } from 'types';
// styles
import styles from './Notifications.module.scss';

type Props = ConnectedNotificationsProps & {
  type: SystemSide;
  truckingCompanyId?: string | number | null;
  truckingCompanyRootId?: number;
};

const Notifications = ({
  authUserId,
  type,
  truckingCompanyId,
  truckingCompanyRootId,
  fetchNotifications,
  dismissAll,
  markAsDismissed,
  markAsRead,
  params,
  resources,
  increaseUnreadNotsCount,
  isLoaded,
  isLoading,
  meta: { total },
}: Props) => {
  const [isSettingRead, setIsSettingRead] = useState(false);
  const { current: readIds } = useRef(new Set<number>());
  const defaultParams = { group: type };

  const getNotifications = (page = 1) => {
    fetchNotifications(authUserId, {
      ...defaultParams,
      ...params,
      page,
      trucking_company_id: truckingCompanyId || undefined,
      current_company_id: truckingCompanyRootId || undefined,
    });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const sendReadIds = () => {
    setIsSettingRead(true);
    if (readIds.size === 0) return;

    markAsRead(authUserId, Array.from(readIds), defaultParams)
      .then(() => {
        increaseUnreadNotsCount(-readIds.size);
        readIds.clear();
      })
      .finally(() => {
        setIsSettingRead(false);
      });
  };

  const handleDismiss = (notificationId: number) => {
    if (isLoading) return false;
    return markAsDismissed(authUserId, notificationId, defaultParams);
  };

  const throttleCheck = throttle(sendReadIds, 2000, { leading: false });

  const throttleMarkAsRead = (id: number) => {
    readIds.add(id);
    throttleCheck();
  };

  const hasMoreItems = total > resources.length;
  const isNoNotifications = isLoaded && !resources.length;

  return (
    <div
      className={cn(styles['list-container'], { [styles['no-notifications']]: isNoNotifications })}
    >
      <HeaderContentPortal>
        <div className={styles['nots-header']}>
          <FormattedMessage id="general.notifications" />
          <div>
            {type === SystemSides.trucking && (
              <Link to="/trucking/my-profile/messaging" className={styles['settings-link']}>
                <FormattedMessage id="general.settings" />
              </Link>
            )}
            {type === SystemSides.logistics && (
              <FormattedMessage id="general.settings">
                {(text: string) => (
                  <span
                    className={styles['settings-link']}
                    onClick={() => redirectToProd('/profile/messaging')}
                  >
                    {text}
                  </span>
                )}
              </FormattedMessage>
            )}
            <Button
              primary
              className="ml-20"
              disabled={!resources.length}
              onClick={() => {
                dismissAll(authUserId, resources[0].id, defaultParams);
              }}
            >
              <FormattedMessage id="notifications.dismissAll" />
            </Button>
          </div>
        </div>
      </HeaderContentPortal>
      <Loader isActive={isLoading} className="align-hor-ver" />
      {isNoNotifications && (
        <EmptyPlaceholder
          icon={NoMessages}
          title={<FormattedMessage id="emptyPlaceholder.allCaughtUp" />}
          subtitle={<FormattedMessage id="emptyPlaceholder.allCaughtUp.detailedText" />}
        />
      )}
      {resources.map((item, index) => {
        const isCurrentItemToday = moment(item.created_at).isSame(new Date(), 'day');
        const isFirstItem = index === 0;
        const hasTodayDivider = isFirstItem && isCurrentItemToday;
        const hasEarlierDivider = isFirstItem
          ? !isCurrentItemToday
          : !isCurrentItemToday &&
            moment(resources[index - 1].created_at).isSame(new Date(), 'day');
        return (
          <NotificationItem
            key={item.id}
            hasEarlierDivider={hasEarlierDivider}
            hasTodayDivider={hasTodayDivider}
            onMarkAsRead={throttleMarkAsRead}
            onDismiss={handleDismiss}
            notification={item}
          />
        );
      })}
      {!isLoading && hasMoreItems && isLoaded && !isSettingRead && (
        <Waypoint onEnter={() => getNotifications(params.page + 1)} />
      )}
    </div>
  );
};

export default notificationsConnector(Notifications);
