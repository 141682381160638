import React from 'react';
// utils
import cn from 'classnames';
// styles
import styles from './Divider.module.scss';

const Divider = ({ className, isDashed }: { className?: string; isDashed?: boolean }) => (
  <div className={cn(styles['divider'], { [styles['dashed']]: isDashed }, className)} />
);

export default Divider;
