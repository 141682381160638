import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// types
import { StatusBadgeProps } from './StatusBadge.types';
// styles
import styles from './StatusBadge.module.scss';

const cn = classnames.bind(styles);

const StatusBadge = ({ status, className, customTheme }: StatusBadgeProps) => (
  <div className={cn('profile-status', [status], className)} style={customTheme?.[status]}>
    <FormattedMessage id={`status.${status}`} />
  </div>
);

export default StatusBadge;
