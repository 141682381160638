import React, { ReactNode } from 'react';
// components
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as DragIcon } from 'icons/drag-indicator.svg';
// utils
import classnames from 'classnames/bind';
// types
import { AvailManageColumns } from 'types';
// styles
import styles from '../ManageTableColumnsPage.module.scss';

const cn = classnames.bind(styles);

type ColumnsDropZoneProps = {
  droppableId: string;
  fields: AvailManageColumns[];
  isEmpty: boolean;
  placeholder: ReactNode;
  label: ReactNode;
  matchSearch: (label: string) => boolean;
};

export const ColumnsDropZone = ({
  droppableId,
  fields,
  isEmpty,
  placeholder,
  label,
  matchSearch,
}: ColumnsDropZoneProps) => (
  <Droppable droppableId={droppableId}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        className={cn('drop-zone', {
          'is-dragging-over': snapshot.isDraggingOver,
          'is-empty': isEmpty,
        })}
      >
        {fields.map((column, index) => (
          <Draggable key={column.label} draggableId={column.label} index={index}>
            {(draggableProvided, draggableSnapshot) => (
              <div
                className={cn('visible-field', {
                  'is-dragging': draggableSnapshot.isDragging,
                  'is-not-matching-search': !matchSearch(column.label),
                })}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
              >
                <DragIcon className={styles['drag-icon']} />
                {column.label}
                <span className={styles['fields-status']}>{label}</span>
              </div>
            )}
          </Draggable>
        ))}
        {isEmpty && <div className={styles['defaults-placeholder']}>{placeholder}</div>}
        {!isEmpty && provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default ColumnsDropZone;
