import React, { ReactNode } from 'react';
// types
import { CoordsType } from 'types';
// utils
import cn from 'classnames';
// styles
import styles from './Markers.module.scss';

type MarkerType = CoordsType & {
  distanceValue?: number;
  distanceLabel?: string;
  name: string | JSX.Element;
  hasDot?: boolean;
  label: string | ReactNode;
  theme?: 'purple' | 'primary' | 'light-blue' | 'brand-blue';
};

const HintMarker = ({
  name,
  label,
  distanceValue,
  distanceLabel,
  theme = 'primary',
  hasDot,
}: MarkerType) => {
  return (
    <div className={cn(styles.marker, { [styles.dot]: hasDot })}>
      <div className={styles.hint}>
        {typeof distanceValue === 'number' && distanceLabel && (
          <div className={cn(styles['distance-block'], styles[theme])}>
            <div className={styles.value}>{distanceValue}</div>
            <div className={styles.label}>{distanceLabel}</div>
          </div>
        )}
        <div className={styles['company-info-block']}>
          <div className={styles.name}>{name}</div>
          <div className={styles.label}>{label}</div>
        </div>
      </div>
    </div>
  );
};

export default HintMarker;
