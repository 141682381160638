import React, { useMemo, useRef } from 'react';
// components
import { ReactComponent as CloseIcon } from 'icons/close.svg';
// utils
import classnames from 'classnames/bind';
// hooks
import useRouteListenEffect from 'hooks/useRouteListenEffect';
import useOnClickOutside from 'hooks/useOnClickOutside';
// context
import { useModalSetter } from 'context/useModal';
// types
import { CloseButtonProps, ContextModalProps, SectionProps } from './Modal.types';
// styles
import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

const Modal = ({
  children,
  className = '',
  isMobileFullScreen = false,
  closeCallback,
  hasAutoWidth = false,
}: ContextModalProps) => {
  const modalRef = useRef(null);
  const setModal = useModalSetter();

  const closeModal = () => {
    if (closeCallback) closeCallback();
    setModal(null);
  };

  useRouteListenEffect(() => {
    setModal(null);
  }, [setModal]);

  useOnClickOutside(modalRef, closeModal, {
    isOpen: true,
    toastElem: useMemo(() => document.querySelector('.Toastify'), []),
  });

  return (
    <div
      id="context-modal"
      ref={modalRef}
      className={cn('modal', 'modal-root', className, {
        isMobileFullScreen,
        'auto-width': hasAutoWidth,
      })}
    >
      {children({ closeModal })}
    </div>
  );
};

const Header = ({ children, className, isOverflowVisible }: SectionProps) => (
  <section className={cn('modal-header', className, { 'overflow-visible': isOverflowVisible })}>
    {children}
  </section>
);

const Body = ({ children, className }: SectionProps) => (
  <section className={cn('modal-body', className)}>{children}</section>
);

const Footer = ({ children, className }: SectionProps) => (
  <section className={cn('modal-footer', className)}>{children}</section>
);

const CloseButton = ({ className, onClick }: CloseButtonProps) => (
  <div className={cn('close-button', className)} onClick={onClick}>
    <CloseIcon />
  </div>
);

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.CloseButton = CloseButton;

export default Modal;
