import React from 'react';
// components
import BadgeColumn from './index';
// utils
import { formatDate } from 'utils/formatDateHelper';
// types
import { ColumnRenderType } from 'types';

const BadgeDateColumn = ({ data, placeholder }: ColumnRenderType) => (
  <BadgeColumn data={formatDate(data?.toString())} placeholder={placeholder} />
);

export default BadgeDateColumn;
