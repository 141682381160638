import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
// utils
import pick from 'lodash.pick';
import { getColumnsFromStorage } from './localStorageHelper';
import { getInitialColumnKeys } from 'components/VirtualizedTable/columns/columnsManagement';
// types
import { AvailManageColumns, Column, TableColumnsType, ManageColumnNamesByType } from 'types';
import { isEqualStringArrays } from './index';

export function buildColumns(
  keys: ManageColumnNamesByType,
  columns: Record<string, AvailManageColumns>
) {
  return {
    fixed: Object.values<AvailManageColumns>(pick(columns, keys.fixed)),
    manageable: Object.values<AvailManageColumns>(pick(columns, keys.manageable)),
    hidden: Object.values<AvailManageColumns>(pick(columns, keys.hidden)),
    unmanageable: Object.values<AvailManageColumns>(pick(columns, keys.unmanageable)),
  };
}

// for now, we can move columns between fixed & manageable collections
export function getColumnsKeys(tableName: TableColumnsType) {
  const initialKeys = getInitialColumnKeys(tableName);
  const storageColumns = getColumnsFromStorage(tableName);

  if (!storageColumns) {
    return initialKeys;
  }

  if (
    isEqualStringArrays(storageColumns.fixed, initialKeys.fixed) &&
    isEqualStringArrays(storageColumns.unmanageable, initialKeys.unmanageable)
  ) {
    return storageColumns;
  }
  const hidden = storageColumns.hidden.filter(
    colName => !initialKeys.fixed.includes(colName) && !initialKeys.unmanageable.includes(colName)
  );
  const storageFixedAndManageable = [...storageColumns.fixed, ...storageColumns.manageable];
  const manageable = storageFixedAndManageable.filter(colName =>
    initialKeys.manageable.includes(colName)
  );

  return {
    ...initialKeys,
    hidden,
    manageable,
  };
}

export function getColumns<T>(columns: { [key: string]: Column<T> }, tableName: TableColumnsType) {
  const { fixed, manageable, unmanageable } = getColumnsKeys(tableName);
  return Object.values(pick(columns, [...fixed, ...manageable, ...unmanageable]));
}

export function getManageFieldsOptions(page: TableColumnsType) {
  return [
    {
      label: <FormattedMessage id="general.btn.manageFields" />,
      component: Link,
      itemProps: { to: `/manage-table-columns?name=${page}` },
    },
  ];
}
