import React, { useState } from 'react';
// components
import TruncatedTextColumn from '../TruncatedTextColumn';
import PortalTooltip from 'components/Tooltip/PortalTooltip';
// hooks
import usePortalTooltipState from 'utils/hooks/usePortalTooltipState';

type PortalTooltipColumnProps<T> = {
  size?: 'small' | 'medium';
  text: T;
  maxWidth?: number;
  placeholder?: string | JSX.Element;
};

function PortalTooltipColumn<T>({
  maxWidth,
  placeholder,
  size = 'medium',
  text,
}: PortalTooltipColumnProps<T>) {
  const [isTruncated, setIsTruncated] = useState(false);
  const { coords, isHovered, onMouseEnter, onMouseLeave } = usePortalTooltipState();

  return (
    <TruncatedTextColumn
      maxWidth={maxWidth}
      text={text}
      placeholder={placeholder}
      setIsTruncatedCallback={setIsTruncated}
      onMouseEnter={isTruncated ? onMouseEnter : undefined}
      onMouseLeave={isTruncated ? onMouseLeave : undefined}
    >
      {isHovered && <PortalTooltip content={text} size={size} style={{ maxWidth }} {...coords} />}
    </TruncatedTextColumn>
  );
}

export default PortalTooltipColumn;
