import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// types
import {
  AppNotification,
  MetaType,
  DefaultNotificationsParams,
  NotificationsResponse,
} from 'types';

type NotificationsState = {
  resources: AppNotification[];
  isLoading: boolean;
  isLoaded: boolean;
  params: DefaultNotificationsParams;
  meta: MetaType;
};

const initialState: NotificationsState = {
  resources: [],
  isLoading: false,
  isLoaded: false,
  params: {
    page: 1,
    per_page: 25,
  },
  meta: {
    total: 0,
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(
      state,
      action: PayloadAction<NotificationsResponse & { params: DefaultNotificationsParams }>
    ) {
      const { meta, params, resources } = action.payload;
      return {
        params,
        meta,
        resources: params.page === 1 ? resources : state.resources.concat(resources),
        isLoaded: true,
        isLoading: false,
      };
    },
    setNotificationsIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setAsDismissed(
      state,
      action: PayloadAction<{ dismissedId: number; resources: AppNotification[] }>
    ) {
      return {
        ...state,
        isLoading: false,
        resources: state.resources
          .filter(item => item.id !== action.payload.dismissedId)
          .concat(action.payload.resources),
        meta: {
          total: state.meta.total - 1,
        },
      };
    },
    setAsRead(state, action: PayloadAction<number[]>) {
      state.resources = state.resources.map(item =>
        action.payload.includes(item.id) ? { ...item, read: true } : item
      );
      state.isLoading = false;
    },
    setAllDismiss() {
      return { ...initialState, isLoaded: true };
    },
    resetNotificationsState() {
      return initialState;
    },
  },
});

export const {
  resetNotificationsState,
  setAsDismissed,
  setAsRead,
  setNotifications,
  setNotificationsIsLoading,
  setAllDismiss,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
