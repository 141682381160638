import { makeAxiosRequestV1Api } from 'utils/api';
// types
import { NotificationsResponse, NotificationsParams } from 'types';

export const getUserNotifications = (
  userId: string,
  params: NotificationsParams
): Promise<NotificationsResponse> =>
  makeAxiosRequestV1Api(`/users/${userId}/notifications`, { params });

export const dismissAllNotifications = (
  userId: string,
  last_notification_id: number,
  params: Pick<NotificationsParams, 'group'>
) =>
  makeAxiosRequestV1Api(`/users/${userId}/notifications/dismiss_all`, {
    method: 'put',
    params: { last_notification_id, ...params },
  });

export const dismissNotification = (
  userId: string,
  notificationId: number,
  params: Pick<NotificationsParams, 'group'>
) =>
  makeAxiosRequestV1Api(`/users/${userId}/notifications/${notificationId}/dismiss`, {
    method: 'put',
    params,
  });

export const fetchNotificationAfterDismiss = (
  userId: string,
  params: Pick<NotificationsParams, 'group' | 'padding'>
): Promise<NotificationsResponse> =>
  makeAxiosRequestV1Api(`/users/${userId}/notifications`, {
    params: { ...params, page: 1, per_page: 1 },
  });

export const markNotificationAsRead = (
  userId: string,
  viewedIds: number[],
  params: Pick<NotificationsParams, 'group'>
) =>
  makeAxiosRequestV1Api(`/users/${userId}/notifications/read`, {
    method: 'put',
    params,
    data: { resource: { read_notifications_ids: viewedIds } },
  }).then(() => viewedIds);

export const fetchUnReadNotificationsCount = (
  id: string,
  params: Pick<NotificationsParams, 'group' | 'trucking_company_id' | 'current_company_id'>
) => makeAxiosRequestV1Api(`/users/${id}/notifications/unread_notifications_count`, { params });
