import React, { Fragment, useState } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Divider from 'components/Divider';
import SalesResultEdit from 'components/SalesResults/SalesResultEdit';
// hooks
import useStateArray from 'hooks/useStateArray';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useMeasurementSystem from 'hooks/useMeasurementSystem';
// utils
import { formatWeight } from 'utils/weightHelper';
import cn from 'classnames';
import { formatDateTimeToISO } from 'utils/formatDateHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getInitialSalesResults } from 'utils/salesResultsHelper';
// types
import { SalesResultLoadSectionProps } from './LoadSections.types';
// styles
import styles from './EditableSalesResultSection.module.scss';

const EditableSalesResultSection: React.FC<SalesResultLoadSectionProps> = ({
  salesResults,
  onCreate,
  onUpdate,
  className,
  commitments,
  toggleLockResult,
  isSubmitBtnSticky = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatingResultId, setUpdatingResultId] = useState('');
  const { measurementSystem } = useMeasurementSystem();

  const [stateResults, setStateResults, { setValuesByIndex }] = useStateArray(
    getInitialSalesResults(salesResults, measurementSystem)
  );
  const isResultsExists = salesResults.every(({ id }) => id);
  const isAllResultsLocked = salesResults.every(({ locked }) => locked);
  const isMultipleResults = salesResults.length > 1;

  useDidUpdateEffect(() => {
    setStateResults(getInitialSalesResults(salesResults, measurementSystem));
  }, [salesResults]);

  const onSendData = () => {
    setIsLoading(true);

    const attributes = stateResults
      .filter(result => !result.locked)
      .map(
        ({
          id,
          avg_pig_weight,
          arrive_minute,
          arrive_hour,
          comment,
          delivered_at,
          destination_loading_commitment_id,
          head_count,
          source_loading_commitment_id,
        }) => ({
          id: id || '',
          head_count,
          avg_pig_weight: formatWeight(avg_pig_weight, 'imperial', measurementSystem) || 0,
          comment,
          delivered_at: formatDateTimeToISO(delivered_at, arrive_hour, arrive_minute),
          destination_loading_commitment_id,
          source_loading_commitment_id,
        })
      );
    (isResultsExists ? onUpdate : onCreate)(attributes)
      .catch(toastResponseErrors)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLockSaleResult = (resultId?: string) => {
    if (!resultId) return;
    setUpdatingResultId(resultId);
    toggleLockResult(resultId)
      .catch(toastResponseErrors)
      .finally(() => {
        setUpdatingResultId('');
      });
  };

  return (
    <Fragment>
      <div className={cn(className, styles['sales-result-section'])}>
        {stateResults.map((result, index) => {
          const {
            arrive_hour,
            arrive_minute,
            avg_pig_weight,
            comment,
            delivered_at,
            destination_loading_commitment_id,
            head_count,
            id,
            locked,
            modified_by,
            source_loading_commitment_id,
          } = result;
          const isLast = !stateResults[index + 1];
          const source = isMultipleResults
            ? commitments.find(({ id }) => +id === source_loading_commitment_id)
            : undefined;
          const destination = isMultipleResults
            ? commitments.find(({ id }) => +id === destination_loading_commitment_id)
            : undefined;
          return (
            <Fragment key={index}>
              <SalesResultEdit
                arriveHour={arrive_hour}
                arriveMinute={arrive_minute}
                avgPigWeight={avg_pig_weight}
                comment={comment}
                deliveredAt={delivered_at}
                destinationLoadCommitment={destination}
                headCount={head_count}
                isLockable={!!result.id}
                isLocked={locked}
                isUpdating={!!result.id && result.id === updatingResultId}
                modifiedBy={modified_by}
                onChange={values => setValuesByIndex(index, values)}
                resultId={id || index.toString()}
                sourceLoadCommitment={source}
                toggleLock={() => handleLockSaleResult(result.id)}
              />
              {!isLast && <Divider className="mv-20" />}
            </Fragment>
          );
        })}
      </div>
      <div className={cn(styles['btn-section'], { [styles.sticky]: isSubmitBtnSticky })}>
        <Button
          disabled={isLoading || !!updatingResultId || isAllResultsLocked}
          shine
          onClick={onSendData}
          className={styles.btn}
          rounded
          primary
        >
          {isResultsExists ? (
            <FormattedMessage id="general.btn.update" />
          ) : (
            <FormattedMessage id="general.btn.save" />
          )}
        </Button>
      </div>
    </Fragment>
  );
};

export default EditableSalesResultSection;
