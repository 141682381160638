import { useState, useRef, ReactNode } from 'react';
// hooks
import useDidUpdateEffect from './useDidUpdateEffect';

export default function(propContent?: ReactNode): [boolean, ReactNode?] {
  const [{ content, isVisible }, setState] = useState({
    content: propContent,
    isVisible: !!propContent,
  });
  const ref = useRef({ isPrevContentExist: !!propContent });

  useDidUpdateEffect(() => {
    const isCurrentContentExist = !!propContent;
    const { isPrevContentExist } = ref.current;

    if (isPrevContentExist && isCurrentContentExist) {
      setState(prevState => ({ ...prevState, content: propContent }));
    }

    if (!isPrevContentExist && isCurrentContentExist) {
      setState(prevState => ({ ...prevState, content: propContent }));
      const mountTimer = setTimeout(() => {
        setState(prevState => ({ ...prevState, isVisible: true }));
        clearTimeout(mountTimer);
      }, 150);
      ref.current.isPrevContentExist = true;
    }

    if (isPrevContentExist && !isCurrentContentExist) {
      setState(prevState => ({ ...prevState, isVisible: false }));
      const unMountTimer = setTimeout(() => {
        setState(prevState => ({ ...prevState, content: null }));
        clearTimeout(unMountTimer);
      }, 300);
      ref.current.isPrevContentExist = false;
    }
  }, [propContent]);

  return [isVisible, content];
}
