import React from 'react';
// components
import UserAvatarUploadButton from './UserAvatarUploadButton';
// utils
import { getLetterInitials, formatSrcWithApiUrl, isObjectAllValueEmpty } from 'utils';
import classnames from 'classnames/bind';
// types
import { UserAvatarProps } from './UserAvatar.types';
// styles
import styles from './UserAvatar.module.scss';

const cn = classnames.bind(styles);

const UserAvatar = ({ name, srcData, size = 'medium', isEditable, className }: UserAvatarProps) => (
  <div className={cn(styles.avatar, className, { [size]: !!size })}>
    {isObjectAllValueEmpty(srcData) ? (
      <div className={cn('letters')}>{getLetterInitials(name || '')}</div>
    ) : (
      <img className={cn('image')} src={formatSrcWithApiUrl(srcData?.medium || '')} alt={name} />
    )}
    {isEditable && (
      <UserAvatarUploadButton className={cn('upload-btn')} avatarSrc={srcData?.large || ''} />
    )}
  </div>
);

export default UserAvatar;
