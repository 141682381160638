import React, { CSSProperties, Fragment } from 'react';
import ContentLoader from 'react-content-loader';
import { HeaderLoaderProps } from './HeaderLoader.types';

const WIDTH = 300;
const HEIGHT = 45;

const style: CSSProperties = { width: WIDTH, marginRight: 'auto' };

const HeaderLoader = ({ isActive, type = 'circle', children }: HeaderLoaderProps) => (
  <Fragment>
    {isActive ? (
      <ContentLoader
        style={style}
        height={HEIGHT}
        width={WIDTH}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="55" y="5" rx="4" ry="4" width="200" height="15" />
        <rect x="55" y="26" rx="3" ry="3" width="75" height="12" />
        {type === 'square' && <rect x="5" y="2" rx="2" ry="2" width="40" height="40" />}
        {type === 'circle' && <circle cx="25" cy="20" r="20" />}
      </ContentLoader>
    ) : (
      children
    )}
  </Fragment>
);

export default HeaderLoader;
