import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DateTimeRange from 'components/DateTimeRange';
import FormInput from 'components/Input/FormInput';
import IconInput from 'components/Input/IconInput';
import NumberInput from 'components/Input/NumberInput';
import LabeledInput from 'components/Input/LabeledInput';
import LabelValueRow from 'components/LabelValueRow';
import Loader from 'components/Loader';
import NoteCollapse from 'components/Collapse/NoteCollapse';
import ReadMoreComment from 'components/ReadMoreComment';
import SingleDatePicker from 'components/SingleDatePicker';
import TruckingRouteCard from 'components/TruckingRouteCard';
import UserAvatar from 'components/UserAvatar';
import FormattedWeight from 'components/FormattedWeight';
import { ReactComponent as LockIcon } from 'icons/lock.svg';
import { ReactComponent as UnLockIcon } from 'icons/unlock.svg';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
import { ReactComponent as PiggyIcon } from 'icons/piggy.svg';
import { ReactComponent as WeightIcon } from 'icons/weight.svg';
// utils
import cn from 'classnames';
import {
  formatDateTimeToISO,
  formatDateToISO,
  formatISOToCustom,
  formatToTime,
} from 'utils/formatDateHelper';
// types
import {
  LoadCommitment,
  SalesResultFormValues,
  SaleResultFocusedField,
  User,
  TruckingLoadCommitment,
} from 'types';
// styles
import styles from './SalesResultEdit.module.scss';

type Props = {
  arriveHour?: number;
  arriveMinute?: number;
  avgPigWeight: number | undefined;
  comment: string;
  deliveredAt?: string;
  destinationLoadCommitment?: LoadCommitment | TruckingLoadCommitment;
  focusedField?: SaleResultFocusedField;
  headCount?: number;
  isLockable?: boolean;
  isLocked?: boolean;
  isUpdating?: boolean;
  modifiedBy?: User;
  onChange: (values: Partial<SalesResultFormValues>) => void;
  resultId?: string;
  sourceLoadCommitment?: LoadCommitment | TruckingLoadCommitment;
  toggleLock?: () => void;
};

const SalesResultEdit = ({
  arriveHour,
  arriveMinute,
  avgPigWeight,
  comment,
  deliveredAt,
  destinationLoadCommitment,
  focusedField,
  headCount,
  isLockable = false,
  isLocked = false,
  isUpdating = false,
  modifiedBy,
  onChange,
  resultId,
  sourceLoadCommitment,
  toggleLock,
}: Props) => {
  const handleChange = (name: string) => (value?: number) => {
    onChange({ [name]: value ?? null });
  };

  return (
    <div className={cn(styles['sales-result-edit'], { [styles['is-loading']]: isUpdating })}>
      <Loader isActive={isUpdating} isCentered />
      {isLockable && (
        <Button
          medium
          gray
          rounded
          className={styles['lock-btn']}
          disabled={isUpdating}
          onClick={toggleLock}
        >
          {isLocked ? <UnLockIcon className={styles.icon} /> : <LockIcon className={styles.icon} />}
          <FormattedMessage id={isLocked ? 'general.unlockResult' : 'general.lockResult'} />
        </Button>
      )}
      {sourceLoadCommitment && destinationLoadCommitment && (
        <TruckingRouteCard
          className="mb-10"
          source={sourceLoadCommitment}
          destination={destinationLoadCommitment}
        />
      )}
      {isLocked && (
        <Fragment>
          <LabelValueRow
            className="mb-5"
            label={<FormattedMessage id="load.salesResults.dateDelivered" />}
            value={formatISOToCustom(
              formatDateTimeToISO(deliveredAt, arriveHour, arriveMinute),
              'MM/dd/yyyy'
            )}
            icon={CalendarIcon}
          />
          <LabelValueRow
            className="mb-5"
            label={<FormattedMessage id="load.salesResults.deliveryTime" />}
            value={formatToTime(arriveHour, arriveMinute)}
            icon={ClockIcon}
          />
          <LabelValueRow
            className="mb-5"
            label={<FormattedMessage id="load.salesResults.headDelivered" />}
            value={headCount}
            icon={PiggyIcon}
          />
          <LabelValueRow
            className="mb-5"
            label={<FormattedMessage id="load.salesResults.prelimAvgWeight" />}
            value={<FormattedWeight value={avgPigWeight} />}
            icon={WeightIcon}
          />
          {modifiedBy && (
            <LabelValueRow
              className="mb-5"
              label={<FormattedMessage id="load.salesResults.submittedBy" />}
              value={
                <>
                  <UserAvatar
                    size="small"
                    name={modifiedBy.full_name || ''}
                    srcData={modifiedBy.profile_photo}
                    className="mr-10"
                  />
                  {modifiedBy.full_name}
                </>
              }
            />
          )}
          {!!comment && (
            <div className="mt-15">
              <span className="bold">
                <FormattedMessage id="general.notes" />
              </span>
              <div className="mt-10">
                <ReadMoreComment comment={comment} />
              </div>
            </div>
          )}
        </Fragment>
      )}
      {!isLocked && (
        <Fragment>
          <FormInput
            className={styles['section-row']}
            label={<FormattedMessage id="load.salesResults.dateDelivered" />}
            inputRenderer={() => (
              <SingleDatePicker
                isFocused={focusedField === 'delivered_date'}
                numberOfMonths={1}
                value={formatDateToISO(deliveredAt)}
                onChange={delivered_at => onChange({ delivered_at })}
                id={`picker-${resultId}`}
                isOutsideRange={() => false}
              />
            )}
          />
          <FormInput
            label={<FormattedMessage id="load.salesResults.deliveryTime" />}
            inputRenderer={() => (
              <DateTimeRange
                isFocused={focusedField === 'delivered_time'}
                hourValue={arriveHour}
                hourInputName="arrive_hour"
                minuteInputName="arrive_minute"
                minuteValue={arriveMinute}
                setValues={onChange}
              />
            )}
          />
          <FormInput
            className={styles['section-row']}
            label={<FormattedMessage id="load.salesResults.headDelivered" />}
            inputRenderer={() => (
              <NumberInput
                autoFocus={focusedField === 'head_count'}
                floatRight
                onChange={handleChange('head_count')}
                placeholder="0"
                value={headCount}
              />
            )}
          />
          <FormInput
            className="mb-25"
            label={<FormattedMessage id="load.salesResults.prelimAvgWeight" />}
            inputRenderer={() => (
              <NumberInput
                value={avgPigWeight}
                onChange={handleChange('avg_pig_weight')}
                max={9999}
              >
                {props => (
                  <LabeledInput
                    autoFocus={focusedField === 'avg_pig_weight'}
                    label={<FormattedWeight.Label />}
                    labelRight
                    placeholder="0"
                    {...props}
                  />
                )}
              </NumberInput>
            )}
          />
          <NoteCollapse
            autoFocus={focusedField === 'comment'}
            className="mb-10"
            isOpened={!!comment || focusedField === 'comment'}
            value={comment}
            onChange={comment => onChange({ comment })}
          />

          {modifiedBy && (
            <FormInput
              label={<FormattedMessage id="load.salesResults.submittedBy" />}
              inputRenderer={() => (
                <IconInput
                  className="mb-10"
                  value={modifiedBy?.full_name}
                  icon={
                    <UserAvatar
                      size="small"
                      srcData={modifiedBy?.profile_photo}
                      name={modifiedBy?.full_name || ''}
                    />
                  }
                  hasNoBorders
                  disabled
                />
              )}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default SalesResultEdit;
