// redux toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
import {
  filterByTypeAndCondition,
  isInvalidSectionData,
  isSectionsEqual,
} from 'components/Filters/utils';
// types
import {
  AddSectionPayload,
  ExistingUserFilter,
  InitialFilterPayload,
  InitialFiltersState,
  MergeSectionPayload,
  Page,
  ReduxUserFilter,
  RemoveSectionPayload,
  SelectExistingFilterPayload,
  SetConditionPayload,
  SetMobileFilterPayload,
  SetSearchPayload,
  SetSectionsPayload,
  UpdateSectionPayload,
} from 'types';
import { StateUserFilterSection } from 'types/filterSections';

const initialFilter: ReduxUserFilter = {
  any_condition: false,
  sections: [],
  searchValue: '',
  savedUserFilters: {
    isLoaded: false,
    isLoading: false,
    resources: [],
  },
};

const initialState: InitialFiltersState = {
  arrivalPlans: initialFilter,
  arrivalBarns: initialFilter,
  contracts: initialFilter,
  loads: initialFilter,
  loadArchive: initialFilter,
  loadsPreview: initialFilter,
  salePlans: initialFilter,
  worksheetGroups: initialFilter,
  salesResults: initialFilter,
  specialRequirements: initialFilter,
  transferPlans: initialFilter,
  transferBarns: initialFilter,
  truckingLoads: initialFilter,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addCreatedFilter(state, action: PayloadAction<Page & { filter: ExistingUserFilter }>) {
      const { page, filter } = action.payload;
      state[page] = {
        id: filter.id,
        name: filter.name,
        any_condition: filter.any_condition || false,
        sections: filter.sections.map((section, index) => ({
          sectionIndex: index + 1,
          ...section,
        })),
        searchValue: '',
        savedUserFilters: {
          ...state[page].savedUserFilters,
          isLoading: false,
          resources: state[page].savedUserFilters.resources.concat(filter),
        },
      };
    },
    addCreatedFilterMobile(state, action: PayloadAction<Page & { filter: ExistingUserFilter }>) {
      const { page, filter } = action.payload;
      const { resources } = state[page].savedUserFilters;
      state[page].savedUserFilters.isLoading = false;
      state[page].savedUserFilters.resources = resources.concat(filter);
    },
    addSection(state, action: PayloadAction<AddSectionPayload>) {
      const { page, sectionType } = action.payload;
      const sections = state[page].sections;
      const newSection = {
        type: sectionType,
        condition: '',
        isInitialOpened: true,
        isInvalid: true,
        sectionIndex: (sections[sections.length - 1]?.sectionIndex || 0) + 1,
      } as StateUserFilterSection; // todo: check typescript update

      state[page].sections = [...sections, newSection];
    },
    deleteExistingUserFilter(state, action: PayloadAction<Page & { filterId: number }>) {
      const { page, filterId } = action.payload;
      const updatedResources = state[page].savedUserFilters.resources.filter(
        ({ id }) => id !== filterId
      );

      if (state[page].id === filterId) {
        state[page] = {
          ...initialFilter,
          savedUserFilters: {
            ...state[page].savedUserFilters,
            isLoading: false,
            resources: updatedResources,
          },
        };
      } else {
        state[page].savedUserFilters.isLoading = false;
        state[page].savedUserFilters.resources = updatedResources;
      }
    },
    mergeReadySection(state, action: PayloadAction<MergeSectionPayload>) {
      const { page, section, isUnique } = action.payload;
      const sections = state[page].sections;
      const currentLength = sections.length;
      const lastKey = sections[currentLength - 1]?.sectionIndex;

      if (isUnique) {
        const { type, condition } = section;
        const existedSections = sections.filter(section =>
          filterByTypeAndCondition(section, type, condition)
        );
        if (existedSections.length === 1 && isSectionsEqual(existedSections[0], section)) {
          return;
        }
        const filteredSections = sections.filter(
          section => !filterByTypeAndCondition(section, type, condition)
        );
        state[page].sections = [
          ...filteredSections,
          { ...section, sectionIndex: (lastKey || currentLength) + 1 },
        ];
        return;
      }

      state[page].sections[currentLength] = {
        ...section,
        sectionIndex: (lastKey || currentLength) + 1,
      };
    },
    selectExistingFilter(state, action: PayloadAction<SelectExistingFilterPayload>) {
      const { page, filter } = action.payload;
      state[page] = {
        ...state[page],
        id: filter.id,
        name: filter.name,
        any_condition: filter.any_condition || false,
        sections: filter.sections.map((section, index) => ({
          sectionIndex: index + 1,
          ...section,
        })),
        searchValue: '',
      };
    },
    setMobileFilter(state, action: PayloadAction<SetMobileFilterPayload>) {
      const { page, filter } = action.payload;
      state[page] = {
        ...state[page],
        id: filter.id,
        name: filter.name,
        any_condition: filter.any_condition || false,
        sections: filter.sections,
        searchValue: '',
      };
    },
    setInitialFilter(state, action: PayloadAction<InitialFilterPayload>) {
      const { page, filter } = action.payload;
      state[page].any_condition = filter.any_condition || false;
      state[page].sections =
        filter.sections.map((section, index) => ({ sectionIndex: index + 1, ...section })) || [];
    },
    setAnyCondition(state, action: PayloadAction<SetConditionPayload>) {
      const { page, any_condition } = action.payload;
      state[page].any_condition = any_condition;
    },
    setSearchValue(state, action: PayloadAction<SetSearchPayload>) {
      const { page, text } = action.payload;
      state[page].searchValue = text;
    },
    setSections(state, action: PayloadAction<SetSectionsPayload>) {
      const { page, sections } = action.payload;
      state[page].sections = sections;
    },
    setIsUserFiltersLoading(state, action: PayloadAction<Page & { isLoading: boolean }>) {
      const { page, isLoading } = action.payload;
      state[page].savedUserFilters.isLoading = isLoading;
    },
    setUserFiltersList(state, action: PayloadAction<Page & { resources: ExistingUserFilter[] }>) {
      const { page, resources } = action.payload;
      state[page].savedUserFilters = {
        resources,
        isLoading: false,
        isLoaded: true,
      };
    },
    updateExistedFilter(state, action: PayloadAction<Page & { filter: ExistingUserFilter }>) {
      const { page, filter } = action.payload;
      state[page] = {
        id: filter.id,
        name: filter.name,
        any_condition: filter.any_condition || false,
        sections: filter.sections.map((section, index) => ({
          sectionIndex: index + 1,
          ...section,
        })),
        searchValue: '',
        savedUserFilters: {
          ...state[page].savedUserFilters,
          isLoading: false,
          resources: state[page].savedUserFilters.resources.map(listFilter =>
            listFilter.id === filter.id ? filter : listFilter
          ),
        },
      };
    },
    updateExistedFilterMobile(state, action: PayloadAction<Page & { filter: ExistingUserFilter }>) {
      const { page, filter } = action.payload;
      const { resources } = state[page].savedUserFilters;
      state[page].savedUserFilters.isLoading = false;
      state[page].savedUserFilters.resources = resources.map(listFilter =>
        listFilter.id === filter.id ? filter : listFilter
      );
    },
    updateSection(state, action: PayloadAction<UpdateSectionPayload>) {
      const { page, sectionIndex, condition, options, other } = action.payload;
      const sections = state[page].sections;
      const updatedSection = {
        ...sections[sectionIndex],
        condition,
        options,
        isInitialOpened: false,
        ...other,
      } as StateUserFilterSection; // todo: check typescript update
      state[page].sections = [
        ...sections.slice(0, sectionIndex),
        { ...updatedSection, isInvalid: isInvalidSectionData(updatedSection, condition, options) },
        ...sections.slice(sectionIndex + 1),
      ];
    },
    removeSection(state, action: PayloadAction<RemoveSectionPayload>) {
      const { page, sectionIndex } = action.payload;
      const sections = state[page].sections;
      state[page].sections = [
        ...sections.slice(0, sectionIndex),
        ...sections.slice(sectionIndex + 1),
      ];
    },
    resetFilter(state, action: PayloadAction<Page>) {
      const { page } = action.payload;
      state[page] = {
        ...initialFilter,
        savedUserFilters: state[page].savedUserFilters,
      };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const {
  addCreatedFilter,
  addCreatedFilterMobile,
  addSection,
  deleteExistingUserFilter,
  mergeReadySection,
  removeSection,
  resetFilter,
  selectExistingFilter,
  setAnyCondition,
  setInitialFilter,
  setIsUserFiltersLoading,
  setMobileFilter,
  setSearchValue,
  setSections,
  setUserFiltersList,
  updateExistedFilter,
  updateExistedFilterMobile,
  updateSection,
} = filtersSlice.actions;

export default filtersSlice.reducer;
