import React from 'react';
// utils
import cn from 'classnames';
// types
import { FieldHighlighterProps } from './FieldHighlighter.types';
// styles
import styles from './FieldHighlighter.module.scss';

const FieldHighlighter: React.FC<FieldHighlighterProps> = ({
  children,
  hasValue,
  leftIndent = 30,
  rightIndent = 30,
}) => (
  <div style={{ margin: `0 -${rightIndent}px 0 -${leftIndent}px` }} className={styles.wrapper}>
    <div className={cn({ [styles.highlighter]: !hasValue })} />
    <div style={{ margin: `0 ${rightIndent}px 0 ${leftIndent}px` }}>{children}</div>
  </div>
);

export default FieldHighlighter;
