import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// types
import { CurrentUser } from 'types';

type AuthState = {
  user: CurrentUser;
  isLoggedIn: boolean;
  unreadNotificationsCount: number;
};

const initialState: AuthState = {
  user: { id: '' },
  isLoggedIn: false,
  unreadNotificationsCount: 0,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<Partial<CurrentUser>>) {
      state.user = { ...state.user, ...action.payload };
    },
    setUserLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    setUnreadNotsCount(state, action: PayloadAction<number>) {
      state.unreadNotificationsCount = action.payload;
    },
    increaseUnreadNotsCount(state, action: PayloadAction<number>) {
      state.unreadNotificationsCount = state.unreadNotificationsCount + action.payload;
    },
    resetUserData() {
      return initialState;
    },
  },
});

export const {
  resetUserData,
  setUserData,
  setUserLoggedIn,
  setUnreadNotsCount,
  increaseUnreadNotsCount,
} = authSlice.actions;

export default authSlice.reducer;
