import React, { Fragment } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import FilterSectionWrapper from '../FilterSectionWrapper';
import CheckboxMenu from '../Menu/CheckboxMenu';
import Select from 'components/Select';
import { ReactComponent as FarmIcon } from 'icons/farm.svg';
// types
import { FarmTypeSectionType, UserFilterSectionProps } from 'types/filterSections';
import { OptionKeysTypes } from 'components/Select/Select.types';
// utils, kooks
import useFilterSectionState from 'utils/hooks/useFilterSectionState';

const FarmTypeSection = ({
  conditions,
  isDisabled,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
}: UserFilterSectionProps<FarmTypeSectionType>) => {
  const { formatMessage } = useIntl();
  const { condition, isInitialOpened, isInvalid, options } = section;
  const sectionText = options?.[0]?.value || '';
  const { state, handleConditionChange, handleValueChange, resetData } = useFilterSectionState(
    sectionText,
    condition
  );
  const { text, value } = state;
  const isConditionWithOptions = ['eq', 'not_eq'].includes(value);

  const handleApply = () => {
    if (value) {
      onOptionSelect(
        value,
        isConditionWithOptions ? [{ key: 'farm_type', value: text }] : undefined
      );
    }
  };

  const triggerLabel =
    isInvalid || !condition ? (
      <FormattedMessage id="filters.emptyFarmType" />
    ) : (
      <FormattedMessage id={labelKeys?.[condition]} values={{ text: sectionText }} />
    );

  const farmTypesOptions = [
    { value: 'su', label: formatMessage({ id: 'farmType.sowUnit' }) },
    { value: 'n', label: formatMessage({ id: 'farmType.nursery' }) },
    { value: 'wf', label: formatMessage({ id: 'farmType.weanToFinish' }) },
    { value: 'f', label: formatMessage({ id: 'farmType.finisher' }) },
    { value: 'ff', label: formatMessage({ id: 'farmType.farrowToFinish' }) },
    { value: 'm', label: formatMessage({ id: 'farmType.mixed' }) },
  ];

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isDisabled={isDisabled}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={FarmIcon}
      triggerLabel={triggerLabel}
    >
      <CheckboxMenu
        options={conditions}
        onApply={handleApply}
        onConditionChange={handleConditionChange}
        currentValue={value}
      >
        {optionValue => (
          <Fragment>
            {optionValue === value && isConditionWithOptions && (
              <div className="p-10">
                <Select
                  placeholder={<FormattedMessage id="general.selectFarmType" />}
                  value={farmTypesOptions.find(option => option?.value === text)}
                  onChange={option => handleValueChange((option as OptionKeysTypes)?.value)}
                  options={farmTypesOptions}
                  isSearchable
                  isClearable
                  isSmall
                />
              </div>
            )}
          </Fragment>
        )}
      </CheckboxMenu>
    </FilterSectionWrapper>
  );
};

export default FarmTypeSection;
