import React from 'react';
// components
import Link from 'components/Link';
import Button from 'components/Button';
import TimeAgo from 'react-timeago';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import { formatNotificationTarget } from 'utils/appNotificationsHelper';
// types
import { AppNotification } from 'types';
// styles
import './NotificationBody.scss';
// notifications
import { notificationsList } from './list';

type NotificationBodyProps = {
  className?: string;
  notification: AppNotification;
  hasTopDivider: boolean;
  isDropdownView?: boolean;
  handleDismissAction: (id: number) => void;
};

const NotificationBody = ({
  notification,
  handleDismissAction,
  className = '',
  hasTopDivider,
}: NotificationBodyProps) => {
  const { created_at, id, notification_type, read, target } = notification;
  const { icon, getIcon, iconType, getText, getRedirectLink, linkLabel } = notificationsList[
    notification_type
  ];
  const formattedTarget = formatNotificationTarget(notification);
  return (
    <div className={cn('NotificationBody', { hasTopDivider, [className]: !!className })}>
      <div className="content-block">
        <div className={cn({ 'is-svg': iconType === 'svg' })}>
          {icon || getIcon?.(formattedTarget)}
        </div>
        <div className="notification-block">
          <div className="notification-caption">{getText(formattedTarget)}</div>
          <div className="notification-footer">
            <div className="notification-time">
              <TimeAgo date={created_at} /> &nbsp;•&nbsp;
              <FormattedMessage id={`general.${read ? 'read' : 'unread'}`} />
            </div>
            <div className="notification-actions">
              {!!getRedirectLink && target && (
                <span>
                  <Link className="primary-link" to={getRedirectLink(formattedTarget)}>
                    {linkLabel}
                  </Link>
                  &nbsp;•&nbsp;
                </span>
              )}
              <span onClick={() => handleDismissAction(id)}>
                <FormattedMessage id="general.dismiss" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-actions">
        {!!getRedirectLink && target && (
          <Button className="action-btn" isLink to={getRedirectLink(formattedTarget)}>
            {linkLabel}
          </Button>
        )}
        <Button darkGray className="action-btn" onClick={() => handleDismissAction(id)}>
          <FormattedMessage id="general.dismiss" />
        </Button>
      </div>
    </div>
  );
};

export default NotificationBody;
