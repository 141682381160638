import React from 'react';
// components
import Dropdown from 'components/Dropdown';
import { ReactComponent as DotsIcon } from 'icons/dots.svg';
// utils
import classnames from 'classnames/bind';
// types
import { DotsDropdownProps } from 'components/Dropdown/Dropdown.types';
// styles
import styles from './DotsDropdown.module.scss';

const cn = classnames.bind(styles);

const DotsDropdown = ({
  options = [],
  rotated,
  className,
  dropdownComponent: DropdownComponent = Dropdown,
  ...other
}: DotsDropdownProps) => {
  const showingOptions = options.filter(({ isHidden }) => !isHidden);

  if (!showingOptions.length) {
    return null;
  }

  return (
    <DropdownComponent options={showingOptions} isOpenLeft {...other}>
      {(isOpen, onTriggerClick, triggerRef) => (
        <div
          ref={triggerRef}
          onClick={e => {
            e.stopPropagation();
            onTriggerClick(e);
          }}
          className={className}
        >
          <DotsIcon className={cn('dots-icon', { rotated })} />
        </div>
      )}
    </DropdownComponent>
  );
};

export default DotsDropdown;
