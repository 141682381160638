import React, { useEffect } from 'react';
// hooks
import useAuthUserSelector from 'store/selectors/useAuthUserSelector';
import { useHistory } from 'react-router-dom';
// utils
import classnames from 'classnames/bind';
import { formatNotificationTarget } from 'utils/appNotificationsHelper';
import { notificationsList } from 'components/Notifications/list';
// types
import { AppNotification } from 'types';
// styles
import styles from './ToastContent.module.scss';

const cn = classnames.bind(styles);

const InAppNotification = ({ resource }: { resource: AppNotification }) => {
  const { sounds_effects } = useAuthUserSelector();
  const { push } = useHistory();
  const formattedTarget = formatNotificationTarget(resource);
  const { icon, getIcon, getText, getRedirectLink } = notificationsList[resource.notification_type];
  const linkTo = getRedirectLink?.(formattedTarget);

  useEffect(() => {
    if (sounds_effects) {
      const audio = new Audio('/static/sounds/notification_sound.mp3');
      const promise = audio.play();
      if (promise !== undefined) promise.catch(error => console.warn(error));
    }
  }, [sounds_effects]);

  return (
    <div className={cn('in-app')} onClick={linkTo ? () => push(linkTo) : undefined}>
      <div className={cn('icon-block', { 'is-not-svg': !icon })}>
        {icon || getIcon?.(formattedTarget)}
      </div>
      <div className={cn('text-block')}>{getText(formattedTarget)}</div>
    </div>
  );
};

export default InAppNotification;
