import React from 'react';
// hooks
import useAnimatedContentEffect from 'hooks/useAnimatedContentEffect';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

interface ModalWrapperProps {
  children: React.ReactNode | null;
}

const ModalAnimatedWrapper = ({ children }: ModalWrapperProps) => {
  const [isVisible, content] = useAnimatedContentEffect(children);

  if (!content) return null;

  return (
    <div className={cn('modal-wrapper', { 'is-visible': isVisible })}>
      <div className={cn('animation-wrapper')}>{content}</div>
    </div>
  );
};

export default ModalAnimatedWrapper;
