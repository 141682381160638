import React from 'react';
// components
import BadgeDateColumn from '../BadgeColumn/BadgeDateColumn';
// types
import { Column, FarmBarn, FormatMessageFunc, RendererType, User } from 'types';
// renderers
import {
  renderShowMoreColumn,
  renderTooltipColumn,
  renderUserAvatarColumn,
} from '../columnRenderers';

const renderPlacedDate: RendererType<FarmBarn> = (
  { latest_placement_date },
  { emptyPlaceholder }
) => {
  return <BadgeDateColumn data={latest_placement_date || ''} placeholder={emptyPlaceholder} />;
};

const renderScheduleToEmptyDate: RendererType<FarmBarn> = (
  { schedule_to_empty_date },
  { emptyPlaceholder }
) => {
  return <BadgeDateColumn data={schedule_to_empty_date || ''} placeholder={emptyPlaceholder} />;
};

export const getBarnColumnsData = (
  formatMessage: FormatMessageFunc
): Record<string, Column<FarmBarn>> => ({
  barn: {
    label: formatMessage({ id: 'general.barn' }),
    renderer: ({ name }, data) => renderTooltipColumn(name || '', { ...data, valueMaxWidth: 200 }),
    emptyPlaceholder: 'N/A',
    sortable: 'name',
    width: 220,
  },
  farm: {
    label: formatMessage({ id: 'general.farm' }),
    renderer: ({ farm }, data) =>
      renderTooltipColumn(farm?.name || '', { ...data, valueMaxWidth: 200 }),
    sortable: 'farm_name',
    width: 220,
  },
  farm_state: {
    label: formatMessage({ id: 'general.farmState' }),
    dataPath: 'farm.state',
    emptyPlaceholder: '-',
    sortable: 'farm_state',
  },
  groups: {
    label: formatMessage({ id: 'table.headers.activeGroups' }),
    renderer: ({ opened_pig_groups }, data) =>
      renderShowMoreColumn(opened_pig_groups?.map(({ name }) => name) || [], {
        ...data,
        valueMaxWidth: 130,
      }),
    width: 150,
  },
  placed: {
    label: formatMessage({ id: 'table.headers.placedDate' }),
    renderer: renderPlacedDate,
  },
  space: {
    label: formatMessage({ id: 'table.headers.availableSpaces' }),
    dataPath: 'capacity',
    sortable: 'capacity',
  },
  matches_count: {
    label: formatMessage({ id: 'table.headers.matchesCount' }),
    dataPath: 'matched_loadings_count',
    emptyPlaceholder: '0',
    width: 140,
  },
  scheduled_to_empty: {
    label: formatMessage({ id: 'table.headers.scheduledToEmpty' }),
    emptyPlaceholder: 'N/A',
    renderer: renderScheduleToEmptyDate,
    sortable: 'schedule_to_empty_date',
  },
  user: {
    label: '',
    emptyPlaceholder: '',
    width: 40,
    renderer: (data: FarmBarn & { modified_by?: User }) => renderUserAvatarColumn(data.modified_by),
  },
});
