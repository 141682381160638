import { isEmpty } from 'utils';

type ResponseDataObjectType = {
  id: string;
  type: string;
  attributes: Record<string, unknown>;
  relationships: any;
};

type ResponseType = {
  data: ResponseDataObjectType | ResponseDataObjectType[];
  included: ResponseDataObjectType[];
  meta: {
    total: number;
    is_admin?: boolean;
  };
};

function addDataByRelations(data: ResponseDataObjectType, included: ResponseDataObjectType[]) {
  const { id, attributes, relationships = {} } = data;
  const parsedData = { ...attributes, id };

  if (isEmpty(relationships)) {
    return parsedData;
  }

  const relationObject: any = {};
  const keys = Object.keys(relationships);

  const parseIncluded = (included: ResponseDataObjectType[], relation: any) => {
    const data = included.find(({ id, type }) => type === relation.type && id === relation.id);
    return data ? addDataByRelations(data, included) : {};
  };

  keys.forEach((key: any) => {
    const { data } = relationships[key];
    if (Array.isArray(data) && data.length) {
      const ids = data.map(({ id }) => id);
      relationObject[key] = included
        .filter(({ id, type }) => type === data[0].type && ids.includes(id))
        .map(item => addDataByRelations(item, included));
      return;
    }
    if (typeof data === 'object' && !isEmpty(data)) {
      relationObject[key] = parseIncluded(included, data);
    }
  });

  return {
    ...parsedData,
    ...relationObject,
  };
}

export const parseResponse = (response: ResponseType) => {
  const { data, included = [], ...others } = response;
  if (Array.isArray(data)) {
    return {
      data: data.map(item => addDataByRelations(item, included)),
      included,
      ...others,
    };
  }
  if (typeof data === 'object') {
    return {
      data: addDataByRelations(data, included),
      included,
      ...others,
    };
  }

  return response;
};
