import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// utils
import { formatDate } from './formatDateHelper';
import { findDestinationCommitment, findSourceCommitment } from './loadHelper';
import { getAverage } from './index';
import { formatWeight } from './weightHelper';
// types
import {
  Load,
  LoadCommitment,
  SalesResult,
  SalesResultFormValues,
  TruckingLoad,
  TruckingLoadCommitment,
} from 'types';

function buildPreFilledSalesResults(
  sources: LoadCommitment[] | TruckingLoadCommitment[],
  destinations: LoadCommitment[] | TruckingLoadCommitment[],
  measurementSystem?: string
) {
  const salesResults: SalesResult[] = [];
  sources.forEach((source: LoadCommitment | TruckingLoadCommitment) => {
    destinations.forEach((dest: LoadCommitment | TruckingLoadCommitment) => {
      const formattedEstWeight = measurementSystem
        ? formatWeight(source.pig_group?.estimated_weight, measurementSystem)
        : source.pig_group?.estimated_weight;

      const formattedWeightMin = measurementSystem
        ? formatWeight(dest.contract_commitment?.weight_min, measurementSystem)
        : dest.contract_commitment?.weight_min;

      const formattedWeightMax = measurementSystem
        ? formatWeight(dest.contract_commitment?.weight_max, measurementSystem)
        : dest.contract_commitment?.weight_max;

      salesResults.push({
        id: '',
        source_loading_commitment_id: +source.id,
        destination_loading_commitment_id: +dest.id,
        delivered_at: dest.arrive_at || '',
        head_count: source.head_count || 0,
        avg_pig_weight: Math.round(
          formattedEstWeight || getAverage(formattedWeightMin, formattedWeightMax)
        ),
        comment: '',
      });
    });
  });
  return salesResults;
}

export function getInitialSalesResults(
  salesResults: SalesResult[],
  measurementSystem: string,
  isCreate?: boolean
): SalesResultFormValues[] {
  return salesResults.map(salesResult => ({
    id: salesResult.id,
    avg_pig_weight: isCreate
      ? undefined
      : formatWeight(salesResult.avg_pig_weight, measurementSystem) || 0,
    arrive_hour: +formatDate(salesResult.delivered_at, 'HH'),
    arrive_minute: +formatDate(salesResult.delivered_at, 'mm'),
    comment: salesResult.comment || '',
    delivered_at: formatDate(salesResult.delivered_at),
    head_count: salesResult.head_count || 0,
    modified_by: salesResult.modified_by,
    source_loading_commitment_id: salesResult.source_loading_commitment_id,
    destination_loading_commitment_id: salesResult.destination_loading_commitment_id,
    locked: salesResult.locked,
  }));
}

export function getTruckingPreFilledSalesResults(load: TruckingLoad, measurementSystem?: string) {
  const sources = load.commitments.filter(findSourceCommitment);
  const destinations = load.commitments.filter(findDestinationCommitment);
  return buildPreFilledSalesResults(sources, destinations, measurementSystem);
}

export function getPreFilledSalesResults(load: Load, measurementSystem?: string) {
  const sources = load.commitments.filter(findSourceCommitment);
  const destinations = load.commitments.filter(findDestinationCommitment);
  return buildPreFilledSalesResults(sources, destinations, measurementSystem);
}

export function getSalesResultInfo(
  salesResult: SalesResult,
  destCommitment?: TruckingLoadCommitment
) {
  const contractHeadCount = destCommitment?.contract_commitment?.head_count;
  return [
    {
      label: <FormattedMessage id="load.salesResults.dateDelivered" />,
      value: salesResult?.delivered_at ? formatDate(salesResult.delivered_at) : 'N/A',
      targetValue: destCommitment?.arrive_at ? formatDate(destCommitment.arrive_at) : 'N/A',
      focus: 'delivered_date',
      step: 'delivered_datetime',
    },
    {
      label: <FormattedMessage id="load.salesResults.deliveryTime" />,
      value: salesResult?.delivered_at ? formatDate(salesResult.delivered_at, 't') : 'N/A',
      targetValue: destCommitment?.arrive_at ? formatDate(destCommitment.arrive_at, 't') : 'N/A',
      focus: 'delivered_time',
      step: 'delivered_datetime',
    },
    {
      label: <FormattedMessage id="load.salesResults.headDelivered" />,
      value: salesResult?.head_count || 'N/A',
      targetValue: contractHeadCount || 'N/A',
      focus: 'head_count',
      step: 'delivered_head',
    },
    {
      label: <FormattedMessage id="load.salesResults.prelimAvgWeight" />,
      value: <FormattedWeight value={salesResult?.avg_pig_weight} />,
      targetValue: <FormattedWeight value={salesResult?.avg_pig_weight} />,
      focus: 'avg_pig_weight',
      step: 'avg_pig_weight',
    },
  ];
}
