import React, { useEffect } from 'react';
// redux, action creators
import { connect, ConnectedProps } from 'react-redux';
import { setTruckingCompany } from 'store/actionCreators/auth';
import { RootState } from 'store/reducers/rootReducer';
// components
import Loader from 'components/Loader';
// utils
import { getValueFromQuery } from 'utils';
// types
import { RouteComponentProps } from 'react-router';

const connector = connect(
  (state: RootState) => ({
    authUser: state.auth.user,
  }),
  { setTruckingCompany }
);

type Props = ConnectedProps<typeof connector> & RouteComponentProps<{ id: string }>;

const TruckingCompanySwitch: React.FC<Props> = ({
  history: { push },
  location: { search },
  match: {
    params: { id },
  },
  authUser,
  setTruckingCompany,
}) => {
  const redirectPath = getValueFromQuery(search, 'redirect_url');
  const { trucking_companies } = authUser;

  useEffect(() => {
    const company = trucking_companies?.find(({ id: company_id }) => company_id === id);
    if (trucking_companies && !company) push('/');
    if (company) {
      setTruckingCompany(company);
      push(redirectPath);
    }
  }, [setTruckingCompany, redirectPath, id, trucking_companies, push]);

  return <Loader isActive className="align-hor-ver" />;
};

export default connector(TruckingCompanySwitch);
