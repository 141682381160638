import React, { useState, useRef } from 'react';
// hooks
import useOnClickOutside from 'hooks/useOnClickOutside';
// utils
import { OPTION_HEIGHT } from './helper';
import classnames from 'classnames/bind';
// types
import { DropdownProps, ArgRendererType } from './Dropdown.types';
// styles
import styles from './Dropdown.module.scss';

const cn = classnames.bind(styles);

const liStyle = { height: OPTION_HEIGHT };

const Dropdown = ({
  children,
  className,
  hideOnScroll = false,
  isOpenLeft = false,
  options = [],
  optionsRenderer,
}: DropdownProps) => {
  const wrapperRef = useRef(null);
  const [isOpened, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(!isOpened);

  const handleClickOutside = () => {
    if (isOpened) setIsOpen(false);
  };

  useOnClickOutside(wrapperRef, handleClickOutside, { isOpen: isOpened, hideOnScroll });

  const defaultOptionsRenderer = ({ closeDropdown }: ArgRendererType) => (
    <ul className={cn('list-ul', { 'is-opened-left': isOpenLeft })}>
      {options.map(
        ({ label, itemProps = {}, component: OptionComponent = 'div', isHidden }, index: number) =>
          !isHidden && (
            <li key={index} className={cn('list-li')} style={liStyle} onClick={closeDropdown}>
              <OptionComponent
                className={cn('label', 'truncated-ellipsis', 'dropdown')}
                {...itemProps}
              >
                {label}
              </OptionComponent>
            </li>
          )
      )}
    </ul>
  );

  const renderer = optionsRenderer || defaultOptionsRenderer;

  return (
    <div ref={wrapperRef} className={cn('dropdown-wrapper', className)}>
      {children(isOpened, handleClick, wrapperRef)}
      {isOpened ? renderer({ closeDropdown: handleClick }) : null}
    </div>
  );
};

export default Dropdown;
