import { getValueFromQuery } from 'utils';

interface HeadersTypes {
  uid: string;
  'access-token': string;
  client: string;
  expiry: string;
}

type KeyType = 'uid' | 'access-token' | 'client' | 'expiry';

function setHeader(data: HeadersTypes, key: KeyType) {
  if (data[key]) localStorage.setItem(key, data[key]);
}

const authHeaders = ['uid', 'access-token', 'client', 'expiry'];

export function setAuthDataFromQuery(query = '') {
  authHeaders.forEach(key => {
    const headerKeyValue = getValueFromQuery(query, key);
    if (headerKeyValue) localStorage.setItem(key, headerKeyValue);
  });
}

export function setAuthData(data: HeadersTypes) {
  if (data) {
    authHeaders.forEach(headerKey => {
      setHeader(data, headerKey as KeyType);
    });
  }
}

export function getAuthData(): { [key: string]: string } {
  const data = {};
  authHeaders.forEach(key => {
    const headerKeyValue = localStorage.getItem(key);
    if (headerKeyValue) Object.assign(data, { [key]: headerKeyValue });
  });
  return data;
}

export function clearAuthData() {
  console.warn('%c clearAuthData()', 'color: blue');
  authHeaders.forEach(key => {
    localStorage.removeItem(key);
  });
}
