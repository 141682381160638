import React, { ChangeEvent } from 'react';
// components
import LabeledInput from '../LabeledInput';
// types
import { DateTimeInputProps } from '../Input.types';
// assets, styles
import styles from './DateTimeInput.module.scss';
import { ReactComponent as AngleUpIcon } from 'icons/angle-up.svg';
import { ReactComponent as AngleDownIcon } from 'icons/angle-down.svg';

const ArrowsLabel: React.FC<DateTimeInputProps> = ({
  disabled,
  setValue,
  value,
  name = '',
  step = 1,
  min = 0,
  max = 99,
}) => {
  const onUpClick = () => {
    const newValue = Number(value) + step;
    if (newValue > max || disabled) return;
    setValue(newValue, name);
  };

  const onDownClick = () => {
    const newValue = Number(value) - step;
    if (newValue < min || disabled) return;
    setValue(newValue, name);
  };

  return (
    <span className={styles['arrows-buttons']}>
      <AngleUpIcon className={styles.icon} onClick={onUpClick} />
      <AngleDownIcon className={styles.icon} onClick={onDownClick} />
    </span>
  );
};

const style = {
  height: 50,
};

const formatValue = (value: string | number, maxLength: number) => {
  if (value === '') return value;
  return String(value).padStart(maxLength, '0');
};

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  setValue,
  value = '',
  maxLength = 2,
  ...others
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      value,
      name,
      validity: { valid },
    } = e.target;

    if (!valid) return;

    const formattedValue = Number(value).toString();
    if (value.length > maxLength && formattedValue.length <= maxLength) {
      setValue(formattedValue, name);
      return;
    }

    setValue(value, name);
  };

  return (
    <LabeledInput
      style={style}
      label={<ArrowsLabel setValue={setValue} value={value} {...others} />}
      labelRight
      floatRight={false}
      placeholder="00"
      pattern="[0-9]*"
      type="number"
      {...others}
      size={2}
      value={formatValue(value, maxLength)}
      onChange={handleChange}
    />
  );
};

DateTimeInput.defaultProps = {
  step: 1,
  min: 0,
  max: 99,
};

export default DateTimeInput;
