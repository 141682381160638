import { getLoadCommitmentAddressEntity } from './loadHelper';
import { MAP_INIT_POSITION } from '../constants';
// types
import { Coords } from 'google-map-react';
import { AddressEntity, CoordsType, Load, TruckingCompany, WithRequired } from 'types';

export const getCoords = ({ lat, lng }: CoordsType = {}) => {
  if (!lng && !lat) return null;
  return { lat, lng };
};

export const getCenterFromArray = (data: (AddressEntity | undefined)[]): Coords => {
  const dataWithValidCoords = data.filter(item => item?.lat && item?.lng) as WithRequired<
    AddressEntity,
    'lat' | 'lng'
  >[];
  const dataLength = dataWithValidCoords.length;
  if (!dataLength) return MAP_INIT_POSITION;
  if (dataLength === 1) {
    const [{ lat = 0, lng = 0 }] = dataWithValidCoords;
    return { lat, lng };
  }
  const sumCoords = dataWithValidCoords.reduce(
    (acc, elem) => ({ lat: acc.lat + (elem.lat || 0), lng: acc.lng + (elem.lng || 0) }),
    { lat: 0, lng: 0 }
  );
  return {
    lat: sumCoords.lat / dataLength,
    lng: sumCoords.lng / dataLength,
  };
};

export function getRoutePairs(load: Load, trucking?: TruckingCompany) {
  const pairs = [];
  pairs.push({
    id: 'trucking',
    origin: trucking,
    destination: getLoadCommitmentAddressEntity(load.commitments[0]),
  });
  for (let i = 0; i < load.commitments.length - 1; i++) {
    const currCommitment = load.commitments[i];
    const nextCommitment = load.commitments[i + 1];
    pairs.push({
      currCommitment,
      id: currCommitment.id,
      origin: getLoadCommitmentAddressEntity(currCommitment),
      destination: getLoadCommitmentAddressEntity(nextCommitment),
    });
  }
  return pairs;
}

export const getGoogleMapLink = (source: AddressEntity, destination: AddressEntity) => {
  if (!source?.lat || !source?.lng || !destination.lat || !destination.lng) return '';
  return (
    `https://www.google.com/maps/dir/?api=1&origin=${source.lat},${source.lng}` +
    `&destination=${destination.lat},${destination.lng}`
  );
};
