import axios, { AxiosRequestConfig } from 'axios';
import { setAuthData, getAuthData, clearAuthData } from 'utils/authData';
import { isEmpty } from 'utils';
import { redirectToProd } from 'utils/redirectHelper';
import { sendNotify } from 'utils/airbrakeHelper';
// constants
import { EP_API_URL } from '../constants';
import { getTruckingCompanyId } from './truckingCompany';

axios.defaults.baseURL = `${EP_API_URL}/api/v2`;

export default function makeAxiosRequest(
  url: string,
  { method = 'get', headers = {}, ...otherOptions }: AxiosRequestConfig = {}
) {
  const authHeaders = getAuthData();

  const jsonHeaders = {
    Accept: 'application/vnd.api+json, application/json',
  };

  return axios({
    method,
    url,
    headers: {
      ...jsonHeaders,
      ...headers,
      ...authHeaders,
      'trucking-company-id': getTruckingCompanyId() || '',
    },
    ...otherOptions,
  })
    .then(response => {
      if (isEmpty(authHeaders)) {
        setAuthData(response.headers);
      }

      return response.data;
    })
    .catch(error => {
      sendNotify(error, authHeaders.uid);
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          clearAuthData();
          redirectToProd();
        }
        throw { status, data };
      }
      throw error;
    });
}

// TODO: rm when all endpoints will be available in new namespace
export function makeAxiosRequestV1Api(url: string, config: AxiosRequestConfig = {}) {
  const baseUrl = `${EP_API_URL}/api`;
  return makeAxiosRequest(url, { ...config, baseURL: baseUrl });
}

export function makeAxiosRequestLocal(url: string, config: AxiosRequestConfig = {}) {
  return makeAxiosRequest(url, { ...config, baseURL: '' });
}
