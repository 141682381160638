import React from 'react';
// components
import NumberInput from '../NumberInput';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import {
  formatHour12To24h,
  formatHour24To12h,
  isBeforeNoon,
  MAX_HOUR_NUMBER,
} from 'utils/formatDateHelper';
// types
import { TimePickerProps } from './TimePicker.types';
// styles
import styles from './TimePicker.module.scss';

const cn = classnames.bind(styles);

const TimePicker: React.FC<TimePickerProps> = ({
  className = '',
  hourInputName = 'hour',
  hourValue,
  minuteInputName = 'minute',
  minuteValue,
  onChange,
  isDisabled,
  isFocused,
}) => {
  const isAM = isBeforeNoon(hourValue);
  const formattedHourValue = formatHour24To12h(hourValue);

  const setDateTimeValue = (value: number | string, name: string) => {
    onChange({ [name]: value });
  };

  const setHourValue = (value: number | string, name: string) => {
    onChange({ [name]: formatHour12To24h(Number(value ?? 0), isAM) });
  };

  const onAMClick = () => {
    if (isAM || isDisabled) return;
    onChange({
      [hourInputName]: formatHour12To24h(formattedHourValue, true),
      [minuteInputName]: minuteValue || 0,
    });
  };

  const onPMClick = () => {
    if (!isAM || isDisabled) return;
    onChange({
      [hourInputName]: formatHour12To24h(formattedHourValue) || MAX_HOUR_NUMBER,
      [minuteInputName]: minuteValue || 0,
    });
  };

  return (
    <div className={cn('time-picker', className, { disabled: isDisabled })}>
      {/* ------------------------------- hours input ------------------------------ */}
      <NumberInput
        value={formattedHourValue ?? ''}
        name={hourInputName}
        autoFocus={isFocused}
        setValue={setHourValue}
        min={1}
        max={MAX_HOUR_NUMBER}
        className={cn('time-input')}
      />

      {/* ------------------------------ minutes input ----------------------------- */}
      <NumberInput
        value={minuteValue}
        name={minuteInputName}
        setValue={setDateTimeValue}
        max={59}
        className={cn('time-input')}
      />

      {/* -------------------------- format picker (AM/PM) ------------------------- */}
      <div className={cn('time-format-picker')}>
        <div onClick={onAMClick} className={cn('time-format-item-wrapper', { active: isAM })}>
          <div className={cn('time-format')}>
            <FormattedMessage id="general.time.am" />
          </div>
        </div>
        <div onClick={onPMClick} className={cn('time-format-item-wrapper', { active: !isAM })}>
          <div className={cn('time-format')}>
            <FormattedMessage id="general.time.pm" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
