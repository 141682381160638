import React, { useState, ChangeEvent, FC, SVGProps, Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FetchOnceMultiSelect from 'components/Select/FetchOnceMultiSelect';
import FilterSectionWrapper from '../FilterSectionWrapper';
import CheckboxMenu from '../Menu/CheckboxMenu';
import SelectedItem from '../Menu/SelectedItem';
// api
import { getTreatmentProducts } from 'requests/treatments';
// types
import { ConditionOptionItem, TreatmentProduct } from 'types';
import { TreatmentProductsSectionType, SectionExtraStateProps } from 'types/filterSections';
import { ValueType } from 'react-select';
import { OptionKeysTypes } from 'components/Select/Select.types';
// utils
import isEqual from 'lodash.isequal';

type TreatmentProductsSectionProps = {
  conditions: ConditionOptionItem[];
  isDisabled: boolean;
  isMaleGenetics?: boolean;
  labelKeys: { [key in TreatmentProductsSectionType['condition']]?: string };
  onOptionSelect: (
    value: TreatmentProductsSectionType['condition'],
    options?: TreatmentProductsSectionType['options'],
    others?: { treatment_products?: TreatmentProduct[] }
  ) => void;
  onRemove: () => void;
  section: SectionExtraStateProps & TreatmentProductsSectionType;
  triggerIcon: FC<SVGProps<SVGSVGElement>>;
};

const TreatmentProductsSection = ({
  conditions,
  isDisabled,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
  triggerIcon,
}: TreatmentProductsSectionProps) => {
  const {
    condition: savedCondition,
    treatment_products,
    isInvalid,
    isInitialOpened,
    options,
  } = section;
  const selectedIds = options?.[0].value || [];
  const [state, setState] = useState({
    condition: savedCondition,
    selected: treatment_products || [],
    selectedIds,
  });
  const { condition, selected } = state;

  const handleConditionChange = ({ target: { name } }: ChangeEvent<HTMLInputElement>) => {
    setState({
      condition: name as TreatmentProductsSectionType['condition'],
      selected: [],
      selectedIds: [],
    });
  };

  const handleSelect = (option?: ValueType<OptionKeysTypes & { details: TreatmentProduct }>) => {
    if (option) {
      // todo: check react-select new version to fix it
      const selected = (option as OptionKeysTypes & { details: TreatmentProduct }).details;
      setState(prevState => ({ ...prevState, selected: [...prevState.selected, selected] }));
    }
  };

  const handleRemove = (option: OptionKeysTypes & { details: TreatmentProduct }) => {
    if (option) {
      setState(prevState => ({
        ...prevState,
        selected: prevState.selected.filter(item => item.id !== option.value),
      }));
    }
  };

  const handleApply = () => {
    onOptionSelect(
      condition,
      [{ key: 'treatment_product_ids', value: selected?.map(item => item.id) || [] }],
      { treatment_products: selected }
    );
  };

  const resetData = () => {
    const newState: typeof state = {
      selected: treatment_products || [],
      condition: savedCondition,
      selectedIds,
    };
    const isEqualState = isEqual(newState, state);
    if (!isEqualState) setState(newState);
  };

  const triggerLabel =
    isInvalid || !savedCondition ? (
      <FormattedMessage id="filters.emptyTreatments" />
    ) : (
      <FormattedMessage id={labelKeys?.[savedCondition]} values={{ count: selectedIds?.length }} />
    );

  const optionFormatter = (item: TreatmentProduct) => ({
    value: item.id,
    label: item.name,
    details: item,
  });

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={triggerIcon}
      triggerLabel={triggerLabel}
    >
      <CheckboxMenu
        options={conditions}
        onConditionChange={handleConditionChange}
        onApply={handleApply}
        currentValue={condition}
      >
        {value => (
          <Fragment>
            {value === condition && (
              <div className="p-10">
                <FetchOnceMultiSelect
                  listType="treatments"
                  isSmall
                  selected={selected}
                  onSelect={handleSelect}
                  optionFormatter={optionFormatter}
                  fetchData={getTreatmentProducts}
                  placeholder={<FormattedMessage id="general.placeholder.searchGenetics" />}
                >
                  {values => (
                    <div className="mt-10">
                      {values.map(item => (
                        <SelectedItem
                          key={item.value}
                          label={item.label}
                          onRemove={() => handleRemove(item)}
                        />
                      ))}
                    </div>
                  )}
                </FetchOnceMultiSelect>
              </div>
            )}
          </Fragment>
        )}
      </CheckboxMenu>
    </FilterSectionWrapper>
  );
};

export default TreatmentProductsSection;
