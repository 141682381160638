import React from 'react';
// utils
import classnames from 'classnames/bind';
import { getLetterInitials } from 'utils';
// styles
import styles from './CompanyAvatar.module.scss';

type CompanyAvatarTypes = {
  name: string;
  size?: 'small' | 'medium' | 'large';
};

const cn = classnames.bind(styles);

const CompanyAvatar: React.FC<CompanyAvatarTypes> = ({ name = '', size = 'medium' }) => (
  <div className={cn('avatar', size)}>{getLetterInitials(name)}</div>
);

export default CompanyAvatar;
