import { RefObject, useEffect, useRef } from 'react';

type MountedRef = { isMounted: boolean };

export default function useMountedRef(): RefObject<MountedRef> {
  const mountedRef = useRef<MountedRef>({ isMounted: true });

  useEffect(() => {
    return () => {
      mountedRef.current.isMounted = false;
    };
  }, []);

  return mountedRef;
}
