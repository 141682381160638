// utils
import makeAxiosRequest, { makeAxiosRequestV1Api } from 'utils/api';
import { parseResponse } from 'utils/parseHelper';
// types
import { CurrentUser, UserTruckingRole } from 'types';

export const validateUser = (): Promise<CurrentUser> => {
  return makeAxiosRequest('/logistics/current_user')
    .then(parseResponse)
    .then(response => response.data);
};

export const logoutUser = () => {
  return makeAxiosRequestV1Api('/auth/sign_out', { method: 'delete' });
};

export const sendPasswordReset = (email: string) => {
  return makeAxiosRequestV1Api('/auth/password', { method: 'post', data: { email } });
};

export const selectCurrentCompany = (company_id: string) => {
  return makeAxiosRequestV1Api('/current_companies', {
    method: 'POST',
    data: { resource: { company_id }, current_company_id: company_id },
  });
};

export const fetchTruckingRoles = (userId: string): Promise<UserTruckingRole[]> => {
  return makeAxiosRequest(`/truckings/users/${userId}/roles`)
    .then(parseResponse)
    .then(response => response.data);
};
