import React, { useState } from 'react';
// components
import { SingleDatePicker as DatePicker } from 'react-dates';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
// utils
import moment, { Moment } from 'moment';
import cn from 'classnames';
// types
import { SingleDatePickerProps } from './SingleDatePicker.types';
// styles
import 'react-dates/lib/css/_datepicker.css';
import './SingleDatePicker.scss';

const SingleDatePicker = ({
  value,
  onChange,
  isFocused = false,
  id,
  iconLeft = false,
  noBorder = false,
  disabled,
  isOutsideRange = day => day.isBefore(moment(), 'day'),
  numberOfMonths = 2,
  className,
}: SingleDatePickerProps) => {
  const [focused, setFocused] = useState(isFocused);

  const handleChange = (value: Moment | null) => {
    const date = value ? moment(value).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
    onChange(date);
  };

  const toggleFocus = () => (!focused ? setFocused(true) : undefined);

  // TODO: fix calendar toggling

  return (
    <div
      className={cn('SingleDatePickerWrapper', {
        focused,
        className,
        disabled,
        'no-border': noBorder,
      })}
      onClick={disabled ? undefined : toggleFocus}
    >
      {iconLeft && (
        <div className={cn('picker-label', 'left', { disabled })}>
          <CalendarIcon className="picker-icon" />
        </div>
      )}
      <DatePicker
        readOnly
        id={id}
        disabled={disabled}
        placeholder="MM/DD/YYYY"
        focused={focused}
        onFocusChange={({ focused }) => (disabled ? undefined : setFocused(Boolean(focused)))}
        numberOfMonths={numberOfMonths}
        onDateChange={handleChange}
        date={value ? moment(value) : null}
        hideKeyboardShortcutsPanel
        isOutsideRange={isOutsideRange}
      />
      {!iconLeft && (
        <div className={cn('picker-label', { disabled })}>
          <CalendarIcon className="picker-icon" />
        </div>
      )}
    </div>
  );
};

export default SingleDatePicker;
