// redux, action creators
import { connect, ConnectedProps } from 'react-redux';
import {
  addPreFilledSalesResult,
  acceptCommitment,
  acceptByTrucking,
  createLoadSalesResults,
  declineLoad,
  fetchLoadWithLogs,
  toggleLockSalesResult,
  makeLoadAsArchived,
  makeLoadAsUnArchived,
  updateLoadData,
  updateLoadSalesResults,
  updateCommitment,
  unMatchLoadData,
  resetLoadData,
} from '../actionCreators/load';
import { resetLoadState } from '../reducers/load';
// types
import { RootState } from '../reducers/rootReducer';

const loadDataConnector = connect(
  (state: RootState) => ({
    load: state.load.data,
    loadLogs: state.load.logs,
    isLoadLoading: state.load.isLoadLoading,
    isLogsLoading: state.load.isLogsLoading,
  }),
  {
    addPreFilledSalesResult,
    acceptByTrucking,
    acceptCommitment,
    createLoadSalesResults,
    declineLoad,
    fetchLoadWithLogs,
    resetLoadState,
    toggleLockSalesResult,
    makeLoadAsArchived,
    makeLoadAsUnArchived,
    updateCommitment,
    updateLoadData,
    updateLoadSalesResults,
    unMatchLoadData,
    resetLoadData,
  }
);

export type ConnectedLoadProps = ConnectedProps<typeof loadDataConnector>;

export default loadDataConnector;
