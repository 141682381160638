import React from 'react';
// components
import Input from 'components/Input';
// utils
import cn from 'classnames';
// types
import { LabeledInputProps } from '../Input.types';
// styles
import styles from './LabeledInput.module.scss';

const LabeledInput: React.FC<LabeledInputProps> = ({
  inputComponent: InputComponent = Input,
  labelRight,
  floatRight = true,
  isFullWidth = false,
  label,
  className,
  style,
  disabled,
  ...rest
}) => (
  <label
    className={cn(styles['input-wrapper'], {
      'full-width': isFullWidth,
      [styles.right]: labelRight,
      [styles.disabled]: disabled,
    })}
  >
    <div style={style} className={styles['icon-wrapper']}>
      {label}
    </div>
    <InputComponent
      {...rest}
      style={style}
      disabled={disabled}
      className={cn(styles['labeled-input'], { [styles['right']]: floatRight }, className)}
    />
  </label>
);

export default LabeledInput;
