import { ContractTypes } from 'types';

export const SOW_UNIT_CONTRACT = ContractTypes.SowUnitContract;
export const PACKER_CONTRACT = ContractTypes.PackerContract;

export const BASIC_INFO_COLLAPSE = 'basicInfo';
export const REQUIREMENTS_COLLAPSE = 'requirements';
export const LOAD_ID_PARADIGM_COLLAPSE = 'loadIdParadigm';
export const SPECIAL_REQUIREMENTS_COLLAPSE = 'specialRequirements';
export const REVIEW_AND_SAVE_COLLAPSE = 'reviewAndSave';

export const REQUIREMENTS_SECTION_FIELDS = [
  'pig_type',
  'head_count',
  'weight_min',
  'weight_max',
  'skip_trucking_company',
  'delivery_frequency_type',
  'delivery_schedules_attributes',
  'delivery_schedules',
  'start_date',
  'arrive_minute',
  'arrive_hour',
  'end_date',
  'end_condition',
  'end_count',
];

export const CONTRACT_STATUSES = ['active', 'closed', 'drafts'];
export const CONTRACT_TYPES = ['sowUnit', 'packer'];

export const CONTRACT_THEME_BY_TYPE = {
  [PACKER_CONTRACT]: 'primary',
  [SOW_UNIT_CONTRACT]: 'blue',
};

export const CONTRACT_CREATE_PATH_BY_TYPE = {
  [SOW_UNIT_CONTRACT]: '/scheduling/contracts/create-sow-unit',
  [PACKER_CONTRACT]: '/scheduling/contracts/create-packer',
};

export const STEP_PROP_KEYS = [
  'isLoading',
  'isOpened',
  'onSubmit',
  'type',
  'onExpand',
  'isBlocked',
];
export const BASIC_STEP_PROP_KEYS = ['isLoading', 'isOpened', 'onExpand', 'isBlocked'];
export const REVIEW_STEP_PROP_KEYS = ['isLoading', 'isOpened', 'type', 'onExpand'];
