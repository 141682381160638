import React, { useEffect } from 'react';
// redux, action creators
import { connect, ConnectedProps } from 'react-redux';
import { signOutUser } from 'store/actionCreators/auth';
// utils
import { redirectToProd } from 'utils/redirectHelper';

const connector = connect(null, { signOutUser });

const Logout: React.FC<ConnectedProps<typeof connector>> = ({ signOutUser }) => {
  useEffect(() => {
    signOutUser().finally(() => {
      redirectToProd();
    });
  }, [signOutUser]);

  return null;
};

export default connector(Logout);
