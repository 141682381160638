import React, { Fragment } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import FilterSectionWrapper from '../FilterSectionWrapper';
import CheckboxMenu from '../Menu/CheckboxMenu';
import Input from 'components/Input';
import { ReactComponent as BusinessIcon } from 'icons/business-socks.svg';
import { ReactComponent as FarmIcon } from 'icons/farm.svg';
import { ReactComponent as IndustryIcon } from 'icons/industry.svg';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';
import { ReactComponent as GroupsIcon } from 'icons/pig-groups.svg';
import { ReactComponent as DocIcon } from 'icons/ep-doc.svg';
// types
import { SectionNames } from 'types';
import { TextSectionType, UserFilterSectionProps } from 'types/filterSections';
// utils, kooks
import useFilterSectionState from 'utils/hooks/useFilterSectionState';
import cn from 'classnames';
// styles
import styles from './Sections.module.scss';

function getSectionSpecificData(sectionType: TextSectionType['type']) {
  switch (sectionType) {
    case SectionNames.Barn:
      return {
        placeholder: <FormattedMessage id="filters.emptyBarnName" />,
        menuPlaceholderKey: 'general.barnName',
        triggerIcon: FarmIcon,
      };
    case SectionNames.Contract:
    case SectionNames.ContractNames:
      return {
        placeholder: <FormattedMessage id="filters.emptyContract" />,
        menuPlaceholderKey: 'general.contractName',
        triggerIcon: DocIcon,
      };
    case SectionNames.ContractOwnerCompany:
      return {
        placeholder: <FormattedMessage id="filters.emptyContractOwner" />,
        menuPlaceholderKey: 'general.contractOwner',
        triggerIcon: BusinessIcon,
      };
    case SectionNames.DestinationEntityNames:
      return {
        placeholder: <FormattedMessage id="filters.emptyDestination" />,
        menuPlaceholderKey: 'loadsTable.destination',
        triggerIcon: FarmIcon,
      };
    case SectionNames.ExternalSource:
      return {
        placeholder: <FormattedMessage id="filters.emptyExternalSource" />,
        menuPlaceholderKey: 'general.externalSource',
        triggerIcon: FarmIcon,
      };
    case SectionNames.Farm:
      return {
        placeholder: <FormattedMessage id="filters.emptyFarmName" />,
        menuPlaceholderKey: 'general.farmName',
        triggerIcon: FarmIcon,
      };
    case SectionNames.ContractCommitment:
      return {
        placeholder: <FormattedMessage id="filters.emptyLoad" />,
        menuPlaceholderKey: 'general.loadName',
        triggerIcon: TruckIcon,
      };
    case SectionNames.LastPackingPlant:
      return {
        placeholder: <FormattedMessage id="filters.emptyLastPlant" />,
        menuPlaceholderKey: 'general.packingPlant',
        triggerIcon: IndustryIcon,
      };
    case SectionNames.Loading:
      return {
        placeholder: <FormattedMessage id="filters.emptyLoad" />,
        menuPlaceholderKey: 'general.loadName',
        triggerIcon: TruckIcon,
      };
    case SectionNames.PackingPlant:
      return {
        placeholder: <FormattedMessage id="filters.emptyPlant" />,
        menuPlaceholderKey: 'general.packingPlant',
        triggerIcon: IndustryIcon,
      };
    case SectionNames.PigGroup:
      return {
        placeholder: <FormattedMessage id="filters.emptyGroup" />,
        menuPlaceholderKey: 'general.groupId',
        triggerIcon: GroupsIcon,
      };
    case SectionNames.SourceEntityNames:
      return {
        placeholder: <FormattedMessage id="filters.emptyOrigin" />,
        menuPlaceholderKey: 'loadsTable.origin',
        triggerIcon: FarmIcon,
      };
    case SectionNames.TruckingCompany:
      return {
        placeholder: <FormattedMessage id="filters.emptyTrucking" />,
        menuPlaceholderKey: 'general.truckingCompany',
        triggerIcon: TruckIcon,
      };
  }
}

const TextSection = ({
  conditions,
  isDisabled,
  labelKeys,
  onOptionSelect,
  onRemove,
  section,
}: UserFilterSectionProps<TextSectionType>) => {
  const { formatMessage } = useIntl();
  const { condition, isInitialOpened, isInvalid, options, type } = section;
  const sectionText = options?.[0]?.value || '';
  const { state, handleConditionChange, handleValueChange, resetData } = useFilterSectionState(
    sectionText,
    condition
  );
  const { text, value } = state;
  const { triggerIcon, menuPlaceholderKey, placeholder } = getSectionSpecificData(type);

  const handleApply = () => {
    if (value) {
      onOptionSelect(value, [{ key: 'text', value: text }]);
    }
  };

  const triggerLabel =
    isInvalid || !condition ? (
      placeholder
    ) : (
      <FormattedMessage id={labelKeys?.[condition]} values={{ text: sectionText }} />
    );

  return (
    <FilterSectionWrapper
      currentValue={condition}
      isDisabled={isDisabled}
      isInitialOpened={isInitialOpened}
      isInvalid={isInvalid}
      isRemovable
      onRemove={onRemove}
      onClose={resetData}
      triggerIcon={triggerIcon}
      triggerLabel={triggerLabel}
    >
      <CheckboxMenu
        options={conditions}
        onApply={handleApply}
        onConditionChange={handleConditionChange}
        currentValue={value}
      >
        {optionValue => (
          <Fragment>
            {optionValue === value && (
              <div className={cn(styles['condition-input-row'], 'p-10')}>
                <Input
                  placeholder={formatMessage({ id: menuPlaceholderKey })}
                  className={cn(styles['value-input'], styles.longer)}
                  value={text}
                  onChange={e => handleValueChange(e.target.value)}
                />
              </div>
            )}
          </Fragment>
        )}
      </CheckboxMenu>
    </FilterSectionWrapper>
  );
};

export default TextSection;
