import React from 'react';
import { createPortal } from 'react-dom';
import { useAppSetting } from 'context/useAppSettingData';

let headerContentElem: HTMLElement | null = null;

const HeaderContentPortal: React.FC = ({ children }) => {
  const {
    setting: { isHeaderMounted },
  } = useAppSetting();

  if (headerContentElem && !isHeaderMounted) {
    headerContentElem = null;
  }
  if (!headerContentElem && isHeaderMounted) {
    headerContentElem = document.getElementById('header-content');
  }
  return headerContentElem && children ? createPortal(children, headerContentElem) : null;
};

export default HeaderContentPortal;
