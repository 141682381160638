import React from 'react';
// components
import Modal from 'components/Modal';
// utils
import { useModalSetter } from 'context/useModal';
import cn from 'classnames';
// styles
import styles from './NoteColumn.module.scss';

type MultiNotesColumnProps = {
  maxWidth?: number;
  notes: { targetName?: string; text: string }[];
  placeholder?: string | JSX.Element;
};

const MultiNotesColumn = ({ maxWidth, notes, placeholder = '-' }: MultiNotesColumnProps) => {
  const setModal = useModalSetter();
  const isAllNotesEmpty = notes.every(({ text }) => !text);

  if (isAllNotesEmpty) {
    return <>{notes.map(() => placeholder).join(', ')}</>;
  }

  const { text: firstNote = placeholder } = notes.find(({ text }) => !!text) || {};

  const handleClick = () => {
    setModal(
      <Modal hasAutoWidth>
        {({ closeModal }) => (
          <div className={styles['notes-modal']}>
            <div className={styles.header}>
              <Modal.CloseButton onClick={closeModal} className={styles['close-icon']} />
            </div>
            <div className={styles.content}>
              {notes.map(({ targetName, text }, index) => (
                <div key={index} className={cn({ 'mt-15': index !== 0 })}>
                  <div className={styles['note-target-name']}>{targetName}:</div>
                  <span>{text || placeholder}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    );
  };

  return (
    <div style={{ maxWidth }} onClick={handleClick} className={cn(styles['multi-notes-column'])}>
      <span className={cn(styles['first-note'], 'truncated-ellipsis')}>{firstNote}</span>,
      <span className={styles.count}>+{notes.length - 1}</span>
    </div>
  );
};

export default MultiNotesColumn;
