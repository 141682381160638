import React from 'react';
import { ReactComponent as StarIcon } from 'icons/star.svg';
import cn from 'classnames';
import styles from './ContractBadge.module.scss';

const SpecialtyContractStar = ({ className }: { className?: string }) => (
  <StarIcon className={cn(className, styles['star-badge'])} />
);

export default SpecialtyContractStar;
