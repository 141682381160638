import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { bold } from 'utils';
// types
import { CurrentUser, Load, LoadLog, LogEventTypes } from 'types';

export const getLogMessage = (log: LoadLog, load: Load, authUser: CurrentUser) => {
  const eventType = log.event_type[0]; // handle only first event for now
  const isCurrentUserAuthor = authUser.id === log.user?.id;
  const loadName = load.name;
  const defaultValues = {
    b: bold,
    authorName: isCurrentUserAuthor ? 'you' : log.user?.full_name || null,
  };
  switch (eventType) {
    case LogEventTypes.archived:
      return <FormattedMessage id="loadLog.archived" values={defaultValues} />;
    case LogEventTypes.unarchived:
      return <FormattedMessage id="loadLog.unarchived" values={defaultValues} />;
    case LogEventTypes.created: {
      if (log.loading_commitment) {
        return (
          <FormattedMessage
            id="loadLog.commitment.created"
            values={{
              ...defaultValues,
              loadName,
              type: log.loading_commitment.commitment_type || null,
            }}
          />
        );
      }
      return <FormattedMessage id="loadLog.created" values={{ ...defaultValues, loadName }} />;
    }

    case LogEventTypes.updated: {
      const hasBarnId = !!log.additional_info?.barn_id;
      const hasSowUnitId = !!log.additional_info?.sow_unit_id;
      const hasPigGroupId = !!log.additional_info?.pig_group_id;
      const hasTruckingChanges = !!log.additional_info?.trucking_company_id;
      const hasTravelData =
        !!log.additional_info?.travel_seconds && !!log.additional_info?.travel_distance;

      if (hasTravelData) {
        return (
          <FormattedMessage
            id="loadLog.travelData.updated"
            values={{ ...defaultValues, loadName }}
          />
        );
      }

      if (hasSowUnitId) {
        const loadCommitment = load.commitments.find(({ id }) => id === log.loading_commitment?.id);
        return (
          <FormattedMessage
            id="loadLog.choseSowUnit"
            values={{
              ...defaultValues,
              loadName,
              sowUnitName: loadCommitment?.sow_unit?.name || null,
            }}
          />
        );
      }

      if (hasPigGroupId) {
        const loadCommitment = load.commitments.find(({ id }) => id === log.loading_commitment?.id);
        return (
          <FormattedMessage
            id="loadLog.matchedWithGroup"
            values={{
              ...defaultValues,
              loadName,
              groupName: loadCommitment?.pig_group?.name || null,
              farmName: loadCommitment?.farm?.name || null,
            }}
          />
        );
      }

      if (hasBarnId) {
        const loadCommitment = load.commitments.find(({ id }) => id === log.loading_commitment?.id);
        return (
          <FormattedMessage
            id="loadLog.matchedWithBarn"
            values={{
              ...defaultValues,
              loadName,
              barnName: loadCommitment?.barn?.name || null,
              farmName: loadCommitment?.farm?.name || null,
            }}
          />
        );
      }

      if (hasTruckingChanges) {
        const [prevId, currId] = log.additional_info?.trucking_company_id || [];
        if (!!prevId && !currId) {
          return <FormattedMessage id="loadLog.skippedTruckingCompany" values={defaultValues} />;
        }
        const companyName =
          currId === +(load.trucking_company?.id || '') ? load.trucking_company?.name : null;
        return (
          <FormattedMessage
            id="loadLog.addedTruckingCompany"
            values={{ ...defaultValues, companyName }}
          />
        );
      }
      return <FormattedMessage id="loadLog.updated" values={defaultValues} />;
    }

    case LogEventTypes.deleted:
      return <FormattedMessage id="loadLog.commitment.deleted" values={defaultValues} />;

    case LogEventTypes.unmatched: {
      if (log.loading_commitment) {
        return (
          <FormattedMessage
            id="loadLog.commitment.unmatched"
            values={{
              ...defaultValues,
              loadName,
              type: log.loading_commitment.commitment_type || null,
            }}
          />
        );
      }
      return <FormattedMessage id="loadLog.unmatched" values={{ ...defaultValues, loadName }} />;
    }

    case LogEventTypes.status_changed: {
      const status = log.additional_info?.status?.[1];

      if (log.loading_commitment) {
        return (
          <FormattedMessage
            id="loadLog.commitment.statusChanged"
            values={{
              ...defaultValues,
              loadName,
              status,
              i: (text: string) => <i className="text-capitalize">{text}</i>,
              type: log.loading_commitment.commitment_type || null,
            }}
          />
        );
      }

      if (status === 'delivered') {
        return (
          <FormattedMessage
            id="loadLog.statusChangedToDelivered"
            values={{ ...defaultValues, loadName }}
          />
        );
      }
      return (
        <FormattedMessage
          id="loadLog.statusChanged"
          values={{
            ...defaultValues,
            i: (text: string) => <i className="text-capitalize">{text}</i>,
            status: <FormattedMessage id={`status.${status}`} />,
          }}
        />
      );
    }

    case LogEventTypes.trucking_company_status_changed: {
      const status = log.additional_info?.trucking_company_status?.[1];
      return (
        <FormattedMessage
          id={`loadLog.truckingCompany.${status}`}
          values={{
            ...defaultValues,
          }}
        />
      );
    }
  }
};
