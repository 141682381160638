import React from 'react';
// components
import Link from 'components/Link';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as EveryPigLogo } from 'icons/everypig-logo.svg';
// utils
import cn from 'classnames';
// styles
import styles from './AuthorizationBox.module.scss';

const a = (msg: string) => (
  <Link
    onClick={() => window.open('https://www.everypig.co/privacy-policy')}
    to=""
    target="_blank"
    rel="noreferrer"
  >
    {msg}
  </Link>
);

const currentDate = new Date();
const year = currentDate.getFullYear();

const AuthorizationBox: React.FC = ({ children }) => (
  <div className={styles['layout']}>
    <div className={styles['link-wrapper']}>
      <EveryPigLogo className={cn(styles['everypig-logo'])} />
    </div>
    <div className={styles['children-wrapper']}>{children}</div>
    <div className={styles['privacy-policy']}>
      <FormattedMessage id="general.rightsReserved" values={{ a, year }} />
    </div>
  </div>
);

export default AuthorizationBox;
