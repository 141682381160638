import { combineReducers } from '@reduxjs/toolkit';
// reducers
import authReducer from './auth';
import filtersReducer from './filters';
import layoutReducer from './layout';
import loadReducer from './load';
import plansListReducer from './plansList';
import planWorksheetReducer from './planWorksheet';
import contractReducer from './contract';
import notificationsReducer from './notifications';

const rootReducer = combineReducers({
  auth: authReducer,
  contract: contractReducer,
  filters: filtersReducer,
  layout: layoutReducer,
  load: loadReducer,
  notifications: notificationsReducer,
  plansList: plansListReducer,
  planWorksheet: planWorksheetReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
