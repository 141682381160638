import React from 'react';
// components
import PortalTooltip from 'components/Tooltip/PortalTooltip';
// utils, hooks
import cn from 'classnames';
import usePortalTooltipState from 'utils/hooks/usePortalTooltipState';
// styles
import styles from './Tooltip.module.scss';

type TooltipProps<T> = {
  className?: string;
  isContentCentered?: boolean;
  maxWidth?: number;
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
  trigger: JSX.Element | string;
  tooltipContent: T;
  wrapperClassName?: string;
};

function Tooltip2<T>({
  className = '',
  isContentCentered = false,
  maxWidth,
  size = 'medium',
  style,
  tooltipContent,
  trigger,
  wrapperClassName = '',
}: TooltipProps<T>) {
  const { coords, isHovered, onMouseEnter, onMouseLeave } = usePortalTooltipState();

  return (
    <div
      style={style}
      className={cn(styles.tooltip2, { [wrapperClassName]: !!wrapperClassName })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {trigger}
      {isHovered && (
        <PortalTooltip
          className={cn({ [className]: !!className })}
          isCenteredHorizontally
          isContentCentered={isContentCentered}
          content={tooltipContent}
          size={size}
          {...coords}
          style={maxWidth ? { maxWidth } : undefined}
        />
      )}
    </div>
  );
}

export default Tooltip2;
